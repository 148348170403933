import { db } from "../firebase";
import { addDoc, collection, doc, setDoc, arrayUnion, increment } from "@firebase/firestore";

const fireIcon = require("../components/image/elements/fire.webp");
const iceIcon = require("../components/image/elements/ice.webp");
const imaginaryIcon = require("../components/image/elements/imaginary.webp");
const lightningIcon = require("../components/image/elements/lightning.webp");
const physicalIcon = require("../components/image/elements/physical.webp");
const quantumIcon = require("../components/image/elements/quantum.webp");
const windIcon = require("../components/image/elements/wind.webp");
const threeStarsIcon = require("../components/image/rarity/three-star.webp");
const fourStarsIcon = require("../components/image/rarity/four-star.webp");
const fiveStarsIcon = require("../components/image/rarity/five-star.webp");
const abundanceIcon = require("../components/image/path/abundance.webp");
const destructionIcon = require("../components/image/path/destruction.webp");
const eruditionIcon = require("../components/image/path/erudition.webp");
const harmonyIcon = require("../components/image/path/harmony.webp");
const huntIcon = require("../components/image/path/hunt.webp");
const nihilityIcon = require("../components/image/path/nihility.webp");
const preservationIcon = require("../components/image/path/preservation.webp");


export function calculateRelic(characterData, language, displayCharacterId)
{
  const translationLanguage = {
  "score": {
    "zh-cn": "分数",
    "zh-tw": "分數",
    "en-us": "Score"
  }
  };

  characterData.lightcone = lightConeRows.find(
    (element) => element.id === characterData.lc.i
  );

  switch (characterData.lc.r) {
    case 1:
      characterData.lc.rank_font_color = "#CCAF84";
      characterData.lc.rank_bg_color = "black";
      characterData.lc.rank = "I";
      break;
    case 2:
      characterData.lc.rank_font_color = "#CCAF84";
      characterData.lc.rank_bg_color = "black";
      characterData.lc.rank = "II";
      break;
    case 3:
      characterData.lc.rank_font_color = "#CCAF84";
      characterData.lc.rank_bg_color = "black";
      characterData.lc.rank = "III";
      break;
    case 4:
      characterData.lc.rank_font_color = "#CCAF84";
      characterData.lc.rank_bg_color = "black";
      characterData.lc.rank = "IV";
      break;
    case 5:
      characterData.lc.rank_font_color = "black";
      characterData.lc.rank_bg_color = "#FFD472";
      characterData.lc.rank = "V";
      break;
    default:
      break;
  }

  var maxLevel = 0;
  switch (characterData.a) {
    case 6:
      maxLevel = 80;
      break;
    case 5:
      maxLevel = 70;
      break;
    case 4:
      maxLevel = 60;
      break;
    case 3:
      maxLevel = 50;
      break;
    case 2:
      maxLevel = 40;
      break;
    case 1:
      maxLevel = 30;
      break;
    case 0:
      maxLevel = 20;
      break;
    default:
      break;
  }
  characterData.maxLevel = maxLevel;

  var lightConeProperties = [];
  if(typeof(characterData.lc.p) !== "undefined")
  {
    for(let i = 0; i < characterData.lc.p.length; i++)
    {
      switch(characterData.lc.p[i].n)
      {
        case "H":
        case "A":
        case "D":
          break;
        default:
          lightConeProperties.push({
            n: characterData.lc.p[i].n,
            a: characterData.lc.p[i].a * 100
          });
          break;
      }
    }
  }

  var propertyArray = [];
  for (let i = 0; i < characterData.p.length; i++) {
    var addition = characterData.p[i].a;
    var base = characterData.p[i].b;
    var total = 0;

    if(addition === null)
      addition = 0;
      
    if(base === null)
      base = 0;

    if(base < 1)
    {
      total = Math.floor((addition + base) * 1000) / 10
      addition = Math.floor(addition * 1000) / 10;
      base = Math.floor(base * 1000) / 10;
    }
    else{
      total = Math.floor(addition + base).toFixed(0);
      if(addition !== null)
      {
        addition = Math.floor(addition).toFixed(0);
      }

      if(base !== null)
      {
        base = Math.floor(base).toFixed(0);
      }
    }

    var currentProperty = {};

    if(lightConeProperties.length > 0)
    {
      var foundProperty = false;
      for(let j = 0; j < lightConeProperties.length; j++)
      {
        if(lightConeProperties[j].n === characterData.p[i].n)
        {
          currentProperty = {
            id: characterData.p[i].n,
            name: revertProperty(characterData.p[i].n, language),
            image: property.find((prop) => prop.id === characterData.p[i].n)
              .image,
            addition: (addition - lightConeProperties[j].a).toFixed(1),
            base: base,
            total: (total).toFixed(1),
            lightcone: lightConeProperties[j].a.toFixed(0)
          };
          foundProperty = true;
          break;
        }
      }

      if(foundProperty === false)
      {
        currentProperty = {
          id: characterData.p[i].n,
          name: revertProperty(characterData.p[i].n, language),
          image: property.find((prop) => prop.id === characterData.p[i].n)
            .image,
          addition: addition,
          base: base,
          total: total
        };
      }
    }
    else{
      currentProperty = {
        id: characterData.p[i].n,
        name: revertProperty(characterData.p[i].n, language),
        image: property.find((prop) => prop.id === characterData.p[i].n)
          .image,
        addition: addition,
        base: base,
        total: total
      };
    }

    if (currentProperty.id === "DB") {
      continue;
    }

    // Sub Value Arrangement
    if(currentProperty.base !== 0)
    {
      if((currentProperty.addition === "0" || currentProperty.addition === 0) && 
        typeof(currentProperty.lightcone) === "undefined")
      {
        // No need display
        currentProperty.arrangement = 0;
      }
      else
      {
        // Base + Addition? + Lightcone?
        currentProperty.arrangement = 1;
      }
    }
    else{
      if(typeof(currentProperty.lightcone) === "undefined")
      {
        // Display Addition Only
        currentProperty.arrangement = 2;
      }
      else
      {
        if(currentProperty.addition !== "0.0")
        {
          // Lightcone + Addition?
          currentProperty.arrangement = 3;
        }
        else{
          // Light Cone only
          currentProperty.arrangement = 4;
        }
      }
    }

    if(currentProperty.addition === null)
      currentProperty.addition = 0;

    propertyArray.push(currentProperty);
  }
  characterData.property = propertyArray;
  
  var totalScore = 0;
  for (let i = 0; i < characterData.rl.length; i++) {

    var scoreText = "";
    var score = 0;
    var currentRelic = relic.find(
      (prop) => prop.id === characterData.rl[i].i
    );

    // Add Colour and Set Effect to All Relics
    for(let j = 0; j < characterData.rs.length; j++)
    {
      if(currentRelic.code === characterData.rs[j].n)
      {
        characterData.rl[i].set = characterData.rs[j].d;
        characterData.rl[i].set_color = currentRelic.colour;
      }
    }

    characterData.rl[i].name = currentRelic.translation[language];
    characterData.rl[i].image = currentRelic.image;
    characterData.rl[i].m.name = revertProperty(characterData.rl[i].m.n, language);
    characterData.rl[i].m.image = property.find(
      (prop) => prop.id === characterData.rl[i].m.n
    ).image;

    if (isPropertyPercentage(characterData.rl[i].m.n) === true) {
      // Guessing 0.0001 Adjustment
      
      if(characterData.rl[i].m.n === "S")
      {
        characterData.rl[i].m.v =Number(characterData.rl[i].m.v).toFixed(1);
      }
      else
      {
        characterData.rl[i].m.v =
        (Math.floor((characterData.rl[i].m.v + 0.0001) * 1000) / 10).toFixed(1) + "%";
      }
    }
    else{
      characterData.rl[i].m.v = Math.floor(characterData.rl[i].m.v).toFixed(0);
    }
    
    // Ignore Main
    if(currentRelic.type === "Cavern Relics" && 
    (currentRelic.id.includes("_1") || currentRelic.id.includes("_0")))
    {
      var mainScore = characterData.rl[i].r / 10 * characterData.rl[i].l;

      scoreText += characterData.rl[i].m.name + " : " + mainScore.toFixed(1) + " " + translationLanguage.score[language] + "\n-----------------------\n";
      score += mainScore;
    }
    else if(typeof displayCharacterId.formula !== "undefined")
    {
      var rate = 0.2;
      if(displayCharacterId.formula.stats[characterData.rl[i].m.n] >= 1)
      {
        if(characterData.rl[i].m.n === "EG" || characterData.rl[i].m.n === "O" || String(characterData.rl[i].m.n).startsWith("DB"))
        {
          rate = 1;
        }
        else
        {
          rate = 1.6;
        }
      }

      var mainScore = rate * characterData.rl[i].r / 10 * characterData.rl[i].l;

        scoreText += characterData.rl[i].m.name + " : " + mainScore.toFixed(1) + " " + translationLanguage.score[language] + "\n--------------------\n";
        score += mainScore;
    }
    
    if(characterData.rl[i].m.n === "HR")
    {
      characterData.rl[i].m.n = "H";
    }
    else if(characterData.rl[i].m.n === "DR")
    {
      characterData.rl[i].m.n = "D";
    }
    else if(characterData.rl[i].m.n === "AR")
    {
      characterData.rl[i].m.n = "A";
    }

  var enhancementCount = 0;
  if(characterData.rl[i].l === 15)
  {
    enhancementCount = 5;
  }
  else if(characterData.rl[i].l >= 12)
  {
    enhancementCount = 4;
  }
  else if(characterData.rl[i].l >= 9)
  {
    enhancementCount = 3;
  }
  else if(characterData.rl[i].l >= 6)
  {
    enhancementCount = 2;
  }
  else if(characterData.rl[i].l >= 3)
  {
    enhancementCount = 1;
  }
  var updateEnhancement = false;
    for (let j = 0; j < characterData.rl[i].s.length; j++) {

      if(typeof characterData.rl[i].s[j].e !== "undefined")
      {
        updateEnhancement = true;
        enhancementCount =  enhancementCount - (characterData.rl[i].s[j].e) + 1;
      }
      characterData.rl[i].s[j].name = revertProperty(
        characterData.rl[i].s[j].n, language
      );

      characterData.rl[i].s[j].image = property.find(
        (prop) => prop.id === characterData.rl[i].s[j].n
      ).image;

      if (isPropertyPercentage(characterData.rl[i].s[j].n) === true) {

        if(characterData.rl[i].s[j].n === "S")
        {
          characterData.rl[i].s[j].v = Number(characterData.rl[i].s[j].v).toFixed(1);
        }
        else
        {
          characterData.rl[i].s[j].v =
            String((Math.floor(characterData.rl[i].s[j].v * 1000) / 10).toFixed(1)) + "%";
        }
      }
      else{
        characterData.rl[i].s[j].v = Math.floor(characterData.rl[i].s[j].v).toFixed(0);
      }
      
      if(typeof displayCharacterId.formula !== "undefined"){

        var subScore = (displayCharacterId.formula.stats[characterData.rl[i].s[j].n] * Number(characterData.rl[i].s[j].v.replace("%","")));
        scoreText += characterData.rl[i].s[j].name + " : " + (subScore).toFixed(1) + " " + translationLanguage.score[language] + "\n";
        if(subScore >= 10)
        {
          characterData.rl[i].s[j].score = "10";
        }
        else if(subScore >= 5)
        {
          characterData.rl[i].s[j].score = "5";
        }
        else{
          characterData.rl[i].s[j].score = "0";
        }
        score += subScore;
      }
      
      if(characterData.rl[i].s[j].n === "HR")
      {
        characterData.rl[i].s[j].n = "H";
      }
      else if(characterData.rl[i].s[j].n === "DR")
      {
        characterData.rl[i].s[j].n = "D";
      }
      else if(characterData.rl[i].s[j].n === "AR")
      {
        characterData.rl[i].s[j].n = "A";
      }
    }

    if(updateEnhancement)
    {
      if(enhancementCount === 1 && characterData.rl[i].s.length >= 1)
      {
        characterData.rl[i].s[characterData.rl[i].s.length - 1].e += 1;
      }
      else if(enhancementCount === 2 && characterData.rl[i].s.length >= 2)
      {
        characterData.rl[i].s[characterData.rl[i].s.length - 1].e += 1;
        characterData.rl[i].s[characterData.rl[i].s.length - 2].e += 1;
      }
      else if(enhancementCount === 3 && characterData.rl[i].s.length >= 3)
      {
        characterData.rl[i].s[characterData.rl[i].s.length - 1].e += 1;
        characterData.rl[i].s[characterData.rl[i].s.length - 2].e += 1;
        characterData.rl[i].s[characterData.rl[i].s.length - 3].e += 1;
      }
    }

    var grade = "E";
    if(Number(score.toFixed(1)) >= 52)
    {
      grade = "S+";
    }
    else if(Number(score.toFixed(1)) >= 42)
    {
      grade = "S";
    }
    else if(Number(score.toFixed(1)) >= 32)
    {
      grade = "A";
    }
    else if(Number(score.toFixed(1)) >= 25)
    {
      grade = "B";
    }
    else if(Number(score.toFixed(1)) >= 20)
    {
      grade = "C";
    }
    else if(Number(score.toFixed(1)) >= 15)
    {
      grade = "D";
    }
    totalScore += Number(score.toFixed(1));
    characterData.rl[i].grade = grade;
    characterData.rl[i].score = score.toFixed(1);
    characterData.rl[i].score_text = scoreText;
  }

  var newSkillList = [];
  for (let i = 0; i < characterData.s.length; i++) {
    switch (characterData.s[i].i) {
      case "b":
        characterData.s[i].image = displayCharacterId.basic_attack;
        break;
      case "s":
        characterData.s[i].image = displayCharacterId.skill;
        break;
      case "u":
        characterData.s[i].image = displayCharacterId.ultimate;
        break;
      case "t":
        characterData.s[i].image = displayCharacterId.talent;
        break;
      case "q":
        continue;
      default:
        characterData.s[i].image = property.find(id => id.id === characterData.s[i].i).image;
        break;
    }
    
    if(characterData.s[i].i === "HR")
    {
      characterData.s[i].i = "H";
    }
    else if(characterData.s[i].i === "DR")
    {
      characterData.s[i].i = "D";
    }
    else if(characterData.s[i].i === "AR")
    {
      characterData.s[i].i = "A";
    }

    newSkillList.push(characterData.s[i]);
  }
  
  var total_grade = "E";
  if(totalScore >= 312)
  {
    total_grade = "S+";
  }
  else if(totalScore >= 252)
  {
    total_grade = "S";
  }
  else if(totalScore >= 192)
  {
    total_grade = "A";
  }
  else if(totalScore >= 150)
  {
    total_grade = "B";
  }
  else if(totalScore >= 120)
  {
    total_grade = "C";
  }
  else if(totalScore >= 90)
  {
    total_grade = "D";
  }


  characterData.total_score = totalScore.toFixed(1);
  characterData.total_grade = total_grade;
  characterData.s = newSkillList;
  return characterData;
}

export function isPropertyPercentage(target) {
  var bool = false;
  switch (target) {
    case "AR":
    case "BE":
    case "CR":
    case "CD":
    case "DR":
    case "DB":
    case "DBF":
    case "DBI":
    case "DBM":
    case "DBP":
    case "DBL":
    case "DBQ":
    case "DBW":
    case "EG":
    case "EH":
    case "ER":
    case "HR":
    case "O":
    case "S":
      bool = true;
      break;
    default:
      break;
  }

  return bool;
}

const translation = {
  check_profile_1: {
    "en-us": "Do you want to check profile ",
    "zh-cn": "您想查前往查看 ",
    "zh-tw": "您想查前往查看",
  },
  check_profile_2: {
    "en-us": " ?",
    "zh-cn": " 吗？",
    "zh-tw": " 嗎？",
  },
};

export async function searchUID(validUid, alertNotification, language) {

  var world_level = 0;
  var character_list = [];
  await fetch(
    "https://asia-southeast1-hsr-statistics.cloudfunctions.net/app/api/search_v2/" +
      validUid,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then(async (data) => {
      try {
        if (typeof data.detail !== "undefined") {
          if (data.detail === "User not found" || data.detail === "Invalid uid") {
            alert("Invalid : User not found");
            return data.detail;
          }
          else if (data.detail === "Queue timeout") {
            
            if(window.location.href.includes("/" + language + "/profile/"  + validUid) === false &&
            language !== "undefined")
              window.location.href = "/" + language + "/profile/"  + validUid;

            return data.detail;
          }

          const ref = collection(db, "error");
          addDoc(ref, {
            code: "Insert Profile",
            data: data,
            error: "Insert Profile",
            created_at: new Date(),
          });


          if(alertNotification === true)
          {
            alert(
              "Honkai : Star Rail API might be updating.\n\nWe encountered some errors.\nPlease try again later."
            );
          }
        } else {
          world_level = data.player.world_level;
          character_list = data.characters;
          var uid = data.player.uid;
          var playerName = data.player.nickname;

          const v2Doc = doc(db, "user_raw_v2", uid);
          //await setDoc(v2Doc, data, { merge: true });

          var MazeGroupID = 0;
          if (
            typeof data.player.space_info.challenge_data !== "undefined" &&
            data.player.space_info.challenge_data !== null &&
            typeof data.player.space_info.challenge_data.maze_group_id !==
              "undefined" &&
            typeof data.player.space_info.challenge_data.maze_group_id ===
              "number"
          ) {
            MazeGroupID = data.player.space_info.challenge_data.maze_group_id;
          }

          var MazeGroupIndex = 0;
          if (
            typeof data.player.space_info.challenge_data !== "undefined" &&
            data.player.space_info.challenge_data !== null &&
            typeof data.player.space_info.challenge_data.maze_group_index !==
              "undefined" &&
            typeof data.player.space_info.challenge_data.maze_group_index ===
              "number"
          ) {
            MazeGroupIndex =
              data.player.space_info.challenge_data.maze_group_index;
          }

          var characterList = [];
          var characterIdList = [];

          // Character Version 1
          for (let i = 0; i < data.characters.length; i++) {
            var characterData = data.characters[i];
            const characterDoc = doc(
              db,
              "character/id/" + characterData.id,
              uid
            );

            var property = [];
            // n -> Code for Property - HP
            // a -> Addition - 1466
            // b -> Base - 1883

            for (let j = 0; j < characterData.attributes.length; j++) {
              property.push({
                n: convertProperty(
                  characterData.attributes[j].name,
                  characterData.attributes[j].display
                ),
                b: characterData.attributes[j].value,
                a: null,
              });
            }

            for (let j = 0; j < characterData.additions.length; j++) {
              var found = false;
              var currentAdditionPropertyCode = convertProperty(
                characterData.additions[j].name,
                characterData.additions[j].display
              );
              for (let k = 0; k < property.length; k++) {
                if (property[k].n === currentAdditionPropertyCode) {
                  found = true;
                  property[k].a = characterData.additions[j].value;
                }
              }

              if (found === false) {
                property.push({
                  n: convertProperty(
                    characterData.additions[j].name,
                    characterData.additions[j].display
                  ),
                  b: null,
                  a: characterData.additions[j].value,
                });
              }
            }

            var relics = [];
            for (let j = 0; j < characterData.relics.length; j++) {
              var relicCode = String(characterData.relics[j].icon)
                .split("/")
                [
                  String(characterData.relics[j].icon).split("/").length - 1
                ].split(".")[0];

              if (relicCode === "") {
                switch (characterData.relics[j].name) {
                  case "Taikiyan Laser Stadium":
                    relicCode = "309_0";
                    break;
                  case "Taikiyan's Arclight Race Track":
                    relicCode = "309_1";
                    break;
                  case "Insumousu's Whalefall Ship":
                    relicCode = "310_0";
                    break;
                  case "Insumousu's Frayed Hawser":
                    relicCode = "310_1";
                    break;
                  default:
                    break;
                }

                if (relicCode === "") {
                  const relicDoc = doc(
                    db,
                    "urgent",
                    "relic_" + characterData.relics[j].set_id
                  );
                  await setDoc(relicDoc, characterData.relics[j], {
                    merge: true,
                  });
                }
              }

              var mainProperty = convertProperty(
                characterData.relics[j].main_affix.name,
                characterData.relics[j].main_affix.display
              );

              var subProperties = [];
              for (
                let k = 0;
                k < characterData.relics[j].sub_affix.length;
                k++
              ) {
                var currentProperty = convertProperty(
                  characterData.relics[j].sub_affix[k].name,
                  characterData.relics[j].sub_affix[k].display
                );
                subProperties.push({
                  n: currentProperty,
                  v: characterData.relics[j].sub_affix[k].value,
                  e: characterData.relics[j].sub_affix[k].count,
                });
              }

              relics.push({
                i: relicCode,
                l: characterData.relics[j].level,
                r: characterData.relics[j].rarity,
                m: {
                  n: mainProperty,
                  v: characterData.relics[j].main_affix.value,
                },
                s: subProperties,
              });
            }

            var relic_set = [];
            for (let j = 0; j < characterData.relic_sets.length; j++) {
              var relicName = convertArtifact(
                characterData.relic_sets[j].name
              );

              var relicFound = false;
              for (let k = 0; k < relic_set.length; k++) {
                if (relic_set[k].n === relicName) {
                  relicFound = true;
                  if (relic_set[k].d < characterData.relic_sets[j].num) {
                    relic_set[k].d = characterData.relic_sets[j].num;
                  }
                }
              }
              if (relicFound === false) {
                relic_set.push({
                  d: characterData.relic_sets[j].num,
                  n: relicName,
                });
              }
            }

            var skills = [];
            var promotion = 0;
            // v2 API for skill tree
            for (let j = 0; j < character_list.length; j++) {
              // If same ID
              if (character_list[j].id === characterData.id) {
                promotion = character_list[j].promotion;

                for (
                  let k = 0;
                  k < character_list[j].skill_trees.length;
                  k++
                ) {
                  if (
                    character_list[j].skill_trees[k].icon.includes(
                      "basic_atk.png"
                    )
                  ) {
                    skills.push({
                      i: "b",
                      l: character_list[j].skill_trees[k].level,
                    });
                  } else if (
                    character_list[j].skill_trees[k].icon.includes(
                      "skill.png"
                    )
                  ) {
                    skills.push({
                      i: "s",
                      l: character_list[j].skill_trees[k].level,
                    });
                  } else if (
                    character_list[j].skill_trees[k].icon.includes(
                      "ultimate.png"
                    )
                  ) {
                    skills.push({
                      i: "u",
                      l: character_list[j].skill_trees[k].level,
                    });
                  } else if (
                    character_list[j].skill_trees[k].icon.includes(
                      "talent.png"
                    )
                  ) {
                    skills.push({
                      i: "t",
                      l: character_list[j].skill_trees[k].level,
                    });
                  } else if (
                    character_list[j].skill_trees[k].icon.includes(
                      "technique.png"
                    )
                  ) {
                    skills.push({
                      i: "q",
                      l: character_list[j].skill_trees[k].level,
                    });
                  } else {
                    // Skill Tree part
                    // bsutq
                    if (
                      character_list[j].skill_trees[k].icon.includes(
                        "skilltree"
                      ) === false && character_list[j].skill_trees[k].level !== 0
                    ) {
                      var skillFound = false;
                      var skillName = convertProperty(
                        character_list[j].skill_trees[k].icon.split("/")[
                          character_list[j].skill_trees[k].icon.split("/")
                            .length - 1
                        ],
                        "%"
                      );
                      for (
                        let innerIndex = 0;
                        innerIndex < skills.length;
                        innerIndex++
                      ) {
                        if (skillName === skills[innerIndex].i) {
                          skills[innerIndex].l += 1;
                          skillFound = true;
                          break;
                        }
                      }

                      if (skillFound === false) {
                        skills.push({
                          i: skillName,
                          l: 1,
                        });
                      }
                    }
                  }
                }
                break;
              }
            }

            var lightCone = {
              i: "",
            };

            if (
              typeof characterData.light_cone !== "undefined" &&
              characterData.light_cone !== null &&
              typeof characterData.light_cone.icon !== "undefined" &&
              characterData.light_cone.icon !== null
            ) {
              var lightconeId = String(characterData.light_cone.icon)
                .split("/")
                [
                  String(characterData.light_cone.icon).split("/").length - 1
                ].split(".")[0];

              var lightConeProperties = [];
              for (
                let j = 0;
                j < characterData.light_cone.attributes.length;
                j++
              ) {
                lightConeProperties.push({
                  n: convertProperty(
                    characterData.light_cone.attributes[j].name,
                    characterData.light_cone.attributes[j].display
                  ),
                  b: characterData.light_cone.attributes[j].value,
                });
              }

              for (
                let j = 0;
                j < characterData.light_cone.properties.length;
                j++
              ) {
                var lcFound = false;
                var currentPropertyCode = convertProperty(
                  characterData.light_cone.properties[j].name,
                  characterData.light_cone.properties[j].display
                );
                for (let k = 0; k < lightConeProperties.length; k++) {
                  if (lightConeProperties[k].n === currentPropertyCode) {
                    lcFound = true;
                    lightConeProperties[k].a =
                      characterData.light_cone.properties[j].value;
                  }
                }

                if (lcFound === false) {
                  lightConeProperties.push({
                    n: convertProperty(
                      characterData.light_cone.properties[j].name,
                      characterData.light_cone.properties[j].display
                    ),
                    a: characterData.light_cone.properties[j].value,
                  });
                }
              }

              lightCone = {
                i: lightconeId,
                l: characterData.light_cone.level,
                r: characterData.light_cone.rank,
                p: lightConeProperties,
              };
            }

            characterIdList.push(characterData.id);
            characterList.push({
              id: characterData.id,
              l: characterData.level,
              lc: lightCone,
              p: property,
              r: characterData.rank,
              rl: relics,
              rs: relic_set,
              s: skills,
              a: promotion,
              d: new Date(),
            });
            await setDoc(
              characterDoc,
              {
                u: uid,
                n: playerName,
                l: characterData.level,
                lc: lightCone,
                p: property,
                r: characterData.rank,
                rl: relics,
                rs: relic_set,
                s: skills,
                a: promotion,
                d: new Date(),
              },
              { merge: true }
            );
          }

          // Character Version 2
          for (let i = 0; i < data.characters.length; i++) {
            var characterData = data.characters[i];
            const characterDoc = doc(
              db,
              "character-two/id/" + characterData.id,
              uid
            );

            var property = [];
            // n -> Code for Property - HP
            // a -> Addition - 1466
            // b -> Base - 1883

            for (let j = 0; j < characterData.attributes.length; j++) {
              property.push({
                n: convertProperty(
                  characterData.attributes[j].name,
                  characterData.attributes[j].display
                ),
                b: characterData.attributes[j].value,
                a: null,
              });
            }

            for (let j = 0; j < characterData.additions.length; j++) {
              var found = false;
              var currentAdditionPropertyCode = convertProperty(
                characterData.additions[j].name,
                characterData.additions[j].display
              );
              for (let k = 0; k < property.length; k++) {
                if (property[k].n === currentAdditionPropertyCode) {
                  found = true;
                  property[k].a = characterData.additions[j].value;
                }
              }

              if (found === false) {
                property.push({
                  n: convertProperty(
                    characterData.additions[j].name,
                    characterData.additions[j].display
                  ),
                  b: null,
                  a: characterData.additions[j].value,
                });
              }
            }

            var relics = [];
            for (let j = 0; j < characterData.relics.length; j++) {
              var relicCode = String(characterData.relics[j].icon)
                .split("/")
                [
                  String(characterData.relics[j].icon).split("/").length - 1
                ].split(".")[0];

              if (relicCode === "") {
                switch (characterData.relics[j].name) {
                  case "Taikiyan Laser Stadium":
                    relicCode = "309_0";
                    break;
                  case "Taikiyan's Arclight Race Track":
                    relicCode = "309_1";
                    break;
                  case "Insumousu's Whalefall Ship":
                    relicCode = "310_0";
                    break;
                  case "Insumousu's Frayed Hawser":
                    relicCode = "310_1";
                    break;
                  default:
                    break;
                }

                if (relicCode === "") {
                  const relicDoc = doc(
                    db,
                    "urgent",
                    "relic_" + characterData.relics[j].set_id
                  );
                  await setDoc(relicDoc, characterData.relics[j], {
                    merge: true,
                  });
                }
              }

              var mainProperty = convertProperty(
                characterData.relics[j].main_affix.name,
                characterData.relics[j].main_affix.display
              );

              var subProperties = [];
              for (
                let k = 0;
                k < characterData.relics[j].sub_affix.length;
                k++
              ) {
                var currentProperty = convertProperty(
                  characterData.relics[j].sub_affix[k].name,
                  characterData.relics[j].sub_affix[k].display
                );
                subProperties.push({
                  n: currentProperty,
                  v: characterData.relics[j].sub_affix[k].value,
                  e: characterData.relics[j].sub_affix[k].count,
                });
              }

              relics.push({
                i: relicCode,
                l: characterData.relics[j].level,
                r: characterData.relics[j].rarity,
                m: {
                  n: mainProperty,
                  v: characterData.relics[j].main_affix.value,
                },
                s: subProperties,
              });
            }

            var relic_set = [];
            for (let j = 0; j < characterData.relic_sets.length; j++) {
              var relicName = convertArtifact(
                characterData.relic_sets[j].name
              );

              var relicFound = false;
              for (let k = 0; k < relic_set.length; k++) {
                if (relic_set[k].n === relicName) {
                  relicFound = true;
                  if (relic_set[k].d < characterData.relic_sets[j].num) {
                    relic_set[k].d = characterData.relic_sets[j].num;
                  }
                }
              }
              if (relicFound === false) {
                relic_set.push({
                  d: characterData.relic_sets[j].num,
                  n: relicName,
                });
              }
            }

            var skills = [];
            var promotion = 0;
            // v2 API for skill tree
            for (let j = 0; j < character_list.length; j++) {
              // If same ID
              if (character_list[j].id === characterData.id) {
                promotion = character_list[j].promotion;

                for (
                  let k = 0;
                  k < character_list[j].skill_trees.length;
                  k++
                ) {
                  if (
                    character_list[j].skill_trees[k].icon.includes(
                      "basic_atk.png"
                    )
                  ) {
                    skills.push({
                      i: "b",
                      l: character_list[j].skill_trees[k].level,
                    });
                  } else if (
                    character_list[j].skill_trees[k].icon.includes(
                      "skill.png"
                    )
                  ) {
                    skills.push({
                      i: "s",
                      l: character_list[j].skill_trees[k].level,
                    });
                  } else if (
                    character_list[j].skill_trees[k].icon.includes(
                      "ultimate.png"
                    )
                  ) {
                    skills.push({
                      i: "u",
                      l: character_list[j].skill_trees[k].level,
                    });
                  } else if (
                    character_list[j].skill_trees[k].icon.includes(
                      "talent.png"
                    )
                  ) {
                    skills.push({
                      i: "t",
                      l: character_list[j].skill_trees[k].level,
                    });
                  } else if (
                    character_list[j].skill_trees[k].icon.includes(
                      "technique.png"
                    )
                  ) {
                    skills.push({
                      i: "q",
                      l: character_list[j].skill_trees[k].level,
                    });
                  } else {
                    // Skill Tree part
                    // bsutq
                    if (
                      character_list[j].skill_trees[k].icon.includes(
                        "skilltree"
                      ) === false && character_list[j].skill_trees[k].level !== 0
                    ) {
                      var skillFound = false;
                      var skillName = convertProperty(
                        character_list[j].skill_trees[k].icon.split("/")[
                          character_list[j].skill_trees[k].icon.split("/")
                            .length - 1
                        ],
                        "%"
                      );
                      for (
                        let innerIndex = 0;
                        innerIndex < skills.length;
                        innerIndex++
                      ) {
                        if (skillName === skills[innerIndex].i) {
                          skills[innerIndex].l += 1;
                          skillFound = true;
                          break;
                        }
                      }

                      if (skillFound === false) {
                        skills.push({
                          i: skillName,
                          l: 1,
                        });
                      }
                    }
                  }
                }
                break;
              }
            }

            var lightCone = {
              i: "",
            };

            if (
              typeof characterData.light_cone !== "undefined" &&
              characterData.light_cone !== null &&
              typeof characterData.light_cone.icon !== "undefined" &&
              characterData.light_cone.icon !== null
            ) {
              var lightconeId = String(characterData.light_cone.icon)
                .split("/")
                [
                  String(characterData.light_cone.icon).split("/").length - 1
                ].split(".")[0];

              var lightConeProperties = [];
              for (
                let j = 0;
                j < characterData.light_cone.attributes.length;
                j++
              ) {
                lightConeProperties.push({
                  n: convertProperty(
                    characterData.light_cone.attributes[j].name,
                    characterData.light_cone.attributes[j].display
                  ),
                  b: characterData.light_cone.attributes[j].value,
                });
              }

              for (
                let j = 0;
                j < characterData.light_cone.properties.length;
                j++
              ) {
                var lcFound = false;
                var currentPropertyCode = convertProperty(
                  characterData.light_cone.properties[j].name,
                  characterData.light_cone.properties[j].display
                );
                for (let k = 0; k < lightConeProperties.length; k++) {
                  if (lightConeProperties[k].n === currentPropertyCode) {
                    lcFound = true;
                    lightConeProperties[k].a =
                      characterData.light_cone.properties[j].value;
                  }
                }

                if (lcFound === false) {
                  lightConeProperties.push({
                    n: convertProperty(
                      characterData.light_cone.properties[j].name,
                      characterData.light_cone.properties[j].display
                    ),
                    a: characterData.light_cone.properties[j].value,
                  });
                }
              }

              lightCone = {
                i: lightconeId,
                l: characterData.light_cone.level,
                r: characterData.light_cone.rank,
                p: lightConeProperties,
              };
            }

            characterIdList.push(characterData.id);
            characterList.push({
              id: characterData.id,
              l: characterData.level,
              lc: lightCone,
              p: property,
              r: characterData.rank,
              rl: relics,
              rs: relic_set,
              s: skills,
              a: promotion,
              d: new Date(),
            });
            await setDoc(
              characterDoc,
              {
                u: uid,
                n: playerName,
                l: characterData.level,
                lc: lightCone,
                p: property,
                r: characterData.rank,
                rl: relics,
                rs: relic_set,
                s: skills,
                a: promotion,
                d: new Date(),
              },
              { merge: true }
            );

            const refreshCharacterDoc = doc(
              db,
              "report-refresh",
              characterData.id
            );

            await setDoc(
              refreshCharacterDoc,
              {
                count: increment(1),
                last_updated: new Date()
              },
              { merge: true }
            );
          }
          
          const profileDoc = doc(db, "user", data.player.uid);
          await setDoc(
            profileDoc,
            {
              i: String(data.player.avatar.icon)
                .split("/")
                [String(data.player.avatar.icon).split("/").length - 1].split(
                  "."
                )[0],
              w: world_level,
              u: uid,
              l: data.player.level,
              n: playerName,
              s: data.player.signature,
              ac: data.player.space_info.achievement_count,
              cc: data.player.space_info.avatar_count,
              m: MazeGroupID,
              c: MazeGroupIndex,
              cl: characterList,
              ci: arrayUnion(...characterIdList),
              d: new Date(),
            },
            { merge: true }
          );

          var storedProfiles = [];
          if (localStorage.getItem("profiles") !== "undefined")
            storedProfiles = JSON.parse(localStorage.getItem("profiles"));

          var inserted = false;
          if (storedProfiles === null) {
            storedProfiles = [];
          } else {
            for (let i = 0; i < storedProfiles.length; i++) {
              if (String(storedProfiles[i]).split(" ")[0].includes(uid)) {
                storedProfiles[i] = uid + " " + playerName;
                inserted = true;
              }
            }
          }

          if (inserted === false) 
            storedProfiles.push(uid + " " + playerName);

          localStorage.setItem("profiles", JSON.stringify(storedProfiles));

          if(alertNotification === true)
          {
            if (
              window.confirm(
                translation.check_profile_1[language] +
                uid +
                translation.check_profile_2[language]
              )
            ) {
              window.location.href = "/" + language + "/profile/"  + uid;
            }
              }
        }
        
        return data;
      } catch (e) {
        
        if (data.detail === "User not found" || data.detail === "Invalid uid") {
          alert("Invalid : User not found");
          return data.detail;
        }
        else if (data.detail.includes("Queue timeout")) {
          if(window.location.href.includes("/" + language + "/profile/"  + uid) === false &&
          language !== "undefined")
            window.location.href = "/" + language + "/profile/"  + uid;
          return data.detail;
        }

        alert("We've encountered some errors.\nPlease try again later.");
        const ref = collection(db, "error");
        addDoc(ref, {
          code: "Insert Profile",
          data: data,
          error: e.message,
          created_at: new Date(),
        });
        return data;
      }
    });
}

export function revertProperty(target, language) {
  var name = "";
  switch (target) {
    case "A":
    case "AR":
      if(language === "zh-cn")
      {
        name = "攻击力";
      }
      else
      if(language === "zh-tw")
      {
        name = "攻擊力";
      }
      else{
        name = "ATK";
      }
      break;
    case "BE":
      if(language === "zh-cn")
      {
        name = "击破特攻";
      }
      else
      if(language === "zh-tw")
      {
        name = "擊破特攻";
      }
      else{
        name = "Break EFF";
      }
      break;
    case "CR":
      if(language === "zh-cn")
      {
        name = "暴击率";
      }
      else
      if(language === "zh-tw")
      {
        name = "暴擊率";
      }
      else{
        name = "CRIT Rate";
      }
      break;
    case "CD":
      if(language === "zh-cn")
      {
        name = "暴击伤害";
      }
      else
      if(language === "zh-tw")
      {
        name = "暴擊傷害";
      }
      else{
        name = "CRIT DMG";
      }
      break;
    case "D":
    case "DR":
      if(language === "zh-cn")
      {
        name = "防御力";
      }
      else
      if(language === "zh-tw")
      {
        name = "防禦力";
      }
      else{
        name = "DEF";
      }
      break;
    case "DB":
      name = "DMG Boost";
      break;
    case "DBF":
      if(language === "zh-cn")
      {
        name = "火属性";
      }
      else
      if(language === "zh-tw")
      {
        name = "火屬性";
      }
      else{
        name = "Fire";
      }
      break;
    case "DBI":
      if(language === "zh-cn")
      {
        name = "冰属性";
      }
      else
      if(language === "zh-tw")
      {
        name = "冰屬性";
      }
      else{
        name = "Ice";
      }
      break;
    case "DBM":
      if(language === "zh-cn")
      {
        name = "虚数属性";
      }
      else
      if(language === "zh-tw")
      {
        name = "虛數屬性";
      }
      else{
        name = "Imaginary";
      }
      break;
    case "DBP":
      if(language === "zh-cn")
      {
        name = "物理属性";
      }
      else
      if(language === "zh-tw")
      {
        name = "物理屬性";
      }
      else{
        name = "Physical";
      }
      break;
    case "DBL":
      if(language === "zh-cn")
      {
        name = "雷属性";
      }
      else
      if(language === "zh-tw")
      {
        name = "雷屬性";
      }
      else{
        name = "Lightning";
      }
      break;
    case "DBQ":
      if(language === "zh-cn")
      {
        name = "量子属性";
      }
      else
      if(language === "zh-tw")
      {
        name = "量子屬性";
      }
      else{
        name = "Quantum";
      }
      break;
    case "DBW":
      if(language === "zh-cn")
      {
        name = "风属性";
      }
      else
      if(language === "zh-tw")
      {
        name = "風屬性";
      }
      else{
        name = "Wind";
      }
      break;
    case "E":
      if(language === "zh-cn")
      {
        name = "能量上限";
      }
      else
      if(language === "zh-tw")
      {
        name = "能量上限";
      }
      else{
        name = "Energy";
      }
      break;
    case "EG":
      if(language === "zh-cn")
      {
        name = "能量恢复";
      }
      else
      if(language === "zh-tw")
      {
        name = "能量恢復";
      }
      else{
        name = "Energy REG";
      }
      break;
    case "EH":
      if(language === "zh-cn")
      {
        name = "效果命中";
      }
      else
      if(language === "zh-tw")
      {
        name = "效果命中";
      }
      else{
        name = "Effect Hit";
      }
      break;
    case "ER":
      if(language === "zh-cn")
      {
        name = "效果抵抗";
      }
      else
      if(language === "zh-tw")
      {
        name = "效果抵抗";
      }
      else{
        name = "Effect RES";
      }
      break;
    case "H":
    case "HR":
    case "HP":
      if(language === "zh-cn")
      {
        name = "生命值";
      }
      else
      if(language === "zh-tw")
      {
        name = "生命值";
      }
      else{
        name = "HP";
      }
      break;
    case "O":
      if(language === "zh-cn")
      {
        name = "治疗量";
      }
      else
      if(language === "zh-tw")
      {
        name = "治療量";
      }
      else{
        name = "Healing";
      }
      break;
    case "S":
      if(language === "zh-cn")
      {
        name = "速度";
      }
      else
      if(language === "zh-tw")
      {
        name = "速度";
      }
      else{
        name = "SPD";
      }
      break;
    default:
      break;
  }

  return name;
}

export function convertProperty(target, value) {
  var property = "";
  switch (target) {
    case "IconAttack.png":
    case "ATK":
      if (value.includes("%")) {
        property = "AR";
      } else {
        property = "A";
      }
      break;
    case "IconBreakUp.png":
    case "Break Effect":
      property = "BE";
      break;
    case "IconCriticalChance.png":
    case "CRIT Rate":
      property = "CR";
      break;
    case "IconCriticalDamage.png":
    case "CRIT DMG":
      property = "CD";
      break;
    case "IconDefence.png":
    case "DEF":
      if (value.includes("%")) {
        property = "DR";
      } else {
        property = "D";
      }
      break;
    case "DMG Boost":
      property = "DB";
      break;
    case "IconFireAddedRatio.png":
    case "Fire DMG Boost":
      property = "DBF";
      break;
    case "IconIceAddedRatio.png":
    case "Ice DMG Boost":
      property = "DBI";
      break;
    case "IconImaginaryAddedRatio.png":
    case "Imaginary DMG Boost":
      property = "DBM";
      break;
    case "IconPhysicalAddedRatio.png":
    case "Physical DMG Boost":
      property = "DBP";
      break;
    case "IconThunderAddedRatio.png":
    case "Lightning DMG Boost":
      property = "DBL";
      break;
    case "IconQuantumAddedRatio.png":
    case "Quantum DMG Boost":
      property = "DBQ";
      break;
    case "IconWindAddedRatio.png":
    case "Wind DMG Boost":
      property = "DBW";
      break;
    case "Energy":
      property = "E";
      break;
    case "IconEnergyRecovery.png":
    case "Energy Regeneration Rate":
      property = "EG";
      break;
    case "IconStatusProbability.png":
    case "Effect Hit Rate":
      property = "EH";
      break;
    case "IconStatusResistance.png":
    case "Effect RES":
      property = "ER";
      break;
    case "IconMaxHP.png":
    case "HP":
      if (value.includes("%")) {
        property = "HR";
      } else {
        property = "H";
      }
      break;
    case "Outgoing Healing Boost":
      property = "O";
      break;
    case "IconSpeed.png":
    case "SPD":
      property = "S";
      break;
    default:
      property = target;
      const ref = collection(db, "urgent");
      addDoc(ref, {
        error: target,
        code: "Unknown Property",
        created_at: new Date(),
      });
      break;
  }

  return property;
}

export function revertArtifact(target) {
  var relicName = "";
  switch (target) {
    case "AGD":
      relicName = "The Ashblazing Grand Duke";
      break;
    case "BA":
      relicName = "Belobog of the Architects";
      break;
    case "BST":
      relicName = "Band of Sizzling Thunder";
      break;
    case "CD":
      relicName = "Celestial Differentiator";
      break;
    case "CSB":
      relicName = "Champion of Streetwise Boxing";
      break;
    case "DDRW":
      relicName = "Duran, Dynasty of Running Wolves";
      break;
    case "ETL":
      relicName = "Eagle of Twilight Line";
      break;
    case "FA":
      relicName = "Fleet of the Ageless";
      break;
    case "FF":
      relicName = "Firmament Frontline: Glamoth";
      break;
    case "FKL":
      relicName = "Forge of the Kalpagni Lantern";
      break;
    case "FLF":
      relicName = "Firesmith of Lava-Forging";
      break;
    case "GBS":
      relicName = "Genius of Brilliant Stars";
      break;
    case "GWS":
      relicName = "Guard of Wuthering Snow";
      break;
    case "HGF":
      relicName = "Hunter of Glacial Forest";
      break;
    case "IS":
      relicName = "Inert Salsotto";
      break;
    case "IGT":
      relicName = "Izumo Gensei and Takama Divine Realm";
      break;
    case "KPP":
      relicName = "Knight of Purity Palace";
      break;
    case "LD":
      relicName = "Longevous Disciple";
      break;
    case "LSS":
      relicName = "Lushaka, the Sunken Seas";
      break;
    case "MWW":
      relicName = "Musketeer of Wild Wheat";
      break;
    case "MTH":
      relicName = "Messenger Traversing Hackerspace";
      break;
    case "PDC":
      relicName = "Prisoner in Deep Confinement";
      break;
    case "PDDW":
      relicName = "Pioneer Diver of Dead Waters";
      break;
    case "PGCE":
      relicName = "Pan-Galactic Commercial Enterprise";
      break;
    case "PL":
      relicName = "Penacony, Land of the Dreams";
      break;
    case "PWC":
      relicName = "Passerby of Wandering Cloud";
      break;
    case "SUD":
      relicName = "Sigonia, the Unclaimed Desolation";
      break;
    case "SSS":
      relicName = "Space Sealing Station";
      break;
    case "SV":
      relicName = "Sprightly Vonwacq";
      break;
    case "TKB":
      relicName = "Talia: Kingdom of Banditry";
      break;
    case "TSM":
      relicName = "Thief of Shooting Meteor";
      break;
    case "TWBP":
      relicName = "The Wondrous BananAmusement Park";
      break;
    case "WBD":
      relicName = "Wastelander of Banditry Desert";
      break;
    case "WMDM":
      relicName = "Watchmaker, Master of Dream Machinations";
      break;
    default:
      relicName = target;
      break;
  }

  return relicName;
}

export function convertArtifact(target) {
  var relicName = "";
  switch (target) {
    
    case "The Ashblazing Grand Duke":
      relicName = "AGD";
      break;
    case "Belobog of the Architects":
      relicName = "BA";
      break;
    case "Broken Keel":
      relicName = "BK";
      break;
    case "Band of Sizzling Thunder":
      relicName = "BST";
      break;
    case "Celestial Differentiator":
      relicName = "CD";
      break;
    case "Champion of Streetwise Boxing":
      relicName = "CSB";
      break;
    case  "Duran, Dynasty of Running Wolves":
      relicName = "DDRW";
      break;
    case "Eagle of Twilight Line":
      relicName = "ETL";
      break;
    case "Fleet of the Ageless":
      relicName = "FA";
      break;
    case "Firmament Frontline: Glamoth":
      relicName = "FF";
      break;
    case "Forge of the Kalpagni Lantern":
      relicName = "FKL";
      break;
    case "Firesmith of Lava-Forging":
      relicName = "FLF";
      break;
    case "Genius of Brilliant Stars":
      relicName = "GBS";
      break;
    case "Guard of Wuthering Snow":
      relicName = "GWS";
      break;
    case "Hunter of Glacial Forest":
      relicName = "HGF";
      break;
    case "Inert Salsotto":
      relicName = "IS";
      break;
    case "Izumo Gensei and Takama Divine Realm":
      relicName = "IGT";
      break;
    case "Knight of Purity Palace":
      relicName = "KPP";
      break;    
    case "Longevous Disciple":
      relicName = "LD";
      break;
    case "Lushaka, the Sunken Seas":
      relicName = "LSS";
      break;
    case "Messenger Traversing Hackerspace":
      relicName = "MTH";
      break;
    case "Musketeer of Wild Wheat":
      relicName = "MWW";
      break;
    case "Prisoner in Deep Confinement":
      relicName = "PDC";
      break;
    case "Pioneer Diver of Dead Waters":
      relicName = "PDDW";
      break;
    case "Pan-Cosmic Commercial Enterprise":
    case "Pan-Galactic Commercial Enterprise":
      relicName = "PGCE";
      break;
    case "Penacony, Land of the Dreams":
      relicName = "PL";
      break;
    case "Passerby of Wandering Cloud":
      relicName = "PWC";
      break;
    case "Sigonia, the Unclaimed Desolation":
      relicName = "SUD";
      break;
    case "Rutilant Arena":
      relicName = "RA";
      break;
    case "Sprightly Vonwacq":
      relicName = "SV";
      break;
    case "Space Sealing Station":
      relicName = "SSS";
      break;
    case "Talia: Kingdom of Banditry":
      relicName = "TKB";
      break;
    case "Thief of Shooting Meteor":
      relicName = "TSM";
      break;
    case "The Wondrous BananAmusement Park":
      relicName = "TWBP";
      break;
    case "Wastelander of Banditry Desert":
      relicName = "WBD";
      break;
    case "Watchmaker, Master of Dream Machinations":
      relicName = "WMDM";
      break;
    default:
      relicName = target;
      const ref = collection(db, "urgent");
      addDoc(ref, {
        error: target,
        code: "Utils / Unknown Relic",
        created_at: new Date(),
      });
      break;
  }

  return relicName;
}

export const itemTypes = [
  {
    id: "lightcone-ascension",
    name: {
      "en-us": "Lightcone Ascension",
      "zh-cn": "光锥晋阶",
      "zh-tw": "光錐晉階"
    }
  },
  {
    id: "character-ascension",
    name: {
      "en-us": "Character Ascension",
      "zh-cn": "角色晋阶",
      "zh-tw": "角色晉階"
    }
  },
  {
    id: "skill-upgrade",
    name: {
      "en-us": "Skill Upgrade",
      "zh-cn": "战技养成",
      "zh-tw": "戰技養成"
    }
  },
  {
    id: "traces-leveling",
    name: {
      "en-us": "Traces Leveling Material",
      "zh-cn": "行迹升级",
      "zh-tw": "行跡升級"
    }
  },
];


export const domainTypes = [
  {
    id: "echo-of-war",
    name: {
      "en-us": "Echo of War",
      "zh-cn": "历战余响",
      "zh-tw": "歷戰餘響"
    },
    icon: require("../components/image/domains/echo-of-war.webp"),
  },
  {
    id: "stagnant-shadow",
    name: {
      "en-us": "Stagnant Shadow",
      "zh-cn": "凝滞虚影",
      "zh-tw": "凝滯虛影"
    },
    icon: require("../components/image/domains/stagnant-shadow.webp"),
  },
  {
    id: "calyx-golden",
    name: {
      "en-us": "Calyx (Golden)",
      "zh-cn": "拟造花萼（金）",
      "zh-tw": "擬造花萼（金）"
    },
    icon: require("../components/image/domains/calyx-golden.webp"),
  },
  {
    id: "calyx-crimson",
    name: {
      "en-us": "Calyx (Crimson)",
      "zh-cn": "拟造花萼（赤）",
      "zh-tw": "擬造花萼（赤）"
    },
    icon: require("../components/image/domains/calyx-crimson.webp"),
  },
]

export const domains = [
  {
    id: "calyx-crimson-bud-of-abundance",
    type: domainTypes.filter(id => id.id === "calyx-crimson")[0],
    name: {
      "en-us": "Bud of Abundance",
      "zh-cn": "丰饶之蕾",
      "zh-tw": "豐饒之蕾"
    },
    icon: abundanceIcon,
  },
  {
    id: "calyx-crimson-bud-of-destruction",
    type: domainTypes.filter(id => id.id === "calyx-crimson")[0],
    name: {
      "en-us": "Bud of Destruction",
      "zh-cn": "毁灭之蕾",
      "zh-tw": "毀滅之蕾"
    },
    icon: destructionIcon,
  },
  {
    id: "calyx-crimson-bud-of-erudition",
    type: domainTypes.filter(id => id.id === "calyx-crimson")[0],
    name: {
      "en-us": "Bud of Erudition",
      "zh-cn": "智识之蕾",
      "zh-tw": "智識之蕾"
    },
    icon: eruditionIcon,
  },
  {
    id: "calyx-crimson-bud-of-harmony",
    type: domainTypes.filter(id => id.id === "calyx-crimson")[0],
    name: {
      "en-us": "Bud of Harmony",
      "zh-cn": "同谐之蕾",
      "zh-tw": "同諧之蕾"
    },
    icon: harmonyIcon,
  },
  {
    id: "calyx-crimson-bud-of-hunt",
    type: domainTypes.filter(id => id.id === "calyx-crimson")[0],
    name: {
      "en-us": "Bud of Hunt",
      "zh-cn": "巡猎之蕾",
      "zh-tw": "巡獵之蕾"
    },
    icon: huntIcon,
  },
  {
    id: "calyx-crimson-bud-of-nihility",
    type: domainTypes.filter(id => id.id === "calyx-crimson")[0],
    name: {
      "en-us": "Bud of Nihility",
      "zh-cn": "虚无之蕾",
      "zh-tw": "虛無之蕾"
    },
    icon: nihilityIcon,
  },
  {
    id: "calyx-crimson-bud-of-preservation",
    type: domainTypes.filter(id => id.id === "calyx-crimson")[0],
    name: {
      "en-us": "Bud of Preservation",
      "zh-cn": "存护之蕾",
      "zh-tw": "存護之蕾"
    },
    icon: preservationIcon,
  },
  {
    id: "calyx-golden-bud-of-aether",
    type: domainTypes.filter(id => id.id === "calyx-golden")[0],
    name: {
      "en-us": "Bud of Aether",
      "zh-cn": "以太之蕾",
      "zh-tw": "以太之蕾"
    },
    icon: require("../components/image/domains/calyx-golden-bud-of-aether.webp"),
  },
  {
    id: "calyx-golden-bud-of-memories",
    type: domainTypes.filter(id => id.id === "calyx-golden")[0],
    name: {
      "en-us": "Bud of Memories",
      "zh-cn": "回忆之蕾",
      "zh-tw": "回憶之蕾"
    },
    icon: require("../components/image/domains/calyx-golden-bud-of-memories.webp"),
  },
  {
    id: "calyx-golden-bud-of-treasures",
    type: domainTypes.filter(id => id.id === "calyx-golden")[0],
    name: {
      "en-us": "Bud of Treasures",
      "zh-cn": "藏珍之蕾",
      "zh-tw": "藏珍之蕾"
    },
    icon: require("../components/image/domains/calyx-golden-bud-of-treasures.webp"),
  },
  {
    id: "echo-of-war-divine-seed",
    type: domainTypes.filter(id => id.id === "echo-of-war")[0],
    name: {
      "en-us": "Phantylia the Undying",
      "zh-cn": "不死神实 • 幻胧",
      "zh-tw": "不死神實 • 幻朧"
    },
    icon: require("../components/image/domains/echo-of-war-divine-seed.webp"),
  },
  {
    id: "echo-of-war-end-of-the-eternal-freeze",
    type: domainTypes.filter(id => id.id === "echo-of-war")[0],
    name: {
      "en-us": "Cocolia, Mother of Deception",
      "zh-cn": "『虛妄之母』可可利亞",
      "zh-tw": "『虛妄之母』可可利亞"
    },
    icon: require("../components/image/domains/echo-of-war-end-of-the-eternal-freeze.webp"),
  },
  {
    id: "echo-of-war-destructions-beginning",
    type: domainTypes.filter(id => id.id === "echo-of-war")[0],
    name: {
      "en-us": "Doomsday Beast",
      "zh-cn": "末日兽",
      "zh-tw": "末日獸"
    },
    icon: require("../components/image/domains/echo-of-war-destructions-beginning.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-abomination",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Abomination",
      "zh-cn": "孽兽之形",
      "zh-tw": "孽獸之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-abomination.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-blaze",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Blaze",
      "zh-cn": "炎华之形",
      "zh-tw": "炎華之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-blaze.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-celestial",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Celestial",
      "zh-cn": "天人之形",
      "zh-tw": "天人之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-celestial.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-doom",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Doom",
      "zh-cn": "震厄之形",
      "zh-tw": "震厄之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-doom.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-fulmination",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Fulmination",
      "zh-cn": "鸣雷之形",
      "zh-tw": "鳴雷之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-fulmination.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-gust",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Gust",
      "zh-cn": "巽风之形",
      "zh-tw": "巽風之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-gust.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-icicle",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Icicle",
      "zh-cn": "冰棱之形",
      "zh-tw": "冰稜之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-icicle.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-mirage",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Mirage",
      "zh-cn": "幻光之形",
      "zh-tw": "幻光之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-mirage.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-puppetry",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Puppetry",
      "zh-cn": "偃偶之形",
      "zh-tw": "偃偶之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-puppetry.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-quanta",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Quanta",
      "zh-cn": "空海之形",
      "zh-tw": "空海之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-quanta.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-rime",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Rime",
      "zh-cn": "霜晶之形",
      "zh-tw": "霜晶之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-rime.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-spike",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Spike",
      "zh-cn": "锋芒之形",
      "zh-tw": "鋒芒之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-spike.webp"),
  },
  {
    id: "stagnant-shadow-shape-of-roast",
    type: domainTypes.filter(id => id.id === "stagnant-shadow")[0],
    name: {
      "en-us": "Shape of Roast",
      "zh-cn": "焦炙之形",
      "zh-tw": "焦炙之形"
    },
    icon: require("../components/image/domains/stagnant-shadow-shape-of-roast.webp"),
  },
];

export const items = [
  {
    id: "credit",
    image: require("../components/image/item/credit.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Credit",
      "zh-cn": "信用点",
      "zh-tw": "信用點"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-golden-bud-of-treasures")[0]
    ]
  },
  {
    id: "destroyers-final-road",
    image: require("../components/image/item/destroyers-final-road.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Destroyer's Final Road",
      "zh-cn": "毁灭者的末路",
      "zh-tw": "毀滅者的末路"
    },
    type: [
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "echo-of-war-destructions-beginning")[0]
    ]
  },
  {
    id: "guardians-lament",
    image: require("../components/image/item/guardians-lament.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Guardian's Lament",
      "zh-cn": "守护者的悲愿",
      "zh-tw": "守護者的悲願"
    },
    type: [
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "echo-of-war-end-of-the-eternal-freeze")[0]
    ]
  },
  {
    id: "regret-of-infinite-ochema",
    image: require("../components/image/item/regret-of-infinite-ochema.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Regret of Infinite Ochema",
      "zh-cn": "无穷假身的遗恨",
      "zh-tw": "無窮假身的遺恨"
    },
    type: [
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "echo-of-war-divine-seed")[0]
    ]
  },
  {
    id: "void-cast-iron",
    image: require("../components/image/item/void-cast-iron.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Void Cast Iron",
      "zh-cn": "虚幻铸铁",
      "zh-tw": "虛幻鑄鐵"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-quanta")[0]
    ]
  },
  {
    id: "shape-shifters-lightning-staff",
    image: require("../components/image/item/shape-shifters-lightning-staff.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Shape Shifter's Lightning Staff",
      "zh-cn": "炼形者雷枝",
      "zh-tw": "煉形者雷枝"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-doom")[0]
    ]
  },
  
  {
    id: "dream-flamer",
    image: require("../components/image/item/dream-flamer.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Dream Flamer",
      "zh-cn": "炙梦喷枪",
      "zh-tw": "炙夢噴槍"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-roast")[0]
    ]
  },
  {
    id: "horn-of-snow",
    image: require("../components/image/item/horn-of-snow.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Horn of Snow",
      "zh-cn": "风雪之角",
      "zh-tw": "風雪之角"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-rime")[0]
    ]
  },
  {
    id: "storm-eye",
    image: require("../components/image/item/storm-eye.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Storm Eye",
      "zh-cn": "暴风之眼",
      "zh-tw": "暴風之眼"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-gust")[0]
    ]
  },
  {
    id: "lightning-crown-of-the-past-shadow",
    image: require("../components/image/item/lightning-crown-of-the-past-shadow.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Lightning Crown of the Past Shadow",
      "zh-cn": "往日之影的雷冠",
      "zh-tw": "往日之影的雷冠"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-fulmination")[0]
    ]
  },
  {
    id: "endotherm-chitin",
    image: require("../components/image/item/endotherm-chitin.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Endotherm Chitin",
      "zh-cn": "恒温晶壳",
      "zh-tw": "恆溫晶殼"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-blaze")[0]
    ]
  },
  {
    id: "suppressing-edict",
    image: require("../components/image/item/suppressing-edict.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Suppressing Edict",
      "zh-cn": "镇灵敕符",
      "zh-tw": "鎮靈敕符"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-puppetry")[0]
    ]
  },
  {
    id: "nail-of-the-ape",
    image: require("../components/image/item/nail-of-the-ape.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Nail of the Ape",
      "zh-cn": "苍猿之钉",
      "zh-tw": "蒼猿之釘"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-abomination")[0]
    ]
  },
  {
    id: "gelid-chitin",
    image: require("../components/image/item/gelid-chitin.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Gelid Chitin",
      "zh-cn": "苦寒晶壳",
      "zh-tw": "苦寒晶殼"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-icicle")[0]
    ]
  },
  {
    id: "ascendant-debris",
    image: require("../components/image/item/ascendant-debris.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Ascendant Debris",
      "zh-cn": "天人遗垢",
      "zh-tw": "天人遺垢"
    },
    type: [
      itemTypes.filter(id => id.id === "character-ascension")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "stagnant-shadow-shape-of-celestial")[0]
    ]
  },
  {
    id: "tracks-of-destiny",
    image: require("../components/image/item/tracks-of-destiny.webp"),
    rarity_number: "five-star",
    name: {
      "en-us": "Tracks of Destiny",
      "zh-cn": "命运的足迹",
      "zh-tw": "命運的足跡"
    },
    type: [
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "silvermane-medal",
    image: require("../components/image/item/silvermane-medal.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Silvermane Medal",
      "zh-cn": "铁卫勋章",
      "zh-tw": "鐵衛勳章"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "silvermane-insignia",
    image: require("../components/image/item/silvermane-insignia.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Silvermane Insignia",
      "zh-cn": "铁卫军徽",
      "zh-tw": "鐵衛軍徽"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "silvermane-badge",
    image: require("../components/image/item/silvermane-badge.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Silvermane Badge",
      "zh-cn": "铁卫扣饰",
      "zh-tw": "鐵衛扣飾"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "conquerors-will",
    image: require("../components/image/item/conquerors-will.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Conqueror's Will",
      "zh-cn": "践踏的意志",
      "zh-tw": "踐踏的意志"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "usurpers-scheme",
    image: require("../components/image/item/usurpers-scheme.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Usurper's Scheme",
      "zh-cn": "篡改的野心",
      "zh-tw": "篡改的野心"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "thiefs-instinct",
    image: require("../components/image/item/thiefs-instinct.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Thief's Instinct",
      "zh-cn": "掠夺的本能",
      "zh-tw": "掠奪的本能"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "artifexs-gyreheart",
    image: require("../components/image/item/artifexs-gyreheart.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Artifex's Gyreheart",
      "zh-cn": "工造浑心",
      "zh-tw": "工造渾心"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "artifexs-cogwheel",
    image: require("../components/image/item/artifexs-cogwheel.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Artifex's Cogwheel",
      "zh-cn": "工造迴轮",
      "zh-tw": "工造迴輪"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "artifexs-module",
    image: require("../components/image/item/artifexs-module.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Artifex's Module",
      "zh-cn": "工造机杼",
      "zh-tw": "工造機杼"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "ancient-engine",
    image: require("../components/image/item/ancient-engine.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Ancient Engine",
      "zh-cn": "古代引擎",
      "zh-tw": "古代引擎"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "ancient-spindle",
    image: require("../components/image/item/ancient-spindle.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Ancient Spindle",
      "zh-cn": "古代转轴",
      "zh-tw": "古代轉軸"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "ancient-part",
    image: require("../components/image/item/ancient-part.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Ancient Part",
      "zh-cn": "古代零件",
      "zh-tw": "古代零件"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "immortal-lumintwig",
    image: require("../components/image/item/immortal-lumintwig.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Immortal Lumintwig",
      "zh-cn": "永寿荣枝",
      "zh-tw": "永壽榮枝"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "immortal-aeroblossom",
    image: require("../components/image/item/immortal-aeroblossom.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Immortal Aeroblossom",
      "zh-cn": "永寿天华",
      "zh-tw": "永壽天華"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "immortal-scionette",
    image: require("../components/image/item/immortal-scionette.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Immortal Scionette",
      "zh-cn": "永寿幼芽",
      "zh-tw": "永壽幼芽"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  
  {
    id: "shards-of-desires",
    image: require("../components/image/item/shards-of-desires.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Shards of Desires",
      "zh-cn": "欲念碎镜",
      "zh-tw": "欲念碎鏡"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "fragments-of-impression",
    image: require("../components/image/item/fragments-of-impression.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Fragments of Impression",
      "zh-cn": "印象残晶",
      "zh-tw": "印象殘晶"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "tatters-of-thought",
    image: require("../components/image/item/tatters-of-thought.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Tatters of Thought",
      "zh-cn": "思绪末屑",
      "zh-tw": "思緒末屑"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },

  {
    id: "squirming-core",
    image: require("../components/image/item/squirming-core.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Squirming Core",
      "zh-cn": "蠢动原核",
      "zh-tw": "蠢動原核"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "glimmering-core",
    image: require("../components/image/item/glimmering-core.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Glimmering Core",
      "zh-cn": "微光原核",
      "zh-tw": "微光原核"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "extinguished-core",
    image: require("../components/image/item/extinguished-core.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Extinguished Core",
      "zh-cn": "熄灭原核",
      "zh-tw": "熄滅原核"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "character-ascension")[0],
      itemTypes.filter(id => id.id === "skill-upgrade")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
    ]
  },
  {
    id: "arrow-of-the-starchaser",
    image: require("../components/image/item/arrow-of-the-starchaser.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Arrow of the Starchaser",
      "zh-cn": "逐星之矢",
      "zh-tw": "逐星之矢"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-hunt")[0]
    ]
  },
  {
    id: "arrow-of-the-demon-slayer",
    image: require("../components/image/item/arrow-of-the-demon-slayer.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Arrow of the Demon Slayer",
      "zh-cn": "屠魔之矢",
      "zh-tw": "屠魔之矢"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-hunt")[0]
    ]
  },
  {
    id: "arrow-of-the-beast-hunter",
    image: require("../components/image/item/arrow-of-the-beast-hunter.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Arrow of the Beast Hunter",
      "zh-cn": "猎兽之矢",
      "zh-tw": "獵獸之矢"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-hunt")[0]
    ]
  },
  {
    id: "obsidian-of-obsession",
    image: require("../components/image/item/obsidian-of-obsession.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Obsidian of Obsession",
      "zh-cn": "沉沦黑曜",
      "zh-tw": "沉淪黑曜"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-harmony")[0]
    ]
  },
  {
    id: "obsidian-of-desolation",
    image: require("../components/image/item/obsidian-of-desolation.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Obsidian of Desolation",
      "zh-cn": "虚空黑曜",
      "zh-tw": "虛空黑曜"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-harmony")[0]
    ]
  },
  {
    id: "obsidian-of-dread",
    image: require("../components/image/item/obsidian-of-dread.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Obsidian of Dread",
      "zh-cn": "黯淡黑曜",
      "zh-tw": "黯淡黑曜"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-harmony")[0]
    ]
  },
  {
    id: "stellaris-symphony",
    image: require("../components/image/item/stellaris-symphony.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Stellaris Symphony",
      "zh-cn": "群星乐章",
      "zh-tw": "群星樂章"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-harmony")[0]
    ]
  },
  {
    id: "ancestral-hymn",
    image: require("../components/image/item/ancestral-hymn.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Ancestral Hymn",
      "zh-cn": "家族颂歌",
      "zh-tw": "家族頌歌"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-harmony")[0]
    ]
  },
  {
    id: "harmonic-tune",
    image: require("../components/image/item/harmonic-tune.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Harmonic Tune",
      "zh-cn": "谐乐小调",
      "zh-tw": "諧樂小調"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-harmony")[0]
    ]
  },
  {
    id: "worldbreaker-blade",
    image: require("../components/image/item/worldbreaker-blade.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Worldbreaker Blade",
      "zh-cn": "净世残刃",
      "zh-tw": "淨世殘刃"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-destruction")[0]
    ]
  },
  {
    id: "lifeless-blade",
    image: require("../components/image/item/lifeless-blade.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Lifeless Blade",
      "zh-cn": "无生残刃",
      "zh-tw": "無生殘刃"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-destruction")[0]
    ]
  },
  {
    id: "shattered-blade",
    image: require("../components/image/item/shattered-blade.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Shattered Blade",
      "zh-cn": "破碎残刃",
      "zh-tw": "破碎殘刃"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-destruction")[0]
    ]
  },
  {
    id: "flower-of-eternity",
    image: require("../components/image/item/flower-of-eternity.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Flower of Eternity",
      "zh-cn": "永恒之花",
      "zh-tw": "永恆之花"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-abundance")[0]
    ]
  },
  {
    id: "sprout-of-life",
    image: require("../components/image/item/sprout-of-life.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Sprout of Life",
      "zh-cn": "生命之芽",
      "zh-tw": "生命之芽"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-abundance")[0]
    ]
  },
  {
    id: "seed-of-abundance",
    image: require("../components/image/item/seed-of-abundance.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Seed of Abundance",
      "zh-cn": "丰饶之种",
      "zh-tw": "豐饒之種"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-abundance")[0]
    ]
  },
  {
    id: "safeguard-of-amber",
    image: require("../components/image/item/safeguard-of-amber.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Safeguard of Amber",
      "zh-cn": "琥珀的坚守",
      "zh-tw": "琥珀的堅守"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-preservation")[0]
    ]
  },
  {
    id: "oath-of-steel",
    image: require("../components/image/item/oath-of-steel.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Oath of Steel",
      "zh-cn": "寒铁的誓言",
      "zh-tw": "寒鐵的誓言"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-preservation")[0]
    ]
  },
  {
    id: "endurance-of-bronze",
    image: require("../components/image/item/endurance-of-bronze.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Endurance of Bronze",
      "zh-cn": "青铜的执着",
      "zh-tw": "青銅的執著"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-preservation")[0]
    ]
  },
  
  {
    id: "key-of-wisdom",
    image: require("../components/image/item/key-of-wisdom.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Key of Wisdom",
      "zh-cn": "智识之钥",
      "zh-tw": "智識之鑰"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-erudition")[0]
    ]
  },
  {
    id: "key-of-knowledge",
    image: require("../components/image/item/key-of-knowledge.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Key of Knowledge",
      "zh-cn": "启迪之钥",
      "zh-tw": "啟迪之鑰"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-erudition")[0]
    ]
  },
  {
    id: "key-of-inspiration",
    image: require("../components/image/item/key-of-inspiration.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Key of Inspiration",
      "zh-cn": "灵感之钥",
      "zh-tw": "靈感之鑰"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-erudition")[0]
    ]
  },

  
  {
    id: "heavenly-melody",
    image: require("../components/image/item/heavenly-melody.webp"),
    rarity_number: "four-star",
    name: {
      "en-us": "Heavenly Melody",
      "zh-cn": "天外乐章",
      "zh-tw": "天外樂章"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-harmony")[0]
    ]
  },
  {
    id: "celestial-section",
    image: require("../components/image/item/celestial-section.webp"),
    rarity_number: "three-star",
    name: {
      "en-us": "Celestial Section",
      "zh-cn": "空际小节",
      "zh-tw": "空際小節"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-harmony")[0]
    ]
  },
  {
    id: "firmament-note",
    image: require("../components/image/item/firmament-note.webp"),
    rarity_number: "two-star",
    name: {
      "en-us": "Firmament Note",
      "zh-cn": "云际音符",
      "zh-tw": "雲際音符"
    },
    type: [
      itemTypes.filter(id => id.id === "lightcone-ascension")[0],
      itemTypes.filter(id => id.id === "traces-leveling")[0],
    ],
    obtain: [
      domains.filter(id => id.id === "calyx-crimson-bud-of-harmony")[0]
    ]
  },
]

export const relic = [
  {
    id: "101_0",
    name: "Passerby's Rejuvenated Wooden Hairstick",
    set: "Passerby of Wandering Cloud",
    type: "Cavern Relics",
    translation:{
      "en-us": "Passerby's Rejuvenated Wooden Hairstick",
      "zh-cn": "过客的逢春木簪",
      "zh-tw": "過客的逢春木簪"
    },
    translation_set:{
      "en-us": "Passerby of Wandering Cloud",
      "zh-cn": "云无留迹的过客",
      "zh-tw": "云無留蹟的過客"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Outgoing Healing</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>治疗量</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>治療量</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "At the beginning of the battle, immediately recovers 1 <p class=\"highlight-property\"}>Skill Point</p>.",
        "zh-cn": "在战斗开始时，立即为我方恢复1个<p class=\"highlight-property\"}>战技点</p>。",
        "zh-tw": "在戰鬥開始時，立即為我方恢復1個<p class=\"highlight-property\"}>戰技點</p>。",
      }
    ],
    code: "PWC",
    colour: "#484f5a",
    image: require("../components/image/relic/101_0.webp"),
  },
  {
    id: "101_1",
    name: "Passerby's Roaming Dragon Bracer",
    set: "Passerby of Wandering Cloud",
    type: "Cavern Relics",
    translation:{
      "en-us": "Passerby's Roaming Dragon Bracer",
      "zh-cn": "过客的游龙臂鞲",
      "zh-tw": "過客的遊龍臂鞲"
    },
    translation_set:{
      "en-us": "Passerby of Wandering Cloud",
      "zh-cn": "云无留迹的过客",
      "zh-tw": "云無留蹟的過客"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Outgoing Healing</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>治疗量</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>治療量</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "At the beginning of the battle, immediately recovers 1 <p class=\"highlight-property\"}>Skill Point</p>.",
        "zh-cn": "在战斗开始时，立即为我方恢复1个<p class=\"highlight-property\"}>战技点</p>。",
        "zh-tw": "在戰鬥開始時，立即為我方恢復1個<p class=\"highlight-property\"}>戰技點</p>。",
      }
    ],
    code: "PWC",
    colour: "#484f5a",
    image: require("../components/image/relic/101_1.webp"),
  },
  {
    id: "101_2",
    name: "Passerby's Ragged Embroided Coat",
    set: "Passerby of Wandering Cloud",
    type: "Cavern Relics",
    translation:{
      "en-us": "Passerby's Ragged Embroided Coat",
      "zh-cn": "过客的残绣风衣",
      "zh-tw": "過客的殘繡風衣"
    },
    translation_set:{
      "en-us": "Passerby of Wandering Cloud",
      "zh-cn": "云无留迹的过客",
      "zh-tw": "云無留蹟的過客"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Outgoing Healing</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>治疗量</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>治療量</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "At the beginning of the battle, immediately recovers 1 <p class=\"highlight-property\"}>Skill Point</p>.",
        "zh-cn": "在战斗开始时，立即为我方恢复1个<p class=\"highlight-property\"}>战技点</p>。",
        "zh-tw": "在戰鬥開始時，立即為我方恢復1個<p class=\"highlight-property\"}>戰技點</p>。",
      }
    ],
    code: "PWC",
    colour: "#484f5a",
    image: require("../components/image/relic/101_2.webp"),
  },
  {
    id: "101_3",
    name: "Passerby's Stygian Hiking Boots",
    set: "Passerby of Wandering Cloud",
    type: "Cavern Relics",
    translation:{
      "en-us": "Passerby's Stygian Hiking Boots",
      "zh-cn": "过客的冥途游履",
      "zh-tw": "過客的冥途遊履"
    },
    translation_set:{
      "en-us": "Passerby of Wandering Cloud",
      "zh-cn": "云无留迹的过客",
      "zh-tw": "云無留蹟的過客"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Outgoing Healing</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>治疗量</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>治療量</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "At the beginning of the battle, immediately recovers 1 <p class=\"highlight-property\"}>Skill Point</p>.",
        "zh-cn": "在战斗开始时，立即为我方恢复1个<p class=\"highlight-property\"}>战技点</p>。",
        "zh-tw": "在戰鬥開始時，立即為我方恢復1個<p class=\"highlight-property\"}>戰技點</p>。",
      }
    ],
    code: "PWC",
    colour: "#484f5a",
    image: require("../components/image/relic/101_3.webp"),
  },
  {
    id: "102_0",
    name: "Musketeer's Wild Wheat Felt Hat",
    set: "Musketeer of Wild Wheat",
    type: "Cavern Relics",
    translation:{
      "en-us": "Musketeer's Wild Wheat Felt Hat",
      "zh-cn": "快枪手的野穗毡帽",
      "zh-tw": "快槍手的野穗氈帽"
    },
    translation_set:{
      "en-us": "Musketeer of Wild Wheat",
      "zh-cn": "野穗伴行的快枪手",
      "zh-tw": "野穗伴行的快槍手"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "<p class=\"highlight-property\"}>ATK</p> increases by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "The wearer's <p class=\"highlight-property\"}>SPD</p> increases by 6% and <p class=\"highlight-property\"}>Basic ATK</p> DMG increases by 10%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>速度</p>提高6%，<p class=\"highlight-property\"}>普攻</p>造成的伤害提高10%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>速度</p>提高6%，<p class=\"highlight-property\"}>普攻</p>造成的傷害提高10%。",
      }
    ],
    code: "MWW",
    colour: "#a37370",
    image: require("../components/image/relic/102_0.webp"),
  },
  {
    id: "102_1",
    name: "Musketeer's Coarse Leather Gloves",
    set: "Musketeer of Wild Wheat",
    type: "Cavern Relics",
    translation:{
      "en-us": "Musketeer's Coarse Leather Gloves",
      "zh-cn": "快枪手的粗革手套",
      "zh-tw": "快槍手的粗革手套"
    },
    translation_set:{
      "en-us": "Musketeer of Wild Wheat",
      "zh-cn": "野穗伴行的快枪手",
      "zh-tw": "野穗伴行的快槍手"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "<p class=\"highlight-property\"}>ATK</p> increases by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "The wearer's <p class=\"highlight-property\"}>SPD</p> increases by 6% and <p class=\"highlight-property\"}>Basic ATK</p> DMG increases by 10%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>速度</p>提高6%，<p class=\"highlight-property\"}>普攻</p>造成的伤害提高10%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>速度</p>提高6%，<p class=\"highlight-property\"}>普攻</p>造成的傷害提高10%。",
      }
    ],
    code: "MWW",
    colour: "#a37370",
    image: require("../components/image/relic/102_1.webp"),
  },
  {
    id: "102_2",
    name: "Musketeer's Wind-Hunting Shawl",
    set: "Musketeer of Wild Wheat",
    type: "Cavern Relics",
    translation:{
      "en-us": "Musketeer's Wind-Hunting Shawl",
      "zh-cn": "快枪手的猎风披肩",
      "zh-tw": "快槍手的獵風披肩"
    },
    translation_set:{
      "en-us": "Musketeer of Wild Wheat",
      "zh-cn": "野穗伴行的快枪手",
      "zh-tw": "野穗伴行的快槍手"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "<p class=\"highlight-property\"}>ATK</p> increases by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "The wearer's <p class=\"highlight-property\"}>SPD</p> increases by 6% and <p class=\"highlight-property\"}>Basic ATK</p> DMG increases by 10%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>速度</p>提高6%，<p class=\"highlight-property\"}>普攻</p>造成的伤害提高10%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>速度</p>提高6%，<p class=\"highlight-property\"}>普攻</p>造成的傷害提高10%。",
      }
    ],
    code: "MWW",
    colour: "#a37370",
    image: require("../components/image/relic/102_2.webp"),
  },
  {
    id: "102_3",
    name: "Musketeer's Rivets Riding Boots",
    set: "Musketeer of Wild Wheat",
    type: "Cavern Relics",
    translation:{
      "en-us": "Musketeer's Rivets Riding Boots",
      "zh-cn": "快枪手的铆钉马靴",
      "zh-tw": "快槍手的鉚釘馬靴"
    },
    translation_set:{
      "en-us": "Musketeer of Wild Wheat",
      "zh-cn": "野穗伴行的快枪手",
      "zh-tw": "野穗伴行的快槍手"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "<p class=\"highlight-property\"}>ATK</p> increases by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "The wearer's <p class=\"highlight-property\"}>SPD</p> increases by 6% and <p class=\"highlight-property\"}>Basic ATK</p> DMG increases by 10%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>速度</p>提高6%，<p class=\"highlight-property\"}>普攻</p>造成的伤害提高10%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>速度</p>提高6%，<p class=\"highlight-property\"}>普攻</p>造成的傷害提高10%。",
      }
    ],
    code: "MWW",
    colour: "#a37370",
    image: require("../components/image/relic/102_3.webp"),
  },
  {
    id: "103_0",
    name: "Knight's Forgiving Casque",
    set: "Knight of Purity Palace",
    type: "Cavern Relics",
    translation:{
      "en-us": "Knight's Forgiving Casque",
      "zh-cn": "圣骑的宽恕盔面",
      "zh-tw": "聖騎的寬恕盔面"
    },
    translation_set:{
      "en-us": "Knight of Purity Palace",
      "zh-cn": "净庭教宗的圣骑士",
      "zh-tw": "淨庭教宗的聖騎士"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>DEF</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>防御力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>防禦力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the max DMG that can be absorbed by the <p class=\"highlight-property\"}>Shield</p> created by the wearer by 20%.",
        "zh-cn": "使装备者提供的<p class=\"highlight-property\"}>护盾量</p>提高20%。",
        "zh-tw": "使裝備者提供的<p class=\"highlight-property\"}>護盾量</p>提高20%。",
      }
    ],
    code: "KPP",
    colour: "#e7e6e9",
    image: require("../components/image/relic/103_0.webp"),
  },
  {
    id: "103_1",
    name: "Knight's Silent Oath Ring",
    set: "Knight of Purity Palace",
    type: "Cavern Relics",
    translation:{
      "en-us": "Knight's Silent Oath Ring",
      "zh-cn": "圣骑的沉默誓环",
      "zh-tw": "聖騎的沉默誓環"
    },
    translation_set:{
      "en-us": "Knight of Purity Palace",
      "zh-cn": "净庭教宗的圣骑士",
      "zh-tw": "淨庭教宗的聖騎士"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>DEF</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>防御力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>防禦力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the max DMG that can be absorbed by the <p class=\"highlight-property\"}>Shield</p> created by the wearer by 20%.",
        "zh-cn": "使装备者提供的<p class=\"highlight-property\"}>护盾量</p>提高20%。",
        "zh-tw": "使裝備者提供的<p class=\"highlight-property\"}>護盾量</p>提高20%。",
      }
    ],
    code: "KPP",
    colour: "#e7e6e9",
    image: require("../components/image/relic/103_1.webp"),
  },
  {
    id: "103_2",
    name: "Knight's Solemn Breastplate",
    set: "Knight of Purity Palace",
    type: "Cavern Relics",
    translation:{
      "en-us": "Knight's Solemn Breastplate",
      "zh-cn": "圣骑的肃穆胸甲",
      "zh-tw": "聖騎的肅穆胸甲"
    },
    translation_set:{
      "en-us": "Knight of Purity Palace",
      "zh-cn": "净庭教宗的圣骑士",
      "zh-tw": "淨庭教宗的聖騎士"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>DEF</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>防御力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>防禦力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the max DMG that can be absorbed by the <p class=\"highlight-property\"}>Shield</p> created by the wearer by 20%.",
        "zh-cn": "使装备者提供的<p class=\"highlight-property\"}>护盾量</p>提高20%。",
        "zh-tw": "使裝備者提供的<p class=\"highlight-property\"}>護盾量</p>提高20%。",
      }
    ],
    code: "KPP",
    colour: "#e7e6e9",
    image: require("../components/image/relic/103_2.webp"),
  },
  {
    id: "103_3",
    name: "Knight's Iron Boots of Order",
    set: "Knight of Purity Palace",
    type: "Cavern Relics",
    translation:{
      "en-us": "Knight's Iron Boots of Order",
      "zh-cn": "圣骑的秩序铁靴",
      "zh-tw": "聖騎的秩序鐵靴"
    },
    translation_set:{
      "en-us": "Knight of Purity Palace",
      "zh-cn": "净庭教宗的圣骑士",
      "zh-tw": "淨庭教宗的聖騎士"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>DEF</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>防御力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>防禦力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the max DMG that can be absorbed by the <p class=\"highlight-property\"}>Shield</p> created by the wearer by 20%.",
        "zh-cn": "使装备者提供的<p class=\"highlight-property\"}>护盾量</p>提高20%。",
        "zh-tw": "使裝備者提供的<p class=\"highlight-property\"}>護盾量</p>提高20%。",
      }
    ],
    code: "KPP",
    colour: "#e7e6e9",
    image: require("../components/image/relic/103_3.webp"),
  },
  {
    id: "104_0",
    name: "Hunter's Artaius Hood",
    set: "Hunter of Glacial Forest",
    type: "Cavern Relics",
    translation:{
      "en-us": "Hunter's Artaius Hood",
      "zh-cn": "雪猎的荒神兜帽",
      "zh-tw": "雪獵的荒神兜帽"
    },
    translation_set:{
      "en-us": "Hunter of Glacial Forest",
      "zh-cn": "密林卧雪的猎人",
      "zh-tw": "密林臥雪的獵人"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Ice DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>冰属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>冰屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer unleashes their <p class=\"highlight-property\"}>Ultimate</p>, their <p class=\"highlight-property\"}>CRIT DMG</p> increases by 25% for 2 turn(s).",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>终结技</p>时，<p class=\"highlight-property\"}>暴击伤害</p>提高25%，持续2回合。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>終結技</p>時，<p class=\"highlight-property\"}>暴擊傷害</p>提高25%，持續2回合。",
      }
    ],
    code: "HGF",
    colour: "#f3ebec",
    image: require("../components/image/relic/104_0.webp"),
  },
  {
    id: "104_1",
    name: "Hunter's Lizard Gloves",
    set: "Hunter of Glacial Forest",
    type: "Cavern Relics",
    translation:{
      "en-us": "Hunter's Lizard Gloves",
      "zh-cn": "雪猎的巨蜥手套",
      "zh-tw": "雪獵的巨蜥手套"
    },
    translation_set:{
      "en-us": "Hunter of Glacial Forest",
      "zh-cn": "密林卧雪的猎人",
      "zh-tw": "密林臥雪的獵人"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Ice DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>冰属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>冰屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer unleashes their <p class=\"highlight-property\"}>Ultimate</p>, their <p class=\"highlight-property\"}>CRIT DMG</p> increases by 25% for 2 turn(s).",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>终结技</p>时，<p class=\"highlight-property\"}>暴击伤害</p>提高25%，持续2回合。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>終結技</p>時，<p class=\"highlight-property\"}>暴擊傷害</p>提高25%，持續2回合。",
      }
    ],
    code: "HGF",
    colour: "#f3ebec",
    image: require("../components/image/relic/104_1.webp"),
  },
  {
    id: "104_2",
    name: "Hunter's Ice Dragon Cloak",
    set: "Hunter of Glacial Forest",
    type: "Cavern Relics",
    translation:{
      "en-us": "Hunter's Ice Dragon Cloak",
      "zh-cn": "雪猎的冰龙披风",
      "zh-tw": "雪獵的冰龍披風"
    },
    translation_set:{
      "en-us": "Hunter of Glacial Forest",
      "zh-cn": "密林卧雪的猎人",
      "zh-tw": "密林臥雪的獵人"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Ice DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>冰属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>冰屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer unleashes their <p class=\"highlight-property\"}>Ultimate</p>, their <p class=\"highlight-property\"}>CRIT DMG</p> increases by 25% for 2 turn(s).",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>终结技</p>时，<p class=\"highlight-property\"}>暴击伤害</p>提高25%，持续2回合。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>終結技</p>時，<p class=\"highlight-property\"}>暴擊傷害</p>提高25%，持續2回合。",
      }
    ],
    code: "HGF",
    colour: "#f3ebec",
    image: require("../components/image/relic/104_2.webp"),
  },
  {
    id: "104_3",
    name: "Hunter's Soft Elkskin Boots",
    set: "Hunter of Glacial Forest",
    type: "Cavern Relics",
    translation:{
      "en-us": "Hunter's Soft Elkskin Boots",
      "zh-cn": "雪猎的鹿皮软靴",
      "zh-tw": "雪獵的鹿皮軟靴"
    },
    translation_set:{
      "en-us": "Hunter of Glacial Forest",
      "zh-cn": "密林卧雪的猎人",
      "zh-tw": "密林臥雪的獵人"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Ice DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>冰属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>冰屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer unleashes their <p class=\"highlight-property\"}>Ultimate</p>, their <p class=\"highlight-property\"}>CRIT DMG</p> increases by 25% for 2 turn(s).",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>终结技</p>时，<p class=\"highlight-property\"}>暴击伤害</p>提高25%，持续2回合。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>終結技</p>時，<p class=\"highlight-property\"}>暴擊傷害</p>提高25%，持續2回合。",
      }
    ],
    code: "HGF",
    colour: "#f3ebec",
    image: require("../components/image/relic/104_3.webp"),
  },
  {
    id: "105_0",
    name: "Champion's Headgear",
    set: "Champion of Streetwise Boxing",
    type: "Cavern Relics",
    translation:{
      "en-us": "Champion's Headgear",
      "zh-cn": "拳王的冠军护头",
      "zh-tw": "拳王的冠軍護頭"
    },
    translation_set:{
      "en-us": "Champion of Streetwise Boxing",
      "zh-cn": "街头出身的拳王",
      "zh-tw": "街頭出身的拳王"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Physical DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>物理属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>物理屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer attacks or is hit, their <p class=\"highlight-property\"}>ATK</p> increases by 5% for the rest of the battle. This effect can stack up to 5 time(s).",
        "zh-cn": "当装备者施放攻击或受到攻击后，其在本场战斗中<p class=\"highlight-property\"}>攻击力</p>提高5%，最多叠加5次。",
        "zh-tw": "當裝備者施放攻擊或受到攻擊後，其在本場戰鬥中<p class=\"highlight-property\"}>攻擊力</p>提高5%，最多疊加5次。",
      }
    ],
    code: "CSB",
    colour: "#3c2f2f",
    image: require("../components/image/relic/105_0.webp"),
  },
  {
    id: "105_1",
    name: "Champion's Heavy Gloves",
    set: "Champion of Streetwise Boxing",
    type: "Cavern Relics",
    translation:{
      "en-us": "Champion's Heavy Gloves",
      "zh-cn": "拳王的重炮拳套",
      "zh-tw": "拳王的重砲拳套"
    },
    translation_set:{
      "en-us": "Champion of Streetwise Boxing",
      "zh-cn": "街头出身的拳王",
      "zh-tw": "街頭出身的拳王"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Physical DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>物理属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>物理屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer attacks or is hit, their <p class=\"highlight-property\"}>ATK</p> increases by 5% for the rest of the battle. This effect can stack up to 5 time(s).",
        "zh-cn": "当装备者施放攻击或受到攻击后，其在本场战斗中<p class=\"highlight-property\"}>攻击力</p>提高5%，最多叠加5次。",
        "zh-tw": "當裝備者施放攻擊或受到攻擊後，其在本場戰鬥中<p class=\"highlight-property\"}>攻擊力</p>提高5%，最多疊加5次。",
      }
    ],
    code: "CSB",
    colour: "#3c2f2f",
    image: require("../components/image/relic/105_1.webp"),
  },
  {
    id: "105_2",
    name: "Champion's Chest Guard",
    set: "Champion of Streetwise Boxing",
    type: "Cavern Relics",
    translation:{
      "en-us": "Champion's Chest Guard",
      "zh-cn": "拳王的贴身护胸",
      "zh-tw": "拳王的貼身護胸"
    },
    translation_set:{
      "en-us": "Champion of Streetwise Boxing",
      "zh-cn": "街头出身的拳王",
      "zh-tw": "街頭出身的拳王"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Physical DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>物理属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>物理屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer attacks or is hit, their <p class=\"highlight-property\"}>ATK</p> increases by 5% for the rest of the battle. This effect can stack up to 5 time(s).",
        "zh-cn": "当装备者施放攻击或受到攻击后，其在本场战斗中<p class=\"highlight-property\"}>攻击力</p>提高5%，最多叠加5次。",
        "zh-tw": "當裝備者施放攻擊或受到攻擊後，其在本場戰鬥中<p class=\"highlight-property\"}>攻擊力</p>提高5%，最多疊加5次。",
      }
    ],
    code: "CSB",
    colour: "#3c2f2f",
    image: require("../components/image/relic/105_2.webp"),
  },
  {
    id: "105_3",
    name: "Champion's Fleetfoot Boots",
    set: "Champion of Streetwise Boxing",
    type: "Cavern Relics",
    translation:{
      "en-us": "Champion's Fleetfoot Boots",
      "zh-cn": "拳王的弧步战靴",
      "zh-tw": "拳王的弧步戰靴"
    },
    translation_set:{
      "en-us": "Champion of Streetwise Boxing",
      "zh-cn": "街头出身的拳王",
      "zh-tw": "街頭出身的拳王"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Physical DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>物理属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>物理屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer attacks or is hit, their <p class=\"highlight-property\"}>ATK</p> increases by 5% for the rest of the battle. This effect can stack up to 5 time(s).",
        "zh-cn": "当装备者施放攻击或受到攻击后，其在本场战斗中<p class=\"highlight-property\"}>攻击力</p>提高5%，最多叠加5次。",
        "zh-tw": "當裝備者施放攻擊或受到攻擊後，其在本場戰鬥中<p class=\"highlight-property\"}>攻擊力</p>提高5%，最多疊加5次。",
      }
    ],
    code: "CSB",
    colour: "#3c2f2f",
    image: require("../components/image/relic/105_3.webp"),
  },
  {
    id: "106_0",
    name: "Guard's Cast Iron Helmet",
    set: "Guard of Wuthering Snow",
    type: "Cavern Relics",
    translation:{
      "en-us": "Guard's Cast Iron Helmet",
      "zh-cn": "铁卫的铸铁面盔",
      "zh-tw": "鐵衛的鑄鐵面盔"
    },
    translation_set:{
      "en-us": "Guard of Wuthering Snow",
      "zh-cn": "戍卫风雪的铁卫",
      "zh-tw": "戍衛風雪的鐵衛"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Reduces <p class=\"highlight-property\"}>DMG</p> taken by 8%.",
        "zh-cn": "受到<p class=\"highlight-property\"}>伤害</p>降低8%。",
        "zh-tw": "受到<p class=\"highlight-property\"}>傷害</p>降低8%。",
      },
      {
        "set" : 4,
        "en-us": "At the beginning of the turn, if the wearer's <p class=\"highlight-property\"}>HP</p> is equal to or less than 50% of their <p class=\"highlight-property\"}>Max HP</p>, restores HP equal to 8% of their Max HP and regenerates 5 <p class=\"highlight-property\"}>Energy</p>.",
        "zh-cn": "回合开始时，如果装备者当前<p class=\"highlight-property\"}>生命值</p>百分比小于等于50%，则回复等同于自身<p class=\"highlight-property\"}>生命上限</p>8%的生命值，并恢复5点<p class=\"highlight-property\"}>能量</p>。",
        "zh-tw": "回合開始時，如果裝備者當前<p class=\"highlight-property\"}>生命值</p>百分比小於等於50%，則回復等同於自身<p class=\"highlight-property\"}>生命上限</p>8%的生命值，並恢復5點<p class=\"highlight-property\"}>能量</p>。",
      }
    ],
    code: "GWS",
    colour: "#23369d",
    image: require("../components/image/relic/106_0.webp"),
  },
  {
    id: "106_1",
    name: "Guard's Shining Gauntlets",
    set: "Guard of Wuthering Snow",
    type: "Cavern Relics",
    translation:{
      "en-us": "Guard's Shining Gauntlets",
      "zh-cn": "铁卫的银鳞手甲",
      "zh-tw": "鐵衛的銀鱗手甲"
    },
    translation_set:{
      "en-us": "Guard of Wuthering Snow",
      "zh-cn": "戍卫风雪的铁卫",
      "zh-tw": "戍衛風雪的鐵衛"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Reduces <p class=\"highlight-property\"}>DMG</p> taken by 8%.",
        "zh-cn": "受到<p class=\"highlight-property\"}>伤害</p>降低8%。",
        "zh-tw": "受到<p class=\"highlight-property\"}>傷害</p>降低8%。",
      },
      {
        "set" : 4,
        "en-us": "At the beginning of the turn, if the wearer's <p class=\"highlight-property\"}>HP</p> is equal to or less than 50% of their <p class=\"highlight-property\"}>Max HP</p>, restores HP equal to 8% of their Max HP and regenerates 5 <p class=\"highlight-property\"}>Energy</p>.",
        "zh-cn": "回合开始时，如果装备者当前<p class=\"highlight-property\"}>生命值</p>百分比小于等于50%，则回复等同于自身<p class=\"highlight-property\"}>生命上限</p>8%的生命值，并恢复5点<p class=\"highlight-property\"}>能量</p>。",
        "zh-tw": "回合開始時，如果裝備者當前<p class=\"highlight-property\"}>生命值</p>百分比小於等於50%，則回復等同於自身<p class=\"highlight-property\"}>生命上限</p>8%的生命值，並恢復5點<p class=\"highlight-property\"}>能量</p>。",
      }
    ],
    code: "GWS",
    colour: "#23369d",
    image: require("../components/image/relic/106_1.webp"),
  },
  {
    id: "106_2",
    name: "Guard's Uniform of Old",
    set: "Guard of Wuthering Snow",
    type: "Cavern Relics",
    translation:{
      "en-us": "Guard's Uniform of Old",
      "zh-cn": "铁卫的旧制军服",
      "zh-tw": "鐵衛的舊制軍服"
    },
    translation_set:{
      "en-us": "Guard of Wuthering Snow",
      "zh-cn": "戍卫风雪的铁卫",
      "zh-tw": "戍衛風雪的鐵衛"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Reduces <p class=\"highlight-property\"}>DMG</p> taken by 8%.",
        "zh-cn": "受到<p class=\"highlight-property\"}>伤害</p>降低8%。",
        "zh-tw": "受到<p class=\"highlight-property\"}>傷害</p>降低8%。",
      },
      {
        "set" : 4,
        "en-us": "At the beginning of the turn, if the wearer's <p class=\"highlight-property\"}>HP</p> is equal to or less than 50% of their <p class=\"highlight-property\"}>Max HP</p>, restores HP equal to 8% of their Max HP and regenerates 5 <p class=\"highlight-property\"}>Energy</p>.",
        "zh-cn": "回合开始时，如果装备者当前<p class=\"highlight-property\"}>生命值</p>百分比小于等于50%，则回复等同于自身<p class=\"highlight-property\"}>生命上限</p>8%的生命值，并恢复5点<p class=\"highlight-property\"}>能量</p>。",
        "zh-tw": "回合開始時，如果裝備者當前<p class=\"highlight-property\"}>生命值</p>百分比小於等於50%，則回復等同於自身<p class=\"highlight-property\"}>生命上限</p>8%的生命值，並恢復5點<p class=\"highlight-property\"}>能量</p>。",
      }
    ],
    code: "GWS",
    colour: "#23369d",
    image: require("../components/image/relic/106_2.webp"),
  },
  {
    id: "106_3",
    name: "Guard's Silver Greaves",
    set: "Guard of Wuthering Snow",
    type: "Cavern Relics",
    translation:{
      "en-us": "Guard's Silver Greaves",
      "zh-cn": "铁卫的白银护胫",
      "zh-tw": "鐵衛的白銀護脛"
    },
    translation_set:{
      "en-us": "Guard of Wuthering Snow",
      "zh-cn": "戍卫风雪的铁卫",
      "zh-tw": "戍衛風雪的鐵衛"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Reduces <p class=\"highlight-property\"}>DMG</p> taken by 8%.",
        "zh-cn": "受到<p class=\"highlight-property\"}>伤害</p>降低8%。",
        "zh-tw": "受到<p class=\"highlight-property\"}>傷害</p>降低8%。",
      },
      {
        "set" : 4,
        "en-us": "At the beginning of the turn, if the wearer's <p class=\"highlight-property\"}>HP</p> is equal to or less than 50% of their <p class=\"highlight-property\"}>Max HP</p>, restores HP equal to 8% of their Max HP and regenerates 5 <p class=\"highlight-property\"}>Energy</p>.",
        "zh-cn": "回合开始时，如果装备者当前<p class=\"highlight-property\"}>生命值</p>百分比小于等于50%，则回复等同于自身<p class=\"highlight-property\"}>生命上限</p>8%的生命值，并恢复5点<p class=\"highlight-property\"}>能量</p>。",
        "zh-tw": "回合開始時，如果裝備者當前<p class=\"highlight-property\"}>生命值</p>百分比小於等於50%，則回復等同於自身<p class=\"highlight-property\"}>生命上限</p>8%的生命值，並恢復5點<p class=\"highlight-property\"}>能量</p>。",
      }
    ],
    code: "GWS",
    colour: "#23369d",
    image: require("../components/image/relic/106_3.webp"),
  },
  {
    id: "107_0",
    name: "Firesmith's Obsidian Goggles",
    set: "Firesmith of Lava-Forging",
    type: "Cavern Relics",
    translation:{
      "en-us": "Firesmith's Obsidian Goggles",
      "zh-cn": "火匠的黑曜目镜",
      "zh-tw": "火匠的黑曜目鏡"
    },
    translation_set:{
      "en-us": "Firesmith of Lava-Forging",
      "zh-cn": "熔岩锻铸的火匠",
      "zh-tw": "熔岩鍛鑄的火匠"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Fire DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>火属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>火屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Skill DMG</p> by 12%. After unleashing <p class=\"highlight-property\"}>Ultimate</p>, increases the wearer's <p class=\"highlight-property\"}>Fire DMG</p> by 12% for next attack.",
        "zh-cn": "使装备者<p class=\"highlight-property\"}>战技造成的伤害</p>提高12%，并使施放<p class=\"highlight-property\"}>终结技</p>后的下一次攻击造成的<p class=\"highlight-property\"}>火属性伤害</p>提高12%。",
        "zh-tw": "使裝備者<p class=\"highlight-property\"}>戰技造成的傷害</p>提高12%，並使施放<p class=\"highlight-property\"}>終結技</p>後的下一次攻擊造成的<p class=\"highlight-property\"}>火屬性傷害</p>提高12%。",
      }
    ],
    code: "FLF",
    colour: "#ae2328",
    image: require("../components/image/relic/107_0.webp"),
  },
  {
    id: "107_1",
    name: "Firesmith's Ring of Flame-Mastery",
    set: "Firesmith of Lava-Forging",
    type: "Cavern Relics",
    translation:{
      "en-us": "Firesmith's Ring of Flame-Mastery",
      "zh-cn": "火匠的御火戒指",
      "zh-tw": "火匠的御火戒指"
    },
    translation_set:{
      "en-us": "Firesmith of Lava-Forging",
      "zh-cn": "熔岩锻铸的火匠",
      "zh-tw": "熔岩鍛鑄的火匠"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Fire DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>火属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>火屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Skill DMG</p> by 12%. After unleashing <p class=\"highlight-property\"}>Ultimate</p>, increases the wearer's <p class=\"highlight-property\"}>Fire DMG</p> by 12% for next attack.",
        "zh-cn": "使装备者<p class=\"highlight-property\"}>战技造成的伤害</p>提高12%，并使施放<p class=\"highlight-property\"}>终结技</p>后的下一次攻击造成的<p class=\"highlight-property\"}>火属性伤害</p>提高12%。",
        "zh-tw": "使裝備者<p class=\"highlight-property\"}>戰技造成的傷害</p>提高12%，並使施放<p class=\"highlight-property\"}>終結技</p>後的下一次攻擊造成的<p class=\"highlight-property\"}>火屬性傷害</p>提高12%。",
      }
    ],
    code: "FLF",
    colour: "#ae2328",
    image: require("../components/image/relic/107_1.webp"),
  },
  {
    id: "107_2",
    name: "Firesmith's Fireproof Apron",
    set: "Firesmith of Lava-Forging",
    type: "Cavern Relics",
    translation:{
      "en-us": "Firesmith's Fireproof Apron",
      "zh-cn": "火匠的阻燃围裙",
      "zh-tw": "火匠的阻燃圍裙"
    },
    translation_set:{
      "en-us": "Firesmith of Lava-Forging",
      "zh-cn": "熔岩锻铸的火匠",
      "zh-tw": "熔岩鍛鑄的火匠"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Fire DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>火属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>火屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Skill DMG</p> by 12%. After unleashing <p class=\"highlight-property\"}>Ultimate</p>, increases the wearer's <p class=\"highlight-property\"}>Fire DMG</p> by 12% for next attack.",
        "zh-cn": "使装备者<p class=\"highlight-property\"}>战技造成的伤害</p>提高12%，并使施放<p class=\"highlight-property\"}>终结技</p>后的下一次攻击造成的<p class=\"highlight-property\"}>火属性伤害</p>提高12%。",
        "zh-tw": "使裝備者<p class=\"highlight-property\"}>戰技造成的傷害</p>提高12%，並使施放<p class=\"highlight-property\"}>終結技</p>後的下一次攻擊造成的<p class=\"highlight-property\"}>火屬性傷害</p>提高12%。",
      }
    ],
    code: "FLF",
    colour: "#ae2328",
    image: require("../components/image/relic/107_2.webp"),
  },
  {
    id: "107_3",
    name: "Firesmith's Alloy Leg",
    set: "Firesmith of Lava-Forging",
    type: "Cavern Relics",
    translation:{
      "en-us": "Firesmith's Alloy Leg",
      "zh-cn": "火匠的合金义肢",
      "zh-tw": "火匠的合金義肢"
    },
    translation_set:{
      "en-us": "Firesmith of Lava-Forging",
      "zh-cn": "熔岩锻铸的火匠",
      "zh-tw": "熔岩鍛鑄的火匠"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Fire DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>火属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>火屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Skill DMG</p> by 12%. After unleashing <p class=\"highlight-property\"}>Ultimate</p>, increases the wearer's <p class=\"highlight-property\"}>Fire DMG</p> by 12% for next attack.",
        "zh-cn": "使装备者<p class=\"highlight-property\"}>战技造成的伤害</p>提高12%，并使施放<p class=\"highlight-property\"}>终结技</p>后的下一次攻击造成的<p class=\"highlight-property\"}>火属性伤害</p>提高12%。",
        "zh-tw": "使裝備者<p class=\"highlight-property\"}>戰技造成的傷害</p>提高12%，並使施放<p class=\"highlight-property\"}>終結技</p>後的下一次攻擊造成的<p class=\"highlight-property\"}>火屬性傷害</p>提高12%。",
      }
    ],
    code: "FLF",
    colour: "#ae2328",
    image: require("../components/image/relic/107_3.webp"),
  },
  {
    id: "108_0",
    name: "Genius's Ultraremote Sensing Visor",
    set: "Genius of Brilliant Stars",
    type: "Cavern Relics",
    translation:{
      "en-us": "Genius's Ultraremote Sensing Visor",
      "zh-cn": "天才的超距遥感",
      "zh-tw": "天才的超距遙感"
    },
    translation_set:{
      "en-us": "Genius of Brilliant Stars",
      "zh-cn": "繁星璀璨的天才",
      "zh-tw": "繁星璀璨的天才"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Quantum DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>量子属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>量子屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer deals DMG to the target enemy, ignores 10% <p class=\"highlight-property\"}>DEF</p>. If the target enemy has Quantum <p class=\"highlight-property\"}>Weakness</p>, the wearer additionally ignores 10% DEF.",
        "zh-cn": "当装备者对敌方目标造成伤害时，无视其10%的<p class=\"highlight-property\"}>防御力</p>。若目标拥有量子属性<p class=\"highlight-property\"}>弱点</p>，额外无视其10%的防御力。",
        "zh-tw": "當裝備者對敵方目標造成傷害時，無視其10%的<p class=\"highlight-property\"}>防禦力</p>。若目標擁有量子屬性<p class=\"highlight-property\"}>弱點</p>，額外無視其10%的防禦力。",
      }
    ],
    code: "GBS",
    colour: "#03e5fa",
    image: require("../components/image/relic/108_0.webp"),
  },
  {
    id: "108_1",
    name: "Genius's Frequency Catcher",
    set: "Genius of Brilliant Stars",
    type: "Cavern Relics",
    translation:{
      "en-us": "Genius's Frequency Catcher",
      "zh-cn": "天才的频变捕手",
      "zh-tw": "天才的頻變捕手"
    },
    translation_set:{
      "en-us": "Genius of Brilliant Stars",
      "zh-cn": "繁星璀璨的天才",
      "zh-tw": "繁星璀璨的天才"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Quantum DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>量子属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>量子屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer deals DMG to the target enemy, ignores 10% <p class=\"highlight-property\"}>DEF</p>. If the target enemy has Quantum <p class=\"highlight-property\"}>Weakness</p>, the wearer additionally ignores 10% DEF.",
        "zh-cn": "当装备者对敌方目标造成伤害时，无视其10%的<p class=\"highlight-property\"}>防御力</p>。若目标拥有量子属性<p class=\"highlight-property\"}>弱点</p>，额外无视其10%的防御力。",
        "zh-tw": "當裝備者對敵方目標造成傷害時，無視其10%的<p class=\"highlight-property\"}>防禦力</p>。若目標擁有量子屬性<p class=\"highlight-property\"}>弱點</p>，額外無視其10%的防禦力。",
      }
    ],
    code: "GBS",
    colour: "#03e5fa",
    image: require("../components/image/relic/108_1.webp"),
  },
  {
    id: "108_2",
    name: "Genius's Metafield Suit",
    set: "Genius of Brilliant Stars",
    type: "Cavern Relics",
    translation:{
      "en-us": "Genius's Metafield Suit",
      "zh-cn": "天才的元域深潜",
      "zh-tw": "天才的元域深潛"
    },
    translation_set:{
      "en-us": "Genius of Brilliant Stars",
      "zh-cn": "繁星璀璨的天才",
      "zh-tw": "繁星璀璨的天才"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Quantum DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>量子属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>量子屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer deals DMG to the target enemy, ignores 10% <p class=\"highlight-property\"}>DEF</p>. If the target enemy has Quantum <p class=\"highlight-property\"}>Weakness</p>, the wearer additionally ignores 10% DEF.",
        "zh-cn": "当装备者对敌方目标造成伤害时，无视其10%的<p class=\"highlight-property\"}>防御力</p>。若目标拥有量子属性<p class=\"highlight-property\"}>弱点</p>，额外无视其10%的防御力。",
        "zh-tw": "當裝備者對敵方目標造成傷害時，無視其10%的<p class=\"highlight-property\"}>防禦力</p>。若目標擁有量子屬性<p class=\"highlight-property\"}>弱點</p>，額外無視其10%的防禦力。",
      }
    ],
    code: "GBS",
    colour: "#03e5fa",
    image: require("../components/image/relic/108_2.webp"),
  },
  {
    id: "108_3",
    name: "Genius's Gravity Walker",
    set: "Genius of Brilliant Stars",
    type: "Cavern Relics",
    translation:{
      "en-us": "Genius's Gravity Walker",
      "zh-cn": "天才的引力漫步",
      "zh-tw": "天才的引力漫步"
    },
    translation_set:{
      "en-us": "Genius of Brilliant Stars",
      "zh-cn": "繁星璀璨的天才",
      "zh-tw": "繁星璀璨的天才"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Quantum DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>量子属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>量子屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer deals DMG to the target enemy, ignores 10% <p class=\"highlight-property\"}>DEF</p>. If the target enemy has Quantum <p class=\"highlight-property\"}>Weakness</p>, the wearer additionally ignores 10% DEF.",
        "zh-cn": "当装备者对敌方目标造成伤害时，无视其10%的<p class=\"highlight-property\"}>防御力</p>。若目标拥有量子属性<p class=\"highlight-property\"}>弱点</p>，额外无视其10%的防御力。",
        "zh-tw": "當裝備者對敵方目標造成傷害時，無視其10%的<p class=\"highlight-property\"}>防禦力</p>。若目標擁有量子屬性<p class=\"highlight-property\"}>弱點</p>，額外無視其10%的防禦力。",
      }
    ],
    code: "GBS",
    colour: "#03e5fa",
    image: require("../components/image/relic/108_3.webp"),
  },
  {
    id: "109_0",
    name: "Band's Polarized Sunglasses",
    set: "Band of Sizzling Thunder",
    type: "Cavern Relics",
    translation:{
      "en-us": "Band's Polarized Sunglasses",
      "zh-cn": "乐队的偏光墨镜",
      "zh-tw": "樂隊的偏光墨鏡"
    },
    translation_set:{
      "en-us": "Band of Sizzling Thunder",
      "zh-cn": "激奏雷电的乐队",
      "zh-tw": "激奏雷電的樂隊"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Lightning DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>雷属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>雷屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses <p class=\"highlight-property\"}>Skill</p>, increases the wearer's <p class=\"highlight-property\"}>ATK</p> by 20% for 1 turn(s).",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>战技</p>时，使装备者的<p class=\"highlight-property\"}>攻击力</p>提高20%，持续1回合。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>戰技</p>時，使裝備者的<p class=\"highlight-property\"}>攻擊力</p>提高20%，持續1回合。",
      }
    ],
    code: "BST",
    colour: "#943ff0",
    image: require("../components/image/relic/109_0.webp"),
  },
  {
    id: "109_1",
    name: "Band's Touring Bracelet",
    set: "Band of Sizzling Thunder",
    type: "Cavern Relics",
    translation:{
      "en-us": "Band's Touring Bracelet",
      "zh-cn": "乐队的巡演手绳",
      "zh-tw": "樂隊的巡演手繩"
    },
    translation_set:{
      "en-us": "Band of Sizzling Thunder",
      "zh-cn": "激奏雷电的乐队",
      "zh-tw": "激奏雷電的樂隊"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Lightning DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>雷属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>雷屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses <p class=\"highlight-property\"}>Skill</p>, increases the wearer's <p class=\"highlight-property\"}>ATK</p> by 20% for 1 turn(s).",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>战技</p>时，使装备者的<p class=\"highlight-property\"}>攻击力</p>提高20%，持续1回合。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>戰技</p>時，使裝備者的<p class=\"highlight-property\"}>攻擊力</p>提高20%，持續1回合。",
      }
    ],
    code: "BST",
    colour: "#943ff0",
    image: require("../components/image/relic/109_1.webp"),
  },
  {
    id: "109_2",
    name: "Band's Leather Jacket With Studs",
    set: "Band of Sizzling Thunder",
    type: "Cavern Relics",
    translation:{
      "en-us": "Band's Leather Jacket With Studs",
      "zh-cn": "乐队的钉刺皮衣",
      "zh-tw": "樂隊的釘刺皮衣"
    },
    translation_set:{
      "en-us": "Band of Sizzling Thunder",
      "zh-cn": "激奏雷电的乐队",
      "zh-tw": "激奏雷電的樂隊"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Lightning DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>雷属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>雷屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses <p class=\"highlight-property\"}>Skill</p>, increases the wearer's <p class=\"highlight-property\"}>ATK</p> by 20% for 1 turn(s).",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>战技</p>时，使装备者的<p class=\"highlight-property\"}>攻击力</p>提高20%，持续1回合。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>戰技</p>時，使裝備者的<p class=\"highlight-property\"}>攻擊力</p>提高20%，持續1回合。",
      }
    ],
    code: "BST",
    colour: "#943ff0",
    image: require("../components/image/relic/109_2.webp"),
  },
  {
    id: "109_3",
    name: "Band's Ankle Boots With Rivets",
    set: "Band of Sizzling Thunder",
    type: "Cavern Relics",
    translation:{
      "en-us": "Band's Ankle Boots With Rivets",
      "zh-cn": "乐队的铆钉短靴",
      "zh-tw": "樂隊的鉚釘短靴"
    },
    translation_set:{
      "en-us": "Band of Sizzling Thunder",
      "zh-cn": "激奏雷电的乐队",
      "zh-tw": "激奏雷電的樂隊"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Lightning DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>雷属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>雷屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses <p class=\"highlight-property\"}>Skill</p>, increases the wearer's <p class=\"highlight-property\"}>ATK</p> by 20% for 1 turn(s).",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>战技</p>时，使装备者的<p class=\"highlight-property\"}>攻击力</p>提高20%，持续1回合。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>戰技</p>時，使裝備者的<p class=\"highlight-property\"}>攻擊力</p>提高20%，持續1回合。",
      }
    ],
    code: "BST",
    colour: "#943ff0",
    image: require("../components/image/relic/109_3.webp"),
  },
  {
    id: "110_0",
    name: "Eagle's Beaked Helmet",
    set: "Eagle of Twilight Line",
    type: "Cavern Relics",
    translation:{
      "en-us": "Eagle's Beaked Helmet",
      "zh-cn": "翔鹰的长喙头盔",
      "zh-tw": "翔鷹的長喙頭盔"
    },
    translation_set:{
      "en-us": "Eagle of Twilight Line",
      "zh-cn": "晨昏交界的翔鹰",
      "zh-tw": "晨昏交界的翔鷹"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Wind DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>风属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>風屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer uses <p class=\"highlight-property\"}>Ultimate</p>, their action is <p class=\"highlight-property\"}>Advanced Forward</p> by 25%.",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>终结技</p>后，使其<p class=\"highlight-property\"}>行动提前</p>25%。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>終結技</p>後，使其<p class=\"highlight-property\"}>行動提前</p>25%。",
      }
    ],
    code: "ETL",
    colour: "#535671",
    image: require("../components/image/relic/110_0.webp"),
  },
  {
    id: "110_1",
    name: "Eagle's Soaring Ring",
    set: "Eagle of Twilight Line",
    type: "Cavern Relics",
    translation:{
      "en-us": "Eagle's Soaring Ring",
      "zh-cn": "翔鹰的鹰击指环",
      "zh-tw": "翔鷹的鷹擊指環"
    },
    translation_set:{
      "en-us": "Eagle of Twilight Line",
      "zh-cn": "晨昏交界的翔鹰",
      "zh-tw": "晨昏交界的翔鷹"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Wind DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>风属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>風屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer uses <p class=\"highlight-property\"}>Ultimate</p>, their action is <p class=\"highlight-property\"}>Advanced Forward</p> by 25%.",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>终结技</p>后，使其<p class=\"highlight-property\"}>行动提前</p>25%。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>終結技</p>後，使其<p class=\"highlight-property\"}>行動提前</p>25%。",
      }
    ],
    code: "ETL",
    colour: "#535671",
    image: require("../components/image/relic/110_1.webp"),
  },
  {
    id: "110_2",
    name: "Eagle's Winged Suit Harness",
    set: "Eagle of Twilight Line",
    type: "Cavern Relics",
    translation:{
      "en-us": "Eagle's Winged Suit Harness",
      "zh-cn": "翔鹰的翼装束带",
      "zh-tw": "翔鷹的翼裝束帶"
    },
    translation_set:{
      "en-us": "Eagle of Twilight Line",
      "zh-cn": "晨昏交界的翔鹰",
      "zh-tw": "晨昏交界的翔鷹"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Wind DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>风属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>風屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer uses <p class=\"highlight-property\"}>Ultimate</p>, their action is <p class=\"highlight-property\"}>Advanced Forward</p> by 25%.",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>终结技</p>后，使其<p class=\"highlight-property\"}>行动提前</p>25%。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>終結技</p>後，使其<p class=\"highlight-property\"}>行動提前</p>25%。",
      }
    ],
    code: "ETL",
    colour: "#535671",
    image: require("../components/image/relic/110_2.webp"),
  },
  {
    id: "110_3",
    name: "Eagle's Quilted Puttees",
    set: "Eagle of Twilight Line",
    type: "Cavern Relics",
    translation:{
      "en-us": "Eagle's Quilted Puttees",
      "zh-cn": "翔鹰的绒羽绑带",
      "zh-tw": "翔鷹的絨羽綁帶"
    },
    translation_set:{
      "en-us": "Eagle of Twilight Line",
      "zh-cn": "晨昏交界的翔鹰",
      "zh-tw": "晨昏交界的翔鷹"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Wind DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>风属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>風屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "After the wearer uses <p class=\"highlight-property\"}>Ultimate</p>, their action is <p class=\"highlight-property\"}>Advanced Forward</p> by 25%.",
        "zh-cn": "当装备者施放<p class=\"highlight-property\"}>终结技</p>后，使其<p class=\"highlight-property\"}>行动提前</p>25%。",
        "zh-tw": "當裝備者施放<p class=\"highlight-property\"}>終結技</p>後，使其<p class=\"highlight-property\"}>行動提前</p>25%。",
      }
    ],
    code: "ETL",
    colour: "#535671",
    image: require("../components/image/relic/110_3.webp"),
  },
  {
    id: "111_0",
    name: "Thief's Myriad-Faced Mask",
    set: "Thief of Shooting Meteor",
    type: "Cavern Relics",
    translation:{
      "en-us": "Thief's Myriad-Faced Mask",
      "zh-cn": "怪盗的千人假面",
      "zh-tw": "怪盜的千人假面"
    },
    translation_set:{
      "en-us": "Thief of Shooting Meteor",
      "zh-cn": "流星追迹的怪盗",
      "zh-tw": "流星追蹟的怪盜"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Break Effect</p> by 16%. When the wearer inflicts <p class=\"highlight-property\"}>Weakness Break</p> on an enemy, regenerates 3 <p class=\"highlight-property\"}>Energy</p>.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>击破特攻</p>提高16%。当装备者击<p class=\"highlight-property\"}>破敌方目标弱点</p>后，恢复3点<p class=\"highlight-property\"}>能量</p>。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>擊破特攻</p>提高16%。當裝備者擊<p class=\"highlight-property\"}>破敵方目標弱點</p>後，恢復3點<p class=\"highlight-property\"}>能量</p>。",
      }
    ],
    code: "TSM",
    colour: "#2a38ad",
    image: require("../components/image/relic/111_0.webp"),
  },
  {
    id: "111_1",
    name: "Thief's Gloves With Prints",
    set: "Thief of Shooting Meteor",
    type: "Cavern Relics",
    translation:{
      "en-us": "Thief's Gloves With Prints",
      "zh-cn": "怪盗的绘纹手套",
      "zh-tw": "怪盜的繪紋手套"
    },
    translation_set:{
      "en-us": "Thief of Shooting Meteor",
      "zh-cn": "流星追迹的怪盗",
      "zh-tw": "流星追蹟的怪盜"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Break Effect</p> by 16%. When the wearer inflicts <p class=\"highlight-property\"}>Weakness Break</p> on an enemy, regenerates 3 <p class=\"highlight-property\"}>Energy</p>.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>击破特攻</p>提高16%。当装备者击<p class=\"highlight-property\"}>破敌方目标弱点</p>后，恢复3点<p class=\"highlight-property\"}>能量</p>。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>擊破特攻</p>提高16%。當裝備者擊<p class=\"highlight-property\"}>破敵方目標弱點</p>後，恢復3點<p class=\"highlight-property\"}>能量</p>。",
      }
    ],
    code: "TSM",
    colour: "#2a38ad",
    image: require("../components/image/relic/111_1.webp"),
  },
  {
    id: "111_2",
    name: "Thief's Steel Grappling Hook",
    set: "Thief of Shooting Meteor",
    type: "Cavern Relics",
    translation:{
      "en-us": "Thief's Steel Grappling Hook",
      "zh-cn": "怪盗的纤钢爪钩",
      "zh-tw": "怪盜的纖鋼爪鉤"
    },
    translation_set:{
      "en-us": "Thief of Shooting Meteor",
      "zh-cn": "流星追迹的怪盗",
      "zh-tw": "流星追蹟的怪盜"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Break Effect</p> by 16%. When the wearer inflicts <p class=\"highlight-property\"}>Weakness Break</p> on an enemy, regenerates 3 <p class=\"highlight-property\"}>Energy</p>.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>击破特攻</p>提高16%。当装备者击<p class=\"highlight-property\"}>破敌方目标弱点</p>后，恢复3点<p class=\"highlight-property\"}>能量</p>。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>擊破特攻</p>提高16%。當裝備者擊<p class=\"highlight-property\"}>破敵方目標弱點</p>後，恢復3點<p class=\"highlight-property\"}>能量</p>。",
      }
    ],
    code: "TSM",
    colour: "#2a38ad",
    image: require("../components/image/relic/111_2.webp"),
  },
  {
    id: "111_3",
    name: "Thief's Meteor Boots",
    set: "Thief of Shooting Meteor",
    type: "Cavern Relics",
    translation:{
      "en-us": "Thief's Meteor Boots",
      "zh-cn": "怪盗的流星快靴",
      "zh-tw": "怪盜的流星快靴"
    },
    translation_set:{
      "en-us": "Thief of Shooting Meteor",
      "zh-cn": "流星追迹的怪盗",
      "zh-tw": "流星追蹟的怪盜"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Break Effect</p> by 16%. When the wearer inflicts <p class=\"highlight-property\"}>Weakness Break</p> on an enemy, regenerates 3 <p class=\"highlight-property\"}>Energy</p>.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>击破特攻</p>提高16%。当装备者击<p class=\"highlight-property\"}>破敌方目标弱点</p>后，恢复3点<p class=\"highlight-property\"}>能量</p>。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>擊破特攻</p>提高16%。當裝備者擊<p class=\"highlight-property\"}>破敵方目標弱點</p>後，恢復3點<p class=\"highlight-property\"}>能量</p>。",
      }
    ],
    code: "TSM",
    colour: "#2a38ad",
    image: require("../components/image/relic/111_3.webp"),
  },
  {
    id: "112_0",
    name: "Wastelander's Breathing Mask",
    set: "Wastelander of Banditry Desert",
    type: "Cavern Relics",
    translation:{
      "en-us": "Wastelander's Breathing Mask",
      "zh-cn": "废土客的呼吸面罩",
      "zh-tw": "廢土客的呼吸面罩"
    },
    translation_set:{
      "en-us": "Wastelander of Banditry Desert",
      "zh-cn": "盗匪荒漠的废土客",
      "zh-tw": "盜匪荒漠的廢土客"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Imaginary DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>虚数属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>虛數屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When attacking debuffed enemies, the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> increases by 10%. If the enemy is Imprisoned, then the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> increases by 20%.",
        "zh-cn": "装备者对陷入负面效果的敌方目标造成伤害时<p class=\"highlight-property\"}>暴击率</p>提高10%，对陷入禁锢状态的敌方目标造成伤害时<p class=\"highlight-property\"}>暴击伤害</p>提高20%。",
        "zh-tw": "裝備者對陷入負面效果的敵方目標造成傷害時<p class=\"highlight-property\"}>暴擊率</p>提高10%，對陷入禁錮狀態的敵方目標造成傷害時<p class=\"highlight-property\"}>暴擊傷害</p>提高20%。",
      }
    ],
    code: "WBD",
    colour: "#977f71",
    image: require("../components/image/relic/112_0.webp"),
  },
  {
    id: "112_1",
    name: "Wastelander's Desert Terminal",
    set: "Wastelander of Banditry Desert",
    type: "Cavern Relics",
    translation:{
      "en-us": "Wastelander's Desert Terminal",
      "zh-cn": "废土客的荒漠终端",
      "zh-tw": "廢土客的荒漠終端"
    },
    translation_set:{
      "en-us": "Wastelander of Banditry Desert",
      "zh-cn": "盗匪荒漠的废土客",
      "zh-tw": "盜匪荒漠的廢土客"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Imaginary DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>虚数属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>虛數屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When attacking debuffed enemies, the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> increases by 10%. If the enemy is Imprisoned, then the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> increases by 20%.",
        "zh-cn": "装备者对陷入负面效果的敌方目标造成伤害时<p class=\"highlight-property\"}>暴击率</p>提高10%，对陷入禁锢状态的敌方目标造成伤害时<p class=\"highlight-property\"}>暴击伤害</p>提高20%。",
        "zh-tw": "裝備者對陷入負面效果的敵方目標造成傷害時<p class=\"highlight-property\"}>暴擊率</p>提高10%，對陷入禁錮狀態的敵方目標造成傷害時<p class=\"highlight-property\"}>暴擊傷害</p>提高20%。",
      }
    ],
    code: "WBD",
    colour: "#977f71",
    image: require("../components/image/relic/112_1.webp"),
  },
  {
    id: "112_2",
    name: "Wastelander's Friar Robe",
    set: "Wastelander of Banditry Desert",
    type: "Cavern Relics",
    translation:{
      "en-us": "Wastelander's Friar Robe",
      "zh-cn": "废土客的修士长袍",
      "zh-tw": "廢土客的修士長袍"
    },
    translation_set:{
      "en-us": "Wastelander of Banditry Desert",
      "zh-cn": "盗匪荒漠的废土客",
      "zh-tw": "盜匪荒漠的廢土客"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Imaginary DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>虚数属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>虛數屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When attacking debuffed enemies, the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> increases by 10%. If the enemy is Imprisoned, then the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> increases by 20%.",
        "zh-cn": "装备者对陷入负面效果的敌方目标造成伤害时<p class=\"highlight-property\"}>暴击率</p>提高10%，对陷入禁锢状态的敌方目标造成伤害时<p class=\"highlight-property\"}>暴击伤害</p>提高20%。",
        "zh-tw": "裝備者對陷入負面效果的敵方目標造成傷害時<p class=\"highlight-property\"}>暴擊率</p>提高10%，對陷入禁錮狀態的敵方目標造成傷害時<p class=\"highlight-property\"}>暴擊傷害</p>提高20%。",
      }
    ],
    code: "WBD",
    colour: "#977f71",
    image: require("../components/image/relic/112_2.webp"),
  },
  {
    id: "112_3",
    name: "Wastelander's Powered Greaves",
    set: "Wastelander of Banditry Desert",
    type: "Cavern Relics",
    translation:{
      "en-us": "Wastelander's Powered Greaves",
      "zh-cn": "废土客的动力腿甲",
      "zh-tw": "廢土客的動力腿甲"
    },
    translation_set:{
      "en-us": "Wastelander of Banditry Desert",
      "zh-cn": "盗匪荒漠的废土客",
      "zh-tw": "盜匪荒漠的廢土客"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Imaginary DMG</p> by 10%.",
        "zh-cn": "<p class=\"highlight-property\"}>虚数属性伤害</p>提高10%。",
        "zh-tw": "<p class=\"highlight-property\"}>虛數屬性傷害</p>提高10%。",
      },
      {
        "set" : 4,
        "en-us": "When attacking debuffed enemies, the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> increases by 10%. If the enemy is Imprisoned, then the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> increases by 20%.",
        "zh-cn": "装备者对陷入负面效果的敌方目标造成伤害时<p class=\"highlight-property\"}>暴击率</p>提高10%，对陷入禁锢状态的敌方目标造成伤害时<p class=\"highlight-property\"}>暴击伤害</p>提高20%。",
        "zh-tw": "裝備者對陷入負面效果的敵方目標造成傷害時<p class=\"highlight-property\"}>暴擊率</p>提高10%，對陷入禁錮狀態的敵方目標造成傷害時<p class=\"highlight-property\"}>暴擊傷害</p>提高20%。",
      }
    ],
    code: "WBD",
    colour: "#977f71",
    image: require("../components/image/relic/112_3.webp"),
  },
  {
    id: "113_0",
    name: "Disciple's Prosthetic Eye",
    set: "Longevous Disciple",
    type: "Cavern Relics",
    translation:{
      "en-us": "Disciple's Prosthetic Eye",
      "zh-cn": "莳者的复明义眼",
      "zh-tw": "蒔者的複明義眼"
    },
    translation_set:{
      "en-us": "Longevous Disciple",
      "zh-cn": "宝命长存的莳者",
      "zh-tw": "寶命長存的蒔者"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Max HP</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>生命上限</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>生命上限</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer is hit or has their <p class=\"highlight-property\"}>HP consumed</p> by an ally or themselves, their <p class=\"highlight-property\"}>CRIT Rate</p> increases by 8% for 2 turn(s) and up to 2 stacks.",
        "zh-cn": "当装备者受到攻击或被我方目标<p class=\"highlight-property\"}>消耗生命值</p>后，<p class=\"highlight-property\"}>暴击率</p>提高8%，持续2回合，该效果最多叠加2层。",
        "zh-tw": "當裝備者受到攻擊或被我方目標<p class=\"highlight-property\"}>消耗生命值</p>後，<p class=\"highlight-property\"}>暴擊率</p>提高8%，持續2回合，該效果最多疊加2層。",
      }
    ],
    code: "LD",
    colour: "#54848e",
    image: require("../components/image/relic/113_0.webp"),
  },
  {
    id: "113_1",
    name: "Disciple's Ingenium Hand",
    set: "Longevous Disciple",
    type: "Cavern Relics",
    translation:{
      "en-us": "Disciple's Ingenium Hand",
      "zh-cn": "莳者的机巧木手",
      "zh-tw": "蒔者的機巧木手"
    },
    translation_set:{
      "en-us": "Longevous Disciple",
      "zh-cn": "宝命长存的莳者",
      "zh-tw": "寶命長存的蒔者"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Max HP</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>生命上限</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>生命上限</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer is hit or has their <p class=\"highlight-property\"}>HP consumed</p> by an ally or themselves, their <p class=\"highlight-property\"}>CRIT Rate</p> increases by 8% for 2 turn(s) and up to 2 stacks.",
        "zh-cn": "当装备者受到攻击或被我方目标<p class=\"highlight-property\"}>消耗生命值</p>后，<p class=\"highlight-property\"}>暴击率</p>提高8%，持续2回合，该效果最多叠加2层。",
        "zh-tw": "當裝備者受到攻擊或被我方目標<p class=\"highlight-property\"}>消耗生命值</p>後，<p class=\"highlight-property\"}>暴擊率</p>提高8%，持續2回合，該效果最多疊加2層。",
      }
    ],
    code: "LD",
    colour: "#54848e",
    image: require("../components/image/relic/113_1.webp"),
  },
  {
    id: "113_2",
    name: "Disciple's Dewy Feather Garb",
    set: "Longevous Disciple",
    type: "Cavern Relics",
    translation:{
      "en-us": "Disciple's Dewy Feather Garb",
      "zh-cn": "莳者的承露羽衣",
      "zh-tw": "蒔者的承露羽衣"
    },
    translation_set:{
      "en-us": "Longevous Disciple",
      "zh-cn": "宝命长存的莳者",
      "zh-tw": "寶命長存的蒔者"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Max HP</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>生命上限</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>生命上限</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer is hit or has their <p class=\"highlight-property\"}>HP consumed</p> by an ally or themselves, their <p class=\"highlight-property\"}>CRIT Rate</p> increases by 8% for 2 turn(s) and up to 2 stacks.",
        "zh-cn": "当装备者受到攻击或被我方目标<p class=\"highlight-property\"}>消耗生命值</p>后，<p class=\"highlight-property\"}>暴击率</p>提高8%，持续2回合，该效果最多叠加2层。",
        "zh-tw": "當裝備者受到攻擊或被我方目標<p class=\"highlight-property\"}>消耗生命值</p>後，<p class=\"highlight-property\"}>暴擊率</p>提高8%，持續2回合，該效果最多疊加2層。",
      }
    ],
    code: "LD",
    colour: "#54848e",
    image: require("../components/image/relic/113_2.webp"),
  },
  {
    id: "113_3",
    name: "Disciple's Celestial Silk Sandals",
    set: "Longevous Disciple",
    type: "Cavern Relics",
    translation:{
      "en-us": "Disciple's Celestial Silk Sandals",
      "zh-cn": "莳者的天人丝履",
      "zh-tw": "蒔者的天人絲履"
    },
    translation_set:{
      "en-us": "Longevous Disciple",
      "zh-cn": "宝命长存的莳者",
      "zh-tw": "寶命長存的蒔者"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Max HP</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>生命上限</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>生命上限</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer is hit or has their <p class=\"highlight-property\"}>HP consumed</p> by an ally or themselves, their <p class=\"highlight-property\"}>CRIT Rate</p> increases by 8% for 2 turn(s) and up to 2 stacks.",
        "zh-cn": "当装备者受到攻击或被我方目标<p class=\"highlight-property\"}>消耗生命值</p>后，<p class=\"highlight-property\"}>暴击率</p>提高8%，持续2回合，该效果最多叠加2层。",
        "zh-tw": "當裝備者受到攻擊或被我方目標<p class=\"highlight-property\"}>消耗生命值</p>後，<p class=\"highlight-property\"}>暴擊率</p>提高8%，持續2回合，該效果最多疊加2層。",
      }
    ],
    code: "LD",
    colour: "#54848e",
    image: require("../components/image/relic/113_3.webp"),
  },
  {
    id: "114_0",
    name: "Messenger's Holovisor",
    set: "Messenger Traversing Hackerspace",
    type: "Cavern Relics",
    translation:{
      "en-us": "Messenger's Holovisor",
      "zh-cn": "信使的全息目镜",
      "zh-tw": "信使的全息目鏡"
    },
    translation_set:{
      "en-us": "Messenger Traversing Hackerspace",
      "zh-cn": "骇域漫游的信使",
      "zh-tw": "駭域漫遊的信使"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>SPD</p> by 6%.",
        "zh-cn": "<p class=\"highlight-property\"}>速度</p>提高6%。",
        "zh-tw": "<p class=\"highlight-property\"}>速度</p>提高6%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses their <p class=\"highlight-property\"}>Ultimate</p> on an ally, <p class=\"highlight-property\"}>SPD</p> for all allies increases by 12% for 1 turn(s). This effect cannot be stacked.",
        "zh-cn": "当装备者对我方目标施放<p class=\"highlight-property\"}>终结技</p>时，我方全体<p class=\"highlight-property\"}>速度</p>提高12%，持续1回合，该效果无法叠加。",
        "zh-tw": "當裝備者對我方目標施放<p class=\"highlight-property\"}>終結技</p>時，我方全體<p class=\"highlight-property\"}>速度</p>提高12%，持續1回合，該效果無法疊加。",
      }
    ],
    code: "MTH",
    colour: "#54848e",
    image: require("../components/image/relic/114_0.webp"),
  },
  {
    id: "114_1",
    name: "Messenger's Transformative Arm",
    set: "Messenger Traversing Hackerspace",
    type: "Cavern Relics",
    translation:{
      "en-us": "Messenger's Transformative Arm",
      "zh-cn": "信使的百变义手",
      "zh-tw": "信使的百變義手"
    },
    translation_set:{
      "en-us": "Messenger Traversing Hackerspace",
      "zh-cn": "骇域漫游的信使",
      "zh-tw": "駭域漫遊的信使"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>SPD</p> by 6%.",
        "zh-cn": "<p class=\"highlight-property\"}>速度</p>提高6%。",
        "zh-tw": "<p class=\"highlight-property\"}>速度</p>提高6%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses their <p class=\"highlight-property\"}>Ultimate</p> on an ally, <p class=\"highlight-property\"}>SPD</p> for all allies increases by 12% for 1 turn(s). This effect cannot be stacked.",
        "zh-cn": "当装备者对我方目标施放<p class=\"highlight-property\"}>终结技</p>时，我方全体<p class=\"highlight-property\"}>速度</p>提高12%，持续1回合，该效果无法叠加。",
        "zh-tw": "當裝備者對我方目標施放<p class=\"highlight-property\"}>終結技</p>時，我方全體<p class=\"highlight-property\"}>速度</p>提高12%，持續1回合，該效果無法疊加。",
      }
    ],
    code: "MTH",
    colour: "#54848e",
    image: require("../components/image/relic/114_1.webp"),
  },
  {
    id: "114_2",
    name: "Messenger's Secret Satchel",
    set: "Messenger Traversing Hackerspace",
    type: "Cavern Relics",
    translation:{
      "en-us": "Messenger's Secret Satchel",
      "zh-cn": "信使的密信挎包",
      "zh-tw": "信使的密信挎包"
    },
    translation_set:{
      "en-us": "Messenger Traversing Hackerspace",
      "zh-cn": "骇域漫游的信使",
      "zh-tw": "駭域漫遊的信使"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>SPD</p> by 6%.",
        "zh-cn": "<p class=\"highlight-property\"}>速度</p>提高6%。",
        "zh-tw": "<p class=\"highlight-property\"}>速度</p>提高6%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses their <p class=\"highlight-property\"}>Ultimate</p> on an ally, <p class=\"highlight-property\"}>SPD</p> for all allies increases by 12% for 1 turn(s). This effect cannot be stacked.",
        "zh-cn": "当装备者对我方目标施放<p class=\"highlight-property\"}>终结技</p>时，我方全体<p class=\"highlight-property\"}>速度</p>提高12%，持续1回合，该效果无法叠加。",
        "zh-tw": "當裝備者對我方目標施放<p class=\"highlight-property\"}>終結技</p>時，我方全體<p class=\"highlight-property\"}>速度</p>提高12%，持續1回合，該效果無法疊加。",
      }
    ],
    code: "MTH",
    colour: "#54848e",
    image: require("../components/image/relic/114_2.webp"),
  },
  {
    id: "114_3",
    name: "Messenger's Par-kool Sneakers",
    set: "Messenger Traversing Hackerspace",
    type: "Cavern Relics",
    translation:{
      "en-us": "Messenger's Par-kool Sneakers",
      "zh-cn": "信使的酷跑板鞋",
      "zh-tw": "信使的酷跑板鞋"
    },
    translation_set:{
      "en-us": "Messenger Traversing Hackerspace",
      "zh-cn": "骇域漫游的信使",
      "zh-tw": "駭域漫遊的信使"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>SPD</p> by 6%.",
        "zh-cn": "<p class=\"highlight-property\"}>速度</p>提高6%。",
        "zh-tw": "<p class=\"highlight-property\"}>速度</p>提高6%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses their <p class=\"highlight-property\"}>Ultimate</p> on an ally, <p class=\"highlight-property\"}>SPD</p> for all allies increases by 12% for 1 turn(s). This effect cannot be stacked.",
        "zh-cn": "当装备者对我方目标施放<p class=\"highlight-property\"}>终结技</p>时，我方全体<p class=\"highlight-property\"}>速度</p>提高12%，持续1回合，该效果无法叠加。",
        "zh-tw": "當裝備者對我方目標施放<p class=\"highlight-property\"}>終結技</p>時，我方全體<p class=\"highlight-property\"}>速度</p>提高12%，持續1回合，該效果無法疊加。",
      }
    ],
    code: "MTH",
    colour: "#54848e",
    image: require("../components/image/relic/114_3.webp"),
  },
  
  {
    id: "115_0",
    name: "Grand Duke's Crown of Netherflame",
    set: "The Ashblazing Grand Duke",
    type: "Cavern Relics",
    translation:{
      "en-us": "Grand Duke's Crown of Netherflame",
      "zh-cn": "大公的冥焰冠冕",
      "zh-tw": "大公的冥焰冠冕"
    },
    translation_set:{
      "en-us": "The Ashblazing Grand Duke",
      "zh-cn": "毁烬焚骨的大公",
      "zh-tw": "毀燼焚骨的大公"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the DMG dealt by <p class=\"highlight-property\"}>follow-up attacks</p> by 20%.",
        "zh-cn": "<p class=\"highlight-property\"}>追加攻击</p>造成的伤害提高20%。",
        "zh-tw": "<p class=\"highlight-property\"}>追加攻擊</p>造成的傷害增加20%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses <p class=\"highlight-property\"}>follow-up attacks</p>, increases the wearer's ATK by 6% for every time the follow-up attack deals DMG. This effect can stack up to 8 time(s) and lasts for 3 turn(s). This effect is removed the next time the wearer uses a follow-up attack.",
        "zh-cn": "装备者施放<p class=\"highlight-property\"}>追加攻击</p>时，根据追加攻击造成伤害的次数，每次造成伤害时使装备者的攻击力提高6%，最多叠加8次，持续3回合。该效果在装备者下一次施放追加攻击时移除。",
        "zh-tw": "裝備者施放<p class=\"highlight-property\"}>追加攻擊</p>時，根據追加攻擊造成傷害的次數，每次造成傷害時使裝備者的攻擊力提高6%，最多疊加8次，持續3回合。該效果在裝備者下一次施放追加攻擊時移除。",
      }
    ],
    code: "AGD",
    colour: "#ECB811",
    image: require("../components/image/relic/115_0.webp"),
  },
  {
    id: "115_1",
    name: "Grand Duke's Gloves of Fieryfur",
    set: "The Ashblazing Grand Duke",
    type: "Cavern Relics",
    translation:{
      "en-us": "Grand Duke's Gloves of Fieryfur",
      "zh-cn": "大公的绒火指套",
      "zh-tw": "大公的絨火指套"
    },
    translation_set:{
      "en-us": "The Ashblazing Grand Duke",
      "zh-cn": "毁烬焚骨的大公",
      "zh-tw": "毀燼焚骨的大公"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the DMG dealt by <p class=\"highlight-property\"}>follow-up attacks</p> by 20%.",
        "zh-cn": "<p class=\"highlight-property\"}>追加攻击</p>造成的伤害提高20%。",
        "zh-tw": "<p class=\"highlight-property\"}>追加攻擊</p>造成的傷害增加20%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses <p class=\"highlight-property\"}>follow-up attacks</p>, increases the wearer's ATK by 6% for every time the follow-up attack deals DMG. This effect can stack up to 8 time(s) and lasts for 3 turn(s). This effect is removed the next time the wearer uses a follow-up attack.",
        "zh-cn": "装备者施放<p class=\"highlight-property\"}>追加攻击</p>时，根据追加攻击造成伤害的次数，每次造成伤害时使装备者的攻击力提高6%，最多叠加8次，持续3回合。该效果在装备者下一次施放追加攻击时移除。",
        "zh-tw": "裝備者施放<p class=\"highlight-property\"}>追加攻擊</p>時，根據追加攻擊造成傷害的次數，每次造成傷害時使裝備者的攻擊力提高6%，最多疊加8次，持續3回合。該效果在裝備者下一次施放追加攻擊時移除。",
      }
    ],
    code: "AGD",
    colour: "#ECB811",
    image: require("../components/image/relic/115_1.webp"),
  },
  {
    id: "115_2",
    name: "Grand Duke's Robe of Grace",
    set: "The Ashblazing Grand Duke",
    type: "Cavern Relics",
    translation:{
      "en-us": "Grand Duke's Robe of Grace",
      "zh-cn": "大公的蒙恩长袍",
      "zh-tw": "大公的蒙恩長袍"
    },
    translation_set:{
      "en-us": "The Ashblazing Grand Duke",
      "zh-cn": "毁烬焚骨的大公",
      "zh-tw": "毀燼焚骨的大公"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the DMG dealt by <p class=\"highlight-property\"}>follow-up attacks</p> by 20%.",
        "zh-cn": "<p class=\"highlight-property\"}>追加攻击</p>造成的伤害提高20%。",
        "zh-tw": "<p class=\"highlight-property\"}>追加攻擊</p>造成的傷害增加20%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses <p class=\"highlight-property\"}>follow-up attacks</p>, increases the wearer's ATK by 6% for every time the follow-up attack deals DMG. This effect can stack up to 8 time(s) and lasts for 3 turn(s). This effect is removed the next time the wearer uses a follow-up attack.",
        "zh-cn": "装备者施放<p class=\"highlight-property\"}>追加攻击</p>时，根据追加攻击造成伤害的次数，每次造成伤害时使装备者的攻击力提高6%，最多叠加8次，持续3回合。该效果在装备者下一次施放追加攻击时移除。",
        "zh-tw": "裝備者施放<p class=\"highlight-property\"}>追加攻擊</p>時，根據追加攻擊造成傷害的次數，每次造成傷害時使裝備者的攻擊力提高6%，最多疊加8次，持續3回合。該效果在裝備者下一次施放追加攻擊時移除。",
      }
    ],
    code: "AGD",
    colour: "#ECB811",
    image: require("../components/image/relic/115_2.webp"),
  },
  {
    id: "115_3",
    name: "Grand Duke's Ceremonial Boots",
    set: "The Ashblazing Grand Duke",
    type: "Cavern Relics",
    translation:{
      "en-us": "Grand Duke's Ceremonial Boots",
      "zh-cn": "大公的绅雅礼靴",
      "zh-tw": "大公的紳雅禮靴"
    },
    translation_set:{
      "en-us": "The Ashblazing Grand Duke",
      "zh-cn": "毁烬焚骨的大公",
      "zh-tw": "毀燼焚骨的大公"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the DMG dealt by <p class=\"highlight-property\"}>follow-up attacks</p> by 20%.",
        "zh-cn": "<p class=\"highlight-property\"}>追加攻击</p>造成的伤害提高20%。",
        "zh-tw": "<p class=\"highlight-property\"}>追加攻擊</p>造成的傷害增加20%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses <p class=\"highlight-property\"}>follow-up attacks</p>, increases the wearer's ATK by 6% for every time the follow-up attack deals DMG. This effect can stack up to 8 time(s) and lasts for 3 turn(s). This effect is removed the next time the wearer uses a follow-up attack.",
        "zh-cn": "装备者施放<p class=\"highlight-property\"}>追加攻击</p>时，根据追加攻击造成伤害的次数，每次造成伤害时使装备者的攻击力提高6%，最多叠加8次，持续3回合。该效果在装备者下一次施放追加攻击时移除。",
        "zh-tw": "裝備者施放<p class=\"highlight-property\"}>追加攻擊</p>時，根據追加攻擊造成傷害的次數，每次造成傷害時使裝備者的攻擊力提高6%，最多疊加8次，持續3回合。該效果在裝備者下一次施放追加攻擊時移除。",
      }
    ],
    code: "AGD",
    colour: "#ECB811",
    image: require("../components/image/relic/115_3.webp"),
  },

  {
    id: "116_0",
    name: "Prisoner's Sealed Muzzle",
    set: "Prisoner in Deep Confinement",
    type: "Cavern Relics",
    translation:{
      "en-us": "Prisoner's Sealed Muzzle",
      "zh-cn": "系囚的合啮拘笼",
      "zh-tw": "繫囚的合齧拘籠"
    },
    translation_set:{
      "en-us": "Prisoner in Deep Confinement",
      "zh-cn": "幽锁深牢的系囚",
      "zh-tw": "幽鎖深牢的繫囚"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "<p class=\"highlight-property\"}>ATK</p> increases by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>增加12%。",
      },
      {
        "set" : 4,
        "en-us": "For every <p class=\"highlight-property\"}>DoT</p> the target enemy is afflicted with, the wearer will ignore 6% of its DEF when dealing DMG to it. This effect is valid for a max of 3 DoTs.",
        "zh-cn": "敌方目标每承受1个<p class=\"highlight-property\"}>持续伤害</p>效果，装备者对其造成伤害时就无视其6%的防御力，最多计入3个持续伤害效果。",
        "zh-tw": "敵方目標每承受1個<p class=\"highlight-property\"}>持續傷害</p>效果，裝備者對其造成傷害時就無視其6%的防禦力，最多計入3個持續傷害效果。",
      }
    ],
    code: "PDC",
    colour: "#7E9DA0",
    image: require("../components/image/relic/116_0.webp"),
  },
  
  {
    id: "116_1",
    name: "Prisoner's Leadstone Shackles",
    set: "Prisoner in Deep Confinement",
    type: "Cavern Relics",
    translation:{
      "en-us": "Prisoner's Leadstone Shackles",
      "zh-cn": "系囚的铅石梏铐",
      "zh-tw": "繫囚的鉛石梏銬"
    },
    translation_set:{
      "en-us": "Prisoner in Deep Confinement",
      "zh-cn": "幽锁深牢的系囚",
      "zh-tw": "幽鎖深牢的繫囚"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "<p class=\"highlight-property\"}>ATK</p> increases by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>增加12%。",
      },
      {
        "set" : 4,
        "en-us": "For every <p class=\"highlight-property\"}>DoT</p> the target enemy is afflicted with, the wearer will ignore 6% of its DEF when dealing DMG to it. This effect is valid for a max of 3 DoTs.",
        "zh-cn": "敌方目标每承受1个<p class=\"highlight-property\"}>持续伤害</p>效果，装备者对其造成伤害时就无视其6%的防御力，最多计入3个持续伤害效果。",
        "zh-tw": "敵方目標每承受1個<p class=\"highlight-property\"}>持續傷害</p>效果，裝備者對其造成傷害時就無視其6%的防禦力，最多計入3個持續傷害效果。",
      }
    ],
    code: "PDC",
    colour: "#7E9DA0",
    image: require("../components/image/relic/116_1.webp"),
  },
  
  {
    id: "116_2",
    name: "Prisoner's Repressive Straitjacket",
    set: "Prisoner in Deep Confinement",
    type: "Cavern Relics",
    translation:{
      "en-us": "Prisoner's Repressive Straitjacket",
      "zh-cn": "系囚的幽闭缚束",
      "zh-tw": "繫囚的幽閉縛束"
    },
    translation_set:{
      "en-us": "Prisoner in Deep Confinement",
      "zh-cn": "幽锁深牢的系囚",
      "zh-tw": "幽鎖深牢的繫囚"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "<p class=\"highlight-property\"}>ATK</p> increases by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>增加12%。",
      },
      {
        "set" : 4,
        "en-us": "For every <p class=\"highlight-property\"}>DoT</p> the target enemy is afflicted with, the wearer will ignore 6% of its DEF when dealing DMG to it. This effect is valid for a max of 3 DoTs.",
        "zh-cn": "敌方目标每承受1个<p class=\"highlight-property\"}>持续伤害</p>效果，装备者对其造成伤害时就无视其6%的防御力，最多计入3个持续伤害效果。",
        "zh-tw": "敵方目標每承受1個<p class=\"highlight-property\"}>持續傷害</p>效果，裝備者對其造成傷害時就無視其6%的防禦力，最多計入3個持續傷害效果。",
      }
    ],
    code: "PDC",
    colour: "#7E9DA0",
    image: require("../components/image/relic/116_2.webp"),
  },
  
  {
    id: "116_3",
    name: "Prisoner's Restrictive Fetters",
    set: "Prisoner in Deep Confinement",
    type: "Cavern Relics",
    translation:{
      "en-us": "Prisoner's Restrictive Fetters",
      "zh-cn": "系囚的绝足锁桎",
      "zh-tw": "繫囚的合齧拘籠"
    },
    translation_set:{
      "en-us": "Prisoner in Deep Confinement",
      "zh-cn": "幽锁深牢的系囚",
      "zh-tw": "繫囚的絕足鎖桎"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "<p class=\"highlight-property\"}>ATK</p> increases by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>增加12%。",
      },
      {
        "set" : 4,
        "en-us": "For every <p class=\"highlight-property\"}>DoT</p> the target enemy is afflicted with, the wearer will ignore 6% of its DEF when dealing DMG to it. This effect is valid for a max of 3 DoTs.",
        "zh-cn": "敌方目标每承受1个<p class=\"highlight-property\"}>持续伤害</p>效果，装备者对其造成伤害时就无视其6%的防御力，最多计入3个持续伤害效果。",
        "zh-tw": "敵方目標每承受1個<p class=\"highlight-property\"}>持續傷害</p>效果，裝備者對其造成傷害時就無視其6%的防禦力，最多計入3個持續傷害效果。",
      }
    ],
    code: "PDC",
    colour: "#7E9DA0",
    image: require("../components/image/relic/116_3.webp"),
  },
  
  {
    id: "117_0",
    name: "Pioneer's Heatproof Shell",
    set: "Pioneer Diver of Dead Waters",
    type: "Cavern Relics",
    translation:{
      "en-us": "Pioneer's Heatproof Shell",
      "zh-cn": "先驱的绝热围壳",
      "zh-tw": "先驅的絕熱圍殼"
    },
    translation_set:{
      "en-us": "Pioneer Diver of Dead Waters",
      "zh-cn": "死水深潜的先驱",
      "zh-tw": "死水深潛的先驅"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>DMG</p> dealt to enemies with debuff by 12%.",
        "zh-cn": "对受负面状态影响的敌人造成的<p class=\"highlight-property\"}>伤害</p>提高12%。",
        "zh-tw": "對受負面狀態影響的敵人造成的<p class=\"highlight-property\"}>傷害</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases <p class=\"highlight-property\"}>CRIT Rate</p> by 4%. The wearer deals 8%/12% increased <p class=\"highlight-property\"}>CRIT DMG</p> to enemies with at least 2/3 debuffs. After the wearer inflicts a debuff on enemy targets, the aforementioned effects increase by 100%, lasting for 1 turn(s).",
        "zh-cn": "<p class=\"highlight-property\"}>暴击率</p>提高4%，装备者对陷入不少于2/3个负面效果的敌方目标造成的<p class=\"highlight-property\"}>暴击伤害</p>提高8%/12%。装备者对敌方目标施加负面效果后，上述效果提高100%，持续1回合。",
        "zh-tw": "<p class=\"highlight-property\"}>爆擊率</p>增加4%，裝備者對陷入不少於2/3個負面效果的敵方目標造成的<p class=\"highlight-property\"}>暴擊傷害</p>提高8%/12%。裝備者對敵方目標施加負面效果後，上述效果提高100%，持續1回合。",
      }
    ],
    code: "PDDW",
    colour: "#693c39",
    image: require("../components/image/relic/117_0.webp"),
  },
  
  {
    id: "117_1",
    name: "Pioneer's Lacuna Compass",
    set: "Pioneer Diver of Dead Waters",
    type: "Cavern Relics",
    translation:{
      "en-us": "Pioneer's Lacuna Compass",
      "zh-cn": "先驱的虚极罗盘",
      "zh-tw": "先驅的虛極羅盤"
    },
    translation_set:{
      "en-us": "Pioneer Diver of Dead Waters",
      "zh-cn": "死水深潜的先驱",
      "zh-tw": "死水深潛的先驅"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>DMG</p> dealt to enemies with debuff by 12%.",
        "zh-cn": "对受负面状态影响的敌人造成的<p class=\"highlight-property\"}>伤害</p>提高12%。",
        "zh-tw": "對受負面狀態影響的敵人造成的<p class=\"highlight-property\"}>傷害</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases <p class=\"highlight-property\"}>CRIT Rate</p> by 4%. The wearer deals 8%/12% increased <p class=\"highlight-property\"}>CRIT DMG</p> to enemies with at least 2/3 debuffs. After the wearer inflicts a debuff on enemy targets, the aforementioned effects increase by 100%, lasting for 1 turn(s).",
        "zh-cn": "<p class=\"highlight-property\"}>暴击率</p>提高4%，装备者对陷入不少于2/3个负面效果的敌方目标造成的<p class=\"highlight-property\"}>暴击伤害</p>提高8%/12%。装备者对敌方目标施加负面效果后，上述效果提高100%，持续1回合。",
        "zh-tw": "<p class=\"highlight-property\"}>爆擊率</p>增加4%，裝備者對陷入不少於2/3個負面效果的敵方目標造成的<p class=\"highlight-property\"}>暴擊傷害</p>提高8%/12%。裝備者對敵方目標施加負面效果後，上述效果提高100%，持續1回合。",
      }
    ],
    code: "PDDW",
    colour: "#693c39",
    image: require("../components/image/relic/117_1.webp"),
  },
  
  {
    id: "117_2",
    name: "Pioneer's Sealed Lead Apron",
    set: "Pioneer Diver of Dead Waters",
    type: "Cavern Relics",
    translation:{
      "en-us": "Pioneer's Sealed Lead Apron",
      "zh-cn": "先驱的密合铅衣",
      "zh-tw": "先驅的密合鉛衣"
    },
    translation_set:{
      "en-us": "Pioneer Diver of Dead Waters",
      "zh-cn": "死水深潜的先驱",
      "zh-tw": "死水深潛的先驅"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>DMG</p> dealt to enemies with debuff by 12%.",
        "zh-cn": "对受负面状态影响的敌人造成的<p class=\"highlight-property\"}>伤害</p>提高12%。",
        "zh-tw": "對受負面狀態影響的敵人造成的<p class=\"highlight-property\"}>傷害</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases <p class=\"highlight-property\"}>CRIT Rate</p> by 4%. The wearer deals 8%/12% increased <p class=\"highlight-property\"}>CRIT DMG</p> to enemies with at least 2/3 debuffs. After the wearer inflicts a debuff on enemy targets, the aforementioned effects increase by 100%, lasting for 1 turn(s).",
        "zh-cn": "<p class=\"highlight-property\"}>暴击率</p>提高4%，装备者对陷入不少于2/3个负面效果的敌方目标造成的<p class=\"highlight-property\"}>暴击伤害</p>提高8%/12%。装备者对敌方目标施加负面效果后，上述效果提高100%，持续1回合。",
        "zh-tw": "<p class=\"highlight-property\"}>爆擊率</p>增加4%，裝備者對陷入不少於2/3個負面效果的敵方目標造成的<p class=\"highlight-property\"}>暴擊傷害</p>提高8%/12%。裝備者對敵方目標施加負面效果後，上述效果提高100%，持續1回合。",
      }
    ],
    code: "PDDW",
    colour: "#693c39",
    image: require("../components/image/relic/117_2.webp"),
  },
  
  {
    id: "117_3",
    name: "Pioneer's Starfaring Anchor",
    set: "Pioneer Diver of Dead Waters",
    type: "Cavern Relics",
    translation:{
      "en-us": "Pioneer's Starfaring Anchor",
      "zh-cn": "先驱的泊星桩锚",
      "zh-tw": "先驅的泊星樁錨"
    },
    translation_set:{
      "en-us": "Pioneer Diver of Dead Waters",
      "zh-cn": "死水深潜的先驱",
      "zh-tw": "死水深潛的先驅"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>DMG</p> dealt to enemies with debuff by 12%.",
        "zh-cn": "对受负面状态影响的敌人造成的<p class=\"highlight-property\"}>伤害</p>提高12%。",
        "zh-tw": "對受負面狀態影響的敵人造成的<p class=\"highlight-property\"}>傷害</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases <p class=\"highlight-property\"}>CRIT Rate</p> by 4%. The wearer deals 8%/12% increased <p class=\"highlight-property\"}>CRIT DMG</p> to enemies with at least 2/3 debuffs. After the wearer inflicts a debuff on enemy targets, the aforementioned effects increase by 100%, lasting for 1 turn(s).",
        "zh-cn": "<p class=\"highlight-property\"}>暴击率</p>提高4%，装备者对陷入不少于2/3个负面效果的敌方目标造成的<p class=\"highlight-property\"}>暴击伤害</p>提高8%/12%。装备者对敌方目标施加负面效果后，上述效果提高100%，持续1回合。",
        "zh-tw": "<p class=\"highlight-property\"}>爆擊率</p>增加4%，裝備者對陷入不少於2/3個負面效果的敵方目標造成的<p class=\"highlight-property\"}>暴擊傷害</p>提高8%/12%。裝備者對敵方目標施加負面效果後，上述效果提高100%，持續1回合。",
      }
    ],
    code: "PDDW",
    colour: "#693c39",
    image: require("../components/image/relic/117_3.webp"),
  },

  {
    id: "118_0",
    name: "Watchmaker's Telescoping Lens",
    set: "Watchmaker, Master of Dream Machinations",
    type: "Cavern Relics",
    translation:{
      "en-us": "Watchmaker's Telescoping Lens",
      "zh-cn": "钟表匠的极目透镜",
      "zh-tw": "鐘錶匠的極目透鏡"
    },
    translation_set:{
      "en-us": "Watchmaker, Master of Dream Machinations",
      "zh-cn": "机心戏梦的钟表匠",
      "zh-tw": "機心戲夢的鐘錶匠"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses their Ultimate on an ally, <p class=\"highlight-property\"}>Break Effect</p> for all allies increases by 30% for 2 turn(s). This effect cannot be stacked.",
        "zh-cn": "当装备者对我方目标施放终结技时，我方全体<p class=\"highlight-property\"}>击破特攻</p>提高30%，持续2回合，该效果无法叠加。",
        "zh-tw": "當裝備者對我方目標施放終結技時，我方全體<p class=\"highlight-property\"}>擊破特攻</p>提高30%，持續2回合，該效果無法疊加。",
      }
    ],
    code: "WMDM",
    colour: "#edd888",
    image: require("../components/image/relic/118_0.webp"),
  },
  
  {
    id: "118_1",
    name: "Watchmaker's Fortuitous Wristwatch",
    set: "Watchmaker, Master of Dream Machinations",
    type: "Cavern Relics",
    translation:{
      "en-us": "Watchmaker's Fortuitous Wristwatch",
      "zh-cn": "钟表匠的交运腕表",
      "zh-tw": "鐘錶匠的交運腕錶"
    },
    translation_set:{
      "en-us": "Watchmaker, Master of Dream Machinations",
      "zh-cn": "机心戏梦的钟表匠",
      "zh-tw": "機心戲夢的鐘錶匠"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses their Ultimate on an ally, <p class=\"highlight-property\"}>Break Effect</p> for all allies increases by 30% for 2 turn(s). This effect cannot be stacked.",
        "zh-cn": "当装备者对我方目标施放终结技时，我方全体<p class=\"highlight-property\"}>击破特攻</p>提高30%，持续2回合，该效果无法叠加。",
        "zh-tw": "當裝備者對我方目標施放終結技時，我方全體<p class=\"highlight-property\"}>擊破特攻</p>提高30%，持續2回合，該效果無法疊加。",
      }
    ],
    code: "WMDM",
    colour: "#edd888",
    image: require("../components/image/relic/118_1.webp"),
  },
  
  {
    id: "118_2",
    name: "Watchmaker's Illusory Formal Suit",
    set: "Watchmaker, Master of Dream Machinations",
    type: "Cavern Relics",
    translation:{
      "en-us": "Watchmaker's Illusory Formal Suit",
      "zh-cn": "钟表匠的空幻礼服",
      "zh-tw": "鐘錶匠的空幻禮服"
    },
    translation_set:{
      "en-us": "Watchmaker, Master of Dream Machinations",
      "zh-cn": "机心戏梦的钟表匠",
      "zh-tw": "機心戲夢的鐘錶匠"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses their Ultimate on an ally, <p class=\"highlight-property\"}>Break Effect</p> for all allies increases by 30% for 2 turn(s). This effect cannot be stacked.",
        "zh-cn": "当装备者对我方目标施放终结技时，我方全体<p class=\"highlight-property\"}>击破特攻</p>提高30%，持续2回合，该效果无法叠加。",
        "zh-tw": "當裝備者對我方目標施放終結技時，我方全體<p class=\"highlight-property\"}>擊破特攻</p>提高30%，持續2回合，該效果無法疊加。",
      }
    ],
    code: "WMDM",
    colour: "#edd888",
    image: require("../components/image/relic/118_2.webp"),
  },
  
  {
    id: "118_3",
    name: "Watchmaker's Dream-Concealing Dress Shoes",
    set: "Watchmaker, Master of Dream Machinations",
    type: "Cavern Relics",
    translation:{
      "en-us": "Watchmaker's Dream-Concealing Dress Shoes",
      "zh-cn": "钟表匠的隐梦革履",
      "zh-tw": "鐘錶匠的隱夢革履"
    },
    translation_set:{
      "en-us": "Watchmaker, Master of Dream Machinations",
      "zh-cn": "机心戏梦的钟表匠",
      "zh-tw": "機心戲夢的鐘錶匠"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "When the wearer uses their Ultimate on an ally, <p class=\"highlight-property\"}>Break Effect</p> for all allies increases by 30% for 2 turn(s). This effect cannot be stacked.",
        "zh-cn": "当装备者对我方目标施放终结技时，我方全体<p class=\"highlight-property\"}>击破特攻</p>提高30%，持续2回合，该效果无法叠加。",
        "zh-tw": "當裝備者對我方目標施放終結技時，我方全體<p class=\"highlight-property\"}>擊破特攻</p>提高30%，持續2回合，該效果無法疊加。",
      }
    ],
    code: "WMDM",
    colour: "#edd888",
    image: require("../components/image/relic/118_3.webp"),
  },
  {
    id: "119_0",
    name: "Iron Cavalry’s Homing Helm",
    set: "Iron Cavalry Against the Scourge",
    type: "Cavern Relics",
    translation:{
      "en-us": "Iron Cavalry’s Homing Helm",
      "zh-cn": "铁骑的索敌战盔",
      "zh-tw": "鐵騎的索敵戰盔"
    },
    translation_set:{
      "en-us": "Iron Cavalry Against the Scourge",
      "zh-cn": "荡除蠹灾的铁骑",
      "zh-tw": "蕩除蠹災的鐵騎"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "If the wearer's <p class=\"highlight-property\"}>Break Effect</p> is 150% or higher, the Break DMG dealt to the enemy target ignores 10% of their DEF. If the wearer's <p class=\"highlight-property\"}>Break Effect</p> is 250% or higher, the Super Break DMG dealt to the enemy target additionally ignores 15% of their DEF.",
        "zh-cn": "当装备者的<p class=\"highlight-property\"}>击破特攻</p>大于等于150%时，对敌方目标造成的击破伤害无视其10%的防御力。当装备者的<p class=\"highlight-property\"}>击破特攻</p>大于等于250%时，对敌方目标造成的超击破伤害额外无视其15%的防御力。",
        "zh-tw": "當裝備者的<p class=\"highlight-property\"}>擊破特攻</p>大於等於150%時，對敵方目標造成的擊破傷害無視其10%的防禦力。當裝備者的<p class=\"highlight-property\"}>擊破特攻</p>大於等於250%時，對敵方目標造成的超擊破傷害額外無視其15%的防禦力。",
      }
    ],
    code: "ICAS",
    colour: "#a9acb9",
    image: require("../components/image/relic/119_0.webp"),
  },
  {
    id: "119_1",
    name: "Iron Cavalry’s Crushing Wristguard",
    set: "Iron Cavalry Against the Scourge",
    type: "Cavern Relics",
    translation:{
      "en-us": "Iron Cavalry’s Crushing Wristguard",
      "zh-cn": "铁骑的摧坚铁腕",
      "zh-tw": "鐵騎的摧堅鐵腕"
    },
    translation_set:{
      "en-us": "Iron Cavalry Against the Scourge",
      "zh-cn": "荡除蠹灾的铁骑",
      "zh-tw": "蕩除蠹災的鐵騎"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "If the wearer's <p class=\"highlight-property\"}>Break Effect</p> is 150% or higher, the Break DMG dealt to the enemy target ignores 10% of their DEF. If the wearer's <p class=\"highlight-property\"}>Break Effect</p> is 250% or higher, the Super Break DMG dealt to the enemy target additionally ignores 15% of their DEF.",
        "zh-cn": "当装备者的<p class=\"highlight-property\"}>击破特攻</p>大于等于150%时，对敌方目标造成的击破伤害无视其10%的防御力。当装备者的<p class=\"highlight-property\"}>击破特攻</p>大于等于250%时，对敌方目标造成的超击破伤害额外无视其15%的防御力。",
        "zh-tw": "當裝備者的<p class=\"highlight-property\"}>擊破特攻</p>大於等於150%時，對敵方目標造成的擊破傷害無視其10%的防禦力。當裝備者的<p class=\"highlight-property\"}>擊破特攻</p>大於等於250%時，對敵方目標造成的超擊破傷害額外無視其15%的防禦力。",
      }
    ],
    code: "ICAS",
    colour: "#a9acb9",
    image: require("../components/image/relic/119_1.webp"),
  },
  
  {
    id: "119_2",
    name: "Iron Cavalry’s Silvery Armor",
    set: "Iron Cavalry Against the Scourge",
    type: "Cavern Relics",
    translation:{
      "en-us": "Iron Cavalry’s Silvery Armor",
      "zh-cn": "铁骑的银影装甲",
      "zh-tw": "鐵騎的銀影裝甲"
    },
    translation_set:{
      "en-us": "Iron Cavalry Against the Scourge",
      "zh-cn": "荡除蠹灾的铁骑",
      "zh-tw": "蕩除蠹災的鐵騎"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "If the wearer's <p class=\"highlight-property\"}>Break Effect</p> is 150% or higher, the Break DMG dealt to the enemy target ignores 10% of their DEF. If the wearer's <p class=\"highlight-property\"}>Break Effect</p> is 250% or higher, the Super Break DMG dealt to the enemy target additionally ignores 15% of their DEF.",
        "zh-cn": "当装备者的<p class=\"highlight-property\"}>击破特攻</p>大于等于150%时，对敌方目标造成的击破伤害无视其10%的防御力。当装备者的<p class=\"highlight-property\"}>击破特攻</p>大于等于250%时，对敌方目标造成的超击破伤害额外无视其15%的防御力。",
        "zh-tw": "當裝備者的<p class=\"highlight-property\"}>擊破特攻</p>大於等於150%時，對敵方目標造成的擊破傷害無視其10%的防禦力。當裝備者的<p class=\"highlight-property\"}>擊破特攻</p>大於等於250%時，對敵方目標造成的超擊破傷害額外無視其15%的防禦力。",
      }
    ],
    code: "ICAS",
    colour: "#a9acb9",
    image: require("../components/image/relic/119_2.webp"),
  },
  
  {
    id: "119_3",
    name: "Iron Cavalry’s Skywalk Greaves",
    set: "Iron Cavalry Against the Scourge",
    type: "Cavern Relics",
    translation:{
      "en-us": "Iron Cavalry’s Skywalk Greaves",
      "zh-cn": "铁骑的行空护胫",
      "zh-tw": "鐵騎的行空護脛"
    },
    translation_set:{
      "en-us": "Iron Cavalry Against the Scourge",
      "zh-cn": "荡除蠹灾的铁骑",
      "zh-tw": "蕩除蠹災的鐵騎"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>Break Effect</p> by 16%.",
        "zh-cn": "<p class=\"highlight-property\"}>击破特攻</p>提高16%。",
        "zh-tw": "<p class=\"highlight-property\"}>擊破特攻</p>提高16%。",
      },
      {
        "set" : 4,
        "en-us": "If the wearer's <p class=\"highlight-property\"}>Break Effect</p> is 150% or higher, the Break DMG dealt to the enemy target ignores 10% of their DEF. If the wearer's <p class=\"highlight-property\"}>Break Effect</p> is 250% or higher, the Super Break DMG dealt to the enemy target additionally ignores 15% of their DEF.",
        "zh-cn": "当装备者的<p class=\"highlight-property\"}>击破特攻</p>大于等于150%时，对敌方目标造成的击破伤害无视其10%的防御力。当装备者的<p class=\"highlight-property\"}>击破特攻</p>大于等于250%时，对敌方目标造成的超击破伤害额外无视其15%的防御力。",
        "zh-tw": "當裝備者的<p class=\"highlight-property\"}>擊破特攻</p>大於等於150%時，對敵方目標造成的擊破傷害無視其10%的防禦力。當裝備者的<p class=\"highlight-property\"}>擊破特攻</p>大於等於250%時，對敵方目標造成的超擊破傷害額外無視其15%的防禦力。",
      }
    ],
    code: "ICAS",
    colour: "#a9acb9",
    image: require("../components/image/relic/119_3.webp"),
  },
  
  {
    id: "120_0",
    name: "Valorous Mask of Northern Skies",
    set: "The Wind-Soaring Valorous",
    type: "Cavern Relics",
    translation:{
      "en-us": "Valorous Mask of Northern Skies",
      "zh-cn": "勇烈的玄枵面甲",
      "zh-tw": "勇烈的玄枵面甲"
    },
    translation_set:{
      "en-us": "The Wind-Soaring Valorous",
      "zh-cn": "风举云飞的勇烈",
      "zh-tw": "風舉雲飛的勇烈"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>ATK</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> by 6%. After the wearer uses a follow-up attack, increases the DMG dealt by their Ultimate by 36%, lasting for 1 turn(s).",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击率</p>提高6%，装备者施放追加攻击时，使终结技造成的伤害提高36%，持续1回合。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>爆擊率</p>提高6%，裝備者施放追加攻擊時，使終結技造成的傷害提高36%，持續1回合。",
      }
    ],
    code: "TWSV",
    colour: "#50d6f0",
    image: require("../components/image/relic/120_0.webp"),
  },
  
  {
    id: "120_1",
    name: "Valorous Bracelet of Grappling Hooks",
    set: "The Wind-Soaring Valorous",
    type: "Cavern Relics",
    translation:{
      "en-us": "Valorous Bracelet of Grappling Hooks",
      "zh-cn": "勇烈的钩爪腕甲",
      "zh-tw": "勇烈的鉤爪腕甲"
    },
    translation_set:{
      "en-us": "The Wind-Soaring Valorous",
      "zh-cn": "风举云飞的勇烈",
      "zh-tw": "風舉雲飛的勇烈"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>ATK</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> by 6%. After the wearer uses a follow-up attack, increases the DMG dealt by their Ultimate by 36%, lasting for 1 turn(s).",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击率</p>提高6%，装备者施放追加攻击时，使终结技造成的伤害提高36%，持续1回合。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>爆擊率</p>提高6%，裝備者施放追加攻擊時，使終結技造成的傷害提高36%，持續1回合。",
      }
    ],
    code: "TWSV",
    colour: "#50d6f0",
    image: require("../components/image/relic/120_1.webp"),
  },
  
  {
    id: "120_2",
    name: "Valorous Plate of Soaring Flight",
    set: "The Wind-Soaring Valorous",
    type: "Cavern Relics",
    translation:{
      "en-us": "Valorous Plate of Soaring Flight",
      "zh-cn": "勇烈的飞翎瓷甲",
      "zh-tw": "勇烈的鉤爪腕甲"
    },
    translation_set:{
      "en-us": "The Wind-Soaring Valorous",
      "zh-cn": "风举云飞的勇烈",
      "zh-tw": "勇烈的飛翎瓷甲"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>ATK</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> by 6%. After the wearer uses a follow-up attack, increases the DMG dealt by their Ultimate by 36%, lasting for 1 turn(s).",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击率</p>提高6%，装备者施放追加攻击时，使终结技造成的伤害提高36%，持续1回合。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>爆擊率</p>提高6%，裝備者施放追加攻擊時，使終結技造成的傷害提高36%，持續1回合。",
      }
    ],
    code: "TWSV",
    colour: "#50d6f0",
    image: require("../components/image/relic/120_2.webp"),
  },
  
  {
    id: "120_3",
    name: "Valorous Greaves of Pursuing Hunt",
    set: "The Wind-Soaring Valorous",
    type: "Cavern Relics",
    translation:{
      "en-us": "Valorous Greaves of Pursuing Hunt",
      "zh-cn": "勇烈的逐猎腿甲",
      "zh-tw": "勇烈的逐獵腿甲"
    },
    translation_set:{
      "en-us": "The Wind-Soaring Valorous",
      "zh-cn": "风举云飞的勇烈",
      "zh-tw": "勇烈的飛翎瓷甲"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases <p class=\"highlight-property\"}>ATK</p> by 12%.",
        "zh-cn": "<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "<p class=\"highlight-property\"}>攻擊力</p>提高12%。",
      },
      {
        "set" : 4,
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> by 6%. After the wearer uses a follow-up attack, increases the DMG dealt by their Ultimate by 36%, lasting for 1 turn(s).",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击率</p>提高6%，装备者施放追加攻击时，使终结技造成的伤害提高36%，持续1回合。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>爆擊率</p>提高6%，裝備者施放追加攻擊時，使終結技造成的傷害提高36%，持續1回合。",
      }
    ],
    code: "TWSV",
    colour: "#50d6f0",
    image: require("../components/image/relic/120_3.webp"),
  },


  {
    id: "301_0",
    name: "Herta's Space Station",
    set: "Space Sealing Station",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Herta's Space Station",
      "zh-cn": "「黑塔」的空间站点",
      "zh-tw": "「黑塔」的空間站點"
    },
    translation_set:{
      "en-us": "Space Sealing Station",
      "zh-cn": "太空封印站",
      "zh-tw": "太空封印站"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>ATK</p> by 12%. When the wearer's <p class=\"highlight-property\"}>SPD reaches 120 or higher</p>, the wearer's <p class=\"highlight-property\"}>ATK</p> increases by another 12%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>攻击力</p>提高12%。当装备者的<p class=\"highlight-property\"}>速度大于等于120</p>时，<p class=\"highlight-property\"}>攻击力</p>额外提高12%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>攻擊力</p>提高12%。當裝備者的<p class=\"highlight-property\"}>速度大於等於120</p>時，<p class=\"highlight-property\"}>攻擊力</p>額外提高12%。",
      },
    ],
    code: "SSS",
    colour: "#9e8dc7",
    image: require("../components/image/relic/301_0.webp"),
  },
  {
    id: "301_1",
    name: "Herta's Wandering Trek",
    set: "Space Sealing Station",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Herta's Wandering Trek",
      "zh-cn": "「黑塔」的漫历轨迹",
      "zh-tw": "「黑塔」的漫歷軌跡"
    },
    translation_set:{
      "en-us": "Space Sealing Station",
      "zh-cn": "太空封印站",
      "zh-tw": "太空封印站"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>ATK</p> by 12%. When the wearer's <p class=\"highlight-property\"}>SPD reaches 120 or higher</p>, the wearer's <p class=\"highlight-property\"}>ATK</p> increases by another 12%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>攻击力</p>提高12%。当装备者的<p class=\"highlight-property\"}>速度大于等于120</p>时，<p class=\"highlight-property\"}>攻击力</p>额外提高12%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>攻擊力</p>提高12%。當裝備者的<p class=\"highlight-property\"}>速度大於等於120</p>時，<p class=\"highlight-property\"}>攻擊力</p>額外提高12%。",
      },
    ],
    code: "SSS",
    colour: "#9e8dc7",
    image: require("../components/image/relic/301_1.webp"),
  },
  {
    id: "302_0",
    name: "The Xianzhou Luofu's Celestial Ark",
    set: "Fleet of the Ageless",
    type: "Planar Ornaments",
    translation:{
      "en-us": "The Xianzhou Luofu's Celestial Ark",
      "zh-cn": "罗浮仙舟的天外楼船",
      "zh-tw": "羅浮仙舟的天外樓船"
    },
    translation_set:{
      "en-us": "Fleet of the Ageless",
      "zh-cn": "不老者的仙舟",
      "zh-tw": "不老者的仙舟"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Max HP</p> by 12%. When the wearer's <p class=\"highlight-property\"}>SPD reaches 120 or higher</p>, all allies' <p class=\"highlight-property\"}>ATK</p> increases by 8%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>生命上限</p>提高12%。当装备者的<p class=\"highlight-property\"}>速度大于等于120</p>时，我方全体<p class=\"highlight-property\"}>攻击力</p>提高8%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>生命上限</p>提高12%。當裝備者的<p class=\"highlight-property\"}>速度大於等於120</p>時，我方全體<p class=\"highlight-property\"}>攻擊力</p>提高8%。",
      },
    ],
    code: "FA",
    colour: "#93d6c8",
    image: require("../components/image/relic/302_0.webp"),
  },
  {
    id: "302_1",
    name: "The Xianzhou Luofu's Ambrosial Arbor Vines",
    set: "Fleet of the Ageless",
    type: "Planar Ornaments",
    translation:{
      "en-us": "The Xianzhou Luofu's Ambrosial Arbor Vines",
      "zh-cn": "罗浮仙舟的建木枝蔓",
      "zh-tw": "羅浮仙舟的建木枝蔓"
    },
    translation_set:{
      "en-us": "Fleet of the Ageless",
      "zh-cn": "不老者的仙舟",
      "zh-tw": "不老者的仙舟"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Max HP</p> by 12%. When the wearer's <p class=\"highlight-property\"}>SPD reaches 120 or higher</p>, all allies' <p class=\"highlight-property\"}>ATK</p> increases by 8%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>生命上限</p>提高12%。当装备者的<p class=\"highlight-property\"}>速度大于等于120</p>时，我方全体<p class=\"highlight-property\"}>攻击力</p>提高8%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>生命上限</p>提高12%。當裝備者的<p class=\"highlight-property\"}>速度大於等於120</p>時，我方全體<p class=\"highlight-property\"}>攻擊力</p>提高8%。",
      },
    ],
    code: "FA",
    colour: "#93d6c8",
    image: require("../components/image/relic/302_1.webp"),
  },
  {
    id: "303_0",
    name: "The IPC's Mega HQ",
    set: "Pan-Galactic Commercial Enterprise",
    type: "Planar Ornaments",
    translation:{
      "en-us": "The IPC's Mega HQ",
      "zh-cn": "公司的巨构总部",
      "zh-tw": "公司的巨構總部"
    },
    translation_set:{
      "en-us": "Pan-Galactic Commercial Enterprise",
      "zh-cn": "泛银河商业公司",
      "zh-tw": "泛銀河商業公司"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Effect Hit Rate</p> by 10%. Meanwhile, the wearer's <p class=\"highlight-property\"}>ATK increases by an amount that is equal to 25% of Effect Hit Rate</p>, up to a maximum of 25%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>效果命中</p>提高10%，同时提高装备者等同于<p class=\"highlight-property\"}>当前效果命中25%的攻击力</p>，最多提高25%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>效果命中</p>提高10%，同時提高裝備者等同於<p class=\"highlight-property\"}>當前效果命中25%的攻擊力</p>，最多提高25%。",
      },
    ],
    code: "PGCE",
    colour: "#e9ca73",
    image: require("../components/image/relic/303_0.webp"),
  },
  {
    id: "303_1",
    name: "The IPC's Trade Route",
    set: "Pan-Galactic Commercial Enterprise",
    type: "Planar Ornaments",
    translation:{
      "en-us": "The IPC's Trade Route",
      "zh-cn": "公司的贸易航道",
      "zh-tw": "公司的貿易航道"
    },
    translation_set:{
      "en-us": "Pan-Galactic Commercial Enterprise",
      "zh-cn": "泛银河商业公司",
      "zh-tw": "泛銀河商業公司"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Effect Hit Rate</p> by 10%. Meanwhile, the wearer's <p class=\"highlight-property\"}>ATK increases by an amount that is equal to 25% of Effect Hit Rate</p>, up to a maximum of 25%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>效果命中</p>提高10%，同时提高装备者等同于<p class=\"highlight-property\"}>当前效果命中25%的攻击力</p>，最多提高25%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>效果命中</p>提高10%，同時提高裝備者等同於<p class=\"highlight-property\"}>當前效果命中25%的攻擊力</p>，最多提高25%。",
      },
    ],
    code: "PGCE",
    colour: "#e9ca73",
    image: require("../components/image/relic/303_1.webp"),
  },
  {
    id: "304_0",
    name: "Belobog's Fortress of Preservation",
    set: "Belobog of the Architects",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Belobog's Fortress of Preservation",
      "zh-cn": "贝洛伯格的存护堡垒",
      "zh-tw": "貝洛伯格的存護堡壘"
    },
    translation_set:{
      "en-us": "Belobog of the Architects",
      "zh-cn": "筑城者的贝洛伯格",
      "zh-tw": "築城者的貝洛伯格"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>DEF</p> by 15%. When the wearer's <p class=\"highlight-property\"}>Effect Hit Rate is 50% or higher</p>, the wearer gains an extra 15% <p class=\"highlight-property\"}>DEF</p>.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>防御力</p>提高15%。当装备者的<p class=\"highlight-property\"}>效果命中大于等于50%</p>时，<p class=\"highlight-property\"}>防御力</p>额外提高15%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>防禦力</p>提高15%。當裝備者的<p class=\"highlight-property\"}>效果命中大於等於50%</p>時，<p class=\"highlight-property\"}>防禦力</p>額外提高15%。",
      },
    ],
    code: "BA",
    colour: "#75d1ea",
    image: require("../components/image/relic/304_0.webp"),
  },
  {
    id: "304_1",
    name: "Belobog's Iron Defense",
    set: "Belobog of the Architects",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Belobog's Iron Defense",
      "zh-cn": "贝洛伯格的铁卫防线",
      "zh-tw": "貝洛伯格的鐵衛防線"
    },
    translation_set:{
      "en-us": "Belobog of the Architects",
      "zh-cn": "筑城者的贝洛伯格",
      "zh-tw": "築城者的貝洛伯格"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>DEF</p> by 15%. When the wearer's <p class=\"highlight-property\"}>Effect Hit Rate is 50% or higher</p>, the wearer gains an extra 15% <p class=\"highlight-property\"}>DEF</p>.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>防御力</p>提高15%。当装备者的<p class=\"highlight-property\"}>效果命中大于等于50%</p>时，<p class=\"highlight-property\"}>防御力</p>额外提高15%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>防禦力</p>提高15%。當裝備者的<p class=\"highlight-property\"}>效果命中大於等於50%</p>時，<p class=\"highlight-property\"}>防禦力</p>額外提高15%。",
      },
    ],
    code: "BA",
    colour: "#75d1ea",
    image: require("../components/image/relic/304_1.webp"),
  },
  {
    id: "305_0",
    name: "Planet Screwllum's Mechanical Sun",
    set: "Celestial Differentiator",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Planet Screwllum's Mechanical Sun",
      "zh-cn": "螺丝星的机械烈阳",
      "zh-tw": "螺絲星的機械烈陽"
    },
    translation_set:{
      "en-us": "Celestial Differentiator",
      "zh-cn": "星体差分机",
      "zh-tw": "星體差分機"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> by 16%. When the wearer's <p class=\"highlight-property\"}>current CRIT DMG reaches 120% or higher</p>, after entering battle, the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> increases by 60% until the end of their first attack.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击伤害</p>提高16%。当装备者的<p class=\"highlight-property\"}>当前暴击伤害大于等于120%</p>时，进入战斗后装备者的<p class=\"highlight-property\"}>暴击率</p>提高60%，持续到施放首次攻击后结束。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>暴擊傷害</p>提高16%。當裝備者的<p class=\"highlight-property\"}>當前暴擊傷害大於等於120%</p>時，進入戰鬥後裝備者的<p class=\"highlight-property\"}>暴擊率</p>提高60%，持續到施放首次攻擊後結束。",
      },
    ],
    code: "CD",
    colour: "#556ec5",
    image: require("../components/image/relic/305_0.webp"),
  },
  {
    id: "305_1",
    name: "Planet Screwllum's Ring System",
    set: "Celestial Differentiator",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Planet Screwllum's Ring System",
      "zh-cn": "螺丝星的环星孔带",
      "zh-tw": "螺絲星的環星孔帶"
    },
    translation_set:{
      "en-us": "Celestial Differentiator",
      "zh-cn": "星体差分机",
      "zh-tw": "星體差分機"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> by 16%. When the wearer's <p class=\"highlight-property\"}>current CRIT DMG reaches 120% or higher</p>, after entering battle, the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> increases by 60% until the end of their first attack.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击伤害</p>提高16%。当装备者的<p class=\"highlight-property\"}>当前暴击伤害大于等于120%</p>时，进入战斗后装备者的<p class=\"highlight-property\"}>暴击率</p>提高60%，持续到施放首次攻击后结束。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>暴擊傷害</p>提高16%。當裝備者的<p class=\"highlight-property\"}>當前暴擊傷害大於等於120%</p>時，進入戰鬥後裝備者的<p class=\"highlight-property\"}>暴擊率</p>提高60%，持續到施放首次攻擊後結束。",
      },
    ],
    code: "CD",
    colour: "#556ec5",
    image: require("../components/image/relic/305_1.webp"),
  },
  {
    id: "306_0",
    name: "Salsotto's Moving City",
    set: "Inert Salsotto",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Salsotto's Moving City",
      "zh-cn": "萨尔索图的移动城市",
      "zh-tw": "薩爾索圖的移動城市"
    },
    translation_set:{
      "en-us": "Inert Salsotto",
      "zh-cn": "停转的萨尔索图",
      "zh-tw": "停轉的薩爾索圖"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> by 8%. When the wearer's <p class=\"highlight-property\"}>current CRIT Rate reaches 50% or higher</p>, the wearer's <p class=\"highlight-property\"}>Ultimate and Follow-up attack</p> DMG increases by 15%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击率</p>提高8%。当装备者<p class=\"highlight-property\"}>当前暴击率大于等于50%</p>时，<p class=\"highlight-property\"}>终结技和追加攻击</p>造成的伤害提高15%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>暴擊率</p>提高8%。當裝備者<p class=\"highlight-property\"}>當前暴擊率大於等於50%</p>時，<p class=\"highlight-property\"}>終結技和追加攻擊</p>造成的傷害提高15%。",
      },
    ],
    code: "IS",
    colour: "#f8e8e5",
    image: require("../components/image/relic/306_0.webp"),
  },
  {
    id: "306_1",
    name: "Salsotto's Terminator Line",
    set: "Inert Salsotto",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Salsotto's Terminator Line",
      "zh-cn": "萨尔索图的晨昏界线",
      "zh-tw": "薩爾索圖的晨昏界線"
    },
    translation_set:{
      "en-us": "Inert Salsotto",
      "zh-cn": "停转的萨尔索图",
      "zh-tw": "停轉的薩爾索圖"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> by 8%. When the wearer's <p class=\"highlight-property\"}>current CRIT Rate reaches 50% or higher</p>, the wearer's <p class=\"highlight-property\"}>Ultimate and Follow-up attack</p> DMG increases by 15%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击率</p>提高8%。当装备者<p class=\"highlight-property\"}>当前暴击率大于等于50%</p>时，<p class=\"highlight-property\"}>终结技和追加攻击</p>造成的伤害提高15%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>暴擊率</p>提高8%。當裝備者<p class=\"highlight-property\"}>當前暴擊率大於等於50%</p>時，<p class=\"highlight-property\"}>終結技和追加攻擊</p>造成的傷害提高15%。",
      },
    ],
    code: "IS",
    colour: "#f8e8e5",
    image: require("../components/image/relic/306_1.webp"),
  },
  {
    id: "307_0",
    name: "Talia's Nailscrap Town",
    set: "Talia: Kingdom of Banditry",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Talia's Nailscrap Town",
      "zh-cn": "塔利亚的钉壳小镇",
      "zh-tw": "塔利亞的釘殼小鎮"
    },
    translation_set:{
      "en-us": "Talia: Kingdom of Banditry",
      "zh-cn": "盗贼公国塔利亚",
      "zh-tw": "盜賊公國塔利亞"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Break Effect</p> by 16%. When the wearer's <p class=\"highlight-property\"}>SPD reaches 145 or higher</p>, the wearer's <p class=\"highlight-property\"}>Break Effect</p> increases by an extra 20%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>击破特攻</p>提高16%。当装备者的<p class=\"highlight-property\"}>速度大于等于145</p>时，<p class=\"highlight-property\"}>击破特攻</p>额外提高20%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>擊破特攻</p>提高16%。當裝備者的<p class=\"highlight-property\"}>速度大於等於145</p>時，<p class=\"highlight-property\"}>擊破特攻</p>額外提高20%。",
      },
    ],
    code: "TKB",
    colour: "#c4b9a4",
    image: require("../components/image/relic/307_0.webp"),
  },
  {
    id: "307_1",
    name: "Talia's Exposed Electric Wire",
    set: "Talia: Kingdom of Banditry",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Talia's Exposed Electric Wire",
      "zh-cn": "塔利亚的裸皮电线",
      "zh-tw": "塔利亞的裸皮電線"
    },
    translation_set:{
      "en-us": "Talia: Kingdom of Banditry",
      "zh-cn": "盗贼公国塔利亚",
      "zh-tw": "盜賊公國塔利亞"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Break Effect</p> by 16%. When the wearer's <p class=\"highlight-property\"}>SPD reaches 145 or higher</p>, the wearer's <p class=\"highlight-property\"}>Break Effect</p> increases by an extra 20%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>击破特攻</p>提高16%。当装备者的<p class=\"highlight-property\"}>速度大于等于145</p>时，<p class=\"highlight-property\"}>击破特攻</p>额外提高20%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>擊破特攻</p>提高16%。當裝備者的<p class=\"highlight-property\"}>速度大於等於145</p>時，<p class=\"highlight-property\"}>擊破特攻</p>額外提高20%。",
      },
    ],
    code: "TKB",
    colour: "#c4b9a4",
    image: require("../components/image/relic/307_1.webp"),
  },
  {
    id: "308_0",
    name: "Vonwacq's Island of Birth",
    set: "Sprightly Vonwacq",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Vonwacq's Island of Birth",
      "zh-cn": "翁瓦克的诞生之岛",
      "zh-tw": "翁瓦克的誕生之島"
    },
    translation_set:{
      "en-us": "Sprightly Vonwacq",
      "zh-cn": "生命的翁瓦克",
      "zh-tw": "生命的翁瓦克"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Energy Regeneration Rate</p> by 5%. When the wearer's <p class=\"highlight-property\"}>SPD reaches 120 or higher</p>, the wearer's action is <p class=\"highlight-property\"}>Advanced Forward</p> by 40% immediately upon entering battle.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>能量恢复效率</p>提高5%。当装备者的<p class=\"highlight-property\"}>速度大于等于120</p>时，进入战斗时立刻使<p class=\"highlight-property\"}>行动提前</p>40%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>能量恢復效率</p>提高5%。當裝備者的<p class=\"highlight-property\"}>速度大於等於120</p>時，進入戰鬥時立刻使<p class=\"highlight-property\"}>行動提前</p>40%。",
      },
    ],
    code: "SV",
    colour: "#51d2d7",
    image: require("../components/image/relic/308_0.webp"),
  },
  {
    id: "308_1",
    name: "Vonwacq's Islandic Coast",
    set: "Sprightly Vonwacq",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Vonwacq's Islandic Coast",
      "zh-cn": "翁瓦克的环岛海岸",
      "zh-tw": "翁瓦克的環島海岸"
    },
    translation_set:{
      "en-us": "Sprightly Vonwacq",
      "zh-cn": "生命的翁瓦克",
      "zh-tw": "生命的翁瓦克"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Energy Regeneration Rate</p> by 5%. When the wearer's <p class=\"highlight-property\"}>SPD reaches 120 or higher</p>, the wearer's action is <p class=\"highlight-property\"}>Advanced Forward</p> by 40% immediately upon entering battle.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>能量恢复效率</p>提高5%。当装备者的<p class=\"highlight-property\"}>速度大于等于120</p>时，进入战斗时立刻使<p class=\"highlight-property\"}>行动提前</p>40%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>能量恢復效率</p>提高5%。當裝備者的<p class=\"highlight-property\"}>速度大於等於120</p>時，進入戰鬥時立刻使<p class=\"highlight-property\"}>行動提前</p>40%。",
      },
    ],
    code: "SV",
    colour: "#51d2d7",
    image: require("../components/image/relic/308_1.webp"),
  },
  {
    id: "309_0",
    name: "Taikiyan Laser Stadium",
    set: "Rutilant Arena",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Taikiyan Laser Stadium",
      "zh-cn": "泰科铵的镭射球场",
      "zh-tw": "泰科銨的鐳射球場"
    },
    translation_set:{
      "en-us": "Rutilant Arena",
      "zh-cn": "繁星竞技场",
      "zh-tw": "繁星競技場"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> by 8%. When the wearer's <p class=\"highlight-property\"}>current CRIT Rate reaches 70% or higher</p>, the wearer's <p class=\"highlight-property\"}>Basic ATK and Skill DMG</p> increase by 20%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击率</p>提高8%。当装备者的<p class=\"highlight-property\"}>当前暴击率大于等于70%</p>时，<p class=\"highlight-property\"}>普攻和战技</p>造成的伤害提高20%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>暴擊率</p>提高8%。當裝備者的<p class=\"highlight-property\"}>當前暴擊率大於等於70%</p>時，<p class=\"highlight-property\"}>普攻和戰技</p>造成的傷害提高20%。",
      },
    ],
    code: "RA",
    colour: "#d18f8a",
    image: require("../components/image/relic/309_0.webp"),
  },
  {
    id: "309_1",
    name: "Taikiyan's Arclight Race Track",
    set: "Rutilant Arena",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Taikiyan's Arclight Race Track",
      "zh-cn": "泰科铵的弧光赛道",
      "zh-tw": "泰科銨的弧光賽道"
    },
    translation_set:{
      "en-us": "Rutilant Arena",
      "zh-cn": "繁星竞技场",
      "zh-tw": "繁星競技場"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> by 8%. When the wearer's <p class=\"highlight-property\"}>current CRIT Rate reaches 70% or higher</p>, the wearer's <p class=\"highlight-property\"}>Basic ATK and Skill DMG</p> increase by 20%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击率</p>提高8%。当装备者的<p class=\"highlight-property\"}>当前暴击率大于等于70%</p>时，<p class=\"highlight-property\"}>普攻和战技</p>造成的伤害提高20%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>暴擊率</p>提高8%。當裝備者的<p class=\"highlight-property\"}>當前暴擊率大於等於70%</p>時，<p class=\"highlight-property\"}>普攻和戰技</p>造成的傷害提高20%。",
      },
    ],
    code: "RA",
    colour: "#d18f8a",
    image: require("../components/image/relic/309_1.webp"),
  },
  {
    id: "310_0",
    name: "Insumousu's Whalefall Ship",
    set: "Broken Keel",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Insumousu's Whalefall Ship",
      "zh-cn": "伊须磨洲的残船鲸落",
      "zh-tw": "伊須磨洲的殘船鯨落"
    },
    translation_set:{
      "en-us": "Broken Keel",
      "zh-cn": "折断的龙骨",
      "zh-tw": "折斷的龍骨"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Effect RES</p> by 10%. When the wearer's <p class=\"highlight-property\"}>Effect RES is at 30% or higher</p>, all allies' <p class=\"highlight-property\"}>CRIT DMG</p> increases by 10%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>效果抵抗</p>提高10%。当装备者的<p class=\"highlight-property\"}>效果抵抗大于等于30%</p>时，我方全体<p class=\"highlight-property\"}>暴击伤害</p>提高10%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>效果抵抗</p>提高10%。當裝備者的<p class=\"highlight-property\"}>效果抵抗大於等於30%</p>時，我方全體<p class=\"highlight-property\"}>暴擊傷害</p>提高10%。",
      },
    ],
    code: "BK",
    colour: "#43adc4",
    image: require("../components/image/relic/310_0.webp"),
  },
  {
    id: "310_1",
    name: "Insumousu's Frayed Hawser",
    set: "Broken Keel",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Insumousu's Frayed Hawser",
      "zh-cn": "伊须磨洲的坼裂缆索",
      "zh-tw": "伊須磨洲的坼裂纜索"
    },
    translation_set:{
      "en-us": "Broken Keel",
      "zh-cn": "折断的龙骨",
      "zh-tw": "折斷的龍骨"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Effect RES</p> by 10%. When the wearer's <p class=\"highlight-property\"}>Effect RES is at 30% or higher</p>, all allies' <p class=\"highlight-property\"}>CRIT DMG</p> increases by 10%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>效果抵抗</p>提高10%。当装备者的<p class=\"highlight-property\"}>效果抵抗大于等于30%</p>时，我方全体<p class=\"highlight-property\"}>暴击伤害</p>提高10%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>效果抵抗</p>提高10%。當裝備者的<p class=\"highlight-property\"}>效果抵抗大於等於30%</p>時，我方全體<p class=\"highlight-property\"}>暴擊傷害</p>提高10%。",
      },
    ],
    code: "BK",
    colour: "#43adc4",
    image: require("../components/image/relic/310_1.webp"),
  },
  
  {
    id: "311_0",
    name: "Glamoth's Iron Cavalry Regiment",
    set: "Firmament Frontline: Glamoth",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Glamoth's Iron Cavalry Regiment",
      "zh-cn": "格拉默的铁骑兵团",
      "zh-tw": "格拉默的鐵騎兵團"
    },
    translation_set:{
      "en-us": "Firmament Frontline: Glamoth",
      "zh-cn": "苍穹战线格拉默",
      "zh-tw": "蒼穹戰線格拉默"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>ATK</p> by 12%. When the wearer's <p class=\"highlight-property\"}>SPD</p> is equal to or higher than 135/160, the wearer deals 12%/18% more DMG.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>效果抵抗</p>提高10%。当装备者的<p class=\"highlight-property\"}>效果抵抗大于等于30%</p>时，我方全体<p class=\"highlight-property\"}>暴击伤害</p>提高10%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>效果抵抗</p>提高10%。當裝備者的<p class=\"highlight-property\"}>效果抵抗大於等於30%</p>時，我方全體<p class=\"highlight-property\"}>暴擊傷害</p>提高10%。",
      },
    ],
    code: "FF",
    colour: "#F87896",
    image: require("../components/image/relic/311_0.webp"),
  },
  {
    id: "311_1",
    name: "Glamoth's Silent Tombstone",
    set: "Firmament Frontline: Glamoth",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Glamoth's Silent Tombstone",
      "zh-cn": "格拉默的寂静坟碑",
      "zh-tw": "格拉默的寂靜墳碑"
    },
    translation_set:{
      "en-us": "Firmament Frontline: Glamoth",
      "zh-cn": "苍穹战线格拉默",
      "zh-tw": "蒼穹戰線格拉默"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>ATK</p> by 12%. When the wearer's <p class=\"highlight-property\"}>SPD</p> is equal to or higher than 135/160, the wearer deals 12%/18% more DMG.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>效果抵抗</p>提高10%。当装备者的<p class=\"highlight-property\"}>效果抵抗大于等于30%</p>时，我方全体<p class=\"highlight-property\"}>暴击伤害</p>提高10%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>效果抵抗</p>提高10%。當裝備者的<p class=\"highlight-property\"}>效果抵抗大於等於30%</p>時，我方全體<p class=\"highlight-property\"}>暴擊傷害</p>提高10%。",
      },
    ],
    code: "FF",
    colour: "#F87896",
    image: require("../components/image/relic/311_1.webp"),
  },
  {
    id: "312_0",
    name: "Penacony's Grand Hotel",
    set: "Penacony, Land of the Dreams",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Penacony's Grand Hotel",
      "zh-cn": "匹诺康尼的堂皇酒店",
      "zh-tw": "匹諾康尼的堂皇飯店"
    },
    translation_set:{
      "en-us": "Penacony, Land of the Dreams",
      "zh-cn": "梦想之地匹诺康尼",
      "zh-tw": "夢想之地匹諾康尼"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases wearer's <p class=\"highlight-property\"}>Energy Regeneration Rate</p> by 5%. Increases <p class=\"highlight-property\"}>DMG</p> by 10% for all other allies that are of the same Type as the wearer.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>能量恢复效率</p>提高5%。使队伍中与装备者属性相同的我方其他角色造成的<p class=\"highlight-property\"}>伤害</p>提高10%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>能量恢復效率</p>提升5%。使隊伍中與裝備者屬性相同的我方其他角色造成的<p class=\"highlight-property\"}>傷害</p>提高10%。",
      },
    ],
    code: "PL",
    colour: "#9E90FD",
    image: require("../components/image/relic/312_0.webp"),
  },
  {
    id: "312_1",
    name: "Penacony's Dream-Seeking Tracks",
    set: "Penacony, Land of the Dreams",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Penacony's Dream-Seeking Tracks",
      "zh-cn": "匹诺康尼的逐梦轨道",
      "zh-tw": "匹諾康尼的逐夢軌道"
    },
    translation_set:{
      "en-us": "Penacony, Land of the Dreams",
      "zh-cn": "梦想之地匹诺康尼",
      "zh-tw": "夢想之地匹諾康尼"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases wearer's <p class=\"highlight-property\"}>Energy Regeneration Rate</p> by 5%. Increases <p class=\"highlight-property\"}>DMG</p> by 10% for all other allies that are of the same Type as the wearer.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>能量恢复效率</p>提高5%。使队伍中与装备者属性相同的我方其他角色造成的<p class=\"highlight-property\"}>伤害</p>提高10%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>能量恢復效率</p>提升5%。使隊伍中與裝備者屬性相同的我方其他角色造成的<p class=\"highlight-property\"}>傷害</p>提高10%。",
      },
    ],
    code: "PL",
    colour: "#9E90FD",
    image: require("../components/image/relic/312_1.webp"),
  },
  
  {
    id: "313_0",
    name: "Sigonia's Gaiathra Berth",
    set: "Sigonia, the Unclaimed Desolation",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Sigonia's Gaiathra Berth",
      "zh-cn": "茨冈尼亚的母神卧榻",
      "zh-tw": "茨岡尼亞的母神臥榻"
    },
    translation_set:{
      "en-us": "Sigonia, the Unclaimed Desolation",
      "zh-cn": "无主荒星茨冈尼亚",
      "zh-tw": "無主荒星茨岡尼亞"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> by 4%. When an enemy target gets defeated, the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> increases by 4%, stacking up to <p class=\"highlight-property\"}>10</p> time(s).",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击率</p>提高4%。当敌方目标被消灭时，装备者<p class=\"highlight-property\"}>暴击伤害</p>提高4%，最多叠加<p class=\"highlight-property\"}>10</p>层。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>爆擊率</p>增加4%。當敵方目標被消滅時，裝備者<p class=\"highlight-property\"}>暴擊傷害</p>增加4%，最多疊加<p class=\"highlight-property\"}>10</p>層。",
      },
    ],
    code: "SUD",
    colour: "#fbf6b6",
    image: require("../components/image/relic/313_0.webp"),
  },
  {
    id: "313_1",
    name: "Sigonia's Knot of Cyclicality",
    set: "Sigonia, the Unclaimed Desolation",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Sigonia's Knot of Cyclicality",
      "zh-cn": "茨冈尼亚的轮回纽结",
      "zh-tw": "茨岡尼亞的輪迴紐結"
    },
    translation_set:{
      "en-us": "Sigonia, the Unclaimed Desolation",
      "zh-cn": "无主荒星茨冈尼亚",
      "zh-tw": "無主荒星茨岡尼亞"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> by 4%. When an enemy target gets defeated, the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> increases by 4%, stacking up to <p class=\"highlight-property\"}>10</p> time(s).",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击率</p>提高4%。当敌方目标被消灭时，装备者<p class=\"highlight-property\"}>暴击伤害</p>提高4%，最多叠加<p class=\"highlight-property\"}>10</p>层。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>爆擊率</p>增加4%。當敵方目標被消滅時，裝備者<p class=\"highlight-property\"}>暴擊傷害</p>增加4%，最多疊加<p class=\"highlight-property\"}>10</p>層。",
      },
    ],
    code: "SUD",
    colour: "#fbf6b6",
    image: require("../components/image/relic/313_1.webp"),
  },

  
  {
    id: "314_0",
    name: "Izumo's Magatsu no Morokami",
    set: "Izumo Gensei and Takama Divine Realm",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Izumo's Magatsu no Morokami",
      "zh-cn": "出云的祸津众神",
      "zh-tw": "出雲的禍津眾神"
    },
    translation_set:{
      "en-us": "Izumo Gensei and Takama Divine Realm",
      "zh-cn": "出云显世与高天神国",
      "zh-tw": "出雲顯世與高天神國"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>ATK</p> by 12%. When entering battle, if at least one other ally follows the <p class=\"highlight-property\"}>same Path</p> as the wearer, then the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> increases by 12%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>攻击力</p>提高12%。进入战斗时，若至少存在一名与装备者<p class=\"highlight-property\"}>命途相同</p>的队友，装备者的<p class=\"highlight-property\"}>暴击率</p>提高12%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>攻擊力</p>增加12%。進入戰鬥時，若至少存在一名與裝備者<p class=\"highlight-property\"}>命途相同</p>的隊友，裝備者的<p class=\"highlight-property\"}>暴擊率</p>提高12%。",
      },
    ],
    code: "IGT",
    colour: "#dc6c9b",
    image: require("../components/image/relic/314_0.webp"),
  },
  {
    id: "314_1",
    name: "Izumo's Blades of Origin and End",
    set: "Izumo Gensei and Takama Divine Realm",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Izumo's Blades of Origin and End",
      "zh-cn": "出云的终始一刀",
      "zh-tw": "出雲的終始一刀"
    },
    translation_set:{
      "en-us": "Izumo Gensei and Takama Divine Realm",
      "zh-cn": "出云显世与高天神国",
      "zh-tw": "出雲顯世與高天神國"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>ATK</p> by 12%. When entering battle, if at least one other ally follows the <p class=\"highlight-property\"}>same Path</p> as the wearer, then the wearer's <p class=\"highlight-property\"}>CRIT Rate</p> increases by 12%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>攻击力</p>提高12%。进入战斗时，若至少存在一名与装备者<p class=\"highlight-property\"}>命途相同</p>的队友，装备者的<p class=\"highlight-property\"}>暴击率</p>提高12%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>攻擊力</p>增加12%。進入戰鬥時，若至少存在一名與裝備者<p class=\"highlight-property\"}>命途相同</p>的隊友，裝備者的<p class=\"highlight-property\"}>暴擊率</p>提高12%。",
      },
    ],
    code: "IGT",
    colour: "#dc6c9b",
    image: require("../components/image/relic/314_1.webp"),
  },

  {
    id: "315_0",
    name: "Duran’s Tent of Golden Sky",
    set: "Duran, Dynasty of Running Wolves",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Duran’s Tent of Golden Sky",
      "zh-cn": "都蓝的穹窿金帐",
      "zh-tw": "都蓝的穹窿金帐"
    },
    translation_set:{
      "en-us": "Duran, Dynasty of Running Wolves",
      "zh-cn": "奔狼的都蓝王朝",
      "zh-tw": "奔狼的都藍王朝"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "When allies <p class=\"highlight-property\"}>use follow-up attacks</p>, the wearer receives 1 stack of Merit, stacking up to 6 times. Every stack of Merit increases the <p class=\"highlight-property\"}>DMG dealt</p> by the wearer's follow-up attacks by 4%. When there are 6 stacks, additionally increases the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> by 24%.",
        "zh-cn": "我方角色<p class=\"highlight-property\"}>施放追加攻击</p>时，装备者获得一层【功勋】，最多叠加6层，每层【功勋】使装备者追加攻击<p class=\"highlight-property\"}>造成的伤害</p>提高4%，叠满6层时，额外使装备者的<p class=\"highlight-property\"}>暴击伤害</p>提高24%。",
        "zh-tw": "我方角色<p class=\"highlight-property\"}>施放追加攻擊</p>時，裝備者獲得一層【功勳】，最多疊加6層，每層【功勳】使裝備者追加攻擊<p class=\"highlight-property\"}>造成的傷害</p>增加4%，疊滿6層時，額外使裝備者的<p class=\"highlight-property\"}>暴擊傷害</p>提高24%。",
      },
    ],
    code: "DDRW",
    colour: "#5c8ef0",
    image: require("../components/image/relic/315_0.webp"),
  },
  {
    id: "315_1",
    name: "Duran's Mechabeast Bridle",
    set: "Duran, Dynasty of Running Wolves",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Duran's Mechabeast Bridle",
      "zh-cn": "都蓝的器兽缰辔",
      "zh-tw": "都蓝的器兽缰辔"
    },
    translation_set:{
      "en-us": "Duran, Dynasty of Running Wolves",
      "zh-cn": "奔狼的都蓝王朝",
      "zh-tw": "奔狼的都藍王朝"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "When allies <p class=\"highlight-property\"}>use follow-up attacks</p>, the wearer receives 1 stack of Merit, stacking up to 6 times. Every stack of Merit increases the <p class=\"highlight-property\"}>DMG dealt</p> by the wearer's follow-up attacks by 4%. When there are 6 stacks, additionally increases the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> by 24%.",
        "zh-cn": "我方角色<p class=\"highlight-property\"}>施放追加攻击</p>时，装备者获得一层【功勋】，最多叠加6层，每层【功勋】使装备者追加攻击<p class=\"highlight-property\"}>造成的伤害</p>提高4%，叠满6层时，额外使装备者的<p class=\"highlight-property\"}>暴击伤害</p>提高24%。",
        "zh-tw": "我方角色<p class=\"highlight-property\"}>施放追加攻擊</p>時，裝備者獲得一層【功勳】，最多疊加6層，每層【功勳】使裝備者追加攻擊<p class=\"highlight-property\"}>造成的傷害</p>增加4%，疊滿6層時，額外使裝備者的<p class=\"highlight-property\"}>暴擊傷害</p>提高24%。",
      },
    ],
    code: "DDRW",
    colour: "#5c8ef0",
    image: require("../components/image/relic/315_1.webp"),
  },
  {
    id: "316_0",
    name: "Forge's Lotus Lantern Wick",
    set: "Forge of the Kalpagni Lantern",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Forge's Lotus Lantern Wick",
      "zh-cn": "铸炼宫的莲华灯芯",
      "zh-tw": "铸炼宫的莲华灯芯"
    },
    translation_set:{
      "en-us": "Forge of the Kalpagni Lantern",
      "zh-cn": "劫火莲灯铸炼宫",
      "zh-tw": "劫火蓮燈鑄煉宮"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increase the wearer's <p class=\"highlight-property\"}>SPD</p> by 6%. When the wearer hits enemy targets with <p class=\"highlight-property\"}>Break Effect</p>, the wearer's <p class=\"highlight-property\"}>Fire Weakness</p> increases by 40%, lasting for 1 turn(s).",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>速度</p>提高6%。当装备者击中拥有<p class=\"highlight-property\"}>火属性弱点</p>的敌方目标时，<p class=\"highlight-property\"}>击破特攻</p>提高40%，持续1回合。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>速度</p>增加6%。當裝備者擊中擁有<p class=\"highlight-property\"}>火屬性弱點</p>的敵方目標時，<p class=\"highlight-property\"}>擊破特攻</p>提高40%，持續1回合。",
      },
    ],
    code: "FKL",
    colour: "#dc7e90",
    image: require("../components/image/relic/316_0.webp"),
  },
  {
    id: "316_1",
    name: "Forge’s Heavenly Flamewheel Silk",
    set: "Forge of the Kalpagni Lantern",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Forge’s Heavenly Flamewheel Silk",
      "zh-cn": "铸炼宫的焰轮天绸",
      "zh-tw": "铸炼宫的焰轮天绸"
    },
    translation_set:{
      "en-us": "Forge of the Kalpagni Lantern",
      "zh-cn": "劫火莲灯铸炼宫",
      "zh-tw": "劫火蓮燈鑄煉宮"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increase the wearer's <p class=\"highlight-property\"}>SPD</p> by 6%. When the wearer hits enemy targets with <p class=\"highlight-property\"}>Break Effect</p>, the wearer's <p class=\"highlight-property\"}>Fire Weakness</p> increases by 40%, lasting for 1 turn(s).",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>速度</p>提高6%。当装备者击中拥有<p class=\"highlight-property\"}>火属性弱点</p>的敌方目标时，<p class=\"highlight-property\"}>击破特攻</p>提高40%，持续1回合。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>速度</p>增加6%。當裝備者擊中擁有<p class=\"highlight-property\"}>火屬性弱點</p>的敵方目標時，<p class=\"highlight-property\"}>擊破特攻</p>提高40%，持續1回合。",
      },
    ],
    code: "FKL",
    colour: "#dc7e90",
    image: require("../components/image/relic/316_1.webp"),
  },
  {
    id: "317_0",
    name: "Lushaka's Waterscape",
    set: "Lushaka, the Sunken Seas",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Lushaka's Waterscape",
      "zh-cn": "露莎卡的水朽苍都",
      "zh-tw": "露莎卡的水朽蒼都"
    },
    translation_set:{
      "en-us": "Lushaka, the Sunken Seas",
      "zh-cn": "沉陆海域露莎卡",
      "zh-tw": "沉陸海域露莎卡"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Energy Regeneration Rate</p> by 5%. If the wearer is not the first character in the team lineup, then increases the <p class=\"highlight-property\"}>ATK</p> of the first character in the team lineup by 12%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>能量恢复效率</p>提高5%，如果装备者不是编队中的第一位角色，使编队中的第一位角色<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>能量恢復效率</p>提高5%，如果裝備者不是編隊中的第一位角色，使編隊中的第一位角色<p class=\"highlight-property\"}>攻擊力</p>提高12%。",
      },
    ],
    code: "LSS",
    colour: "#2eb5df",
    image: require("../components/image/relic/317_0.webp"),
  },
  {
    id: "317_1",
    name: "Lushaka's Twinlanes",
    set: "Lushaka, the Sunken Seas",
    type: "Planar Ornaments",
    translation:{
      "en-us": "Lushaka's Twinlanes",
      "zh-cn": "露莎卡的双生航道",
      "zh-tw": "露莎卡的雙生航道"
    },
    translation_set:{
      "en-us": "Lushaka, the Sunken Seas",
      "zh-cn": "沉陆海域露莎卡",
      "zh-tw": "沉陸海域露莎卡"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>Energy Regeneration Rate</p> by 5%. If the wearer is not the first character in the team lineup, then increases the <p class=\"highlight-property\"}>ATK</p> of the first character in the team lineup by 12%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>能量恢复效率</p>提高5%，如果装备者不是编队中的第一位角色，使编队中的第一位角色<p class=\"highlight-property\"}>攻击力</p>提高12%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>能量恢復效率</p>提高5%，如果裝備者不是編隊中的第一位角色，使編隊中的第一位角色<p class=\"highlight-property\"}>攻擊力</p>提高12%。",
      },
    ],
    code: "LSS",
    colour: "#2eb5df",
    image: require("../components/image/relic/317_1.webp"),
  },
  {
    id: "318_0",
    name: "BananAmusement Park's BananAxis Plaza",
    set: "The Wondrous BananAmusement Park",
    type: "Planar Ornaments",
    translation:{
      "en-us": "BananAmusement Park's BananAxis Plaza",
      "zh-cn": "蕉乐园的蕉芯广场",
      "zh-tw": "蕉樂園的蕉芯廣場"
    },
    translation_set:{
      "en-us": "The Wondrous BananAmusement Park",
      "zh-cn": "奇想蕉乐园",
      "zh-tw": "奇想蕉樂園"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> by 16%. When a target summoned by the wearer is on the field, <p class=\"highlight-property\"}>CRIT DMG</p> additionally increases by 32%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击伤害</p>提高16%，当存在装备者召唤的目标时，<p class=\"highlight-property\"}>暴击伤害</p>额外提高32%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>暴擊傷害</p>提高16%，當存在裝備者召喚的目標時，<p class=\"highlight-property\"}>暴擊傷害</p>額外提高32%。",
      },
    ],
    code: "TWBP",
    colour: "#d5b0e3",
    image: require("../components/image/relic/318_0.webp"),
  },
  
  {
    id: "318_1",
    name: "BananAmusement Park's Memetic Cables",
    set: "The Wondrous BananAmusement Park",
    type: "Planar Ornaments",
    translation:{
      "en-us": "BananAmusement Park's Memetic Cables",
      "zh-cn": "蕉乐园的模因线缆",
      "zh-tw": "蕉樂園的迷因纜線"
    },
    translation_set:{
      "en-us": "The Wondrous BananAmusement Park",
      "zh-cn": "奇想蕉乐园",
      "zh-tw": "奇想蕉樂園"
    },
    bonus:[
      {
        "set" : 2, 
        "en-us": "Increases the wearer's <p class=\"highlight-property\"}>CRIT DMG</p> by 16%. When a target summoned by the wearer is on the field, <p class=\"highlight-property\"}>CRIT DMG</p> additionally increases by 32%.",
        "zh-cn": "使装备者的<p class=\"highlight-property\"}>暴击伤害</p>提高16%，当存在装备者召唤的目标时，<p class=\"highlight-property\"}>暴击伤害</p>额外提高32%。",
        "zh-tw": "使裝備者的<p class=\"highlight-property\"}>暴擊傷害</p>提高16%，當存在裝備者召喚的目標時，<p class=\"highlight-property\"}>暴擊傷害</p>額外提高32%。",
      },
    ],
    code: "TWBP",
    colour: "#d5b0e3",
    image: require("../components/image/relic/318_1.webp"),
  },
];

export const mainStats = [
  {
    id: "body",
    name: "body",
    translation:{
      "en-us": "Body",
      "zh-cn": "躯干",
      "zh-tw": "軀幹"
    },
    image: require("../components/image/icon/body.webp")
  },
  {
    id: "feet",
    name: "feet",
    translation:{
      "en-us": "Feet",
      "zh-cn": "脚部",
      "zh-tw": "腳部"
    },
    image: require("../components/image/icon/feet.webp")
  },
  {
    id: "planar_sphere",
    name: "planar_sphere",
    translation:{
      "en-us": "Planar Sphere",
      "zh-cn": "面位球",
      "zh-tw": "面位球"
    },
    image: require("../components/image/icon/planar_sphere.webp")
  },
  {
    id: "link_rope",
    name: "link_rope",
    translation:{
      "en-us": "Link Rope",
      "zh-cn": "连结绳",
      "zh-tw": "連結繩"
    },
    image: require("../components/image/icon/link_rope.webp")
  },
]

export const property = [
  {
    id: "A",
    name: "ATK",
    translation:{
      "en-us": "ATK",
      "zh-cn": "攻击力",
      "zh-tw": "攻擊力"
    },
    type: "relic",
    image: require("../components/image/property/a.webp"),
  },
  {
    id: "AR",
    name: "ATT",
    translation:{
      "en-us": "ATK %",
      "zh-cn": "攻击力 %",
      "zh-tw": "攻擊力 %"
    },
    type: "relic",
    image: require("../components/image/property/a.webp"),
  },
  {
    id: "BE",
    name: "Break Effect",
    translation:{
      "en-us": "Break Effect",
      "zh-cn": "击破特攻",
      "zh-tw": "擊破特攻"
    },
    type: "relic",
    image: require("../components/image/property/be.webp"),
  },
  {
    id: "CR",
    name: "CRIT Rate",
    translation:{
      "en-us": "CRIT Rate",
      "zh-cn": "暴击率",
      "zh-tw": "暴擊率"
    },
    type: "relic",
    image: require("../components/image/property/cr.webp"),
  },
  {
    id: "CD",
    name: "CRIT DMG",
    translation:{
      "en-us": "CRIT DMG",
      "zh-cn": "暴击伤害",
      "zh-tw": "暴擊傷害"
    },
    type: "relic",
    image: require("../components/image/property/cd.webp"),
  },
  {
    id: "D",
    name: "DEF",
    translation:{
      "en-us": "DEF",
      "zh-cn": "防御力",
      "zh-tw": "防禦力"
    },
    type: "relic",
    image: require("../components/image/property/d.webp"),
  },
  {
    id: "DR",
    name: "DEF",
    translation:{
      "en-us": "DEF %",
      "zh-cn": "防御力 %",
      "zh-tw": "防禦力 %"
    },
    type: "relic",
    image: require("../components/image/property/d.webp"),
  },
  {
    id: "DB",
    name: "DMG Boost",
    type: "",
  },
  {
    id: "DBF",
    name: "Fire DMG Boost",
    translation:{
      "en-us": "Fire DMG Boost",
      "zh-cn": "火属性伤害提高",
      "zh-tw": "火屬性傷害提高"
    },
    type: "relic",
    image: fireIcon,
  },
  {
    id: "DBI",
    name: "Ice DMG Boost",
    translation:{
      "en-us": "Ice DMG Boost",
      "zh-cn": "冰属性伤害提高",
      "zh-tw": "冰屬性傷害提高"
    },
    type: "relic",
    image: iceIcon,
  },
  {
    id: "DBM",
    name: "Imaginary DMG Boost",
    translation:{
      "en-us": "Imaginary DMG Boost",
      "zh-cn": "虚数属性伤害提高",
      "zh-tw": "虛數屬性傷害提高"
    },
    type: "relic",
    image: imaginaryIcon,
  },
  {
    id: "DBP",
    name: "Physical DMG Boost",
    translation:{
      "en-us": "Physical DMG Boost",
      "zh-cn": "物理属性伤害提高",
      "zh-tw": "物理屬性傷害提高"
    },
    type: "relic",
    image: physicalIcon,
  },
  {
    id: "DBL",
    name: "Lightning DMG Boost",
    translation:{
      "en-us": "Lightning DMG Boost",
      "zh-cn": "雷属性伤害提高",
      "zh-tw": "雷屬性傷害提高"
    },
    type: "relic",
    image: lightningIcon,
  },
  {
    id: "DBQ",
    name: "Quantum DMG Boost",
    translation:{
      "en-us": "Quantum DMG Boost",
      "zh-cn": "量子属性伤害提高",
      "zh-tw": "量子屬性傷害提高"
    },
    type: "relic",
    image: quantumIcon,
  },
  {
    id: "DBW",
    name: "Wind DMG Boost",
    translation:{
      "en-us": "Wind DMG Boost",
      "zh-cn": "风属性伤害提高",
      "zh-tw": "風屬性傷害提高"
    },
    type: "relic",
    image: windIcon,
  },
  {
    id: "E",
    name: "Energy",
    translation:{
      "en-us": "Energy",
      "zh-cn": "能量上限",
      "zh-tw": "能量上限"
    },
    type: "",
  },
  {
    id: "EG",
    name: "Energy Regeneration Rate",
    translation:{
      "en-us": "Energy Regeneration Rate",
      "zh-cn": "能量恢复效率",
      "zh-tw": "能量恢復效率"
    },
    type: "relic",
    image: require("../components/image/property/eg.webp"),
  },
  {
    id: "EH",
    name: "Effect Hit Rate",
    translation:{
      "en-us": "Effect Hit Rate",
      "zh-cn": "效果命中",
      "zh-tw": "效果命中"
    },
    type: "relic",
    image: require("../components/image/property/eh.webp"),
  },
  {
    id: "ER",
    name: "Effect RES",
    translation:{
      "en-us": "Effect RES",
      "zh-cn": "效果抵抗",
      "zh-tw": "效果抵抗"
    },
    type: "relic",
    image: require("../components/image/property/er.webp"),
  },
  {
    id: "H",
    name: "HP",
    translation:{
      "en-us": "HP",
      "zh-cn": "生命值",
      "zh-tw": "生命值"
    },
    type: "relic",
    image: require("../components/image/property/h.webp"),
  },
  {
    id: "HR",
    name: "HP",
    translation:{
      "en-us": "HP %",
      "zh-cn": "生命值 %",
      "zh-tw": "生命值 %"
    },
    type: "relic",
    image: require("../components/image/property/h.webp"),
  },
  {
    id: "O",
    name: "Outgoing Healing Boost",
    translation:{
      "en-us": "Outgoing Healing Boost",
      "zh-cn": "治疗量加成",
      "zh-tw": "治療量加成"
    },
    type: "relic",
    image: require("../components/image/property/o.webp"),
  },
  {
    id: "S",
    name: "SPD",
    translation:{
      "en-us": "SPD",
      "zh-cn": "速度",
      "zh-tw": "速度"
    },
    type: "relic",
    image: require("../components/image/property/s.webp"),
  },
];

export const path = [
  {
    id: "abundance",
    name: "Abundance",
    translation:{
      "en-us": "Abundance",
      "zh-cn": "丰饶",
      "zh-tw": "豐饒"
    },
    checked: false,
    image: abundanceIcon,
  },
  {
    id: "destruction",
    name: "Destruction",
    translation:{
      "en-us": "Destruction",
      "zh-cn": "毁灭",
      "zh-tw": "毀滅"
    },
    checked: false,
    image: destructionIcon,
  },
  {
    id: "erudition",
    name: "Erudition",
    translation:{
      "en-us": "Erudition",
      "zh-cn": "智识",
      "zh-tw": "智識"
    },
    checked: false,
    image: eruditionIcon,
  },
  {
    id: "harmony",
    name: "Harmony",
    translation:{
      "en-us": "Harmony",
      "zh-cn": "同谐",
      "zh-tw": "同諧"
    },
    checked: false,
    image: harmonyIcon,
  },
  {
    id: "hunt",
    name: "Hunt",
    translation:{
      "en-us": "Hunt",
      "zh-cn": "巡猎",
      "zh-tw": "巡獵"
    },
    checked: false,
    image: huntIcon,
  },
  {
    id: "nihility",
    name: "Nihility",
    translation:{
      "en-us": "Nihility",
      "zh-cn": "虚无",
      "zh-tw": "虛無"
    },
    checked: false,
    image: nihilityIcon,
  },
  {
    id: "preservation",
    name: "Preservation",
    translation:{
      "en-us": "Preservation",
      "zh-cn": "存护",
      "zh-tw": "存護"
    },
    checked: false,
    image: preservationIcon,
  },
];

export const rarity = [
  {
    id: 4,
    name: "4 Stars",
    rarity_number: "four-star",
    translation: {
      "en-us": "4 Stars",
      "zh-cn": "4 星",
      "zh-tw": "4 星"
    },
    checked: false,
    image: fourStarsIcon,
  },
  {
    id: 5,
    name: "5 Stars",
    rarity_number: "five-star",
    translation: {
      "en-us": "5 Stars",
      "zh-cn": "5 星",
      "zh-tw": "5 星"
    },
    checked: false,
    image: fiveStarsIcon,
  },
];

export const elements = [
  {
    id: "fire",
    name: "Fire",
    translation :{
      "en-us": "Fire",
      "zh-cn": "火",
      "zh-tw": "火"
    },
    checked: false,
    image: fireIcon,
  },
  {
    id: "ice",
    name: "Ice",
    translation:{
      "en-us": "Ice",
      "zh-cn": "冰",
      "zh-tw": "冰"
    },
    checked: false,
    image: iceIcon,
  },
  {
    id: "imaginary",
    name: "Imaginary",
    translation:{
      "en-us": "Imaginary",
      "zh-cn": "虚数",
      "zh-tw": "虛數"
    },
    checked: false,
    image: imaginaryIcon,
  },
  {
    id: "lightning",
    name: "Lightning",
    translation:{
      "en-us": "Lightning",
      "zh-cn": "雷",
      "zh-tw": "雷"
    },
    checked: false,
    image: lightningIcon,
  },
  {
    id: "physical",
    name: "Physical",
    translation :{
      "en-us": "Physical",
      "zh-cn": "物理",
      "zh-tw": "物理"
    },
    checked: false,
    image: physicalIcon,
  },
  {
    id: "quantum",
    name: "Quantum",
    translation:{
      "en-us": "Quantum",
      "zh-cn": "量子",
      "zh-tw": "量子"
    },
    checked: false,
    image: quantumIcon,
  },
  {
    id: "wind",
    name: "Wind",
    translation:{
      "en-us": "Wind",
      "zh-cn": "风",
      "zh-tw": "風"
    },
    checked: false,
    image: windIcon,
  },
];

export const lightConeRows = [
  {
    id: "20000",
    name: "Arrows",
    translation:{
      "en-us": "Arrows",
      "zh-cn": "锋镝",
      "zh-tw": "鋒鏑"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20000.webp"),
  },
  {
    id: "20001",
    name: "Cornucopia",
    translation:{
      "en-us": "Cornucopia",
      "zh-cn": "物穰",
      "zh-tw": "物穰"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20001.webp"),
  },
  {
    id: "20002",
    name: "Collapsing Sky",
    translation:{
      "en-us": "Collapsing Sky",
      "zh-cn": "天倾",
      "zh-tw": "天傾"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20002.webp"),
  },
  {
    id: "20003",
    name: "Amber",
    translation:{
      "en-us": "Amber",
      "zh-cn": "琥珀",
      "zh-tw": "琥珀"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20003.webp"),
  },
  {
    id: "20004",
    name: "Void",
    translation:{
      "en-us": "Void",
      "zh-cn": "幽邃",
      "zh-tw": "幽邃"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20004.webp"),
  },
  {
    id: "20005",
    name: "Chorus",
    translation:{
      "en-us": "Chorus",
      "zh-cn": "齐颂",
      "zh-tw": "齊頌"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20005.webp"),
  },
  {
    id: "20006",
    name: "Data Bank",
    translation:{
      "en-us": "Data Bank",
      "zh-cn": "智库",
      "zh-tw": "智庫"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20006.webp"),
  },
  {
    id: "20007",
    name: "Darting Arrow",
    translation:{
      "en-us": "Darting Arrow",
      "zh-cn": "离弦",
      "zh-tw": "離弦"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20007.webp"),
  },
  {
    id: "20008",
    name: "Fine Fruit",
    translation:{
      "en-us": "Fine Fruit",
      "zh-cn": "嘉果",
      "zh-tw": "嘉果"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20008.webp"),
  },
  {
    id: "20009",
    name: "Shattered Home",
    translation:{
      "en-us": "Shattered Home",
      "zh-cn": "乐圮",
      "zh-tw": "樂圮"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20009.webp"),
  },
  {
    id: "20010",
    name: "Defense",
    translation:{
      "en-us": "Defense",
      "zh-cn": "戍御",
      "zh-tw": "戍禦"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20010.webp"),
  },
  {
    id: "20011",
    name: "Loop",
    translation:{
      "en-us": "Loop",
      "zh-cn": "渊环",
      "zh-tw": "淵環"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20011.webp"),
  },
  {
    id: "20012",
    name: "Meshing Cogs",
    translation:{
      "en-us": "Meshing Cogs",
      "zh-cn": "轮契",
      "zh-tw": "輪契"
    },
    effect_translation:{
      "en-us": "After the wearer uses attacks or gets hit, additionally regenerates <p class=\"highlight-property\"}>4-8</p> Energy. This effect can only be triggered 1 time per turn.",
      "zh-cn": "使装备者施放攻击或受到攻击后，额外恢复<p class=\"highlight-property\"}>4-8</p>点能量，该效果单个回合内不可重复触发。",
      "zh-tw": "使裝備者施放攻擊或受到攻擊後，額外恢復<p class=\"highlight-property\"}>4-8</p>點能量，此效果單個回合內不可重複觸發。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "231K"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "stellaris-symphony")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "ancestral-hymn")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "harmonic-tune")[0],
        value: "3"
      },
    ],
    hp: "846",
    atk: "317",
    def: "264",
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20012.webp"),
  },
  {
    id: "20013",
    name: "Passkey",
    translation:{
      "en-us": "Passkey",
      "zh-cn": "灵钥",
      "zh-tw": "靈鑰"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20013.webp"),
  },
  {
    id: "20014",
    name: "Adversarial",
    translation:{
      "en-us": "Adversarial",
      "zh-cn": "相抗",
      "zh-tw": "相抗"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20014.webp"),
  },
  {
    id: "20015",
    name: "Multiplication",
    translation:{
      "en-us": "Multiplication",
      "zh-cn": "蕃息",
      "zh-tw": "蕃息"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20015.webp"),
  },
  {
    id: "20016",
    name: "Mutual Demise",
    translation:{
      "en-us": "Mutual Demise",
      "zh-cn": "俱殁",
      "zh-tw": "俱歿"
    },
    effect_translation:{
      "en-us": "If the wearer's current HP is lower than 80%, CRIT Rate increases by <p class=\"highlight-property\"}>12%-24%</p>.",
      "zh-cn": "装备者当前生命值百分比小于80%时，暴击率提高<p class=\"highlight-property\"}>12%-24%</p>。",
      "zh-tw": "裝備者當前生命值百分比小於80%時，暴擊率提高<p class=\"highlight-property\"}>12%-24%</p>。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "10"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "2"
      },
    ],
    hp: "846",
    atk: "370",
    def: "198",
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20016.webp"),
  },
  {
    id: "20017",
    name: "Pioneering",
    translation:{
      "en-us": "Pioneering",
      "zh-cn": "开疆",
      "zh-tw": "開疆"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20017.webp"),
  },
  {
    id: "20018",
    name: "Hidden Shadow",
    translation:{
      "en-us": "Hidden Shadow",
      "zh-cn": "匿影",
      "zh-tw": "匿影"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20018.webp"),
  },
  {
    id: "20019",
    name: "Mediation",
    translation:{
      "en-us": "Mediation",
      "zh-cn": "调和",
      "zh-tw": "調和"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20019.webp"),
  },
  {
    id: "20020",
    name: "Sagacity",
    translation:{
      "en-us": "Sagacity",
      "zh-cn": "睿见",
      "zh-tw": "睿見"
    },
    rarity_number: "three-star",
    rarity: threeStarsIcon,
    image: require("../components/image/lightcone/20020.webp"),
  },
  {
    id: "21000",
    name: "Post-Op Conversation",
    translation:{
      "en-us": "Post-Op Conversation",
      "zh-cn": "一场术后对话",
      "zh-tw": "一場術後對話"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21000.webp"),
  },
  {
    id: "21001",
    name: "Good Night and Sleep Well",
    translation:{
      "en-us": "Good Night and Sleep Well",
      "zh-cn": "晚安与睡颜",
      "zh-tw": "晚安與睡顏"
    },
    effect_translation:{
      "en-us": "For every debuff the target enemy has, the DMG dealt by the wearer increases by <p class=\"highlight-property\"}>12%-24%</p>, stacking up to 3 time(s). This effect also applies to DoT.",
      "zh-cn": "敌方目标每承受一个负面状态，装备者对其造成的伤害提高<p class=\"highlight-property\"}>12%-24%</p>，最多叠加3次。该效果对持续伤害也会生效。",
      "zh-tw": "敵方目標每承受一個負面狀態，裝備者對其造成的傷害提高<p class=\"highlight-property\"}>12%-24%</p>，最多疊加3次。該效果對持續傷害也會生效。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "476",
    def: "330",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21001.webp"),
  },
  {
    id: "21002",
    name: "Day One of My New Life",
    translation:{
      "en-us": "Day One of My New Life",
      "zh-cn": "余生的第一天",
      "zh-tw": "餘生的第一天"
    },
    
    effect_translation:{
      "en-us": "Increases wearer's DEF by <p class=\"highlight-property\"}>16%-24%</p>. After entering battle, increases DMG RES of all allies by <p class=\"highlight-property\"}>8%-12%</p>. Effects of the same type cannot stack.",
      "zh-cn": "使装备者的防御力提高<p class=\"highlight-property\"}>16%-24%</p>。进入战斗后，使我方全体的伤害抗性提高<p class=\"highlight-property\"}>8%-12%</p>。同类技能无法重复生效。",
      "zh-tw": "使裝備者的防禦力提高<p class=\"highlight-property\"}>16%-24%</p>。進入戰鬥後，使我方全體的傷害抗性提高<p class=\"highlight-property\"}>8%-12%</p>。同類技能無法重複生效。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "safeguard-of-amber")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "oath-of-steel")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "endurance-of-bronze")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "370",
    def: "463",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21002.webp"),
  },
  {
    id: "21003",
    name: "Only Silence Remains",
    translation:{
      "en-us": "Only Silence Remains",
      "zh-cn": "唯有沉默",
      "zh-tw": "唯有沉默"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21003.webp"),
  },
  {
    id: "21004",
    name: "Memories of the Past",
    translation:{
      "en-us": "Memories of the Past",
      "zh-cn": "记忆中的模样",
      "zh-tw": "記憶中的模樣"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Break Effect by <p class=\"highlight-property\"}>28%-56%</p>. When the wearer attacks, additionally regenerates <p class=\"highlight-property\"}>4-8</p> Energy. This effect can only be triggered 1 time per turn.",
      "zh-cn": "使装备者的击破特攻提高<p class=\"highlight-property\"}>28%-56%</p>。装备者施放攻击后，额外恢复<p class=\"highlight-property\"}>4-8</p>点能量，该效果单个回合内不可重复触发。",
      "zh-tw": "使裝備者的擊破特攻提高<p class=\"highlight-property\"}>28%-56%</p>。裝備者施放攻擊後，額外恢復<p class=\"highlight-property\"}>4-8</p>點能量，此效果單個回合內不可重複觸發。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "stellaris-symphony")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "ancestral-hymn")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "harmonic-tune")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "423",
    def: "396",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21004.webp"),
  },
  {
    id: "21005",
    name: "The Moles Welcome You",
    translation:{
      "en-us": "The Moles Welcome You",
      "zh-cn": "鼹鼠党欢迎你",
      "zh-tw": "鼴鼠黨歡迎你"
    },
    effect_translation:{
      "en-us": "Increases the wearer's ATK by <p class=\"highlight-property\"}>16%-32%</p>. When the wearer defeats an enemy, the wearer's CRIT Rate increases by <p class=\"highlight-property\"}>12%-24%</p> for 3 turn(s).",
      "zh-cn": "使装备者攻击力提高<p class=\"highlight-property\"}>16%-32%</p>，当装备者消灭敌方目标后，暴击率提高<p class=\"highlight-property\"}>12%-24%</p>，持续3回合。",
      "zh-tw": "使裝備者攻擊力提高<p class=\"highlight-property\"}>16%-32%</p>，當裝備者消滅敵方目標後，暴擊率提高<p class=\"highlight-property\"}>12%-24%</p>，持續3回合。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "476",
    def: "330",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21005.webp"),
  },
  {
    id: "21006",
    name: "The Birth of the Self",
    translation:{
      "en-us": "The Birth of the Self",
      "zh-cn": "「我」的诞生",
      "zh-tw": "「我」的誕生"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21006.webp"),
  },
  {
    id: "21007",
    name: "Shared Feeling",
    translation:{
      "en-us": "Shared Feeling",
      "zh-cn": "同一种心情",
      "zh-tw": "同一種心情"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21007.webp"),
  },
  {
    id: "21008",
    name: "Eyes of the Prey",
    translation:{
      "en-us": "Eyes of the Prey",
      "zh-cn": "猎物的视线",
      "zh-tw": "獵物的視線"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21008.webp"),
  },
  {
    id: "21009",
    name: "Landau's Choice",
    translation:{
      "en-us": "Landau's Choice",
      "zh-cn": "朗道的选择",
      "zh-tw": "朗道的選擇"
    },
    effect_translation:{
      "en-us": "The wearer is more likely to be attacked, but DMG taken is reduced by <p class=\"highlight-property\"}>16%-24%</p>.",
      "zh-cn": "使装备者受到攻击的概率提高，同时受到的伤害降低<p class=\"highlight-property\"}>16%-24%</p>。",
      "zh-tw": "使裝備者受到攻擊的概率提高，同時受到的傷害降低<p class=\"highlight-property\"}>16%-24%</p>。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "safeguard-of-amber")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "oath-of-steel")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "endurance-of-bronze")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "423",
    def: "396",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21009.webp"),
  },
  {
    id: "21010",
    name: "Swordplay",
    translation:{
      "en-us": "Swordplay",
      "zh-cn": "论剑",
      "zh-tw": "論劍"
    },
    
    effect_translation:{
      "en-us": "For each time the wearer hits the same target, DMG dealt increases by <p class=\"highlight-property\"}>8%-16%</p>, stacking up to 5 time(s). This effect will be dispelled when the wearer changes targets.",
      "zh-cn": "当装备者多次击中同一敌方目标时，每次造成的伤害提高<p class=\"highlight-property\"}>8%-16%</p>，最多叠加5次。若攻击目标发生变化，立即解除当前的增益状态。",
      "zh-tw": "當裝備者多次擊中同一敵方目標時，每次造成的傷害提高<p class=\"highlight-property\"}>8%-16%</p>，最多疊加5次。若攻擊目標發生變化，立即解除當前的增益狀態。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-starchaser")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-demon-slayer")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-beast-hunter")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "476",
    def: "330",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21010.webp"),
  },
  {
    id: "21011",
    name: "Planetary Rendezvous",
    translation:{
      "en-us": "Planetary Rendezvous",
      "zh-cn": "与行星相会",
      "zh-tw": "與行星相會"
    },
    effect_translation:{
      "en-us": "Upon battle entry, if an ally deals the same DMG Type as the wearer, DMG dealt increases by <p class=\"highlight-property\"}>12%-24%</p>.",
      "zh-cn": "进入战斗后，当我方目标造成与装备者相同属性的伤害时，造成的伤害提高<p class=\"highlight-property\"}>12%-24%</p>。",
      "zh-tw": "進入戰鬥後，當我方目標造成與裝備者相同屬性的傷害時，造成的傷害提高<p class=\"highlight-property\"}>12%-24%</p>。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "stellaris-symphony")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "ancestral-hymn")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "harmonic-tune")[0],
        value: "3"
      },
    ],
    hp: "1058",
    atk: "423",
    def: "330",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21011.webp"),
  },
  {
    id: "21012",
    name: "A Secret Vow",
    translation:{
      "en-us": "A Secret Vow",
      "zh-cn": "秘密誓心",
      "zh-tw": "秘密誓心"
    },
    effect_translation:{
      "en-us": "Increases DMG dealt by the wearer by <p class=\"highlight-property\"}>20%-40%</p>. The wearer also deals an extra <p class=\"highlight-property\"}>20%-40%</p> of DMG to enemies with a higher HP percentage than the wearer.",
      "zh-cn": "使装备者造成的伤害提高<p class=\"highlight-property\"}>20%-40%</p>，同时对当前生命值百分比高于装备者自身当前生命值百分比的敌方目标造成的伤害额外提高<p class=\"highlight-property\"}>20%-40%</p>。",
      "zh-tw": "使裝備者造成的傷害提高<p class=\"highlight-property\"}>20%-40%</p>，同時對當前生命值百分比高於裝備者自身當前生命值百分比的敵方目標造成的傷害額外提高<p class=\"highlight-property\"}>20%-40%</p>。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "3"
      },
    ],
    hp: "1058",
    atk: "476",
    def: "264",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21012.webp"),
  },
  {
    id: "21013",
    name: "Make the World Clamor",
    translation:{
      "en-us": "Make the World Clamor",
      "zh-cn": "别让世界静下来",
      "zh-tw": "別讓世界靜下來"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21013.webp"),
  },
  {
    id: "21014",
    name: "Perfect Timing",
    translation:{
      "en-us": "Perfect Timing",
      "zh-cn": "此时恰好",
      "zh-tw": "此時恰好"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21014.webp"),
  },
  {
    id: "21015",
    name: "Resolution Shines As Pearls of Sweat",
    translation:{
      "en-us": "Resolution Shines As Pearls of Sweat",
      "zh-cn": "决心如汗珠般闪耀",
      "zh-tw": "決心如汗珠般閃耀"
    },
    effect_translation:{
      "en-us": "When the wearer hits an enemy and if the hit enemy is not already Ensnared, then there is a <p class=\"highlight-property\"}>60%-100%</p> base chance to Ensnare the hit enemy. Ensnared enemies' DEF decreases by <p class=\"highlight-property\"}>12%-16%</p> for 1 turn(s).",
      "zh-cn": "当装备者击中敌方目标时，如果该目标不处于【攻陷】状态，则有<p class=\"highlight-property\"}>60%-100%</p>的基础概率使其陷入【攻陷】状态。【攻陷】状态下的敌方目标防御力降低<p class=\"highlight-property\"}>12%-16%</p>，持续1回合。",
      "zh-tw": "當裝備者擊中敵方目標時，如果該目標不處於【攻陷】狀態，則有<p class=\"highlight-property\"}>60%-100%</p>的基礎概率使其陷入【攻陷】狀態。【攻陷】狀態下的敵方目標防禦力降低<p class=\"highlight-property\"}>12%-16%</p>，持續1回合。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "artifexs-gyreheart")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "artifexs-cogwheel")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "artifexs-module")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "476",
    def: "330",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21015.webp"),
  },
  {
    id: "21016",
    name: "Trend of the Universal Market",
    translation:{
      "en-us": "Trend of the Universal Market",
      "zh-cn": "宇宙市场趋势",
      "zh-tw": "宇宙市場趨勢"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21016.webp"),
  },
  {
    id: "21017",
    name: "Subscribe for More!",
    translation:{
      "en-us": "Subscribe for More!",
      "zh-cn": "点个关注吧！",
      "zh-tw": "點個關注吧！"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21017.webp"),
  },
  {
    id: "21018",
    name: "Dance! Dance! Dance!",
    translation:{
      "en-us": "Dance! Dance! Dance!",
      "zh-cn": "舞！舞！舞！",
      "zh-tw": "舞！舞！舞！"
    },
    effect_translation:{
      "en-us": "When the wearer uses their Ultimate, all allies' actions are Advanced Forward by <p class=\"highlight-property\"}>16%-24%</p>.",
      "zh-cn": "当装备者施放终结技后，我方全体行动提前<p class=\"highlight-property\"}>16%-24%</p>。",
      "zh-tw": "當裝備者施放終結技後，我方全體行動提前<p class=\"highlight-property\"}>16%-24%</p>。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "artifexs-gyreheart")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "artifexs-cogwheel")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "artifexs-module")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "stellaris-symphony")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "ancestral-hymn")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "harmonic-tune")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "423",
    def: "396",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21018.webp"),
  },
  {
    id: "21019",
    name: "Under the Blue Sky",
    translation:{
      "en-us": "Under the Blue Sky",
      "zh-cn": "在蓝天下",
      "zh-tw": "在藍天下"
    },
    effect_translation:{
      "en-us": "Increases the wearer's ATK by <p class=\"highlight-property\"}>16%-32%</p>. When the wearer defeats an enemy, the wearer's CRIT Rate increases by <p class=\"highlight-property\"}>12%-24%</p> for 3 turn(s).",
      "zh-cn": "使装备者攻击力提高<p class=\"highlight-property\"}>16%-32%</p>，当装备者消灭敌方目标后，暴击率提高<p class=\"highlight-property\"}>12%-24%</p>，持续3回合。",
      "zh-tw": "使裝備者攻擊力提高<p class=\"highlight-property\"}>16%-32%</p>，當裝備者消滅敵方目標後，暴擊率提高<p class=\"highlight-property\"}>12%-24%</p>，持續3回合。",
    },
    obtain:{
      "en-us": "All Banner",
      "zh-cn": "所有卡池",
      "zh-tw": "所有卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "476",
    def: "330",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21019.webp"),
  },
  {
    id: "21020",
    name: "Geniuses' Repose",
    translation:{
      "en-us": "Geniuses' Repose",
      "zh-cn": "天才们的休憩",
      "zh-tw": "天才們的休憩"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21020.webp"),
  },
  {
    id: "21021",
    name: "Quid Pro Quo",
    translation:{
      "en-us": "Quid Pro Quo",
      "zh-cn": "等价交换",
      "zh-tw": "等價交換"
    },
    effect_translation:{
      "en-us": "At the start of the wearer's turn, regenerates <p class=\"highlight-property\"}>8-16</p> Energy for a randomly chosen ally (excluding the wearer) whose current Energy is lower than 50%.",
      "zh-cn": "当装备者的回合开始时，随机为1个当前能量百分比小于50%的我方其他目标恢复<p class=\"highlight-property\"}>8-16</p>点能量。",
      "zh-tw": "當裝備者的回合開始時，隨機為1個當前能量百分比小於50%的我方其他目標恢復<p class=\"highlight-property\"}>8-16</p>點能量。",
    },
    obtain:{
      "en-us": "Forgotten Hall Store",
      "zh-cn": "忘却之庭商店",
      "zh-tw": "忘卻之庭商店",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "300K"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "5"
      },
      {
        item: items.filter(id => id.id === "flower-of-eternity")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "sprout-of-life")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "seed-of-abundance")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "423",
    def: "396",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21021.webp"),
  },
  {
    id: "21022",
    name: "Fermata",
    translation:{
      "en-us": "Fermata",
      "zh-cn": "延长记号",
      "zh-tw": "延長記號"
    },
    effect_translation:{
      "en-us": "Increases Break Effect dealt by the wearer by <p class=\"highlight-property\"}>16%-32%</p>, and increases their DMG to enemies afflicted with Shock or Wind Shear by <p class=\"highlight-property\"}>16%-32%</p>. This also applies to DoT.",
      "zh-cn": "使装备者的击破特攻提高<p class=\"highlight-property\"}>16%-32%</p>，对处于触电或风化状态的敌方目标造成的伤害提高<p class=\"highlight-property\"}>16%-32%</p>，该效果对持续伤害也会生效。",
      "zh-tw": "使裝備者的擊破特攻提高<p class=\"highlight-property\"}>16%-32%</p>，對處於觸電或風化狀態的敵方目標造成的傷害提高<p class=\"highlight-property\"}>16%-32%</p>，該效果對持續傷害也會生效。",
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21022.webp"),
  },
  {
    id: "21023",
    name: "We Are Wildfire",
    translation:{
      "en-us": "We Are Wildfire",
      "zh-cn": "我们是地火",
      "zh-tw": "我們是地火"
    },
    
    effect_translation:{
      "en-us": "At the start of the battle, the DMG dealt to all allies decreases by <p class=\"highlight-property\"}>8%-16%</p> for 5 turn(s). At the same time, immediately restores HP to all allies equal to <p class=\"highlight-property\"}>30%-50%</p> of the respective HP difference between the characters' Max HP and current HP.",
      "zh-cn": "战斗开始时，使我方全体伤害承受降低<p class=\"highlight-property\"}>8%-16%</p>，持续5回合。同时立即为我方全体回复等同于各自已损失生命值<p class=\"highlight-property\"}>30%-50%</p>的生命值。",
      "zh-tw": "戰鬥開始時，使我方全體傷害承受降低<p class=\"highlight-property\"}>8%-16%</p>，持續5回合。同時立即為我方全體回覆等同於各自已損失生命值<p class=\"highlight-property\"}>30%-50%</p>的生命值。",
    },
    obtain:{
      "en-us": "Forgotten Hall Store",
      "zh-cn": "忘却之庭商店",
      "zh-tw": "忘卻之庭商店",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "ancient-engine")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "ancient-spindle")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "ancient-part")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "safeguard-of-amber")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "oath-of-steel")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "endurance-of-bronze")[0],
        value: "3"
      },
    ],
    hp: "740",
    atk: "476",
    def: "463",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21023.webp"),
  },
  {
    id: "21024",
    name: "River Flows in Spring",
    translation:{
      "en-us": "River Flows in Spring",
      "zh-cn": "春水初生",
      "zh-tw": "春水初生"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21024.webp"),
  },
  {
    id: "21025",
    name: "Past and Future",
    translation:{
      "en-us": "Past and Future",
      "zh-cn": "过往未来",
      "zh-tw": "過往未來"
    },
    effect_translation:{
      "en-us": "When the wearer uses their Skill, then the next ally taking action (except the wearer) deals <p class=\"highlight-property\"}>16%-32%</p> increased DMG for 1 turn(s).",
      "zh-cn": "当装备者施放战技后，使下一个行动的我方其他目标造成的伤害提高<p class=\"highlight-property\"}>16%-32%</p>，持续1回合。",
      "zh-tw": "當裝備者施放戰技後，使下一個行動的我方其他目標造成的傷害提高<p class=\"highlight-property\"}>16%-32%</p>，持續1回合。",
    },
    obtain:{
      "en-us": "Forgotten Hall Store",
      "zh-cn": "忘却之庭商店",
      "zh-tw": "忘卻之庭商店",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "stellaris-symphony")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "ancestral-hymn")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "harmonic-tune")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "423",
    def: "396",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21025.webp"),
  },
  {
    id: "21026",
    name: "Woof! Walk Time!",
    translation:{
      "en-us": "Woof! Walk Time!",
      "zh-cn": "汪！散步时间！",
      "zh-tw": "汪！散步時間！"
    },
    effect_translation:{
      "en-us": "Increases the wearer’s ATK by <p class=\"highlight-property\"}>10%-20%</p>, and increases their DMG to enemies afflicted with Burn or Bleed by <p class=\"highlight-property\"}>16%-32%</p>. This also applies to DoT.",
      "zh-cn": "使装备者的攻击力提高<p class=\"highlight-property\"}>10%-20%</p>，对处于灼烧或裂伤状态的敌方目标造成的伤害提高<p class=\"highlight-property\"}>16%-32%</p>，该效果对持续伤害也会生效。",
      "zh-tw": "使裝備者的攻擊力提高<p class=\"highlight-property\"}>10%-20%</p>，對處於灼燒或裂傷狀態的敵方目標造成的傷害提高<p class=\"highlight-property\"}>16%-32%</p>，該效果對持續傷害也會生效。",
    },
    obtain:{
      "en-us": "Forgotten Hall Store",
      "zh-cn": "忘却之庭商店",
      "zh-tw": "忘卻之庭商店",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "476",
    def: "330",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21026.webp"),
  },
  {
    id: "21027",
    name: "The Seriousness of Breakfast",
    translation:{
      "en-us": "The Seriousness of Breakfast",
      "zh-cn": "早餐的仪式感",
      "zh-tw": "早餐的儀式感"
    },
    effect_translation:{
      "en-us": "Increases the wearer's DMG by <p class=\"highlight-property\"}>12%-24%</p>. For every defeated enemy, the wearer's ATK increases by <p class=\"highlight-property\"}>4%-8%</p>, stacking up to 3 time(s).",
      "zh-cn": "使装备者造成伤害提高<p class=\"highlight-property\"}>12%-24%</p>。每消灭1个敌方目标，装备者的攻击力提高<p class=\"highlight-property\"}>4%-8%</p>，该效果最多叠加3层。",
      "zh-tw": "使裝備者造成傷害提高<p class=\"highlight-property\"}>12%-24%</p>。每消滅1個敵方目標，裝備者的攻擊力提高<p class=\"highlight-property\"}>4%-8%</p>，該效果最多疊加3層。",
    },
    obtain:{
      "en-us": "Forgotten Hall Store",
      "zh-cn": "忘却之庭商店",
      "zh-tw": "忘卻之庭商店",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "key-of-wisdom")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "key-of-knowledge")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "key-of-inspiration")[0],
        value: "3"
      },
    ],
    hp: "846",
    atk: "476",
    def: "396",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21027.webp"),
  },
  {
    id: "21028",
    name: "Warmth Shortens Cold Nights",
    translation:{
      "en-us": "Warmth Shortens Cold Nights",
      "zh-cn": "暖夜不会漫长",
      "zh-tw": "暖夜不會漫長"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Max HP by <p class=\"highlight-property\"}>16%-32%</p>. When using Basic ATK or Skill, restores all allies' HP by an amount equal to <p class=\"highlight-property\"}>2%-4%</p> of their respective Max HP.",
      "zh-cn": "使装备者的生命上限提高<p class=\"highlight-property\"}>16%-32%</p>。施放普攻或战技后，为我方全体回复等同于各自生命上限<p class=\"highlight-property\"}>2%-4%</p>的生命值。",
      "zh-tw": "使裝備者的生命上限提高<p class=\"highlight-property\"}>16%-32%</p>。施放普攻或戰技後，為我方全體回復等同於各自生命上限<p class=\"highlight-property\"}>2%-4%</p>的生命值。",
    },
    obtain:{
      "en-us": "Nameless Honor",
      "zh-cn": "无名勋礼",
      "zh-tw": "無名勳禮",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "300K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "5"
      },
      {
        item: items.filter(id => id.id === "flower-of-eternity")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "sprout-of-life")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "seed-of-abundance")[0],
        value: "3"
      },
    ],
    hp: "1058",
    atk: "370",
    def: "396",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21028.webp"),
  },
  {
    id: "21029",
    name: "We Will Meet Again",
    translation:{
      "en-us": "We Will Meet Again",
      "zh-cn": "后会有期",
      "zh-tw": "後會有期"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21029.webp"),
  },
  {
    id: "21030",
    name: "This Is Me!",
    translation:{
      "en-us": "This Is Me!",
      "zh-cn": "这就是我啦！",
      "zh-tw": "這就是我啦！"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21030.webp"),
  },
  {
    id: "21031",
    name: "Return to Darkness",
    translation:{
      "en-us": "Return to Darkness",
      "zh-cn": "重返幽冥",
      "zh-tw": "重返幽冥"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21031.webp"),
  },
  {
    id: "21032",
    name: "Carve the Moon, Weave the Clouds",
    translation:{
      "en-us": "Carve the Moon, Weave the Clouds",
      "zh-cn": "镂月裁云之意",
      "zh-tw": "鏤月裁雲之意"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21032.webp"),
  },
  {
    id: "21033",
    name: "Nowhere to Run",
    translation:{
      "en-us": "Nowhere to Run",
      "zh-cn": "无处可逃",
      "zh-tw": "無處可逃"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21033.webp"),
  },
  {
    id: "21034",
    name: "Today Is Another Peaceful Day",
    translation:{
      "en-us": "Today Is Another Peaceful Day",
      "zh-cn": "今日亦是和平的一日",
      "zh-tw": "今日亦是和平的一日"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21034.webp"),
  },
  {
    id: "21035",
    name: "What Is Real?",
    translation:{
      "en-us": "What Is Real?",
      "zh-cn": "何物为真",
      "zh-tw": "何物為真"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21035.webp"),
  },
  {
    id: "21036",
    name: "Dreamville Adventure",
    translation:{
      "en-us": "Dreamville Adventure",
      "zh-cn": "美梦小镇大冒险",
      "zh-tw": "美夢小鎮大冒險"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21036.webp"),
  },
  {
    id: "21037",
    name: "Final Victor",
    translation:{
      "en-us": "Final Victor",
      "zh-cn": "最后的赢家",
      "zh-tw": "最後的贏家"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21037.webp"),
  },
  {
    id: "21038",
    name: "Flames Afar",
    translation:{
      "en-us": "Flames Afar",
      "zh-cn": "在火的远处",
      "zh-tw": "在火的遠處"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21038.webp"),
  },
  {
    id: "21039",
    name: "Destiny's Threads Forewoven",
    translation:{
      "en-us": "Destiny's Threads Forewoven",
      "zh-cn": "织造命运之线",
      "zh-tw": "織造命運之線"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21039.webp"),
  },
  {
    id: "21040",
    name: "The Day The Cosmos Fell",
    translation:{
      "en-us": "The Day The Cosmos Fell",
      "zh-cn": "银河沦陷日",
      "zh-tw": "銀河淪陷日"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21040.webp"),
  },
  {
    id: "21041",
    name: "It's Showtime",
    translation:{
      "en-us": "It's Showtime",
      "zh-cn": "好戏开演",
      "zh-tw": "好戲開演"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21041.webp"),
  },
  {
    id: "21042",
    name: "Indelible Promise",
    translation:{
      "en-us": "Indelible Promise",
      "zh-cn": "铭记于心的约定",
      "zh-tw": "銘記於心的約定"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21042.webp"),
  },
  {
    id: "21044",
    name: "Boundless Choreo",
    translation:{
      "en-us": "Boundless Choreo",
      "zh-cn": "无边曼舞",
      "zh-tw": "無邊曼舞"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/21044.webp"),
  },
  {
    id: "22000",
    name: "Before the Tutorial Mission Starts",
    translation:{
      "en-us": "Before the Tutorial Mission Starts",
      "zh-cn": "新手任务开始前",
      "zh-tw": "新手任務開始前"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Effect Hit Rate by <p class=\"highlight-property\"}>20%-40%</p>. When the wearer attacks enemies that have reduced DEF, regenerates <p class=\"highlight-property\"}>4-8</p> Energy.",
      "zh-cn": "使装备者的效果命中提高<p class=\"highlight-property\"}>20%-40%</p>。当装备者攻击防御力被降低的敌方目标后，恢复<p class=\"highlight-property\"}>4-8</p>点能量。",
      "zh-tw": "使裝備者的效果命中提高<p class=\"highlight-property\"}>20%-40%</p>。當裝備者攻擊防禦力被降低的敵方目標後，恢復<p class=\"highlight-property\"}>4-8</p>點能量。",
    },
    obtain:{
      "en-us": "Event Exclusive",
      "zh-cn": "活动限定",
      "zh-tw": "活動限定",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "476",
    def: "330",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/22000.webp"),
  },
  
  {
    id: "22001",
    name: "Hey, Over Here",
    translation:{
      "en-us": "Hey, Over Here",
      "zh-cn": "嘿，我在这儿",
      "zh-tw": "嘿，我在這裡"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Max HP by <p class=\"highlight-property\"}>8%-12%</p>. When the wearer uses their Skill, increases Outgoing Healing by <p class=\"highlight-property\"}>16%-28%</p>, lasting for 2 turn(s).",
      "zh-cn": "使装备者的生命上限提高<p class=\"highlight-property\"}>8%-12%</p>。当装备者施放战技时，治疗量提高<p class=\"highlight-property\"}>16%-28%</p>，持续2回合。",
      "zh-tw": "使裝備者的生命上限提高<p class=\"highlight-property\"}>8%-12%</p>。當裝備者施放戰技時，治療量增加<p class=\"highlight-property\"}>16%-28%</p>，持續2回合。",
    },
    obtain:{
      "en-us": "Event Exclusive",
      "zh-cn": "活动限定",
      "zh-tw": "活動限定",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "artifexs-gyreheart")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "artifexs-cogwheel")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "artifexs-module")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "flower-of-eternity")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "sprout-of-life")[0],
        value: "9"
      },
      {
        item: items.filter(id => id.id === "seed-of-abundance")[0],
        value: "3"
      },
    ],
    hp: "952",
    atk: "423",
    def: "396",
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/22001.webp"),
  },
  
  {
    id: "22002",
    name: "For Tomorrow's Journey",
    translation:{
      "en-us": "For Tomorrow's Journey",
      "zh-cn": "为了明日的旅途",
      "zh-tw": "为了明日的旅途"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    image: require("../components/image/lightcone/22002.webp"),
  },

  {
    id: "23000",
    name: "Night on the Milky Way",
    translation:{
      "en-us": "Night on the Milky Way",
      "zh-cn": "银河铁道之夜",
      "zh-tw": "銀河鐵道之夜"
    },
    effect_translation:{
      "en-us": "For every enemy on the field, increases the wearer's ATK by <p class=\"highlight-property\"}>9%-15%</p>, up to 5 stacks. When an enemy is inflicted with Weakness Break, the DMG dealt by the wearer increases by <p class=\"highlight-property\"}>30%-50%</p> for 1 turn.",
      "zh-cn": "当场上每有1个敌方目标，使装备者的攻击力提高<p class=\"highlight-property\"}>9%-15%</p>，该效果最多叠加5层。当有敌方目标的弱点被击破时，装备者造成的伤害提高<p class=\"highlight-property\"}>30%-50%</p>，持续1回合。",
      "zh-tw": "當場上每有1個敵方目標，使裝備者的攻擊力提高<p class=\"highlight-property\"}>9%-15%</p>，該效果最多疊加5層。當有敵方目標的弱點被擊破時，裝備者造成的傷害提高<p class=\"highlight-property\"}>30%-50%</p>，持續1回合。",
    },
    obtain:{
      "en-us": "Starlight Exchange",
      "zh-cn": "星芒兑换",
      "zh-tw": "星芒兌換",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "key-of-wisdom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "key-of-knowledge")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "key-of-inspiration")[0],
        value: "4"
      },
    ],
    hp: "1164",
    atk: "582",
    def: "396",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23000.webp"),
  },
  {
    id: "23001",
    name: "In the Night",
    translation:{
      "en-us": "In the Night",
      "zh-cn": "于夜色中",
      "zh-tw": "於夜色中"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT Rate by <p class=\"highlight-property\"}>18%-30%</p>. While the wearer is in battle, for every 10 SPD that exceeds 100, the DMG of the wearer's Basic ATK and Skill is increased by <p class=\"highlight-property\"}>6%-10%</p> and the CRIT DMG of their Ultimate is increased by <p class=\"highlight-property\"}>12%-20%</p>. This effect can stack up to 6 time(s).",
      "zh-cn": "使装备者的暴击率提高<p class=\"highlight-property\"}>18%-30%</p>。当装备者在战斗中速度达到大于100时，每超过10点，普攻和战技造成的伤害提高<p class=\"highlight-property\"}>6%-10%</p>，同时终结技的暴击伤害提高<p class=\"highlight-property\"}>12%-20%</p>，该效果可叠加6层。",
      "zh-tw": "使裝備者的暴擊率提高<p class=\"highlight-property\"}>18%-30%</p>。當裝備者在戰鬥中速度達到大於100時，每超過10點，普攻和戰技造成的傷害提高<p class=\"highlight-property\"}>6%-10%</p>，同時終結技的暴擊傷害提高<p class=\"highlight-property\"}>12%-20%</p>，該效果可疊加6層。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-starchaser")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-demon-slayer")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-beast-hunter")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "582",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23001.webp"),
  },
  {
    id: "23002",
    name: "Something Irreplaceable",
    translation:{
      "en-us": "Something Irreplaceable",
      "zh-cn": "无可取代的东西",
      "zh-tw": "無可取代的東西"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23002.webp"),
  },
  {
    id: "23003",
    name: "But the Battle Isn't Over",
    translation:{
      "en-us": "But the Battle Isn't Over",
      "zh-cn": "但战斗还未结束",
      "zh-tw": "但戰鬥還未結束"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Energy Regeneration Rate by <p class=\"highlight-property\"}>10%-18%</p> and regenerates 1 Skill Point when the wearer uses their Ultimate on an ally. This effect can be triggered after every 2 uses of the wearer's Ultimate. When the wearer uses their Skill, the next ally taking action (except the wearer) deals <p class=\"highlight-property\"}>30%-50%</p> more DMG for 1 turn(s).",
      "zh-cn": "使装备者的能量恢复效率提高<p class=\"highlight-property\"}>10%-18%</p>，并在对我方目标施放终结技时恢复1个战技点。该效果每施放2次终结技可触发一次。当装备者施放战技后，使下1个行动的我方其他目标造成的伤害提高<p class=\"highlight-property\"}>30%-50%</p>，持续1回合。",
      "zh-tw": "使裝備者的能量恢復效率提升<p class=\"highlight-property\"}>10%-18%</p>，並在對我方目標施放終結技時恢復1個戰技點。此效果每施放2次終結技可觸發一次。當裝備者施放戰技後，使下1個行動的我方其他目標造成的傷害提高<p class=\"highlight-property\"}>30%-50%</p>，持續1回合。",
    },
    obtain:{
      "en-us": "Starlight Exchange",
      "zh-cn": "星芒兑换",
      "zh-tw": "星芒兌換",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "stellaris-symphony")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "ancestral-hymn")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "harmonic-tune")[0],
        value: "4"
      },
    ],
    hp: "1283",
    atk: "529",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23003.webp"),
  },
  {
    id: "23004",
    name: "In the Name of the World",
    translation:{
      "en-us": "In the Name of the World",
      "zh-cn": "以世界之名",
      "zh-tw": "以世界之名"
    },
    effect_translation:{
      "en-us": "Increases the wearer's DMG to debuffed enemies by <p class=\"highlight-property\"}>24%-40%</p>. When the wearer uses their Skill, the Effect Hit Rate for this attack increases by <p class=\"highlight-property\"}>18%-30%</p>, and ATK increases by <p class=\"highlight-property\"}>24%-40%</p>.",
      "zh-cn": "使装备者对陷入负面效果的敌方目标造成的伤害提高<p class=\"highlight-property\"}>24%-40%</p>。当装备者施放战技时，装备者此次攻击的效果命中提高<p class=\"highlight-property\"}>18%-30%</p>，攻击力提高<p class=\"highlight-property\"}>24%-40%</p>。",
      "zh-tw": "使裝備者對陷入負面效果的敵方目標造成的傷害提高<p class=\"highlight-property\"}>24%-40%</p>。當裝備者施放戰技時，裝備者此次攻擊的效果命中提高<p class=\"highlight-property\"}>18%-30%</p>，攻擊力提高<p class=\"highlight-property\"}>24%-40%</p>。",
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23004.webp"),
  },
  {
    id: "23005",
    name: "Moment of Victory",
    translation:{
      "en-us": "Moment of Victory",
      "zh-cn": "制胜的瞬间",
      "zh-tw": "制勝的瞬間"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23005.webp"),
  },
  {
    id: "23006",
    name: "Patience Is All You Need",
    translation:{
      "en-us": "Patience Is All You Need",
      "zh-cn": "只需等待",
      "zh-tw": "只需等待"
    },
    effect_translation:{
      "en-us": "Increases DMG dealt by the wearer by <p class=\"highlight-property\"}>24%-40%</p>. After every attack unleashed by the wearer, the wearer's SPD increases by <p class=\"highlight-property\"}>4.8%-8%</p>, stacking up to 3 times. If the wearer hits an enemy target that is not affected by Erode, there is a 100% base chance to inflict Erode on the target. Enemies afflicted with Erode are also considered to be Shocked and will receive Lightning DoT at the start of each turn equal to <p class=\"highlight-property\"}>60%-100%</p> of the wearer\'s ATK, lasting for 1 turn.",
      "zh-cn": "使装备者造成的伤害提高<p class=\"highlight-property\"}>24%-40%</p>。装备者每次施放攻击后，速度提高<p class=\"highlight-property\"}>4.8%-8%</p>，最多叠加3层。当装备者击中敌方目标时，如果该目标不处于【游丝】状态，则有100%的基础概率使其陷入【游丝】状态。当敌方目标处于【游丝】状态时，也会被视为陷入了触电状态。【游丝】状态下，敌方目标每回合开始时受到等同于装备者<p class=\"highlight-property\"}>60%-100%</p>攻击力的雷属性持续伤害，持续1回合。",
      "zh-tw": "使裝備者造成的傷害提高<p class=\"highlight-property\"}>24%-40%</p>。裝備者每次施放攻擊後，速度提高<p class=\"highlight-property\"}>4.8%-8%</p>，最多疊加3層。當裝備者擊中敵方目標時，如果該目標不處於【游絲】狀態，則有100%的基礎概率使其陷入【游絲】狀態。當敵方目標處於【游絲】狀態時，也會被視為陷入了觸電狀態。【游絲】狀態下，敵方目標每回合開始時受到等同於裝備者<p class=\"highlight-property\"}>60%-100%</p>攻擊力的雷屬性持續傷害，持續1回合。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "582",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23006.webp"),
  },
  {
    id: "23007",
    name: "Incessant Rain",
    translation:{
      "en-us": "Incessant Rain",
      "zh-cn": "雨一直下",
      "zh-tw": "雨一直下"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Effect Hit Rate by <p class=\"highlight-property\"}>24%-40%</p>. When the wearer deals DMG to an enemy that currently has 3 or more debuffs, increases the wearer's CRIT Rate by <p class=\"highlight-property\"}>12%-20%</p>. After the wearer uses their Basic ATK, Skill, or Ultimate, there is a 100% base chance to implant Aether Code on a random hit target that does not yet have it. Targets with Aether Code receive <p class=\"highlight-property\"}>12%-20%</p> increased DMG for 1 turn.",
      "zh-cn": "使装备者的效果命中提高<p class=\"highlight-property\"}>24%-40%</p>。当装备者对同时处于大于等于3个负面效果的敌方目标造成伤害时，暴击率提高<p class=\"highlight-property\"}>12%-20%</p>。装备者施放普攻、战技、终结技后，有100%的基础概率对随机1个未持有【以太编码】的受击目标施加【以太编码】。持有【以太编码】的目标受到的伤害提高<p class=\"highlight-property\"}>12%-20%</p>，持续1回合。",
      "zh-tw": "使裝備者的效果命中提高<p class=\"highlight-property\"}>24%-40%</p>。當裝備者對同時處於大於等於3個負面效果的敵方目標造成傷害時，暴擊率提高<p class=\"highlight-property\"}>12%-20%</p>。裝備者施放普攻、戰技、終結技後，有100%的基礎概率對隨機1個未持有【以太編碼】的受擊目標施加【以太編碼】。持有【以太編碼】的目標受到的傷害提高<p class=\"highlight-property\"}>12%-20%</p>，持續1回合。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "ancient-engine")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "ancient-spindle")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "ancient-part")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "1270",
    atk: "582",
    def: "330",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23007.webp"),
  },
  {
    id: "23008",
    name: "Echoes of the Coffin",
    translation:{
      "en-us": "Echoes of the Coffin",
      "zh-cn": "棺的回响",
      "zh-tw": "棺的迴響"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23008.webp"),
  },
  {
    id: "23009",
    name: "The Unreachable Side",
    translation:{
      "en-us": "The Unreachable Side",
      "zh-cn": "到不了的彼岸",
      "zh-tw": "到不了的彼岸"
    },
    effect_translation:{
      "en-us": "Increase the wearer's CRIT Rate and Max HP by <p class=\"highlight-property\"}>18%-30%</p>. After the wearer is attacked or consumes their own HP, increases the wearer's DMG by <p class=\"highlight-property\"}>24%-40%</p>. This effect is dispelled after the user uses an attack.",
      "zh-cn": "使装备者的暴击率和生命上限提高<p class=\"highlight-property\"}>18%-30%</p>。当装备者受到攻击或装备者消耗自身生命值后，造成的伤害提高<p class=\"highlight-property\"}>24%-40%</p>，该效果在装备者施放攻击后解除。",
      "zh-tw": "使裝備者的暴擊率和生命上限提高<p class=\"highlight-property\"}>18%-30%</p>。當裝備者受到攻擊或裝備者消耗自身生命值後，造成的傷害提高<p class=\"highlight-property\"}>24%-40%</p>，該效果在裝備者施放攻擊後解除。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "375K"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "4"
      },
    ],
    hp: "1270",
    atk: "582",
    def: "330",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23009.webp"),
  },
  {
    id: "23010",
    name: "Before Dawn",
    translation:{
      "en-us": "Before Dawn",
      "zh-cn": "拂晓之前",
      "zh-tw": "拂曉之前"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT DMG by <p class=\"highlight-property\"}>36%-60%</p>. Increases the wearer's Skill and Ultimate DMG by <p class=\"highlight-property\"}>18%-30%</p>. After the wearer uses their Skill or Ultimate, they gain Somnus Corpus. Upon triggering a follow-up attack, Somnus Corpus will be consumed and the follow-up attack DMG increases by <p class=\"highlight-property\"}>48%-80%</p>.",
      "zh-cn": "使装备者暴击伤害提高<p class=\"highlight-property\"}>36%-60%</p>。使装备者战技和终结技造成的伤害提高<p class=\"highlight-property\"}>18%-30%</p>。当装备者施放战技或终结技后，获得【梦身】效果。触发追加攻击时，消耗【梦身】，使追加攻击造成的伤害提高<p class=\"highlight-property\"}>48%-80%</p>。",
      "zh-tw": "使裝備者暴擊傷害提高<p class=\"highlight-property\"}>36%-60%</p>。使裝備者戰技和終結技造成的傷害提高<p class=\"highlight-property\"}>18%-30%</p>。當裝備者施放戰技或終結技後，獲得【夢身】效果。觸發追加攻擊時，消耗【夢身】，使追加攻擊造成的傷害提高<p class=\"highlight-property\"}>48%-80%</p>。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "key-of-wisdom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "key-of-knowledge")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "key-of-inspiration")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "582",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23010.webp"),
  },
  {
    id: "23011",
    name: "She Already Shut Her Eyes",
    translation:{
      "en-us": "She Already Shut Her Eyes",
      "zh-cn": "她已闭上双眼",
      "zh-tw": "她已閉上雙眼"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Max HP by <p class=\"highlight-property\"}>24%-40%</p> and Energy Regeneration Rate by <p class=\"highlight-property\"}>12%-20%</p>. When the wearer's HP is reduced, all allies' DMG dealt increases by <p class=\"highlight-property\"}>9%-15%</p>, for 2 turn(s). At the start of every wave, restores HP to all allies equal to <p class=\"highlight-property\"}>80%-100%</p> of the respective HP difference between each characters' Max HP and their current HP.",
      "zh-cn": "使装备者的生命上限提高<p class=\"highlight-property\"}>24%-40%</p>，能量恢复效率提高<p class=\"highlight-property\"}>12%-20%</p>。当装备者的生命值降低时，使我方全体造成的伤害提高<p class=\"highlight-property\"}>9%-15%</p>，持续2回合。每个波次开始时，为我方全体回复等同于各自已损失生命值<p class=\"highlight-property\"}>80%-100%</p>的生命值。",
      "zh-tw": "使裝備者的生命上限提高<p class=\"highlight-property\"}>24%-40%</p>，能量恢復效率提高<p class=\"highlight-property\"}>12%-20%</p>。當裝備者的生命值降低時，使我方全體造成的傷害提高<p class=\"highlight-property\"}>9%-15%</p>，持續2回合。每個波次開始時，為我方全體回復等同於各自已損失生命值<p class=\"highlight-property\"}>80%-100%</p>的生命值。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "375K"
      },
      {
        item: items.filter(id => id.id === "artifexs-gyreheart")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "artifexs-cogwheel")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "artifexs-module")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "safeguard-of-amber")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "oath-of-steel")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "endurance-of-bronze")[0],
        value: "4"
      },
    ],
    hp: "1270",
    atk: "423",
    def: "529",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/she-already-shut-her-eyes.webp"),
  },
  {
    id: "23012",
    name: "Sleep Like the Dead",
    translation:{
      "en-us": "Sleep Like the Dead",
      "zh-cn": "如泥酣眠",
      "zh-tw": "如泥酣眠"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23012.webp"),
  },
  {
    id: "23013",
    name: "Time Waits for No One",
    translation:{
      "en-us": "Time Waits for No One",
      "zh-cn": "时节不居",
      "zh-tw": "時節不居"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Max HP by <p class=\"highlight-property\"}>18%-30%</p> and Outgoing Healing by <p class=\"highlight-property\"}>12%-20%</p>. When the wearer heals allies, record the amount of Outgoing Healing. When any ally launches an attack, a random attacked enemy takes additional DMG equal to <p class=\"highlight-property\"}>36%-60%</p> of the recorded Outgoing Healing value. This Additional DMG is of the same Type as the wearer's, is not affected by other buffs, and can only occur 1 time per turn.",
      "zh-cn": "使装备者生命上限提高<p class=\"highlight-property\"}>18%-30%</p>，治疗量提高<p class=\"highlight-property\"}>12%-20%</p>。当装备者对我方目标提供治疗时，记录治疗量。当任意我方目标施放攻击后，根据记录治疗量的<p class=\"highlight-property\"}>36%-60%</p>，对随机1个受到攻击的敌方目标造成基于装备者属性的附加伤害。该伤害不受加成影响，每回合最多结算1次。",
      "zh-tw": "使裝備者生命上限提高<p class=\"highlight-property\"}>18%-30%</p>，治療量提高<p class=\"highlight-property\"}>12%-20%</p>。當裝備者對我方目標提供治療時，記錄治療量。當任意我方目標施放攻擊後，根據記錄治療量的<p class=\"highlight-property\"}>36%-60%</p>，對隨機1個受到攻擊的敵方目標造成基於裝備者屬性的附加傷害。該傷害不受加成影響，每回合最多結算1次。",
    },
    obtain:{
      "en-us": "Starlight Exchange",
      "zh-cn": "星芒兑换",
      "zh-tw": "星芒兌換",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "flower-of-eternity")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "sprout-of-life")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "seed-of-abundance")[0],
        value: "4"
      },
    ],
    hp: "1270",
    atk: "476",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23013.webp"),
  },
  {
    id: "24000",
    name: "On the Fall of an Aeon",
    translation:{
      "en-us": "On the Fall of an Aeon",
      "zh-cn": "记一位星神的陨落",
      "zh-tw": "記一位星神的隕落"
    },
    effect_translation:{
      "en-us": "Whenever the wearer attacks, their ATK is increased by <p class=\"highlight-property\"}>8%-16%</p> in this battle, up to 4 time(s). When the wearer inflicts Weakness Break on enemies, the wearer's DMG increases by <p class=\"highlight-property\"}>12%-24%</p> for 2 turn(s).",
      "zh-cn": "当装备者施放攻击时，使装备者本场战斗中的攻击力提升<p class=\"highlight-property\"}>8%-16%</p>，该效果最多叠加4层。当装备者击破敌方弱点后，造成的伤害提高<p class=\"highlight-property\"}>12%-24%</p>，持续2回合。",
      "zh-tw": "當裝備者施放攻擊時，使裝備者本場戰鬥中的攻擊力提升<p class=\"highlight-property\"}>8%-16%</p>，該效果最多疊加4層。當裝備者擊破敵方弱點後，造成的傷害提高<p class=\"highlight-property\"}>12%-24%</p>，持續2回合。",
    },
    obtain:{
      "en-us": "Herta's Store",
      "zh-cn": "黑塔商店",
      "zh-tw": "黑塔商店",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "529",
    def: "396",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/24000.webp"),
  },
  {
    id: "24001",
    name: "Cruising in the Stellar Sea",
    translation:{
      "en-us": "Cruising in the Stellar Sea",
      "zh-cn": "星海巡航",
      "zh-tw": "星海巡航"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT rate by <p class=\"highlight-property\"}>8%-16%</p>, and increases their CRIT rate against enemies with HP less than or equal to 50% by an extra <p class=\"highlight-property\"}>8%-16%</p>. When the wearer defeats an enemy, their ATK is increased by <p class=\"highlight-property\"}>20%-40%</p> for 2 turn(s).",
      "zh-cn": "使装备者的暴击率提高<p class=\"highlight-property\"}>8%-16%</p>，装备者对生命值百分比小于等于50%的敌方目标暴击率额外提高<p class=\"highlight-property\"}>8%-16%</p>。当装备者消灭敌方目标后，攻击力提高<p class=\"highlight-property\"}>20%-40%</p>，持续2回合。",
      "zh-tw": "使裝備者的暴擊率提高<p class=\"highlight-property\"}>8%-16%</p>，裝備者對生命值百分比小於等於50%的敵方目標暴擊率額外提高<p class=\"highlight-property\"}>8%-16%</p>。當裝備者消滅敵方目標後，攻擊力提高<p class=\"highlight-property\"}>20%-40%</p>，持續2回合。",
    },
    obtain:{
      "en-us": "Herta's Store",
      "zh-cn": "黑塔商店",
      "zh-tw": "黑塔商店",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-starchaser")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-demon-slayer")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-beast-hunter")[0],
        value: "4"
      },
    ],
    hp: "952",
    atk: "529",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/24001.webp"),
  },
  {
    id: "24002",
    name: "Texture of Memories",
    translation:{
      "en-us": "Texture of Memories",
      "zh-cn": "记忆的质料",
      "zh-tw": "記憶的質料"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Effect RES by <p class=\"highlight-property\"}>8%-16%</p>. If the wearer is attacked and has no Shield, they gain a Shield equal to <p class=\"highlight-property\"}>16%-32%</p> of their Max HP for 2 turn(s). This effect can only be triggered once every 3 turn(s). If the wearer has a Shield when attacked, the DMG they receive decreases by <p class=\"highlight-property\"}>12%-24%</p>.",
      "zh-cn": "使装备者的效果抵抗提高<p class=\"highlight-property\"}>8%-16%</p>，当装备者受到攻击后，如果自身未持有护盾，则获得1个等同于装备者<p class=\"highlight-property\"}>16%-32%</p>生命上限的护盾，持续2回合。该效果每3回合只能触发1次。如果装备者持有护盾，则使自身受到的伤害降低<p class=\"highlight-property\"}>12%-24%</p>。",
      "zh-tw": "使裝備者的效果抵抗提高<p class=\"highlight-property\"}>8%-16%</p>，當裝備者受到攻擊後，如果自身未持有護盾，則獲得1個等同於裝備者<p class=\"highlight-property\"}>16%-32%</p>生命上限的護盾，持續2回合。該效果每3回合只能觸發1次。如果裝備者持有護盾，則使自身受到的傷害降低<p class=\"highlight-property\"}>12%-24%</p>。",
    },
    obtain:{
      "en-us": "Herta's Store",
      "zh-cn": "黑塔商店",
      "zh-tw": "黑塔商店",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "safeguard-of-amber")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "oath-of-steel")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "endurance-of-bronze")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "423",
    def: "529",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/24002.webp"),
  },
  {
    id: "24003",
    name: "Solitary Healing",
    translation:{
      "en-us": "Solitary Healing",
      "zh-cn": "孤独的疗愈",
      "zh-tw": "孤獨的療愈"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Break Effect <p class=\"highlight-property\"}>20%-40%</p>. When the wearer unleashes their Ultimate, increases DoT dealt by the wearer by <p class=\"highlight-property\"}>24%-48%</p> for 2 turn(s). Defeating the target suffering from the wearer's DoT allows the wearer to regenerate <p class=\"highlight-property\"}>4-8</p> Energy.",
      "zh-cn": "使装备者的击破特攻提高<p class=\"highlight-property\"}>20%-40%</p>。当装备者施放终结技时，使装备者造成的持续伤害提高<p class=\"highlight-property\"}>24%-48%</p>，持续2回合。陷入装备者施加的持续伤害效果的敌方目标被消灭时，装备者恢复<p class=\"highlight-property\"}>4-8</p>点能量。",
      "zh-tw": "使裝備者的擊破特攻提高<p class=\"highlight-property\"}>20%-40%</p>。當裝備者施放終結技時，使裝備者造成的持續傷害提高<p class=\"highlight-property\"}>24%-48%</p>，持續2回合。陷入裝備者施加的持續傷害效果的敵方目標被消滅時，裝備者恢復<p class=\"highlight-property\"}>4-8</p>點能量。",
    },
    obtain:{
      "en-us": "Herta's Store",
      "zh-cn": "黑塔商店",
      "zh-tw": "黑塔商店",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "529",
    def: "396",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/solitary-healing.webp"),
  },
  
  {
    id: "24004",
    name: "Eternal Calculus",
    translation:{
      "en-us": "Eternal Calculus",
      "zh-cn": "不息的演算",
      "zh-tw": "不息的演算"
    },
    effect_translation:{
      "en-us": "Increases the wearer's ATK by <p class=\"highlight-property\"}>8%-12%</p>. After using an attack, for each enemy target hit, additionally increases ATK by <p class=\"highlight-property\"}>4%-8%</p>. This effect can stack 5 times and lasts until the next attack. If there are 3 or more enemy targets hit, this unit's SPD increases by <p class=\"highlight-property\"}>8%-16%</p>, lasting for 1 turn(s).",
      "zh-cn": "使装备者的攻击力提高<p class=\"highlight-property\"}>8%-12%</p>。施放攻击后，每击中一名敌方目标，使攻击力额外提高<p class=\"highlight-property\"}>4%-8%</p>，该效果最多叠加5次，持续至下次攻击后，若击中大于等于3名敌方目标，使自身速度提高<p class=\"highlight-property\"}>8%-12%</p>，持续1回合。",
      "zh-tw": "使裝備者的攻擊力提高<p class=\"highlight-property\"}>8%-12%</p>。施放攻擊後，每擊中一名敵方目標，使攻擊力額外提高<p class=\"highlight-property\"}>4%-8%</p>，此效果最多疊加5次，持續至下次攻擊後，若擊中大於等於3名敵方目標，使自身速度提高<p class=\"highlight-property\"}>8%-12%</p>，持續1回合。",
    },
    obtain:{
      "en-us": "Herta's Store",
      "zh-cn": "黑塔商店",
      "zh-tw": "黑塔商店",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "529",
    def: "396",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/24004.webp"),
  },

  {
    id: "23014",
    name: "I Shall Be My Own Sword",
    translation:{
      "en-us": "I Shall Be My Own Sword",
      "zh-cn": "此身为剑",
      "zh-tw": "此身為劍"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT DMG by <p class=\"highlight-property\"}>20%-32%</p>. When ally gets attacked or loses HP, wearer gains 1 stack of Eclipse, up to a max of 3 stack(s). Each stack of Eclipse will increase the DMG of the wearer's next attack by <p class=\"highlight-property\"}>14%-24%</p>. When 3 stack(s) are maxed out, an additional attack will be delivered that ignores <p class=\"highlight-property\"}>12%-20%</p> of the enemy's DEF. This effect will be dispeled after the wearer delivers their attack.",
      "zh-cn": "使装备者的暴击伤害提高<p class=\"highlight-property\"}>20%-32%</p>。当队友受到攻击或消耗生命值后，装备者获得1层【月蚀】，最多叠加3层。每层【月蚀】使装备者下一次攻击造成的伤害提高<p class=\"highlight-property\"}>14%-24%</p>。叠满3层时，额外使该次攻击无视目标<p class=\"highlight-property\"}>12%-20%</p>的防御力。该效果在装备者施放攻击后解除。",
      "zh-tw": "使裝備者的爆擊傷害提高<p class=\"highlight-property\"}>20%-32%</p>。當隊友受到攻擊或消耗生命值後，裝備者獲得1層【月蝕】，最多疊加3層。每層【月蝕】使裝備者下一次攻擊造成的傷害提高<p class=\"highlight-property\"}>14%-24%</p>。疊滿3層時，額外使該次攻擊無視目標<p class=\"highlight-property\"}>12%-20%</p>的防禦力。該效果在裝備者施放攻擊後解除。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "4"
      },
    ],
    hp: "1164",
    atk: "582",
    def: "396",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23014.webp"),
  },
  {
    id: "23015",
    name: "Brighter Than the Sun",
    translation:{
      "en-us": "Brighter Than the Sun",
      "zh-cn": "比阳光更明亮的",
      "zh-tw": "比陽光更明亮的"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT Rate by <p class=\"highlight-property\"}>18%-30%</p>. When the wearer uses their Basic ATK, they will gain 1 stack of Dragon's Call for 2 turns. Each stack of Dragon's Call increases the wearer's ATK by <p class=\"highlight-property\"}>18%-30%</p> and Energy Regeneration Rate by <p class=\"highlight-property\"}>6%-10%</p>. Dragon's Call can be stacked up to 2 times.",
      "zh-cn": "使装备者的暴击率提高<p class=\"highlight-property\"}>18%-30%</p>。当装备者施放普攻时，获得1层【龙吟】，持续2回合。每层【龙吟】使装备者的攻击力提高<p class=\"highlight-property\"}>18%-30%</p>，能量恢复效率提高<p class=\"highlight-property\"}>6%-10%</p>。【龙吟】最多叠加2层。",
      "zh-tw": "使裝備者的暴擊率提高<p class=\"highlight-property\"}>18%-30%</p>。當裝備者施放普攻時，獲得1層【龍吟】，持續2回合。每層【龍吟】使裝備者的攻擊力提高<p class=\"highlight-property\"}>18%-30%</p>，能量恢復效率提高<p class=\"highlight-property\"}>6%-10%</p>。 【龍吟】最多疊加2層。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "375K"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "635",
    def: "396",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/brighter-than-the-sun.webp"),
  },
  {
    id: "23016",
    name: "Worrisome, Blissful",
    translation:{
      "en-us": "Worrisome, Blissful",
      "zh-cn": "烦恼着，幸福着",
      "zh-tw": "煩惱著，幸福著"
    },
    effect_translation:{
      "en-us": "Increase the wearer's CRIT Rate by <p class=\"highlight-property\"}>18%-30%</p> and their follow-up attacks' DMG by <p class=\"highlight-property\"}>30%-50%</p>. After the wearer uses a follow-up attack, apply the Tame state to the target, stacking up to 2 stacks. When allies hit enemy targets under the Tame state, every Tame stack increases the CRIT DMG dealt by <p class=\"highlight-property\"}>12%-20%</p>.",
      "zh-cn": "使装备者暴击率提高<p class=\"highlight-property\"}>18%-30%</p>，追加攻击造成的伤害提高<p class=\"highlight-property\"}>30%-50%</p>。装备者施放追加攻击后，使目标陷入【温驯】状态，该效果最多叠加2层。我方目标击中【温驯】状态下的敌方目标时，每层【温驯】使造成的暴击伤害提高<p class=\"highlight-property\"}>12%-20%</p>。",
      "zh-tw": "使裝備者爆擊率提高<p class=\"highlight-property\"}>18%-30%</p>，追加攻擊造成的傷害增加<p class=\"highlight-property\"}>30%-50%</p>。裝備者施放追加攻擊後，使目標陷入【溫馴】狀態，該效果最多疊加2層。我方目標擊中【溫馴】狀態下的敵方目標時，每層【溫馴】使造成的暴擊傷害提高<p class=\"highlight-property\"}>12%-20%</p>。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-starchaser")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-demon-slayer")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-beast-hunter")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "582",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23016.webp"),
  },
  {
    id: "23017",
    name: "	Night of Fright",
    translation:{
      "en-us": "Night of Fright",
      "zh-cn": "惊魂夜",
      "zh-tw": "驚魂夜"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Energy Regeneration Rate by <p class=\"highlight-property\"}>12%-20%</p>. When any ally uses their Ultimate, the wearer restores HP for the ally currently with the lowest HP percentage by an amount equal to <p class=\"highlight-property\"}>10%-14%</p> of the healed ally's Max HP. When the wearer provides healing for an ally, increases the healed ally's ATK by <p class=\"highlight-property\"}>2.4%-4%</p>. This effect can stack up to 5 times and lasts for 2 turn(s).",
      "zh-cn": "使装备者的能量恢复效率提高<p class=\"highlight-property\"}>12%-20%</p>。我方目标施放终结技时，装备者为当前生命值百分比最低的我方目标回复等同于其<p class=\"highlight-property\"}>10%-14%</p>生命上限的生命值。当装备者为我方目标提供治疗时，使该目标的攻击力提高<p class=\"highlight-property\"}>2.4%-4%</p>，该效果最多叠加5层，持续2回合。",
      "zh-tw": "使裝備者的能量恢復效率提升<p class=\"highlight-property\"}>12%-20%</p>。我方目標施放終結技時，裝備者為當前生命值百分比最低的我方目標回復等同於其<p class=\"highlight-property\"}>10%-14%</p>生命上限的生命值。當裝備者為我方目標提供治療時，使該目標的攻擊力提高<p class=\"highlight-property\"}>2.4%-4%</p>，該效果最多疊加5層，持續2回合。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "flower-of-eternity")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "sprout-of-life")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "seed-of-abundance")[0],
        value: "4"
      },
    ],
    hp: "1164",
    atk: "476",
    def: "529",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23017.webp"),
  },
  {
    id: "23018",
    name: "An Instant Before A Gaze",
    translation:{
      "en-us": "An Instant Before A Gaze",
      "zh-cn": "片刻，留在眼底",
      "zh-tw": "片刻，留在眼底"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT DMG by <p class=\"highlight-property\"}>36%-60%</p>. When the wearer uses Ultimate, increases the wearer's Ultimate DMG based on their Max Energy. Each point of Energy increases the Ultimate DMG by <p class=\"highlight-property\"}>0.36%-0.60%</p>, up to 180 points of Energy.",
      "zh-cn": "使装备者的暴击伤害提高<p class=\"highlight-property\"}>36%-60%</p>。当装备者施放终结技时，根据装备者的能量上限，提高装备者终结技造成的伤害：每点能量提高<p class=\"highlight-property\"}>0.36%-0.60%</p>，最多计入180点。",
      "zh-tw": "使裝備者的爆擊傷害提高<p class=\"highlight-property\"}>36%-60%</p>。當裝備者施放終結技時，根據裝備者的能量上限，提高裝備者終結技造成的傷害：每點能量提高<p class=\"highlight-property\"}>0.36%-0.60%</p>，最多計入180點。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "key-of-wisdom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "key-of-knowledge")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "key-of-inspiration")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "582",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23018.webp"),
  },
  {
    id: "23019",
    name: "Past Self in Mirror",
    translation:{
      "en-us": "Past Self in Mirror",
      "zh-cn": "镜中故我",
      "zh-tw": "鏡中故我"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Break Effect by <p class=\"highlight-property\"}>60%-100%</p>. When the wearer uses their Ultimate, increases all allies' DMG by <p class=\"highlight-property\"}>24%-40%</p>, lasting for 3 turn(s). Should the wearer's Break Effect exceed or equal 150%, 1 Skill Point will be recovered. At the start of each wave, all allies regenerate <p class=\"highlight-property\"}>10-20</p> Energy immediately. Effects of the same type cannot stack.",
      "zh-cn": "使装备者击破特攻提高<p class=\"highlight-property\"}>60%-100%</p>。装备者施放终结技后，使我方全体造成的伤害提高<p class=\"highlight-property\"}>24%-40%</p>，持续3回合，并且若装备者击破特攻大于等于150%，则恢复1个战技点。每个波次开始时，我方全体立即恢复<p class=\"highlight-property\"}>10-20</p>点能量，同类技能无法重复生效。",
      "zh-tw": "將裝備者擊破特攻提高<p class=\"highlight-property\"}>60%-100%</p>。裝備者施放終結技後，使我方全體造成的傷害提高<p class=\"highlight-property\"}>24%-40%</p>，持續3回合，並且若裝備者擊破特攻大於等於150%，則恢復1個戰技點。每個波次開始時，我方全體立即恢復<p class=\"highlight-property\"}>10-20</p>點能量，同類技能無法重複生效。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "stellaris-symphony")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "ancestral-hymn")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "harmonic-tune")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "529",
    def: "529",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23019.webp"),
  },
  {
    id: "23020",
    name: "Baptism of Pure Thought",
    translation:{
      "en-us": "Baptism of Pure Thought",
      "zh-cn": "纯粹思维的洗礼",
      "zh-tw": "純粹思維的洗禮"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT DMG by <p class=\"highlight-property\"}>20%-32%</p>. For every debuff on the enemy target, the wearer's CRIT DMG dealt against this target increases by <p class=\"highlight-property\"}>8%-12%</p>, stacking up to 3 times. When using Ultimate to attack the enemy target, the wearer receives the Disputation effect, which increases DMG dealt by <p class=\"highlight-property\"}>36%-60%</p> and enables their follow-up attacks to ignore <p class=\"highlight-property\"}>24%-40%</p> of the target's DEF. This effect lasts for 2 turns.",
      "zh-cn": "使装备者的暴击伤害提高<p class=\"highlight-property\"}>20%-32%</p>。敌方目标每承受1个负面效果，装备者对其造成的暴击伤害额外提高<p class=\"highlight-property\"}>8%-12%</p>，最多叠加3层。施放终结技攻击敌方目标时，使装备者获得【论辩】效果，造成的伤害提高<p class=\"highlight-property\"}>36%-60%</p>，追加攻击无视目标<p class=\"highlight-property\"}>24%-40%</p>的防御力，该效果持续2回合。",
      "zh-tw": "使裝備者的爆擊傷害提高<p class=\"highlight-property\"}>20%-32%</p>。敵方目標每承受1個負面效果，裝備者對其造成的暴擊傷害額外提高<p class=\"highlight-property\"}>8%-12%</p>，最多疊加3層。施放終結技攻擊敵方目標時，使裝備者獲得【論辯】效果，造成的傷害提高<p class=\"highlight-property\"}>36%-60%</p>，追加攻擊無視目標<p class=\"highlight-property\"}>24%-40%</p>的防禦力，此效果持續2回合。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-starchaser")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-demon-slayer")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-beast-hunter")[0],
        value: "4"
      },
    ],
    hp: "953",
    atk: "582",
    def: "529",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23020.webp"),
  },
  
  {
    id: "23021",
    name: "Earthly Escapade",
    translation:{
      "en-us": "Earthly Escapade",
      "zh-cn": "游戏尘寰",
      "zh-tw": "遊戲塵寰"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT DMG by <p class=\"highlight-property\"}>32%-60%</p>. At the start of the battle, the wearer gains Mask, lasting for 3 turn(s). While the wearer has Mask, the wearer's allies have their CRIT Rate increased by <p class=\"highlight-property\"}>10%-14%</p> and their CRIT DMG increased by <p class=\"highlight-property\"}>28%-56%</p>. For every 1 Skill Point the wearer recovers (including Skill Points that exceed the limit), they gain 1 stack of Radiant Flame. And when the wearer has 4 stacks of Radiant Flame, all the stacks are removed, and they gain Mask, lasting for 4 turn(s).",
      "zh-cn": "使装备者的暴击伤害提高<p class=\"highlight-property\"}>32%-60%</p>。战斗开始时，使装备者获得【假面】，持续3回合。当装备者持有【假面】时，装备者的队友暴击率提高<p class=\"highlight-property\"}>10%-14%</p>，暴击伤害提高<p class=\"highlight-property\"}>28%-56%</p>。装备者每恢复1个战技点，获得1层【彩焰】，恢复时溢出的战技点也会被计算在内。当【彩焰】达到4层后，移除所有【彩焰】并获得【假面】，持续4回合。",
      "zh-tw": "使裝備者的爆擊傷害提高<p class=\"highlight-property\"}>32%-60%</p>。戰鬥開始時，使裝備者獲得【假面】，持續3回合。當裝備者持有【假面】時，裝備者的隊友暴擊率提高<p class=\"highlight-property\"}>10%-14%</p>，暴擊傷害提高<p class=\"highlight-property\"}>28%-56%</p>。裝備者每恢復1個戰技點，獲得1層【彩焰】，恢復時溢出的戰技點也會被計算在內。當【彩焰】達到4層後，移除所有【彩焰】並獲得【假面】，持續4回合。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "shards-of-desires")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "fragments-of-impression")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "tatters-of-thought")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "heavenly-melody")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "celestial-section")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "firmament-note")[0],
        value: "4"
      },
    ],
    hp: "1164",
    atk: "529",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23021.webp"),
  },
  {
    id: "23022",
    name: "Reforged Remembrance",
    translation:{
      "en-us": "Reforged Remembrance",
      "zh-cn": "重塑时光之忆",
      "zh-tw": "重塑時光之憶"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Effect Hit Rate by <p class=\"highlight-property\"}>40%-60%</p>. When the wearer deals DMG to an enemy inflicted with Wind Shear, Burn, Shock, or Bleed, each respectively grants 1 stack of Prophet, stacking up to 4 time(s). In a single battle, only 1 stack of Prophet can be granted for each type of DoT. Every stack of Prophet increases wearer's ATK by <p class=\"highlight-property\"}>5%-9%</p> and enables the DoT dealt to ignore <p class=\"highlight-property\"}>7.2%-10%</p> of the target's DEF.",
      "zh-cn": "使装备者的效果命中提高<p class=\"highlight-property\"}>40%-60%</p>。装备者对陷入风化、灼烧、触电、裂伤状态的敌方目标造成伤害时，分别获得1层【先知】，最多叠加4层。单场战斗中，每种持续伤害状态类型仅可叠加1次【先知】效果。每层【先知】使装备者的攻击力提高<p class=\"highlight-property\"}>5%-9%</p>，造成的持续伤害无视目标<p class=\"highlight-property\"}>7.2%-10%</p>的防御力。",
      "zh-tw": "使裝備者的效果命中提高<p class=\"highlight-property\"}>40%-60%</p>。裝備者對陷入風化、灼燒、觸電、裂傷狀態的敵方目標造成傷害時，分別獲得1層【先知】，最多疊加4層。單場戰鬥中，每種持續傷害狀態類型僅可疊加1次【先知】效果。每層【先知】使裝備者的攻擊力提高<p class=\"highlight-property\"}>5%-9%</p>，造成的持續傷害無視目標<p class=\"highlight-property\"}>7.2%-10%</p>的防禦力。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "582",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23022.webp"),
  },
  
  {
    id: "23023",
    name: "Inherently Unjust Destiny",
    translation:{
      "en-us": "Inherently Unjust Destiny",
      "zh-cn": "命运从未公平",
      "zh-tw": "命運從未公平"
    },
    effect_translation:{
      "en-us": "Increases the wearer's DEF by <p class=\"highlight-property\"}>40%-64%</p>. When the wearer provides a Shield to an ally, the wearer's CRIT DMG increases by <p class=\"highlight-property\"}>40%-64%</p>, lasting for 2 turn(s). When the wearer's follow-up attack hits an enemy target, there is a <p class=\"highlight-property\"}>100%-160%</p> base chance to increase the DMG taken by the attacked enemy target by <p class=\"highlight-property\"}>10%-16%</p>, lasting for 2 turn(s).",
      "zh-cn": "使装备者的防御力提高<p class=\"highlight-property\"}>40%-64%</p>，当装备者为我方目标提供护盾时，使装备者的暴击伤害提高<p class=\"highlight-property\"}>40%-64%</p>，持续2回合。当装备者发动追加攻击击中敌方目标时，有<p class=\"highlight-property\"}>100%-160%</p>的基础概率使受到攻击的敌方目标受到的伤害提高<p class=\"highlight-property\"}>10%-16%</p>，持续2回合。",
      "zh-tw": "使裝備者的防禦力提高<p class=\"highlight-property\"}>40%-64%</p>，當裝備者為我方目標提供護盾時，使裝備者的暴擊傷害提高<p class=\"highlight-property\"}>40%-64%</p>，持續2回合。當裝備者發動追加攻擊擊中敵方目標時，有<p class=\"highlight-property\"}>100%-160%</p>的基礎機率使受到攻擊的敵方目標受到的傷害提高<p class=\"highlight-property\"}>10%-16%</p>，持續2回合。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "423",
    def: "661",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23023.webp"),
  },
  {
    id: "23024",
    name: "Along the Passing Shore",
    translation:{
      "en-us": "Along the Passing Shore",
      "zh-cn": "行于流逝的岸",
      "zh-tw": "行於流逝的岸"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT DMG by <p class=\"highlight-property\"}>36%-60%</p>. When the wearer hits an enemy target, inflicts Mirage Fizzle on the enemy, lasting for 1 turn. Each time the wearer attacks, this effect can only trigger 1 time on each target. The wearer deals <p class=\"highlight-property\"}>24%-40%</p> increased DMG to targets afflicted with Mirage Fizzle, and the DMG dealt by the wearer's Ultimate additionally increases by <p class=\"highlight-property\"}>24%-40%</p>.",
      "zh-cn": "使装备者的暴击伤害提高<p class=\"highlight-property\"}>36%-60%</p>。当装备者击中敌方目标时，使敌方陷入【泡影】状态，持续1回合。装备者每次攻击时，对每个目标只可触发1次。装备者对陷入【泡影】状态的目标造成的伤害提高<p class=\"highlight-property\"}>24%-40%</p>，终结技造成的伤害额外提高<p class=\"highlight-property\"}>24%-40%</p>。",
      "zh-tw": "使裝備者的爆擊傷害提高<p class=\"highlight-property\"}>36%-60%</p>。當裝備者擊中敵方目標時，使敵方陷入【泡影】狀態，持續1回合。裝備者每次攻擊時，對每個目標只可觸發1次。裝備者對陷入【泡影】狀態的目標造成的傷害提高<p class=\"highlight-property\"}>24%-40%</p>，終結技造成的傷害額外提高<p class=\"highlight-property\"}>24%-40%</p>。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "582",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23024.webp"),
  },
  {
    id: "23025",
    name: "Whereabouts Should Dreams Rest",
    translation:{
      "en-us": "Whereabouts Should Dreams Rest",
      "zh-cn": "梦应归于何处",
      "zh-tw": "夢應歸於何處"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Break Effect by <p class=\"highlight-property\"}>60%-100%</p>. When the wearer deals Break DMG to an enemy target, inflicts Routed on the enemy, lasting for 2 turn(s). Targets afflicted with Routed receive <p class=\"highlight-property\"}>24%-40%</p> increased Break DMG from the wearer, and their SPD is lowered by 20%. Effects of the similar type cannot be stacked.",
      "zh-cn": "使装备者的击破特攻提高<p class=\"highlight-property\"}>60%-100%</p>。当装备者对敌方目标造成击破伤害时，使敌方陷入【溃败】状态，持续2回合。【溃败】状态下目标受到装备者造成的击破伤害提高<p class=\"highlight-property\"}>24%-40%</p>，速度降低20%，同类效果无法叠加。",
      "zh-tw": "將裝備者的擊破特攻提高<p class=\"highlight-property\"}>60%-100%</p>。當裝備者對敵方目標造成擊破傷害時，使敵方陷入【潰敗】狀態，持續2回合。【潰敗】狀態下目標受到裝備者造成的擊破傷害提高<p class=\"highlight-property\"}>24%-40%</p>，速度降低20%，同類效果無法疊加。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "582",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23025.webp"),
  },
  
  {
    id: "23026",
    name: "Flowing Nightglow",
    translation:{
      "en-us": "Flowing Nightglow",
      "zh-cn": "夜色流光溢彩",
      "zh-tw": "夜色流光溢彩"
    },
    effect_translation:{
      "en-us": "Every time an ally attacks, the wearer gains 1 stack of Cantillation. Each stack of Cantillation increases the wearer's Energy Regeneration Rate by <p class=\"highlight-property\"}>3%-5%</p>, stacking up to 5 time(s). When the wearer uses their Ultimate, removes Cantillation and gains Cadenza. Cadenza increases the Wearer's ATK by <p class=\"highlight-property\"}>48%-96%</p> and increases all allies' DMG dealt by <p class=\"highlight-property\"}>24%-40%</p>, lasting for 1 turn(s).",
      "zh-cn": "我方角色每次攻击时，使装备者获得1层【歌咏】，每层【歌咏】使装备者的能量恢复效率提高<p class=\"highlight-property\"}>3%-5%</p>，最多叠加5层。装备者施放终结技时，移除【歌咏】并获得【华彩】，【华彩】使装备者的攻击力提高<p class=\"highlight-property\"}>48%-96%</p>，使我方全体造成的伤害提高<p class=\"highlight-property\"}>24%-40%</p>，持续1回合。",
      "zh-tw": "我方角色每次攻擊時，使裝備者獲得1層【歌詠】，每層【歌詠】使裝備者的能量恢復效率提高<p class=\"highlight-property\"}>3%-5%</p>，最多疊加5層。裝備者施放終結技時，移除【歌詠】並獲得【華彩】，【華彩】使裝備者的攻擊力提高<p class=\"highlight-property\"}>48%-96%</p>，使我方全體造成的傷害提高<p class=\"highlight-property\"}>24%-40%</p>，持續1回合。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "953",
    atk: "635",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23026.webp"),
  },
  
  {
    id: "23027",
    name: "Sailing Towards a Second Life",
    translation:{
      "en-us": "Sailing Towards a Second Life",
      "zh-cn": "驶向第二次生命",
      "zh-tw": "駛向第二次生命"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Break Effect by <p class=\"highlight-property\"}>60%-100%</p>. The Break DMG dealt by the wearer ignores <p class=\"highlight-property\"}>20%-32%</p> of the target's DEF. When the wearer's Break Effect in battle is at 150% or greater, increases their SPD by <p class=\"highlight-property\"}>12%-20%</p>.",
      "zh-cn": "使装备者的击破特攻提高<p class=\"highlight-property\"}>60%-100%</p>，造成的击破伤害无视目标<p class=\"highlight-property\"}>20%-32%</p>的防御力。当装备者在战斗中击破特攻大于等于150%时，速度提高<p class=\"highlight-property\"}>12%-20%</p>。",
      "zh-tw": "使裝備者的擊破特攻提高<p class=\"highlight-property\"}>60%-100%</p>，造成的擊破傷害無視目標<p class=\"highlight-property\"}>20%-32%</p>的防禦力。當裝備者在戰鬥中擊破特攻大於等於150%時，速度增加<p class=\"highlight-property\"}>12%-20%</p>。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "953",
    atk: "635",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23027.webp"),
  },
  
  {
    id: "23028",
    name: "Yet Hope Is Priceless",
    translation:{
      "en-us": "Yet Hope Is Priceless",
      "zh-cn": "偏偏希望无价",
      "zh-tw": "偏偏希望無價"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT Rate by <p class=\"highlight-property\"}>16%-28%</p>. While the wearer is in battle, for every 20% CRIT DMG that exceeds 120%, the DMG dealt by follow-up attack increases by <p class=\"highlight-property\"}>12%-20%</p>. This effect can stack up to 4 time(s). When the battle starts or after the wearer uses their Basic ATK, enables the DMG dealt by Ultimate or follow-up attack to ignore <p class=\"highlight-property\"}>20%-36%</p> of the target's DEF, lasting for 2 turn(s).",
      "zh-cn": "使装备者的暴击率提高<p class=\"highlight-property\"}>16%-28%</p>。当装备者在战斗中暴击伤害大于120%时，每超过20%，追加攻击造成的伤害提高<p class=\"highlight-property\"}>12%-20%</p>，该效果可叠加4层。战斗开始时和装备者施放普攻后，使终结技或追加攻击造成的伤害无视目标<p class=\"highlight-property\"}>20%-36%</p>的防御，持续2回合。",
      "zh-tw": "使裝備者的爆擊率提高<p class=\"highlight-property\"}>16%-28%</p>。當裝備者在戰鬥中爆擊傷害大於120%時，每超過20%，追加攻擊造成的傷害提高<p class=\"highlight-property\"}>12%-20%</p>，此效果可疊加4層。戰鬥開始時和裝備者施放普攻後，使終結技或追加攻擊造成的傷害無視目標<p class=\"highlight-property\"}>20%-36%</p>的防禦，持續2回合。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "953",
    atk: "582",
    def: "529",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23028.webp"),
  },
  
  {
    id: "23029",
    name: "Those Many Springs",
    translation:{
      "en-us": "Those Many Springs",
      "zh-cn": "那无数个春天",
      "zh-tw": "那無數個春天"
    },
    effect_translation:{
      "en-us": "Increases the wearer's Effect Hit Rate by <p class=\"highlight-property\"}>60%-100%</p>. After the wearer uses Basic ATK, Skill, or Ultimate to attack an enemy target, there is a 60% base chance to inflict \"Unarmored\" on the target. While in the Unarmored state, the enemy target receives <p class=\"highlight-property\"}>10%-18%</p> increased DMG, lasting for 2 turn(s). If the target is under a DoT state inflicted by the wearer, there is a 60% base chance to upgrade the \"Unarmored\" state inflicted by the wearer to the \"Cornered\" state, which additionally increases the DMG the enemy target receives by <p class=\"highlight-property\"}>14%-22%</p>, lasting for 2 turn(s). During this period, the wearer cannot inflict \"Unarmored\" on the target.",
      "zh-cn": "使装备者的效果命中提高<p class=\"highlight-property\"}>60%-100%</p>，装备者施放普攻、战技、终结技攻击敌方目标后，有60%的基础概率使其陷入【卸甲】状态。【卸甲】状态下，敌方目标受到的伤害提高<p class=\"highlight-property\"}>10%-18%</p>，持续2回合。若目标处于装备者施加的持续伤害状态，则有60%的基础概率将装备者施加的【卸甲】状态升级成【穷寇】状态，使敌方目标受到的伤害额外提高<p class=\"highlight-property\"}>14%-22%</p>，持续2回合，期间装备者无法对其施加【卸甲】。",
      "zh-tw": "使裝備者的效果命中提高<p class=\"highlight-property\"}>60%-100%</p>，裝備者發動普攻、戰技、終結技攻擊敵方目標後，有60%的基礎機率使其陷入【卸甲】狀態。【卸甲】狀態下，敵方目標受到的傷害提高<p class=\"highlight-property\"}>10%-18%</p>，持續2回合。若目標處於裝備者施加的持續傷害狀態，則有60%的基礎機率將裝備者施加的【卸甲】狀態升級成【窮寇】狀態，使敵方目標受到的傷害額外提高<p class=\"highlight-property\"}>14%-22%</p>，持續2回合，期間裝備者無法對其施加【卸甲】。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "953",
    atk: "582",
    def: "529",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23029.webp"),
  },
  {
    id: "23030",
    name: "Dance at Sunset",
    translation:{
      "en-us": "Dance at Sunset",
      "zh-cn": "落日时起舞",
      "zh-tw": "落日時起舞"
    },
    effect_translation:{
      "en-us": "Greatly increases the wearer's <p class=\"highlight-property\"}>chance of getting attacked</p> and increases CRIT DMG by <p class=\"highlight-property\"}>36%-60%</p>. After the wearer uses Ultimate, receives 1 stack of Firedance, lasting for 2 turns and stacking up to 2 time(s). Each stack of Firedance increases the DMG dealt by the wearer's follow-up attack by <p class=\"highlight-property\"}>36%-60%</p>.",
      "zh-cn": "使装备者<p class=\"highlight-property\"}>受到攻击的概率</p>大幅提高，暴击伤害提高<p class=\"highlight-property\"}>36%-60%</p>。当装备者施放终结技后，获得1层【火舞】，持续2回合，最多叠加2层。每层【火舞】使装备者追加攻击造成的伤害提高<p class=\"highlight-property\"}>36%-60%</p>。",
      "zh-tw": "使裝備者<p class=\"highlight-property\"}>受到攻擊的機率</p>大幅提高，爆擊傷害提高<p class=\"highlight-property\"}>36%-60%</p>。當裝備者施放終結技後，獲得1層【火舞】，持續2回合，最多疊加2層。每層【火舞】使裝備者追加攻擊造成的傷害提高<p class=\"highlight-property\"}>36%-60%</p>。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "1058",
    atk: "582",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23030.webp"),
  },
  {
    id: "23031",
    name: "I Venture Forth to Hunt",
    translation:{
      "en-us": "I Venture Forth to Hunt",
      "zh-cn": "我将，巡征追猎",
      "zh-tw": "我將，巡徵追獵"
    },
    effect_translation:{
      "en-us": "Increases the wearer's CRIT Rate by <p class=\"highlight-property\"}>15%-25%</p>. When the wearer launches a follow-up attack, gains 1 stack of \"Luminflux,\" stacking up to 2 time(s). Each stack of \"Luminflux\" enables the Ultimate DMG dealt by the wearer to ignore <p class=\"highlight-property\"}>27%-39%</p> of the target's DEF. When the wearer's turn ends, removes 1 stack of \"Luminflux.\"",
      "zh-cn": "使装备者的暴击率提高<p class=\"highlight-property\"}>15%-25%</p>。装备者施放追加攻击时，获得1层【流光】，最多叠加2层。每层【流光】使装备者造成的终结技伤害无视目标<p class=\"highlight-property\"}>27%-39%</p>的防御力。装备者回合结束时，移除1层【流光】。",
      "zh-tw": "使裝備者的暴擊率提高<p class=\"highlight-property\"}>15%-25%</p>。裝備者發動追加攻擊時，獲得1層【流光】，最多疊加2層。每層【流光】使裝備者造成的終結技傷害無視目標<p class=\"highlight-property\"}>27%-39%</p>的防禦力。裝備者回合結束時，移除1層【流光】。",
    },
    obtain:{
      "en-us": "Limited Banner",
      "zh-cn": "限定卡池",
      "zh-tw": "限定卡池",
    },
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "385K"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "14"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "20"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "4"
      },
    ],
    hp: "953",
    atk: "635",
    def: "463",
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    image: require("../components/image/lightcone/23031.webp"),
  },
];

export const briefCharacterRows = [
  {
    id: "1008",
    image: require("../components/image/characters/arlan/arlan.webp"),
    name: "arlan",
    translation:{
      "en-us": "Arlan",
      "zh-cn": "阿兰",
      "zh-tw": "阿蘭"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Destruction",
    element: "Lightning",
    pathIcon: destructionIcon,
    elementIcon: lightningIcon,
    type: "live",
    guide: false
  },
  {
    id: "1009",
    image: require("../components/image/characters/asta/asta.webp"),
    name: "asta",
    translation:{
      "en-us": "Asta",
      "zh-cn": "艾丝妲",
      "zh-tw": "艾絲妲"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Harmony",
    element: "Fire",
    pathIcon: harmonyIcon,
    elementIcon: fireIcon,
    type: "upcoming",
    guide: true
  },
  {
    id: "1211",
    image: require("../components/image/characters/bailu/bailu.webp"),
    name: "bailu",
    translation:{
      "en-us": "Bailu",
      "zh-cn": "白露",
      "zh-tw": "白露"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Abundance",
    element: "Lightning",
    pathIcon: abundanceIcon,
    elementIcon: lightningIcon,
    type: "live",
    guide: true
  },
  {
    id: "1217",
    image: require("../components/image/characters/huohuo/huohuo.webp"),
    name: "huohuo",
    translation:{
      "en-us": "Huohuo",
      "zh-cn": "藿藿",
      "zh-tw": "藿藿"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Abundance",
    element: "Wind",
    pathIcon: abundanceIcon,
    elementIcon: windIcon,
    type: "live",
    guide: true
  },
  {
    id: "1218",
    image: require("../components/image/characters/jiaoqiu/jiaoqiu.webp"),
    name: "jiaoqiu",
    translation:{
      "en-us": "Jiaoqiu",
      "zh-cn": "椒丘",
      "zh-tw": "椒丘"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Fire",
    pathIcon: nihilityIcon,
    elementIcon: fireIcon,
    type: "live",
    guide: false
  },
  {
    id: "1220",
    image: require("../components/image/characters/feixiao/feixiao.webp"),
    name: "feixiao",
    translation:{
      "en-us": "Feixiao",
      "zh-cn": "飞霄",
      "zh-tw": "飛霄"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Wind",
    pathIcon: huntIcon,
    elementIcon: windIcon,
    type: "upcoming",
    guide: false
  },
  {
    id: "1221",
    image: require("../components/image/characters/yunli/yunli.webp"),
    name: "yunli",
    translation:{
      "en-us": "Yunli",
      "zh-cn": "云璃",
      "zh-tw": "雲璃"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Physical",
    pathIcon: destructionIcon,
    elementIcon: physicalIcon,
    type: "live",
    guide: false
  },
  {
    id: "1205",
    image: require("../components/image/characters/blade/blade.webp"),
    name: "blade",
    translation:{
      "en-us": "Blade",
      "zh-cn": "刃",
      "zh-tw": "刃"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Wind",
    pathIcon: destructionIcon,
    elementIcon: windIcon,
    type: "live",
    guide: true
  },
  {
    id: "1101",
    image: require("../components/image/characters/bronya/bronya.webp"),
    name: "bronya",
    translation:{
      "en-us": "Bronya",
      "zh-cn": "布洛妮娅",
      "zh-tw": "布洛妮婭"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Wind",
    pathIcon: harmonyIcon,
    elementIcon: windIcon,
    type: "live",
    guide: true
  },
  {
    id: "1107",
    image: require("../components/image/characters/clara/clara.webp"),
    name: "clara",
    translation:{
      "en-us": "Clara",
      "zh-cn": "克拉拉",
      "zh-tw": "克拉拉"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Physical",
    pathIcon: destructionIcon,
    elementIcon: physicalIcon,
    type: "live",
    guide: false
  },
  {
    id: "1301",
    image: require("../components/image/characters/gallagher/gallagher.webp"),
    name: "gallagher",
    translation:{
      "en-us": "Gallagher",
      "zh-cn": "加拉赫",
      "zh-tw": "加拉赫"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Abundance",
    element: "Fire",
    pathIcon: abundanceIcon,
    elementIcon: fireIcon,
    type: "live",
    guide: false
  },
  {
    id: "1302",
    image: require("../components/image/characters/argenti/argenti.webp"),
    name: "argenti",
    translation:{
      "en-us": "Argenti",
      "zh-cn": "银枝",
      "zh-tw": "銀枝"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Erudition",
    element: "Physical",
    pathIcon: eruditionIcon,
    elementIcon: physicalIcon,
    type: "live",
    guide: false
  },
  {
    id: "1303",
    image: require("../components/image/characters/ruan-mei/ruan-mei.webp"),
    name: "ruan-mei",
    translation:{
      "en-us": "Ruan Mei",
      "zh-cn": "阮•梅",
      "zh-tw": "阮•梅"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Ice",
    pathIcon: harmonyIcon,
    elementIcon: iceIcon,
    type: "live",
    guide: false
  },
  {
    id: "1304",
    image: require("../components/image/characters/aventurine/aventurine.webp"),
    name: "aventurine",
    translation:{
      "en-us": "Aventurine",
      "zh-cn": "砂金",
      "zh-tw": "砂金"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Preservation",
    element: "Imaginary",
    pathIcon: preservationIcon,
    elementIcon: imaginaryIcon,
    type: "live",
    guide: false
  },
  {
    id: "1305",
    image: require("../components/image/characters/dr._ratio/dr._ratio.webp"),
    name: "dr._ratio",
    translation:{
      "en-us": "Dr. Ratio",
      "zh-cn": "真理医生",
      "zh-tw": "真理醫生"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Imaginary",
    pathIcon: huntIcon,
    elementIcon: imaginaryIcon,
    type: "live",
    guide: true
  },
  {
    id: "1306",
    image: require("../components/image/characters/sparkle/sparkle.webp"),
    name: "sparkle",
    translation:{
      "en-us": "Sparkle",
      "zh-cn": "花火",
      "zh-tw": "花火"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Quantum",
    pathIcon: harmonyIcon,
    elementIcon: quantumIcon,
    type: "live",
    guide: true
  },
  {
    id: "1307",
    image: require("../components/image/characters/black_swan/black_swan.webp"),
    name: "black_swan",
    translation:{
      "en-us": "Black Swan",
      "zh-cn": "黑天鹅",
      "zh-tw": "黑天鵝"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Wind",
    pathIcon: nihilityIcon,
    elementIcon: windIcon,
    type: "upcoming",
    guide: false
  },
  {
    id: "1308",
    image: require("../components/image/characters/acheron/acheron.webp"),
    name: "acheron",
    translation:{
      "en-us": "Acheron",
      "zh-cn": "黄泉",
      "zh-tw": "黃泉"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Lightning",
    pathIcon: nihilityIcon,
    elementIcon: lightningIcon,
    type: "live",
    guide: false
  },
  {
    id: "1309",
    image: require("../components/image/characters/robin/robin.webp"),
    name: "robin",
    translation:{
      "en-us": "Robin",
      "zh-cn": "知更鸟",
      "zh-tw": "知更鳥"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Physical",
    pathIcon: harmonyIcon,
    elementIcon: physicalIcon,
    type: "upcoming",
    guide: false
  },
  {
    id: "1310",
    image: require("../components/image/characters/firefly/firefly.webp"),
    name: "firefly",
    translation:{
      "en-us": "Firefly",
      "zh-cn": "流萤",
      "zh-tw": "流螢"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Fire",
    pathIcon: destructionIcon,
    elementIcon: fireIcon,
    type: "live",
    guide: false
  },
  {
    id: "1312",
    image: require("../components/image/characters/misha/misha.webp"),
    name: "misha",
    translation:{
      "en-us": "Misha",
      "zh-cn": "米沙",
      "zh-tw": "米沙"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Destruction",
    element: "Ice",
    pathIcon: destructionIcon,
    elementIcon: iceIcon,
    type: "live",
    guide: false
  },
  
  {
    id: "1314",
    image: require("../components/image/characters/jade/jade.webp"),
    name: "jade",
    translation:{
      "en-us": "Jade",
      "zh-cn": "翡翠",
      "zh-tw": "翡翠"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Erudition",
    element: "Quantum",
    pathIcon: eruditionIcon,
    elementIcon: quantumIcon,
    type: "live",
    guide: false
  },
  {
    id: "1315",
    image: require("../components/image/characters/boothill/boothill.webp"),
    name: "boothill",
    translation:{
      "en-us": "Boothill",
      "zh-cn": "波提欧",
      "zh-tw": "波提歐"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Physical",
    pathIcon: huntIcon,
    elementIcon: physicalIcon,
    type: "live",
    guide: false
  },
  {
    id: "1002",
    image: require("../components/image/characters/danheng/danheng.webp"),
    name: "dan-heng",
    translation:{
      "en-us": "Dan Heng",
      "zh-cn": "丹恒",
      "zh-tw": "丹恆"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Hunt",
    element: "Wind",
    pathIcon: huntIcon,
    elementIcon: windIcon,
    type: "live",
    guide: false
  },
  {
    id: "1104",
    image: require("../components/image/characters/gepard/gepard.webp"),
    name: "gepard",
    translation:{
      "en-us": "Gepard",
      "zh-cn": "杰帕德",
      "zh-tw": "傑帕德"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Preservation",
    element: "Ice",
    pathIcon: preservationIcon,
    elementIcon: iceIcon,
    type: "live",
    guide: false
  },
  {
    id: "1013",
    image: require("../components/image/characters/herta/herta.webp"),
    name: "herta",
    translation:{
      "en-us": "Herta",
      "zh-cn": "黑塔",
      "zh-tw": "黑塔"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Erudition",
    element: "Ice",
    pathIcon: eruditionIcon,
    elementIcon: iceIcon,
    type: "live",
    guide: false
  },
  {
    id: "1003",
    image: require("../components/image/characters/himeko/himeko.webp"),
    name: "himeko",
    translation:{
      "en-us": "Himeko",
      "zh-cn": "姬子",
      "zh-tw": "姬子"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Erudition",
    element: "Fire",
    pathIcon: eruditionIcon,
    elementIcon: fireIcon,
    type: "live",
    guide: false
  },
  {
    id: "1109",
    image: require("../components/image/characters/hook/hook.webp"),
    name: "hook",
    translation:{
      "en-us": "Hook",
      "zh-cn": "虎克",
      "zh-tw": "虎克"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Destruction",
    element: "Fire",
    pathIcon: destructionIcon,
    elementIcon: fireIcon,
    type: "live",
    guide: true
  },
  {
    id: "1210",
    image: require("../components/image/characters/guinaifen/guinaifen.webp"),
    name: "guinaifen",
    translation:{
      "en-us": "Guinaifen",
      "zh-cn": "桂乃芬",
      "zh-tw": "桂乃芬"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Nihility",
    element: "Fire",
    pathIcon: nihilityIcon,
    elementIcon: fireIcon,
    type: "live",
    guide: false
  },
  {
    id: "1112", // %26 = &
    image: require("../components/image/characters/topaz&numby/topaz&numby.webp"),
    name: "topaz%26numby",
    translation:{
      "en-us": "Topaz & Numby",
      "zh-cn": "托帕 & 账账",
      "zh-tw": "托帕 & 帳賬"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Fire",
    pathIcon: huntIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live",
    guide:true
  },
  {
    id: "1212",
    image: require("../components/image/characters/jingliu/jingliu.webp"),
    name: "jingliu",
    translation:{
      "en-us": "Jingliu",
      "zh-cn": "镜流",
      "zh-tw": "鏡流"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Ice",
    pathIcon: destructionIcon,
    elementIcon: iceIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live",
    guide: true
  },
  {
    id: "1204",
    image: require("../components/image/characters/jing-yuan/jing-yuan.webp"),
    name: "jing-yuan",
    translation:{
      "en-us": "Jing Yuan",
      "zh-cn": "景元",
      "zh-tw": "景元"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Erudition",
    element: "Lightning",
    pathIcon: eruditionIcon,
    elementIcon: lightningIcon,
    type: "live",
    guide: true
  },
  {
    id: "1005",
    image: require("../components/image/characters/kafka/kafka.webp"),
    name: "kafka",
    translation:{
      "en-us": "Kafka",
      "zh-cn": "卡芙卡",
      "zh-tw": "卡芙卡"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Lightning",
    pathIcon: nihilityIcon,
    elementIcon: lightningIcon,
    type: "upcoming",
    guide: true
  },
  {
    id: "1215",
    image: require("../components/image/characters/hanya/hanya.webp"),
    name: "hanya",
    translation:{
      "en-us": "Hanya",
      "zh-cn": "寒鸦",
      "zh-tw": "寒鴉"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Harmony",
    element: "Physical",
    pathIcon: harmonyIcon,
    elementIcon: physicalIcon,
    type: "live",
    guide: false
  },
  {
    id: "1111",
    image: require("../components/image/characters/luka/luka.webp"),
    name: "luka",
    translation:{
      "en-us": "Luka",
      "zh-cn": "卢卡",
      "zh-tw": "盧卡"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Nihility",
    element: "Physical",
    pathIcon: nihilityIcon,
    elementIcon: physicalIcon,
    type: "upcoming",
    guide: false
  },
  {
    id: "1203",
    image: require("../components/image/characters/luocha/luocha.webp"),
    name: "luocha",
    translation:{
      "en-us": "Luocha",
      "zh-cn": "罗刹",
      "zh-tw": "羅剎"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Abundance",
    element: "Imaginary",
    pathIcon: abundanceIcon,
    elementIcon: imaginaryIcon,
    type: "live",
    guide: false
  },
  {
    id: "1001",
    image: require("../components/image/characters/march7th/march7th.webp"),
    name: "march-7th",
    translation:{
      "en-us": "March 7th",
      "zh-cn": "三月七",
      "zh-tw": "三月七"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Preservation",
    element: "Ice",
    pathIcon: preservationIcon,
    elementIcon: iceIcon,
    type: "live",
    guide: true
  },
  {
    id: "1224",
    image: require("../components/image/characters/march7th-hunt/march7th-hunt.webp"),
    name: "march7th-hunt",
    translation:{
      "en-us": "March 7th",
      "zh-cn": "三月七",
      "zh-tw": "三月七"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Hunt",
    element: "Imaginary",
    pathIcon: huntIcon,
    elementIcon: imaginaryIcon,
    type: "live",
    guide: false
  },
  {
    id: "1105",
    image: require("../components/image/characters/natasha/natasha.webp"),
    name: "natasha",
    translation:{
      "en-us": "Natasha",
      "zh-cn": "娜塔莎",
      "zh-tw": "娜塔莎"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Abundance",
    element: "Physical",
    pathIcon: abundanceIcon,
    elementIcon: physicalIcon,
    type: "live",
    guide: false
  },
  {
    id: "1106",
    image: require("../components/image/characters/pela/pela.webp"),
    name: "pela",
    translation:{
      "en-us": "Pela",
      "zh-cn": "佩拉",
      "zh-tw": "佩拉"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Nihility",
    element: "Ice",
    pathIcon: nihilityIcon,
    elementIcon: iceIcon,
    type: "live",
    guide: true
  },
  {
    id: "1201",
    image: require("../components/image/characters/qingque/qingque.webp"),
    name: "qingque",
    translation:{
      "en-us": "Qingque",
      "zh-cn": "青雀",
      "zh-tw": "青雀"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Erudition",
    element: "Quantum",
    color: "#1C29BA",
    pathIcon: eruditionIcon,
    elementIcon: quantumIcon,
    type: "live",
    guide: false
  },
  {
    id: "1108",
    image: require("../components/image/characters/sampo/sampo.webp"),
    name: "sampo",
    translation:{
      "en-us": "Sampo",
      "zh-cn": "桑博",
      "zh-tw": "桑博"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Nihility",
    element: "Wind",
    pathIcon: nihilityIcon,
    elementIcon: windIcon,
    type: "live",
    guide: false
  },
  {
    id: "1102",
    image: require("../components/image/characters/seele/seele.webp"),
    name: "seele",
    translation:{
      "en-us": "Seele",
      "zh-cn": "希儿",
      "zh-tw": "希兒"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Quantum",
    pathIcon: huntIcon,
    elementIcon: quantumIcon,
    type: "live",
    guide: true
  },
  {
    id: "1103",
    image: require("../components/image/characters/serval/serval.webp"),
    name: "serval",
    translation:{
      "en-us": "Serval",
      "zh-cn": "希露瓦",
      "zh-tw": "希露瓦"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Erudition",
    element: "Lightning",
    pathIcon: eruditionIcon,
    elementIcon: lightningIcon,
    type: "live",
    guide: false
  },
  {
    id: "1006",
    image: require("../components/image/characters/silverwolf/silverwolf.webp"),
    name: "silver-wolf",
    translation:{
      "en-us": "Silver Wolf",
      "zh-cn": "银狼",
      "zh-tw": "銀狼"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Quantum",
    pathIcon: nihilityIcon,
    elementIcon: quantumIcon,
    type: "live",
    guide: false
  },
  {
    id: "1206",
    image: require("../components/image/characters/sushang/sushang.webp"),
    name: "sushang",
    translation:{
      "en-us": "Sushang",
      "zh-cn": "素裳",
      "zh-tw": "素裳"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Hunt",
    element: "Physical",
    pathIcon: huntIcon,
    elementIcon: physicalIcon,
    type: "live",
    guide: false
  },
  {
    id: "1202",
    image: require("../components/image/characters/tingyun/tingyun.webp"),
    name: "tingyun",
    translation:{
      "en-us": "Tingyun",
      "zh-cn": "停云",
      "zh-tw": "停雲"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Harmony",
    element: "Lightning",
    pathIcon: harmonyIcon,
    elementIcon: lightningIcon,
    type: "live",
    guide: true
  },
  {
    id: "8001",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    name: "trail-blazer-destruction",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Physical",
    pathIcon: destructionIcon,
    elementIcon: physicalIcon,
    type: "live",
    guide: false
  },
  {
    id: "8002",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    name: "trail-blazer-destruction",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Physical",
    pathIcon: destructionIcon,
    elementIcon: physicalIcon,
    type: "live",
    guide: false
  },
  {
    id: "8003",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    name: "trail-blazer-preservation",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Preservation",
    element: "Fire",
    pathIcon: preservationIcon,
    elementIcon: fireIcon,
    type: "live",
    guide: false
  },
  {
    id: "8004",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    name: "trail-blazer-preservation",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Preservation",
    element: "Fire",
    pathIcon: preservationIcon,
    elementIcon: fireIcon,
    type: "live",
    guide: false
  },  
  {
    id: "8005",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    name: "trail-blazer-harmony",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Imaginary",
    pathIcon: harmonyIcon,
    elementIcon: imaginaryIcon,
    type: "live",
    guide: false
  },  
  {
    id: "8006",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    name: "trail-blazer-harmony",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Imaginary",
    pathIcon: harmonyIcon,
    elementIcon: imaginaryIcon,
    type: "live",
    guide: false
  },  
  {
    id: "1004",
    image: require("../components/image/characters/welt/welt.webp"),
    name: "welt",
    translation:{
      "en-us": "Welt",
      "zh-cn": "瓦尔特",
      "zh-tw": "瓦爾特"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Imaginary",
    pathIcon: nihilityIcon,
    elementIcon: imaginaryIcon,
    type: "live",
    guide: false
  },
  {
    id: "1209",
    image: require("../components/image/characters/yanqing/yanqing.webp"),
    name: "yanqing",
    translation:{
      "en-us": "Yanqing",
      "zh-cn": "彦卿",
      "zh-tw": "彥卿"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Ice",
    pathIcon: huntIcon,
    elementIcon: iceIcon,
    type: "live",
    guide: false
  },
  {
    id: "1207",
    image: require("../components/image/characters/yukong/yukong.webp"),
    name: "yukong",
    translation:{
      "en-us": "Yukong",
      "zh-cn": "驭空",
      "zh-tw": "馭空"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Harmony",
    element: "Imaginary",
    pathIcon: harmonyIcon,
    elementIcon: imaginaryIcon,
    type: "live",
    guide: false
  },
  {
    id: "1213",
    image: require("../components/image/characters/dan-heng-imbibitor-lunae/dan-heng-imbibitor-lunae.webp"),
    name: "dan-heng-imbibitor-lunae",
    translation:{
      "en-us": "Dan Heng • Imbibitor Lunae",
      "zh-cn": "丹恒 • 饮月君",
      "zh-tw": "丹恆 • 飲月君"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Imaginary",
    pathIcon: destructionIcon,
    elementIcon: imaginaryIcon,
    type: "live",
    guide: true
  },
  {
    id: "1214",
    image: require("../components/image/characters/xueyi/xueyi.webp"),
    name: "xueyi",
    translation:{
      "en-us": "Xueyi",
      "zh-cn": "雪衣",
      "zh-tw": "雪衣"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Destruction",
    element: "Quantum",
    pathIcon: destructionIcon,
    elementIcon: quantumIcon,
    type: "live",
    guide: false
  },
  {
    id: "1208",
    image: require("../components/image/characters/fuxuan/fuxuan.webp"),
    name: "fuxuan",
    translation:{
      "en-us": "Fu Xuan",
      "zh-cn": "符玄",
      "zh-tw": "符玄"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Preservation",
    element: "Quantum",
    pathIcon: preservationIcon,
    elementIcon: quantumIcon,
    type: "live",
    guide: true
  },
  {
    id: "1110",
    image: require("../components/image/characters/lynx/lynx.webp"),
    name: "lynx",
    translation:{
      "en-us": "Lynx",
      "zh-cn": "玲可",
      "zh-tw": "玲可"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Abundance",
    element: "Quantum",
    pathIcon: abundanceIcon,
    elementIcon: quantumIcon,
    type: "live",
    guide: true
  },
];

export const characterRows = [
  {
    id: "1008",
    image: require("../components/image/characters/arlan/arlan.webp"),
    background: require("../components/image/characters/arlan/arlan_full.webp"),
    e1: require("../components/image/characters/arlan/e1.webp"),
    e2: require("../components/image/characters/arlan/e2.webp"),
    e3: require("../components/image/characters/arlan/e3.webp"),
    e4: require("../components/image/characters/arlan/e4.webp"),
    e5: require("../components/image/characters/arlan/e5.webp"),
    e6: require("../components/image/characters/arlan/e6.webp"),
    basic_attack: require("../components/image/characters/arlan/b.webp"),
    skill: require("../components/image/characters/arlan/s.webp"),
    ultimate: require("../components/image/characters/arlan/u.webp"),
    talent: require("../components/image/characters/arlan/t.webp"),
    technique: require("../components/image/characters/arlan/q.webp"),
    name: "arlan",
    translation:{
      "en-us": "Arlan",
      "zh-cn": "阿兰",
      "zh-tw": "阿蘭"
    },
    story:[{
      "en-us":
        "The inarticulate head of Herta Space Station's Security Department.",
      "zh-cn": "不善言辞的空间站「黑塔」防卫科负责人。",
      "zh-tw": "不善言辭的空間站「黑塔」防衛科負責人。",
    },
    {
      "en-us":
        "While scientific research is beyond his understanding, Arlan is willing to risk his life to protect the staff who value research so very much. He is used to the pain and wears his scars like badges of honor.",
      "zh-cn": "虽然不懂科研，但为了保护珍视研究的空间站科员顺利完成他们的研究，阿兰可以拼上性命。他早已习惯疼痛，并将负伤视作勋章。",
      "zh-tw": "雖然不懂科研，但為了保護珍視研究的空間站科員順利完成他們的研究，阿蘭可以拼上性命。他早已習慣疼痛，並將負傷視作勳章。",
    },{
      "en-us":
        "Only when holding Peppy does the boy finally let down his guard and show a rare smile.",
      "zh-cn": "也只有抱着佩佩时，男孩才会放下戒备，露出难得一见的笑容。",
      "zh-tw": "也只有抱著佩佩時，男孩才會放下戒備，露出難得一見的笑容。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 1.68,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/arlan/u.webp"),
      description: {
        "en-us": "(192% X ATT) * (100% + Lightning Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(192% X 攻击力) * (100% + 雷属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(192% X 攻擊力) * (100% + 雷屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(192% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Destruction",
    element: "Lightning",
    pathIcon: destructionIcon,
    elementIcon: lightningIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1009",
    image: require("../components/image/characters/asta/asta.webp"),
    background: require("../components/image/characters/asta/asta_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "249K"
      },
      {
        item: items.filter(id => id.id === "endotherm-chitin")[0],
        value: "50"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "2.4M"
      },
      {
        item: items.filter(id => id.id === "destroyers-final-road")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "5"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "28"
      },
      {
        item: items.filter(id => id.id === "stellaris-symphony")[0],
        value: "105"
      },
      {
        item: items.filter(id => id.id === "ancestral-hymn")[0],
        value: "54"
      },
      {
        item: items.filter(id => id.id === "harmonic-tune")[0],
        value: "12"
      },
    ],
    e1: require("../components/image/characters/asta/e1.webp"),
    e1_translation: {
      "en-us": "When using Skill, deals DMG for 1 extra time to a random enemy.",
      "zh-cn": "施放战技时，对敌方随机单体额外造成1次伤害。",
      "zh-tw": "施放戰技時，對敵方隨機單體額外造成1次傷害。"
    },
    e2: require("../components/image/characters/asta/e2.webp"),
    e2_translation: {
      "en-us": "After using her Ultimate, Asta's Charging stacks will not be reduced in the next turn.",
      "zh-cn": "施放终结技时，艾丝妲下回合不会减少蓄能层数。",
      "zh-tw": "施放終結技時，艾絲妲下回合不會減少蓄能層數。"
    },
    e3: require("../components/image/characters/asta/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "战技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "戰技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e4: require("../components/image/characters/asta/e4.webp"),
    e4_translation: {
      "en-us": "Asta's Energy Regeneration Rate increases by 15% when she has 2 or more Charging stacks.",
      "zh-cn": "天赋的蓄能层数大于等于2时，艾丝妲的能量恢复效率提高15%。",
      "zh-tw": "天賦的蓄能層數大於等於2時，艾絲妲的能量恢復效率提高15%。"
    },
    e5: require("../components/image/characters/asta/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "终结技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "終結技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e6: require("../components/image/characters/asta/e6.webp"),
    e6_translation: {
      "en-us": "Charging stack(s) lost in each turn is reduced by 1.",
      "zh-cn": "天赋每回合减少的蓄能层数降低1。",
      "zh-tw": "天賦每回合減少的蓄能層數降低1。"
    },
    basic_attack: require("../components/image/characters/asta/b.webp"),
    skill: require("../components/image/characters/asta/s.webp"),
    ultimate: require("../components/image/characters/asta/u.webp"),
    talent: require("../components/image/characters/asta/t.webp"),
    technique: require("../components/image/characters/asta/q.webp"),
    name: "asta",
    translation:{
      "en-us": "Asta",
      "zh-cn": "艾丝妲",
      "zh-tw": "艾絲妲"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 70
        }, 
        {
          recommended: false,
          value: 54
        },  
        {
          recommended: true,
          value: 50
        },  
        {
          recommended: false,
          value: 34
        }, 
        {
          recommended: true,
          value: 30
        }, 
        {
          recommended: true,
          value: 15
        }],
      skill:[
        {
          stat: "ultimate",
          value: "1",
          level: 9
        },
        {
          stat: "talent",
          value: "1",
          level: 9
        },
        {
          stat: "skill",
          value: "2",
          level: 6
        },
        {
          stat: "basic_attack",
          value: "3",
          level: 1
        },
      ],
      extra_skill:[
        {
          translation: {
            "en-us": "Ignite",
            "zh-cn": "点燃",
            "zh-tw": "點燃"
          },
          effect_translation: {
            "en-us": "When Asta is on the field, all allies' Fire DMG increases by 18%.",
            "zh-cn": "艾丝妲在场时，我方全体的火属性伤害提高18%。",
            "zh-tw": "艾絲妲在場時，我方全體的火屬性傷害提高18%。"
          },
          image: require("../components/image/characters/asta/ignite.webp"),
          value: "1"
        },
      {
        translation: {
          "en-us": "Constellation",
          "zh-cn": "星座",
          "zh-tw": "星座"
        },
        effect_translation: {
          "en-us": "Asta's DEF increases by 6% for every current Charging stack she possesses.",
          "zh-cn": "艾丝妲每拥有1层蓄能，自身防御力提高6%。",
          "zh-tw": "艾絲妲每擁有1層蓄能，自身防禦力提高6%。"
        },
        image: require("../components/image/characters/asta/constellation.webp"),
        value: "1"
      },
      {
      translation: {
        "en-us": "Sparks",
        "zh-cn": "火花",
        "zh-tw": "火花"
      },
      effect_translation: {
        "en-us": "Asta's Basic ATK has a 80% base chance to Burn enemies for 3 turn(s). Burned enemies take Fire DoT equal to 50% of DMG dealt by Asta's Basic ATK at the start of each turn.",
        "zh-cn": "施放普攻时，有80%的基础概率使敌方目标陷入灼烧状态，持续3回合。灼烧状态下，敌方目标每回合开始时受到等同于艾丝妲普攻50%的火属性持续伤害。",
        "zh-tw": "施放普攻時，有80%的基礎機率使敵方目標陷入灼燒狀態，持續3回合。 灼燒狀態下，敵方目標每回合開始時受到等同於艾絲妲普攻50%的火屬性持續傷害。"
      },
      image: require("../components/image/characters/asta/sparks.webp"),
      value: "2"
    },
      ],
      traces:[
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DBF")[0],
        value: "2"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "EG")[0]
        },
      ],
      target_stats: [
        {
          stat: property.filter(id => id.id === "H")[0],
          value: "4500"
        },
        {
        stat: property.filter(id => id.id === "S")[0],
        value: "149"
        },
        {
          stat: property.filter(id => id.id === "D")[0],
          value: "1200"
        },],
      stat:[{
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "BE")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "114_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "113_0")[0],
          set: 2,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "302_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "310_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "307_0")[0],
          set: 2,
          score: 95,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23003")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120,
            },
            {
              rank: "I",
              score: 100,
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21025")[0],
          recommended: false,
          score: [
            {
              rank: "V",
              score: 70
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21018")[0],
          recommended: true,
          score: [
            {
              rank: "I",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1205", "1204", "1102"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1202"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1211", "1208", "1203", "1105"]
            },
          ]
        },
      ]
    },
    story:[{
      "en-us":
        "A fiercely inquisitive and energetic young girl, she is the Lead Researcher of Herta Space Station.",
      "zh-cn": "好奇心与精力都很旺盛的少女，空间站「黑塔」的名义站长。",
      "zh-tw": "好奇心與精力都很旺盛的少女，空間站「黑塔」的名義站長。",
    },
    {
      "en-us":
        "Whether it is managing opinionated staff, or courteously but firmly responding to the Intelligentsia Guild, Asta handles it all effortlessly.",
      "zh-cn": "无论是管理各抒己见的科员，还是直接又不失礼貌地回复博识学会的种种刁难，对艾丝妲来说都易如反掌。",
      "zh-tw": "無論是管理各抒己見的科員，還是直接又不失禮貌地回复博識學會的種種刁難，對艾絲妲來說都易如反掌。",
    },{
      "en-us":
        "After all... commanding a space station is much easier than taking over her family business!",
      "zh-cn": "毕竟指挥空间站…总比继承家业简单吧！",
      "zh-tw": "畢竟指揮空間站…總比繼承家業簡單吧！",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 1,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 1.68,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "MTH4" : 1,
        "MTH2" : 1,
        "SV2" : 1,
        "FA2" : 1,
        "TKB2" : 1,
        "LD2" : 1
      },
      name: {
        "en-us": "Skill",
        "zh-cn": "战技"
      },
      icon: require("../components/image/characters/asta/s.webp"),
      description: {
        "en-us": "(100% + Break Effect %) * (100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (HP / 100) * (DEF / 100) * Relic Set",
        "zh-cn": "(100% + 击破特攻 %) * (100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
        "zh-tw": "(100% + 擊破特攻 %) * (100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
      },
      calculation: "(100%+(BE)) * (100%+(EG)) * (100%+(ER)) * ((S) / 100) * ((H)/100) * ((D)/100) * (RS)"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Harmony",
    element: "Fire",
    pathIcon: harmonyIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "upcoming"
  },
  {
    id: "1211",
    image: require("../components/image/characters/bailu/bailu.webp"),
    background: require("../components/image/characters/bailu/bailu_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "lightning-crown-of-the-past-shadow")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "flower-of-eternity")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "sprout-of-life")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "seed-of-abundance")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/bailu/e1.webp"),
    e1_translation: {
      "en-us": "If the target ally's current HP is equal to their Max HP when Invigoration ends, regenerates 8 extra Energy for this target.",
      "zh-cn": "【生息】结束时若我方目标当前生命值等于其生命值上限，则额外恢复目标8点能量。",
      "zh-tw": "【生息】結束時若我方目標目前生命值等於其生命值上限，則額外恢復目標8點能量。"
    },
    e2: require("../components/image/characters/bailu/e2.webp"),
    e2_translation: {
      "en-us": "After using her Ultimate, Bailu's Outgoing Healing increases by an additional 15% for 2 turn(s).",
      "zh-cn": "施放终结技后，白露的治疗量提高15%，持续2回合。",
      "zh-tw": "施放終結技後，白露的治療量提高15%，持續2回合。"
    },
    e3: require("../components/image/characters/bailu/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "战技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "戰技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e4: require("../components/image/characters/bailu/e4.webp"),
    e4_translation: {
      "en-us": "Every healing provided by the Skill makes the recipient deal 10% more DMG for 2 turn(s). This effect can stack up to 3 time(s).",
      "zh-cn": "战技提供的每1次治疗会额外使受治疗者造成的伤害提高10%，最高叠加3层，持续2回合。",
      "zh-tw": "戰技提供的每1次治療會額外使受治療者造成的傷害提高10%，最高疊加3層，持續2回合。"
    },
    e5: require("../components/image/characters/bailu/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "终结技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "終結技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e6: require("../components/image/characters/bailu/e6.webp"),
    e6_translation: {
      "en-us": "Bailu can heal allies who received a killing blow 1 more time(s) in a single battle.",
      "zh-cn": "白露在单场战斗中累计可以对受到致命攻击的我方目标提供治疗的效果触发次数增加1次。",
      "zh-tw": "白露在單場戰鬥中累積可以對受到致命攻擊的我方目標提供治療的效果觸發次數增加1次。"
    },
    basic_attack: require("../components/image/characters/bailu/b.webp"),
    skill: require("../components/image/characters/bailu/s.webp"),
    ultimate: require("../components/image/characters/bailu/u.webp"),
    talent: require("../components/image/characters/bailu/t.webp"),
    technique: require("../components/image/characters/bailu/q.webp"),
    name: "bailu",
    translation:{
      "en-us": "Bailu",
      "zh-cn": "白露",
      "zh-tw": "白露"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 70
        }, 
        {
          recommended: false,
          value: 54
        },  
        {
          recommended: true,
          value: 50
        },  
        {
          recommended: false,
          value: 34
        }, 
        {
          recommended: true,
          value: 30
        }, 
        {
          recommended: true,
          value: 20
        }],
      skill:[
        {
        stat: "ultimate",
        value: "1",
        level: 9
      },
      {
        stat: "skill",
        value: "1",
        level: 9
      },
      {
        stat: "talent",
        value: "2",
        level: 9
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Qihuang Analects",
          "zh-cn": "岐黄精义",
          "zh-tw": "岐黃精義"
        },
        effect_translation: {
          "en-us": "When Bailu heals a target ally above their normal Max HP, the target's Max HP increases by 10% for 2 turns.",
          "zh-cn": "白露对我方目标造成过量治疗时会提高目标10%的生命上限，持续2回合。",
          "zh-tw": "白露對我方目標造成過量治療時會提高目標10%的生命上限，持續2回合。"
        },
        image: require("../components/image/characters/bailu/qihuang-analects.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Vidyadhara Ichor Lines",
          "zh-cn": "持明龙脉",
          "zh-tw": "持明龍脈"
        },
        effect_translation: {
          "en-us": "Invigoration can trigger 1 more time(s).",
          "zh-cn": "【生息】效果的触发次数增加1次。",
          "zh-tw": "【生息】效果的觸發次數增加1次。"
        },
        image: require("../components/image/characters/bailu/vidyadhara-ichor-lines.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Aquatic Benediction",
          "zh-cn": "鳞渊福泽",
          "zh-tw": "鱗淵福澤"
        },
        effect_translation: {
          "en-us": "Characters with Invigoration receive 10% less DMG.",
          "zh-cn": "拥有【生息】的角色受到的伤害降低10%。",
          "zh-tw": "擁有【生息】的角色受到的傷害降低10%。"
        },
        image: require("../components/image/characters/bailu/aquatic-benediction.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "HR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "O")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "EG")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "H")[0],
        value: "7000"
      },
      {
        stat: property.filter(id => id.id === "D")[0],
        value: "1500"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "30%"
      }],
      stat:[{
        stat: property.filter(id => id.id === "HR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "H")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "101_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "114_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "113_0")[0],
          set: 2,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "302_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "310_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23013")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21028")[0],
          recommended: false,
          score: [
            {
              rank: "I",
              score: 80
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21021")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1205", "1005", "1102", "1213"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1101", "1202", "1207"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1211"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
        "A vivacious young lady of the Vidyadhara race, she is known as the \"Healer Lady\" due to her expertise with medicine.",
      "zh-cn": "性格活泼的小女孩，持明族的「衔药龙女」，药到病除的名医。",
      "zh-tw": "性格活潑的小女孩，持明族的「銜藥龍女」，藥到病除的名醫。",
    },
    {
      "en-us":
        "She often dishes out unorthodox prescriptions such as \"Stay well hydrated\" and \"Get a good night's rest.\"",
      "zh-cn": "经常开出不拘一格的药方，如「多喝热水」、「睡一觉就好了」。",
      "zh-tw": "經常開出不拘一格的藥方，如「多喝熱水」、「睡一覺就好了」。",
    },{
      "en-us":
        "Bailu cannot bear to see people suffer, and that's why you'll see her with her eyes shut tightly as she cures ailments.",
      "zh-cn": "见不得人受苦，治病时总是闭着眼睛。",
      "zh-tw": "見不得人受苦，治病時總是閉著眼睛。",
    },{
    },{
      "en-us":
        "As long as they're cured, that's all that matters, right?",
      "zh-cn": "——总之病好了就行！",
      "zh-tw": "——總之病好了就行！",
    },
  ],
  formula:{
    stats:{
      "A" : 0.04,
      "AR" : 0.2,
      "BE" : 0.5,
      "CR" : 0.2,
      "CD" : 0.1,
      "D" : 0.04,
      "DR" : 1.2,
      "DBF" : 0,
      "DBI": 0,
      "DBM": 0,
      "DBP": 0,
      "DBL": 0,
      "DBQ" : 0,
      "DBW" : 0,
      "EG" : 3.35,
      "EH" : 0.2,
      "ER" : 1.5,
      "H" : 0.05,
      "HR" : 1.5,
      "O" : 1.8,
      "S" : 2.5,
    },
    relic_set_stats: {
      "MTH2" : 1,
      "PWC2" : 1,
      "LD2" : 1,
      "FA2" : 1,
      "BK2" : 1,
    },
    name: {
      "en-us": "Ultimate",
      "zh-cn": "终结技",
      "zh-tw": "終結技"
    },
    icon: require("../components/image/characters/bailu/u.webp"),
    description: {
      "en-us": "(100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (100% + Outgoing Healing Boost %) * (HP / 100) * (DEF / 100)  * Relic Set",
      "zh-cn": "(100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (100% + 治疗量加成 %) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
      "zh-tw": "(100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (100% + 治療量加成 %) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
    },
    calculation: "(100%+(EG)) * (100%+(ER)) * ((S) / 100) * (100%+(O)) * ((H)/100) * ((D)/100) * (RS)"
  }, 
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Abundance",
    element: "Lightning",
    pathIcon: abundanceIcon,
    elementIcon: lightningIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1217",
    image: require("../components/image/characters/huohuo/huohuo.webp"),
    background: require("../components/image/characters/huohuo/huohuo_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "ascendant-debris")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "flower-of-eternity")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "sprout-of-life")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "seed-of-abundance")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/huohuo/e1.webp"),
    e1_translation: {
      "en-us": "The duration of Divine Provision produced by the Talent is extended by 1 turn(s). When Huohuo possesses Divine Provision, all allies' SPD increases by 12%.",
      "zh-cn": "天赋产生的【禳命】持续时间延长1回合，且当藿藿拥有【禳命】时我方全体提高12%的速度。",
      "zh-tw": "天賦產生的【禳命】持續時間延長1回合，且當藿藿擁有【禳命】時我方全體提高12%的速度。"
    },
    e2: require("../components/image/characters/huohuo/e2.webp"),
    e2_translation: {
      "en-us": "If Huohuo possesses Divine Provision when an ally is struck by a killing blow, the ally will not be knocked down, and their HP will immediately be restored by an amount equal to 50% of their Max HP. This reduces the duration of Divine Provision by 1 turn. This effect can only be triggered 2 time(s) per battle.",
      "zh-cn": "当藿藿拥有【禳命】时，若我方目标受到致命攻击，不会陷入无法战斗状态，并立即回复等同于其自身生命上限50%的生命值，使【禳命】的持续回合数减1。该效果单场战斗中可以触发2次。",
      "zh-tw": "當藿藿擁有【禳命】時，若我方目標受到致命攻擊，不會陷入無法戰鬥狀態，並立即回復等同於其自身生命上限50%的生命值，使【禳命】的持續回合數減1。該效果單場戰鬥中可以觸發2次。"
    },
    e3: require("../components/image/characters/huohuo/e3.webp"),
    e3_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e4: require("../components/image/characters/huohuo/e4.webp"),
    e4_translation: {
      "en-us": "When healing a target ally via Skill or Talent, the less HP the target ally currently has, the higher the amount of healing they will receive. The maximum increase in healing provided by Huohuo is 80%.",
      "zh-cn": "施放战技或触发天赋为我方目标提供治疗时，目标的当前生命值越低治疗量越高，最多使藿藿提供的治疗量提高80%。",
      "zh-tw": "施放戰技或觸發天賦為我方目標提供治療時，目標的當前生命值越低治療量越高，最多使藿藿提供的治療量提高80%。"
    },
    e5: require("../components/image/characters/huohuo/e5.webp"),
    e5_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e6: require("../components/image/characters/huohuo/e6.webp"),
    e6_translation: {
      "en-us": "When healing a target ally, increases the target ally's DMG dealt by 50% for 2 turn(s).",
      "zh-cn": "为我方目标提供治疗时，使目标造成的伤害提高50%，持续2回合。",
      "zh-tw": "為我方目標提供治療時，使目標造成的傷害提高50%，持續2回合。"
    },
    basic_attack: require("../components/image/characters/huohuo/b.webp"),
    skill: require("../components/image/characters/huohuo/s.webp"),
    ultimate: require("../components/image/characters/huohuo/u.webp"),
    talent: require("../components/image/characters/huohuo/t.webp"),
    technique: require("../components/image/characters/huohuo/q.webp"),
    name: "huohuo",
    translation:{
      "en-us": "Huohuo",
      "zh-cn": "藿藿",
      "zh-tw": "藿藿"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 70
        }, 
        {
          recommended: false,
          value: 54
        },  
        {
          recommended: true,
          value: 50
        },  
        {
          recommended: false,
          value: 34
        }, 
        {
          recommended: true,
          value: 30
        }, 
        {
          recommended: true,
          value: 20
        }],
      skill:[
        {
        stat: "ultimate",
        value: "1",
        level: 9
      },
      {
        stat: "skill",
        value: "1",
        level: 9
      },
      {
        stat: "talent",
        value: "2",
        level: 9
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Fearful to Act",
          "zh-cn": "不敢自专",
          "zh-tw": "不敢自專"
        },
        effect_translation: {
          "en-us": "When battle starts, Huohuo gains Divine Provision, lasting for 1 turn(s).",
          "zh-cn": "战斗开始时，藿藿获得【禳命】，持续1回合。",
          "zh-tw": "戰鬥開始時，藿藿獲得【禳命】，持續1回合。"
        },
        image: require("../components/image/characters/huohuo/fearful-to-act.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "The Cursed One",
          "zh-cn": "贞凶之命",
          "zh-tw": "貞兇之命"
        },
        effect_translation: {
          "en-us": "The chance to resist Crowd Control Debuffs increases by 35%.",
          "zh-cn": "抵抗控制类负面状态的概率提高35%。",
          "zh-tw": "抵抗控制類負面狀態的機率提高35%。"
        },
        image: require("../components/image/characters/huohuo/the-cursed-one.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Stress Reaction to Horror",
          "zh-cn": "怯惧应激",
          "zh-tw": "怯懼應激"
        },
        effect_translation: {
          "en-us": "When her Talent is triggered to heal allies, Huohuo regenerates 1 Energy.",
          "zh-cn": "触发天赋为我方目标提供治疗时，藿藿恢复1点能量。",
          "zh-tw": "觸發天賦為我方目標提供治療時，藿藿恢復1點能量。"
        },
        image: require("../components/image/characters/huohuo/stress-reaction-to-horror.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "HR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "O")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "EG")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "H")[0],
        value: "7000"
      },
      {
        stat: property.filter(id => id.id === "D")[0],
        value: "1500"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "30%"
      }],
      stat:[{
        stat: property.filter(id => id.id === "HR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "H")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "101_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "114_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "106_0")[0],
          set: 2,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "302_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "310_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23017")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21028")[0],
          recommended: false,
          score: [
            {
              rank: "I",
              score: 80
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21021")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1205", "1005", "1102", "1213"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1101", "1202", "1207"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1217"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
        "A pitiable and helpless Foxian girl who is also a Ten-Lords Commission Judge-in-training, who fears ghosts but must catch them. Due to having a heliobus named Tail sealed in her tail by the judges of the Ten-Lords Commission, she became a \"cursed one\" who attracts inhuman beings.",
      "zh-cn": "可怜又弱小的狐人小姑娘，也是怕鬼捉鬼的罗浮十王司见习判官。被叫作「尾巴」的岁阳被十王司的判官封印在她的颀尾上，使她成为了招邪的「贞凶之命」。",
      "zh-tw": "可憐又弱小的狐人小姑娘，也是怕鬼捉鬼的羅浮十王司見習判官。被叫作「尾巴」的歲陽被十王司的判官封印在她的頎尾上，使她成為了招邪的「貞兇之命」。",
    },
    {
      "en-us":
        "She fears demons and heliobi, but contrarily is tasked with their capture and entrusted with the heavy task of eradicating vile spirits. Though she believes herself lacking in ability, she is unable to muster the courage to resign and continues onward despite walking in a valley of her own fear.",
      "zh-cn": "害怕妖魔邪物，却总是受命捉拿邪祟，完成艰巨的除魔任务；自认能力不足，却无法鼓起勇气辞职，只好默默害怕地继续下去。",
      "zh-tw": "害怕妖魔邪物，卻總是受命捉拿邪祟，完成艱鉅的除魔任務；自認能力不足，卻無法鼓起勇氣辭職，只好默默害怕地繼續下去。",
    },
  ],
  formula:{
    stats:{
      "A" : 0.04,
      "AR" : 0.2,
      "BE" : 0.5,
      "CR" : 0.2,
      "CD" : 0.1,
      "D" : 0.04,
      "DR" : 1.2,
      "DBF" : 0,
      "DBI": 0,
      "DBM": 0,
      "DBP": 0,
      "DBL": 0,
      "DBQ" : 0,
      "DBW" : 0,
      "EG" : 3.35,
      "EH" : 0.2,
      "ER" : 1.5,
      "H" : 0.05,
      "HR" : 1.5,
      "O" : 1.8,
      "S" : 2.5,
    },
    relic_set_stats: {
      "MTH2" : 1,
      "PWC2" : 1,
      "LD2" : 1,
      "FA2" : 1,
      "BK2" : 1,
    },
    name: {
      "en-us": "Ultimate",
      "zh-cn": "终结技",
      "zh-tw": "終結技"
    },
    icon: require("../components/image/characters/huohuo/u.webp"),      
    description: {
      "en-us": "(100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (HP / 100) * (DEF / 100) * Relic Set",
      "zh-cn": "(100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
      "zh-tw": "(100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
    },
    calculation: "(100%+(EG)) * (100%+(ER)) * ((S) / 100) * ((H)/100) * ((D)/100) * (RS)"
  },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Abundance",
    element: "Wind",
    pathIcon: abundanceIcon,
    elementIcon: windIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },

  {
    id: "1218",
    image: require("../components/image/characters/jiaoqiu/jiaoqiu.webp"),
    background: require("../components/image/characters/jiaoqiu/jiaoqiu_full.webp"),
    e1: require("../components/image/characters/jiaoqiu/e1.webp"),
    e2: require("../components/image/characters/jiaoqiu/e2.webp"),
    e3: require("../components/image/characters/jiaoqiu/e3.webp"),
    e4: require("../components/image/characters/jiaoqiu/e4.webp"),
    e5: require("../components/image/characters/jiaoqiu/e5.webp"),
    e6: require("../components/image/characters/jiaoqiu/e6.webp"),
    basic_attack: require("../components/image/characters/jiaoqiu/b.webp"),
    skill: require("../components/image/characters/jiaoqiu/s.webp"),
    ultimate: require("../components/image/characters/jiaoqiu/u.webp"),
    talent: require("../components/image/characters/jiaoqiu/t.webp"),
    technique: require("../components/image/characters/jiaoqiu/q.webp"),
    name: "jiaoqiu",
    translation:{
      "en-us": "Jiaoqiu",
      "zh-cn": "椒丘",
      "zh-tw": "椒丘"
    },
    story:[{
      "en-us": "A foxian healer and counselor from the Xianzhou Yaoqing. Often greets people with a smile on his face and a scheme in his heart.",
      "zh-cn": "仙舟「曜青」的狐人医士、策士，常以笑脸迎人，实际颇有心计。",
      "zh-tw": "仙舟「曜青」的狐人醫士、策士，常以笑臉迎人，實際頗有心計。",
    },
    {
      "en-us": "Born into a prestigious Alchemy Commission family, he once withdrew from practicing medicine due to a broken heart. However, he returned to the field to treat \"the Merlin's Claw,\" General Feixiao.",
      "zh-cn": "出身于丹鼎司名家，曾一度心死避世，不再行医，后为医治「天击将军」飞霄再度出山。",
      "zh-tw": "出身於丹鼎司名家，曾一度心死避世，不再行醫，後為醫治「天擊將軍」飛霄再度出山。",
    },
    {
      "en-us": "Skilled in the study of alchemical prescription that views food as medicine, especially those that induce a sensation of spiciness. They invented a cauldron-based medicinal formula known as the \"nine-squared grid.\"",
      "zh-cn": "精于医食同源的丹方研究，尤其是能带给人痛感的辣味食物，发明了名为「九宫格」的鼎镬药式。",
      "zh-tw": "精於醫食同源的丹方研究，尤其是能帶給人痛感的辣味食物，發明了名為「九宮格」的鼎鑊藥式。",
    },
  ],
  formula:{
      stats:{
      "A" : 0.15,
      "AR" : 1.8,
      "BE" : 0.5,
      "CR" : 0.2,
      "CD" : 0.1,
      "D" : 0.02,
      "DR" : 0.1,
      "DBF" : 1.68,
      "DBI": 0,
      "DBM": 0,
      "DBP": 0,
      "DBL": 0,
      "DBQ" : 0,
      "DBW": 1.68,
      "EG" : 3.35,
      "EH" : 1.8,
      "ER" : 0.5,
      "H" : 0.02,
      "HR" : 0.6,
      "O" : 0,
      "S" : 2.9,
    },
    relic_set_stats: {
      "MTH2": 1,
      "PDDW2": 1,
      "PDC2": 1,
      "PDC4": 1,
      "ETL4": 1,
      "PGCE2" : 1,
      "SV2" : 1,
      "BK2" : 1,
      "FF2" : 1
    },
    name: {
      "en-us": "Ultimate",
      "zh-cn": "终结技",
      "zh-tw": "終結技"
    },
    icon: require("../components/image/characters/jiaoqiu/u.webp"),
    description: {
      "en-us": "(100% X ATT)  * (100% + Energy Regeneration Rate %) * (100% + Fire Boost %) * (100% + Effect Hit Rate % / 2) * (Speed / 80) * Relic Set",
      "zh-cn": "(100% X 攻击力) * (100% + 能量恢复效率 %) * (100% + 火属性伤害提高 %) * (100% + 效果命中 % / 2) * (速度/ 80) * 遗器套装",
      "zh-tw": "(100% X 攻擊力) * (100% + 能量恢復效率 %) * (100% + 火屬性傷害提高 %) * (100% + 效果命中 % / 2) * (速度/ 80) * 遺器套裝",
    },
    calculation: "(100% * (A)) * (100%+(EG)) * (100%+(ED)) * (100%+(EH)/2) * ((S) / 80) * (RS)"
  },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Fire",
    pathIcon: nihilityIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1220",
    image: require("../components/image/characters/feixiao/feixiao.webp"),
    background: require("../components/image/characters/feixiao/feixiao_full.webp"),
    e1: require("../components/image/characters/feixiao/e1.webp"),
    e2: require("../components/image/characters/feixiao/e2.webp"),
    e3: require("../components/image/characters/feixiao/e3.webp"),
    e4: require("../components/image/characters/feixiao/e4.webp"),
    e5: require("../components/image/characters/feixiao/e5.webp"),
    e6: require("../components/image/characters/feixiao/e6.webp"),
    basic_attack: require("../components/image/characters/feixiao/b.webp"),
    skill: require("../components/image/characters/feixiao/s.webp"),
    ultimate: require("../components/image/characters/feixiao/u.webp"),
    talent: require("../components/image/characters/feixiao/t.webp"),
    technique: require("../components/image/characters/feixiao/q.webp"),
    name: "feixiao",
    translation:{
      "en-us": "Feixiao",
      "zh-cn": "飞霄",
      "zh-tw": "飛霄"
    },
    story:[{
      "en-us": "The Xianzhou Yaoqing's Merlin's Claw and one of the Seven Arbiter-Generals. Unconventional and straightforward, she exudes effortless charm.",
      "zh-cn": "仙舟「曜青」的天击将军，帝弓七天将之一，为人不拘一格，率直潇洒。",
      "zh-tw": "仙舟「曜青」的天擊將軍，帝弓七天將之一，為人不拘一格，率直瀟灑。",
    },
    {
      "en-us": "She is skilled in all forms of martial arts and has honed herself into a supreme weapon. She is widely adored by Xianzhou soldiers and civilians alike as \"The Vanquishing General.\"",
      "zh-cn": "精通百般武艺，炼化躯体至极致，享有「大捷将军」美名，深受仙舟军民爱戴。",
      "zh-tw": "精通百般武藝，煉化軀體至極致，享有「大捷將軍」美名，深受仙舟軍民愛戴。",
    },{
      "en-us": "However, she bears the burden of the Moon Rage affliction. If she were to hunt down all the abominations in her limited lifetime — then the only enemy Feixiao has would be herself.",
      "zh-cn": "但身负「月狂」之症，如要在时限内猎尽孽物——飞霄唯一的敌手，便是自己。",
      "zh-tw": "但身負「月狂」之症，如要在時限內獵盡孽物——飛霄唯一的敵手，便是自己。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 1.68,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Talent",
        "zh-cn": "天赋",
        "zh-tw": "天賦"
      },
      icon: require("../components/image/characters/feixiao/t.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Wind Boost %) * (100% - CR% + CR% x (100% + CD%)) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 风属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 風屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Wind",
    pathIcon: huntIcon,
    elementIcon: windIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "upcoming"
  },
  {
    id: "1221",
    image: require("../components/image/characters/yunli/yunli.webp"),
    background: require("../components/image/characters/yunli/yunli_full.webp"),
    e1: require("../components/image/characters/yunli/e1.webp"),
    e2: require("../components/image/characters/yunli/e2.webp"),
    e3: require("../components/image/characters/yunli/e3.webp"),
    e4: require("../components/image/characters/yunli/e4.webp"),
    e5: require("../components/image/characters/yunli/e5.webp"),
    e6: require("../components/image/characters/yunli/e6.webp"),
    basic_attack: require("../components/image/characters/yunli/b.webp"),
    skill: require("../components/image/characters/yunli/s.webp"),
    ultimate: require("../components/image/characters/yunli/u.webp"),
    talent: require("../components/image/characters/yunli/t.webp"),
    technique: require("../components/image/characters/yunli/q.webp"),
    name: "yunli",
    translation:{
      "en-us": "Yunli",
      "zh-cn": "云璃",
      "zh-tw": "雲璃"
    },
    story:[{
      "en-us": "A sword hunter from the Xianzhou Zhuming and \"the Flaming Heart\" General Huaiyan's darling granddaughter. Frank and straightforward.",
      "zh-cn": "仙舟「朱明」的猎剑士，备受「烛渊将军」怀炎宠爱的孙女，性格直率。",
      "zh-tw": "仙舟「朱明」的獵劍士，備受「燭淵將軍」懷炎寵愛的孫女，性格直率。",
    },
    {
      "en-us": "She has learned swordplay and forging from Huaiyan since young, and thus is the second-youngest prodigy swordmaster of the Flamewheel Octet.",
      "zh-cn": "从小跟随怀炎学习剑艺与锻艺，乃「焰轮八叶」中次年幼的天才剑士。",
      "zh-tw": "從小跟隨懷炎學習劍藝與鍛藝，乃「焰輪八葉」中次年幼的天才劍士。",
    },{
      "en-us": "Fueled by an intense loathing for the cursed swords that emerged from the Zhuming, she vowed to \"hunt down and wipe out all cursed swords.\"",
      "zh-cn": "对朱明流出的魔剑深恶痛绝，立誓要「搜猎魔剑，尽数断绝」。",
      "zh-tw": "對朱明流出的魔劍深惡痛絕，立誓要「搜獵魔劍，盡數斷絕」。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 1.68,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Talent",
        "zh-cn": "天赋",
        "zh-tw": "天賦"
      },
      icon: require("../components/image/characters/yunli/t.webp"),
      description: {
        "en-us": "(80% X ATT) * (100% + Physical Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(80% X 攻击力) * (100% + 物理属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(80% X 攻擊力) * (100% + 物理屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(80% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Physical",
    pathIcon: destructionIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  
  {
    id: "1223",
    image: require("../components/image/characters/moze/moze.webp"),
    background: require("../components/image/characters/moze/moze_full.webp"),
    e1: require("../components/image/characters/moze/e1.webp"),
    e2: require("../components/image/characters/moze/e2.webp"),
    e3: require("../components/image/characters/moze/e3.webp"),
    e4: require("../components/image/characters/moze/e4.webp"),
    e5: require("../components/image/characters/moze/e5.webp"),
    e6: require("../components/image/characters/moze/e6.webp"),
    basic_attack: require("../components/image/characters/moze/b.webp"),
    skill: require("../components/image/characters/moze/s.webp"),
    ultimate: require("../components/image/characters/moze/u.webp"),
    talent: require("../components/image/characters/moze/t.webp"),
    technique: require("../components/image/characters/moze/q.webp"),
    name: "moze",
    translation:{
      "en-us": "Moze",
      "zh-cn": "貊泽",
      "zh-tw": "貊澤"
    },
    story:[{
      "en-us": "A Shadow Guard of the Yaoqing, Moze is taciturn and solitary, always acting on his own.",
      "zh-cn": "仙舟「曜青」的影卫，沉默寡言、独来独往。",
      "zh-tw": "仙舟「曜青」的影衛，沉默寡言、獨來獨往。",
    },
    {
      "en-us": "As an expert in intelligence services and other operations that must remain covert, Moze rarely shows himself before others. The moment he reveals his blade usually spells doom for his enemies.",
      "zh-cn": "专司情报与不见光的事务，鲜少在人前露面。貊泽显露锋芒时，便是敌人的死期。",
      "zh-tw": "專司情報與不見光的事務，鮮少在人前露面。貊澤顯露鋒芒時，便是敵人的死期。",
    },{
      "en-us": "He commands a vast wealth of assassination techniques, coupled with an extraordinary obsession for orderliness and cleanliness.",
      "zh-cn": "刺杀手段丰富，对整洁和条理有超乎寻常的执着。",
      "zh-tw": "刺殺手段豐富，對整潔和條理有超乎尋常的執著。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 1.68,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Talent",
        "zh-cn": "天赋",
        "zh-tw": "天賦"
      },
      icon: require("../components/image/characters/moze/t.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Wind Boost %) * (100% - CR% + CR% x (100% + CD%)) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 风属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 風屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * (RS)"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Hunt",
    element: "Wind",
    pathIcon: huntIcon,
    elementIcon: windIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "upcoming"
  },

  {
    id: "1205",
    image: require("../components/image/characters/blade/blade.webp"),
    background: require("../components/image/characters/blade/blade_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "ascendant-debris")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "regret-of-infinite-ochema")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/blade/e1.webp"),
    e1_translation: {
      "en-us": "Blade's Ultimate deals additionally increased DMG to a single enemy target, with the increased amount equal to 150% of Blade's total HP loss in the current battle. The total HP Blade has lost in the current battle is capped at 90% of his Max HP. This value will be reset and re-accumulated after his Ultimate has been used.",
      "zh-cn": "终结技对指定敌方单体造成的伤害额外提高，提高数值等同于刃150%本场战斗中累计的已损失生命值。本场战斗中累计已损失生命值最高不超过刃生命上限的90%，施放终结技后会清空并进行重新累计。",
      "zh-tw": "終結技對指定敵方單體造成的傷害額外提高，提高數值等同於刃150%本場戰鬥中累計的已損失生命值。本場戰鬥中累計已損失生命值最高不超過刃生命上限的90%，施放終結技後會清空並進行重新累計。"
    },
    e2: require("../components/image/characters/blade/e2.webp"),
    e2_translation: {
      "en-us": "When Blade is in the Hellscape state, his CRIT Rate increases by 15%.",
      "zh-cn": "刃处于【地狱变】状态时，暴击率提高15%。",
      "zh-tw": "刃處於【地獄變】狀態時，暴擊率提高15%。"
    },
    e3: require("../components/image/characters/blade/e3.webp"),
    e3_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e4: require("../components/image/characters/blade/e4.webp"),
    e4_translation: {
      "en-us": "When Blade's current HP drops to 50% or lower of his Max HP, increases his Max HP by 20%. Stacks up to 2 time(s).",
      "zh-cn": "当前生命值百分比从大于50%降低至小于等于50%时，生命上限提高20%，该效果最多叠加2层。",
      "zh-tw": "當前生命值百分比從大於50%降低至小於等於50%時，生命上限提高20%，該效果最多疊加2層。"
    },
    e5: require("../components/image/characters/blade/e5.webp"),
    e5_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e6: require("../components/image/characters/blade/e6.webp"),
    e6_translation: {
      "en-us": "The maximum number of Charge stacks is reduced to 4. The DMG of the follow-up attack triggered by Blade's Talent additionally increases by 50% of his Max HP.",
      "zh-cn": "充能的层数上限降低至4层。天赋触发追加攻击造成的伤害额外提高，提高数值等同于刃50%的生命上限。",
      "zh-tw": "充能的層數上限降低至4層。天賦觸發追加攻擊造成的傷害額外提高，提高數值等同於刃50%的生命上限。"
    },
    basic_attack: require("../components/image/characters/blade/b.webp"),
    skill: require("../components/image/characters/blade/s.webp"),
    ultimate: require("../components/image/characters/blade/u.webp"),
    talent: require("../components/image/characters/blade/t.webp"),
    technique: require("../components/image/characters/blade/q.webp"),
    name: "blade",
    translation:{
      "en-us": "Blade",
      "zh-cn": "刃",
      "zh-tw": "刃"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 80
        }, 
        {
          recommended: false,
          value: 50
        },  
        {
          recommended: true,
          value: 46
        },  
        {
          recommended: false,
          value: 20
        }, 
        {
          recommended: false,
          value: 16
        }, 
        {
          recommended: false,
          value: 10
        }],
      skill:[
        {
          stat: "basic_attack",
          value: "1",
          level: 6
        },
        {
          stat: "talent",
          value: "1",
          level: 9
        },
        {
          stat: "skill",
          value: "2",
          level: 9
        },
        {
        stat: "ultimate",
        value: "2",
        level: 9
        },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Vita Infinita",
          "zh-cn": "遁甲星舆",
          "zh-tw": "遁甲星輿"
        },
        effect_translation: {
          "en-us": "When Blade's current HP is at 50% of Max HP or lower, Incoming Healing increases by 20%.",
          "zh-cn": "刃的当前生命值百分比小于等于生命上限的50%时，受到治疗时的回复量提高20%。",
          "zh-tw": "刃的當前生命值百分比小於等於生命上限的50%時，受到治療時的回復量提高20%。"
        },
        image: require("../components/image/characters/blade/vita-infinita.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Neverending Deaths",
          "zh-cn": "太乙式盘",
          "zh-tw": "太乙式盤"
        },
        effect_translation: {
          "en-us": "If Blade hits a Weakness Broken enemy after using Forest of Swords, he will restore HP equal to 5% of his Max HP plus 100.",
          "zh-cn": "施放【无间剑树】后，若击中处于弱点击破状态的敌方目标，刃回复等同于自身5%生命上限+100的生命值。",
          "zh-tw": "施放【無間劍樹】後，若擊中處於弱點擊破狀態的敵方目標，刃回復等同於自身5%生命上限+100的生命值。"
        },
        image: require("../components/image/characters/blade/neverending-deaths.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Cyclone of Destruction",
          "zh-cn": "坏劫隳亡",
          "zh-tw": "壞劫隳亡"
        },
        effect_translation: {
          "en-us": "DMG dealt by Talent's follow-up attack increases by 20%.",
          "zh-cn": "天赋施放的追加攻击伤害提高20%。",
          "zh-tw": "天賦施放的追加攻擊傷害提高20%。"
        },
        image: require("../components/image/characters/blade/cyclone-of-destruction.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "HR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "CD")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "DBW")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "H")[0],
        value: "7500"
      },
      {
        stat: property.filter(id => id.id === "CR")[0],
        value: "70%"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "140%"
      }],
      stat:[{
        stat: property.filter(id => id.id === "HR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "BE")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "113_0")[0],
          set: 4,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "306_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "309_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23009")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21012")[0],
          recommended: true,
          score: [
            {
              rank: "I",
              score: 70
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "20016")[0],
          recommended: false,
          score: [
            {
              rank: "V",
              score: 50
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1205"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1101", "1202", "1207"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1211", "1208", "1203", "1105"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
        "A swordsman who abandoned his body to become a blade. Birth name unknown.",
      "zh-cn": "弃身锋刃的剑客，原名不详。",
      "zh-tw": "棄身鋒刃的劍客，原名不詳。",
    },
    {
      "en-us":
        "He pledged loyalty to Destiny's Slave and possessed a terrifying self-healing ability.",
      "zh-cn": "效忠于「命运的奴隶」，拥有可怖的自愈能力。",
      "zh-tw": "效忠於「命運的奴隸」，擁有可怖的自愈能力。",
    },{
      "en-us":
        "Blade wields an ancient sword riddled with cracks, just like his body and his mind.",
      "zh-cn": "手持古剑作战，剑身遍布破碎裂痕，正如其身，亦如其心。",
      "zh-tw": "手持古劍作戰，劍身遍布破碎裂痕，正如其身，亦如其心。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 0.8,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW" : 1.68,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 0,
        "S" : 2.5,
      },     
      relic_set_stats: {
        "LD4": 1,
        "LD2": 0.9,
        "ETL4": 0.8,
        "ETL2": 0.88,
        "RA2" : 1,
        "IS2" : 1
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/blade/u.webp"),
      description: {
        "en-us": "(5% X ATT + 95% X HP) * (100% + Wind Boost %) * (100% - CR% + CR% x (100% + CD%)) * Relic Set",
        "zh-cn": "(5% X 攻击力 + 95% X 生命值) * (100% + 风属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * 遗器套装",
        "zh-tw": "(5% X 攻擊力 + 95% X 生命值) * (100% + 風屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * 遺器套裝",
      },
      calculation: "(5% * (A) + 95%*(H)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Wind",
    pathIcon: destructionIcon,
    elementIcon: windIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1101",
    image: require("../components/image/characters/bronya/bronya.webp"),
    background: require("../components/image/characters/bronya/bronya_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "storm-eye")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "guardians-lament")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "stellaris-symphony")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "ancestral-hymn")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "harmonic-tune")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/bronya/e1.webp"),
    e1_translation: {
      "en-us": "When using Skill, there is 50% fixed chance of recovering 1 Skill Point. This effect has a 1-turn cooldown.",
      "zh-cn": "施放战技时，有50%固定概率恢复1个战技点，该效果有1回合的触发冷却。",
      "zh-tw": "施放戰技時，有50%固定機率恢復1個戰技點，此效果有1回合的觸發冷卻。"
    },
    e2: require("../components/image/characters/bronya/e2.webp"),
    e2_translation: {
      "en-us": "When using Skill, the target ally's SPD increases by 30% after taking action, lasting for 1 turn.",
      "zh-cn": "施放战技时，被指定的我方目标在行动后速度提高30%，持续1回合。",
      "zh-tw": "施放戰技時，被指定的我方目標在行動後速度提高30%，持續1回合。"
    },
    e3: require("../components/image/characters/bronya/e3.webp"),
    e3_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e4: require("../components/image/characters/bronya/e4.webp"),
    e4_translation: {
      "en-us": "After an ally other than Bronya uses Basic ATK on an enemy with Wind Weakness, Bronya immediately launches a follow-up attack on the target, dealing Wind DMG equal to 80% of Bronya's Basic ATK DMG. This effect can only be triggered 1 time per turn.",
      "zh-cn": "我方其他角色对弱点为风属性的敌方目标施放普攻后，布洛妮娅立即进行1次追加攻击，对该目标造成等同于普攻伤害80%的风属性伤害，该效果每回合可触发1次。",
      "zh-tw": "我方其他角色對弱點為風屬性的敵方目標施放普攻後，布洛妮亞立即進行1次追加攻擊，對該目標造成等同於普攻傷害80%的風屬性傷害，該效果每回合可觸發1次。"
    },
    e5: require("../components/image/characters/bronya/e5.webp"),
    e5_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e6: require("../components/image/characters/bronya/e6.webp"),
    e6_translation: {
      "en-us": "The duration of the DMG Boost effect placed by the Skill on the target ally increases by 1 turn(s).",
      "zh-cn": "战技对指定我方目标造成的伤害提高效果的持续时间增加1回合。",
      "zh-tw": "戰技對指定我方目標造成的傷害提高效果的持續時間增加1回合。"
    },
    basic_attack: require("../components/image/characters/bronya/b.webp"),
    skill: require("../components/image/characters/bronya/s.webp"),
    ultimate: require("../components/image/characters/bronya/u.webp"),
    talent: require("../components/image/characters/bronya/t.webp"),
    technique: require("../components/image/characters/bronya/q.webp"),
    name: "bronya",
    translation:{
      "en-us": "Bronya",
      "zh-cn": "布洛妮娅",
      "zh-tw": "布洛妮婭"
    },
    guide:{
      eidolons: [
        {
          recommended: false,
          value: 50
        }, 
        {
          recommended: false,
          value: 44
        },  
        {
          recommended: false,
          value: 40
        },  
        {
          recommended: false,
          value: 34
        }, 
        {
          recommended: true,
          value: 30
        }, 
        {
          recommended: true,
          value: 20
        }],
      skill:[
        {
          stat: "skill",
          value: "1",
          level: 9
        },
        {
        stat: "ultimate",
        value: "1",
        level: 9
      },
      {
        stat: "talent",
        value: "2",
        level: 9
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[
        {
          translation: {
            "en-us": "Military Might",
            "zh-cn": "军势",
            "zh-tw": "軍勢"
          },
          effect_translation: {
            "en-us": "When Bronya is on the field, all allies deal 10% more DMG.",
            "zh-cn": "布洛妮娅在场时，我方全体造成的伤害提高10%。",
            "zh-tw": "布洛妮亞在場時，我方全體造成的傷害提高10%。"
          },
          image: require("../components/image/characters/bronya/military-might.webp"),
          value: "1"
        },
        {
          translation: {
            "en-us": "Battlefield",
            "zh-cn": "阵地",
            "zh-tw": "陣地"
          },
          effect_translation: {
            "en-us": "At the start of the battle, all allies' DEF increases by 20% for 2 turn(s).",
            "zh-cn": "战斗开始时，我方全体的防御力提高20%，持续2回合。",
            "zh-tw": "戰鬥開始時，我方全體的防禦力提高20%，持續2回合。"
          },
          image: require("../components/image/characters/bronya/battlefield.webp"),
          value: "2"
        },
        {
        translation: {
          "en-us": "Command",
          "zh-cn": "号令",
          "zh-tw": "號令"
        },
        effect_translation: {
          "en-us": "The CRIT Rate for Basic ATK increases to 100%.",
          "zh-cn": "普攻的暴击率提高至100%。",
          "zh-tw": "普攻的暴擊率提高至100%。"
        },
        image: require("../components/image/characters/bronya/command.webp"),
        value: "3"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DBW")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "CD")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "EG")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "H")[0],
        value: "4000"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "134"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "180%"
      }],
      stat:[{
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "114_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "110_0")[0],
          set: 4,
          score: 90,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "310_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "302_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23003")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120,
            },
            {
              rank: "I",
              score: 100,
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21025")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 80
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21018")[0],
          recommended: false,
          score: [
            {
              rank: "I",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1205", "1005", "1102", "1213"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1101"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1211", "1208", "1203", "1105"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
        "Heir to the Supreme Guardian of Belobog, she is the young and capable commander of the Silvermane Guards.",
      "zh-cn": "贝洛伯格「大守护者」继承人，年轻干练的银鬃铁卫统领。",
      "zh-tw": "貝洛伯格「大守護者」繼承人，年輕幹練的銀鬃鐵衛統領。",
    },
    {
      "en-us":
        "Bronya received rigorous education from an early age, and possesses the grace and affinity as expected of an heir.",
      "zh-cn": "布洛妮娅从小接受着严格的教育，具备一名「继承人」所需的优雅举止与亲和力。",
      "zh-tw": "布洛妮婭從小接受著嚴格的教育，具備一名「繼承人」所需的優雅舉止與親和力。",
    },{
      "en-us":
        "However, after witnessing the abysmal conditions in the Underworld, seeds of doubt began growing in Belobog's future leader's mind.",
      "zh-cn": "但在看到下层区的恶劣环境后，未来的最高决策者逐渐生出了疑惑…",
      "zh-tw": "但在看到下層區的惡劣環境後，未來的最高決策者逐漸生出了疑惑…",
    },{
      "en-us":
        "\"Can all the training I've received really help me lead the people to the lives they want?\"",
      "zh-cn": "「我所受的训练，真的能带领人民过上他们想要的生活么？」",
      "zh-tw": "「我所受的訓練，真的能帶領人民過上他們想要的生活麼？」",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 0.2,
        "BE" : 0.5,
        "CR" : 0.2,
        "CD" : 1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW" : 0,
        "EG" : 3.35,
        "EH" : 0.2,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "MTH4": 1,
        "ETL4": 0.9,
        "FA2" : 1,
        "BK2" : 1,
        "CD2" : 1
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/bronya/u.webp"),
      description: {
        "en-us": "(100% + CD) * (100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (HP / 100) * (DEF / 100) * Relic Set",
        "zh-cn": "(100% + 暴伤) * (100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
        "zh-tw": "(100% + 暴傷) * (100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
      },
      calculation: "(100%+(CD)) * (100%+(EG)) * (100%+(ER)) * ((S) / 100) * ((H)/100) * ((D)/100) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Wind",
    pathIcon: harmonyIcon,
    elementIcon: windIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1107",
    image: require("../components/image/characters/clara/clara.webp"),
    background: require("../components/image/characters/clara/clara_full.webp"),
    e1: require("../components/image/characters/clara/e1.webp"),
    e2: require("../components/image/characters/clara/e2.webp"),
    e3: require("../components/image/characters/clara/e3.webp"),
    e4: require("../components/image/characters/clara/e4.webp"),
    e5: require("../components/image/characters/clara/e5.webp"),
    e6: require("../components/image/characters/clara/e6.webp"),
    basic_attack: require("../components/image/characters/clara/b.webp"),
    skill: require("../components/image/characters/clara/s.webp"),
    ultimate: require("../components/image/characters/clara/u.webp"),
    talent: require("../components/image/characters/clara/t.webp"),
    technique: require("../components/image/characters/clara/q.webp"),
    name: "clara",
    translation:{
      "en-us": "Clara",
      "zh-cn": "克拉拉",
      "zh-tw": "克拉拉"
    },
    story:[{
      "en-us":
        "A young girl raised by a robot, her perceptiveness and tenacity are far beyond her years.",
      "zh-cn": "被机器人养大的女孩，有着超越年龄的通透和坚持。",
      "zh-tw": "被機器人養大的女孩，有著超越年齡的通透和堅持。",
    },
    {
      "en-us":
        "For Clara, Svarog's logical calculations are law and infallible.",
      "zh-cn": "对克拉拉而言，史瓦罗理性的计算是世界法则，绝不会出错。",
      "zh-tw": "對克拉拉而言，史瓦羅理性的計算是世界法則，絕不會出錯。",
    },{
      "en-us":
        "That is, until she realized that the results from \"calculations\" don't always necessarily bring joy to everyone.",
      "zh-cn": "直到发现「计算」得到的结果，并不一定能带给大家幸福。",
      "zh-tw": "直到發現「計算」得到的結果，並不一定能帶給大家幸福。",
    },{
      "en-us":
        "The once shy little girl then decided to cast aside her timidness.",
      "zh-cn": "怯生生的女孩决定勇敢起来。",
      "zh-tw": "怯生生的女孩決定勇敢起來。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 1.68,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Talent",
        "zh-cn": "天赋",
        "zh-tw": "天賦"
      },
      icon: require("../components/image/characters/clara/t.webp"),
      description: {
        "en-us": "(80% X ATT) * (100% + Physical Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(80% X 攻击力) * (100% + 物理属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(80% X 攻擊力) * (100% + 物理屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(80% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Physical",
    pathIcon: destructionIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  
  {
    id: "1301",
    image: require("../components/image/characters/gallagher/gallagher.webp"),
    background: require("../components/image/characters/gallagher/gallagher_full.webp"),
    e1: require("../components/image/characters/gallagher/e1.webp"),
    e2: require("../components/image/characters/gallagher/e2.webp"),
    e3: require("../components/image/characters/gallagher/e3.webp"),
    e4: require("../components/image/characters/gallagher/e4.webp"),
    e5: require("../components/image/characters/gallagher/e5.webp"),
    e6: require("../components/image/characters/gallagher/e6.webp"),
    basic_attack: require("../components/image/characters/gallagher/b.webp"),
    skill: require("../components/image/characters/gallagher/s.webp"),
    ultimate: require("../components/image/characters/gallagher/u.webp"),
    talent: require("../components/image/characters/gallagher/t.webp"),
    technique: require("../components/image/characters/gallagher/q.webp"),
    name: "gallagher",
    translation:{
      "en-us": "Gallagher",
      "zh-cn": "加拉赫",
      "zh-tw": "加拉赫"
    },
    story:[{
      "en-us": "A security officer of the Bloodhound Family at Penacony.",
      "zh-cn": "匹诺康尼猎犬家系的治安官，对来访宾客以礼相待，但保有戒心。",
      "zh-tw": "匹諾康尼獵犬家系的治安官，對來訪賓客以禮相待，但保有戒心。",
    },
    {
      "en-us": "He is always courteous toward visiting guests but keeps his vigilance about him. He seems to carry a weight of a complicated past, yet he never voluntarily divulges any details.",
      "zh-cn": "似乎有着诸多往事，却从不主动提及。",
      "zh-tw": "似乎有著諸多往事，卻從不主動提及。",
    },
  ],
  formula:{
    stats:{
      "A" : 0.04,
      "AR" : 0.2,
      "BE" : 0.5,
      "CR" : 0.2,
      "CD" : 0.1,
      "D" : 0.04,
      "DR" : 1.2,
      "DBF" : 0,
      "DBI": 0,
      "DBM": 0,
      "DBP": 0,
      "DBL": 0,
      "DBQ" : 0,
      "DBW" : 0,
      "EG" : 3.35,
      "EH" : 0.2,
      "ER" : 1.5,
      "H" : 0.05,
      "HR" : 1.5,
      "O" : 1.8,
      "S" : 2.5,
    },
    relic_set_stats: {
      "MTH2" : 1,
      "MTH4" : 1,
      "PWC4" : 1,
      "PWC2" : 1,
      "LD2" : 1,
      "FA2" : 1,
      "BK2" : 1,
      "SV2" : 1,
    },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/gallagher/u.webp"),
      description: {
        "en-us": "(100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (100% + Outgoing Healing Boost %) * (HP / 100) * (DEF / 100)  * Relic Set",
        "zh-cn": "(100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (100% + 治疗量加成 %) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
        "zh-tw": "(100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (100% + 治療量加成 %) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
      },
      calculation: "(100%+(EG)) * (100%+(ER)) * ((S) / 100) * (100%+(O)) * ((H)/100) * ((D)/100) * (RS)"
  },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Abundance",
    element: "Fire",
    pathIcon: abundanceIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },

  {
    id: "1302",
    image: require("../components/image/characters/argenti/argenti.webp"),
    background: require("../components/image/characters/argenti/argenti_full.webp"),
    e1: require("../components/image/characters/argenti/e1.webp"),
    e2: require("../components/image/characters/argenti/e2.webp"),
    e3: require("../components/image/characters/argenti/e3.webp"),
    e4: require("../components/image/characters/argenti/e4.webp"),
    e5: require("../components/image/characters/argenti/e5.webp"),
    e6: require("../components/image/characters/argenti/e6.webp"),
    basic_attack: require("../components/image/characters/argenti/b.webp"),
    skill: require("../components/image/characters/argenti/s.webp"),
    ultimate: require("../components/image/characters/argenti/u.webp"),
    talent: require("../components/image/characters/argenti/t.webp"),
    technique: require("../components/image/characters/argenti/q.webp"),
    name: "argenti",
    translation:{
      "en-us": "Argenti",
      "zh-cn": "银枝",
      "zh-tw": "銀枝"
    },
    story:[{
      "en-us":
        "A classic knight of the Knights of Beauty.",
      "zh-cn": "「纯美骑士团」的古典骑士。",
      "zh-tw": "「純美騎士團」的古典騎士。",
    },
    {
      "en-us":
        "Forthright and candid, this noble and admirable man wanders the cosmos alone, resolutely following the Path of Beauty.",
      "zh-cn": "他为人正直、光明磊落，高贵的天性令人敬佩——一位游走宇宙间的独行者，坚定践行「纯美」。",
      "zh-tw": "他為人正直、光明磊落，高貴的天性令人敬佩——一位遊走宇宙間的獨行者，堅定實踐「純美」。",
    },{
      "en-us":
        "Upholding the good name of Beauty is Argenti's duty. To fulfill this responsibility, one must be pious when one initiates battle, and one must make their opponent yield willingly when one strikes with one's spear.",
      "zh-cn": "维护「纯美」在宇宙间的名誉，是银枝的职责：履行这一义务，起手需虔诚，落枪时则将要令人心悦诚服。",
      "zh-tw": "維護「純美」在宇宙間的名譽，是銀枝的職責：履行這項義務，起手需虔誠，落槍時則將要令人心悅誠服。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 1.68,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Talent",
        "zh-cn": "天赋",
        "zh-tw": "天賦"
      },
      icon: require("../components/image/characters/argenti/t.webp"),
      description: {
        "en-us": "(80% X ATT) * (100% + Physical Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(80% X 攻击力) * (100% + 物理属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(80% X 攻擊力) * (100% + 物理屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(80% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Erudition",
    element: "Physical",
    pathIcon: eruditionIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1303",
    image: require("../components/image/characters/ruan-mei/ruan-mei.webp"),
    background: require("../components/image/characters/ruan-mei/ruan-mei_full.webp"),
    e1: require("../components/image/characters/ruan-mei/e1.webp"),
    e2: require("../components/image/characters/ruan-mei/e2.webp"),
    e3: require("../components/image/characters/ruan-mei/e3.webp"),
    e4: require("../components/image/characters/ruan-mei/e4.webp"),
    e5: require("../components/image/characters/ruan-mei/e5.webp"),
    e6: require("../components/image/characters/ruan-mei/e6.webp"),
    basic_attack: require("../components/image/characters/ruan-mei/b.webp"),
    skill: require("../components/image/characters/ruan-mei/s.webp"),
    ultimate: require("../components/image/characters/ruan-mei/u.webp"),
    talent: require("../components/image/characters/ruan-mei/t.webp"),
    technique: require("../components/image/characters/ruan-mei/q.webp"),
    name: "ruan-mei",
    translation:{
      "en-us": "Ruan Mei",
      "zh-cn": "阮•梅",
      "zh-tw": "阮•梅"
    },
    story:[{
      "en-us": "A sweet-tempered and elegant scholar. Member #81 of the Genius Society.",
      "zh-cn": "气质温婉优雅的学者、「天才俱乐部」#81号会员、生命科学领域的专家。",
      "zh-tw": "氣質溫婉優雅的學者、「天才俱樂部」#81號會員、生命科學領域的專家。",
    },
    {
      "en-us": "An expert in the biological sciences. She earned Nous' attention with her talent and terrifying perseverance, and began her research on the origin of life in a secret corner of the universe. For that, she was invited by Herta to collaborate with Screwllum and Stephen to develop the Simulated Universe.",
      "zh-cn": "凭借天赋与惊人的执着得到了博识尊的瞩目，在秘密的角落开始了对生命本源的研究与探索。并因此受到黑塔邀请，同螺丝咕姆、斯蒂芬联合开发了「模拟宇宙」。",
      "zh-tw": "憑藉天賦與驚人的執著得到了博識尊的矚目，在秘密的角落開始了對生命本源的研究與探索。並因此受到黑塔邀請，同螺絲咕姆、斯蒂芬聯合開發了「模擬宇宙」。",
    },{
      "en-us": "Secretly, she greatly enjoys traditional theater and desserts, and she is also very interested in embroidery.",
      "zh-cn": "私底下，她十分喜爱传统戏剧与点心，对刺绣也很感兴趣。",
      "zh-tw": "私底下，她十分喜愛傳統戲劇與點心，對刺繡也很感興趣。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 0.2,
        "BE" : 1,
        "CR" : 0.2,
        "CD" : 0.1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW" : 0,
        "EG" : 3.35,
        "EH" : 0.2,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 1.8,
        "S" : 2.5,
      },
      relic_set_stats: {
        "TSM4": 1,
        "TSM2": 1,
        "MTH2" : 1,
        "PL2" : 1,
        "TKB2" : 1,
        "FA2" : 1,
        "SV2" : 1,
        "BK2" : 1
      },
      name: {
        "en-us": "Talent",
        "zh-cn": "天赋",
        "zh-tw": "天賦"
      },
      icon: require("../components/image/characters/ruan-mei/t.webp"),
      description: {
        "en-us": "(100% + Energy Regeneration Rate %) * (100% + Break Effect %) * (100% + Effect RES %) * (Speed / 100) * (HP / 100) * (DEF / 100) * Relic Set",
        "zh-cn": "(100% + 能量恢复效率 %) * (100% + 击破特攻 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
        "zh-tw": "(100% + 能量恢復效率 %) * (100% + 擊破特攻 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
      },
      calculation: "(100%+(EG)) * (100%+(BE)) * (100%+(ER)) * ((S) / 100) * ((H)/100) * ((D)/100) * (RS)"

    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Ice",
    pathIcon: harmonyIcon,
    elementIcon: iceIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  
  {
    id: "1304",
    image: require("../components/image/characters/aventurine/aventurine.webp"),
    background: require("../components/image/characters/aventurine/aventurine_full.webp"),
    e1: require("../components/image/characters/aventurine/e1.webp"),
    e2: require("../components/image/characters/aventurine/e2.webp"),
    e3: require("../components/image/characters/aventurine/e3.webp"),
    e4: require("../components/image/characters/aventurine/e4.webp"),
    e5: require("../components/image/characters/aventurine/e5.webp"),
    e6: require("../components/image/characters/aventurine/e6.webp"),
    basic_attack: require("../components/image/characters/aventurine/b.webp"),
    skill: require("../components/image/characters/aventurine/s.webp"),
    ultimate: require("../components/image/characters/aventurine/u.webp"),
    talent: require("../components/image/characters/aventurine/t.webp"),
    technique: require("../components/image/characters/aventurine/q.webp"),
    name: "aventurine",
    translation:{
      "en-us": "Aventurine",
      "zh-cn": "砂金",
      "zh-tw": "砂金"
    },
    story:[{
      "en-us": "A high-ranking executive of the IPC's Strategic Investment Department.",
      "zh-cn": "星际和平公司「战略投资部」的高层成员。",
      "zh-tw": "星際和平公司「策略投資部」的高層成員。",
    },
    {
      "en-us": "A risk-taker, his constant smile makes it difficult for people to discern his true feelings.",
      "zh-cn": "风险爱好者，常面带笑容，真心却难以揣测。",
      "zh-tw": "風險愛好者，常面帶笑容，真心卻難以揣測。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 0.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.04,
        "DR" : 1.3,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 1.68,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.2,
        "ER" : 1.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "PDDW4": 1,
        "PDDW2": 0.98,
        "AGD2": 1,
        "KPP4": 0.9,
        "KPP2": 0.9,
        "IS2" : 1,
        "BK2" : 1
      },
      name: {
        "en-us": "Talent",
        "zh-cn": "天赋",
        "zh-tw": "天賦"
      },
      icon: require("../components/image/characters/aventurine/t.webp"),
      description: {
        "en-us": "(100% + Imaginary Boost %) * (100% - CR% + CR% x (100% + CD%)) * (100% + Break Effect % / 8) * (100% + Effect RES % / 4) * (Speed / 100) * (HP / 500) * (DEF / 20) * Relic Set",
        "zh-cn": "(100% + 虚数属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (100% + 击破特攻 % / 8) * (100% + 效果抵抗 % / 4) * (速度/ 100) * (生命值 / 500) * (防御力 / 20) * 遗器套装",
        "zh-tw": "(100% + 虛數屬性傷害提高 %) * (100% - 爆擊% + 暴傷% x (100% + 暴傷%)) * (100% + 擊破特攻 % / 8) * (100% + 效果抵抗 % / 4) * (速度/ 100) * (生命值 / 500) * (防禦力 / 20) * 遺器套裝",
      },
      calculation: "(100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * (100%+(BE)/8) * (100%+(ER)/4) * ((S) / 100) * ((H)/500) * ((D)/20) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Preservation",
    element: "Imaginary",
    pathIcon: preservationIcon,
    elementIcon: imaginaryIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1305",
    image: require("../components/image/characters/dr._ratio/dr._ratio.webp"),
    background: require("../components/image/characters/dr._ratio/dr._ratio_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "suppressing-edict")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-starchaser")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-demon-slayer")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-beast-hunter")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/dr._ratio/e1.webp"),
    e1_translation: {
      "en-us": "The maximum stackable count for the Trace \"Summation\" increases by 4. When a battle begins, immediately obtains 4 stacks of Summation. Needs to unlock Summation first.",
      "zh-cn": "行迹【归纳】的叠加层数上限提高4层。战斗开始时，立即获得4层【归纳】。需要先解锁行迹【归纳】。",
      "zh-tw": "行跡【歸納】的疊加層數上限提高4層。戰鬥開始時，立即獲得4層【歸納】。需要先解鎖行跡【歸納】。"
    },
    e2: require("../components/image/characters/dr._ratio/e2.webp"),
    e2_translation: {
      "en-us": "When his Talent's follow-up attack hits a target, for every debuff the target has, deals Imaginary Additional DMG equal to 20% of Dr. Ratio's ATK. This effect can be triggered for a maximum of 4 time(s) during each follow-up attack.",
      "zh-cn": "天赋的追加攻击击中目标时，目标每有1个负面效果，则额外造成等同于真理医生20%攻击力的虚数属性附加伤害。该效果在每次追加攻击中最多触发4次。",
      "zh-tw": "天賦的追加攻擊命中目標時，目標每有1個負面效果，便額外造成等同於真理醫生20%攻擊力的虛數屬性附加傷害。該效果在每次追加攻擊中最多觸發4次。"
    },
    e3: require("../components/image/characters/dr._ratio/e3.webp"),
    e3_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "终结技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "終結技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/dr._ratio/e4.webp"),
    e4_translation: {
      "en-us": "When triggering the Talent, additionally regenerates 15 Energy for Dr. Ratio.",
      "zh-cn": "触发天赋时，真理医生额外恢复15点能量。",
      "zh-tw": "觸發天賦時，真理醫生額外恢復15點能量。"
    },
    e5: require("../components/image/characters/dr._ratio/e5.webp"),
    e5_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "战技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "戰技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/dr._ratio/e6.webp"),
    e6_translation: {
      "en-us": "Additionally increases the triggerable count for Wiseman's Folly by 1. The DMG dealt by the Talent's follow-up attack increases by 50%.",
      "zh-cn": "【智者的短见】的触发效果额外增加1次，天赋的追加攻击造成的伤害提高50%。",
      "zh-tw": "【智者的短見】的觸發效果額外增加1次，天賦的追加攻擊造成的傷害提高50%。"
    },
    basic_attack: require("../components/image/characters/dr._ratio/b.webp"),
    skill: require("../components/image/characters/dr._ratio/s.webp"),
    ultimate: require("../components/image/characters/dr._ratio/u.webp"),
    talent: require("../components/image/characters/dr._ratio/t.webp"),
    technique: require("../components/image/characters/dr._ratio/q.webp"),
    name: "dr._ratio",
    translation:{
      "en-us": "Dr. Ratio",
      "zh-cn": "真理医生",
      "zh-tw": "真理醫生"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 100
        }, 
        {
          recommended: true,
          value: 64
        },  
        {
          recommended: true,
          value: 56
        },  
        {
          recommended: false,
          value: 28
        }, 
        {
          recommended: true,
          value: 26
        }, 
        {
          recommended: true,
          value: 12
        }],
      skill:[
        {
          stat: "talent",
          value: "1",
          level: 9
        },
        {
          stat: "skill",
          value: "1",
          level: 9
        },
        {
        stat: "ultimate",
        value: "1",
        level: 9
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Summation",
          "zh-cn": "归纳",
          "zh-tw": "歸納"
        },
        effect_translation: {
          "en-us": "When Dr. Ratio uses his Skill, for every debuff on the target, his CRIT Rate increases by 2.5% and CRIT DMG by 5%. This effect can stack up to 6 time(s).",
          "zh-cn": "施放战技时，目标每有1个负面效果，真理医生暴击率提高2.5%、暴击伤害提高5%，该效果最多叠加6层。",
          "zh-tw": "施放戰技時，目標每有1個負面效果，真理醫生暴擊率提高2.5%、暴擊傷害提高5%，該效果最多疊加6層。"
        },
        image: require("../components/image/characters/dr._ratio/summation.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Inference",
          "zh-cn": "演绎",
          "zh-tw": "演繹"
        },
        effect_translation: {
          "en-us": "When Skill is used to attack an enemy target, there is a 100% base chance to reduce the attacked target's Effect RES by 10% for 2 turn(s).",
          "zh-cn": "施放战技攻击敌方目标后，有100%的基础概率使受到攻击的敌方目标效果抵抗降低10%，持续2回合。",
          "zh-tw": "施放戰技攻擊敵方目標後，有100%的基礎機率使受到攻擊的敵方目標效果抗性降低10%，持續2回合。"
        },
        image: require("../components/image/characters/dr._ratio/inference.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Deduction",
          "zh-cn": "推理",
          "zh-tw": "推理"
        },
        effect_translation: {
          "en-us": "When dealing DMG to a target that has 3 or more debuff(s), for each debuff the target has, the DMG dealt by Dr. Ratio to this target increases by 10%, up to a maximum increase of 50%.",
          "zh-cn": "造成伤害时，若目标负面状态大于等于3个，目标每有1个负面效果，真理医生对其造成的伤害提高10%，最多提高50%。",
          "zh-tw": "造成傷害時，若目標負面狀態大於等於3個，目標每有1個負面效果，真理醫生對其造成的傷害提高10%，最多提高50%。"
        },
        image: require("../components/image/characters/dr._ratio/deduction.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "CD")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "DBM")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "A")[0],
        value: "3000"
      },
      {
        stat: property.filter(id => id.id === "CR")[0],
        value: "70%"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "140%"
      }],
      stat:[{
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "BE")[0],
        value: "3"
      },],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "115_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "108_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "112_0")[0],
          set: 4,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "306_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "311_0")[0],
          set: 2,
          score: 90,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23020")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "24001")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 85
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21010")[0],
          recommended: false,
          score: [
            {
              rank: "V",
              score: 80
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1305"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1101", "1202", "1207"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1203"]
            },
          ]
        },
        
        {
          name:{
            "en-us": "Dual Carry",
            "zh-cn": "双C",
            "zh-tw": "雙C",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1305", "1112", "1004"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1303"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1203"]
            },
          ]
        },
      ]
    },
    story:[{
      "en-us": "A candid and self-centered Intelligentsia Guild member, who often conceals his appearance with a strange plaster sculpture.",
      "zh-cn": "直率而自我的博识学会学者，常以奇怪的石膏头雕遮蔽面容。",
      "zh-tw": "直率而自我的博學學會學者，常以奇怪的石膏頭雕遮蔽面容。",
    },
    {
      "en-us": "He demonstrated unparalleled intelligence and talent since his youth, but now refers to himself as a \"Mundanite.\" He firmly believes that intellect and creativity are not confined to geniuses. He seeks to distribute knowledge to the entire universe to cure the persistent disease named ignorance.",
      "zh-cn": "自幼便展露出过人的才智，如今却以「庸人」自居。坚信智慧与创造力并不为天才独有，致力于向全宇宙传播知识，医治名为愚钝的顽疾。",
      "zh-tw": "自幼便展露出過人的才智，如今卻以「庸人」自居。堅信智慧與創造力並非為天才獨有，致力於向全宇宙傳播知識，醫治名為愚鈍的頑疾。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 1.68,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 1,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "AGD2": 1,
        "GBS4": 1,
        "WBD2": 1,
        "WBD4": 1,
        "MWW2": 1,
        "PDC2": 1,
        "IS2" : 1,
        "FF2" : 0.9
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/dr._ratio/u.webp"),
      description: {
        "en-us": "(90% X ATT) * (100% + Imaginary Boost %) * (100% - CR% + CR% x (100% + CD%)) * Relic Set",
        "zh-cn": "(90% X 攻击力) * (100% + 虚数属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * 遗器套装",
        "zh-tw": "(90% X 攻擊力) * (100% + 虛數屬性傷害提高 %) * (100% - 爆擊% + 暴傷% x (100% + 暴傷%)) * 遺器套裝",
      },
      calculation: "(90% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))  * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Imaginary",
    pathIcon: nihilityIcon,
    elementIcon: imaginaryIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1306",
    image: require("../components/image/characters/sparkle/sparkle.webp"),
    background: require("../components/image/characters/sparkle/sparkle_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "dream-flamer")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "shards-of-desires")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "fragments-of-impression")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "tatters-of-thought")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "shards-of-desires")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "fragments-of-impression")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "tatters-of-thought")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "heavenly-melody")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "celestial-section")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "firmament-note")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/sparkle/e1.webp"),
    e1_translation: {
      "en-us": "The Cipher effect granted by the Ultimate lasts for 1 extra turn. All allies with Cipher have their ATK increased by 40%.",
      "zh-cn": "终结技施加的【谜诡】的持续时间额外增加1回合，持有【谜诡】的我方目标攻击力提高40%。",
      "zh-tw": "終結技施加的【謎詭】的持續時間額外增加1回合，持有【謎詭】的我方目標攻擊力提高40%。"
    },
    e2: require("../components/image/characters/sparkle/e2.webp"),
    e2_translation: {
      "en-us": "Every stack of the Talent's effect allows allies to additionally ignore 8% of the target's DEF when dealing DMG.",
      "zh-cn": "天赋每层效果额外使我方目标造成伤害时无视目标8%的防御力。",
      "zh-tw": "天賦每層效果額外使我方目標造成傷害時無視目標8%的防禦力。"
    },
    e3: require("../components/image/characters/sparkle/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/sparkle/e4.webp"),
    e4_translation: {
      "en-us": "The Ultimate recovers 1 more Skill Point. The Talent additionally increases the Max Skill Points by 1.",
      "zh-cn": "终结技可以额外恢复1点战技点，天赋的战技点上限增加效果额外增加1点。",
      "zh-tw": "終結技可以額外恢復1點戰技點，天賦的戰技點上限增加效果額外增加1點。"
    },
    e5: require("../components/image/characters/sparkle/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/sparkle/e6.webp"),
    e6_translation: {
      "en-us": "The CRIT DMG Boost effect provided by the Skill additionally increases by an amount equal to 30% of Sparkle's CRIT DMG. When Sparkle uses Skill, her Skill's CRIT DMG Boost effect will apply to all allies with Cipher. When Sparkle uses her Ultimate, any single ally who benefits from her Skill's CRIT DMG Boost will spread that effect to allies with Cipher.",
      "zh-cn": "战技的暴击伤害提高效果额外提高，提高数值等同于花火暴击伤害的30%，花火施放战技时，使战技的暴击伤害提高效果对持有【谜诡】的队友都会生效，花火施放终结技时，若我方单体中有目标持有战技的暴击伤害提高效果，使该效果扩散至持有【谜诡】的队友。",
      "zh-tw": "戰技的暴擊傷害提高效果額外提高，提高數值等同於花火暴擊傷害的30%。花火施放戰技時，使戰技的暴擊傷害提高效果對持有【謎詭】的隊友都會生效。花火施放終結技時，若我方單體中有目標持有戰技的暴擊傷害提高效果，使該效果擴散至持有【謎詭】的隊友。"
    },
    basic_attack: require("../components/image/characters/sparkle/b.webp"),
    skill: require("../components/image/characters/sparkle/s.webp"),
    ultimate: require("../components/image/characters/sparkle/u.webp"),
    talent: require("../components/image/characters/sparkle/t.webp"),
    technique: require("../components/image/characters/sparkle/q.webp"),
    name: "sparkle",
    translation:{
      "en-us": "Sparkle",
      "zh-cn": "花火",
      "zh-tw": "花火"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 70
        }, 
        {
          recommended: false,
          value: 44
        },  
        {
          recommended: false,
          value: 40
        },  
        {
          recommended: false,
          value: 38
        }, 
        {
          recommended: true,
          value: 34
        }, 
        {
          recommended: true,
          value: 15
        }],
      skill:[
        {
          stat: "talent",
          value: "1",
          level: 9
        },
        {
        stat: "ultimate",
        value: "1",
        level: 9
      },
      {
        stat: "skill",
        value: "1",
        level: 9
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Almanac",
          "zh-cn": "岁时记",
          "zh-tw": "歲時記"
        },
        effect_translation: {
          "en-us": "When using Basic ATK, additionally regenerates 10 Energy.",
          "zh-cn": "施放普攻时额外恢复10点能量。",
          "zh-tw": "發動普攻時額外恢復10點能量。"
        },
        image: require("../components/image/characters/sparkle/almanac.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Artificial Flower",
          "zh-cn": "人造花",
          "zh-tw": "人造花"
        },
        effect_translation: {
          "en-us": "The CRIT DMG Boost effect provided by the Skill will be extended until the start of the target's next turn.",
          "zh-cn": "战技提供的暴击伤害提高效果会延长到目标下一个回合开始。",
          "zh-tw": "戰技提供的暴擊傷害提高效果會延長到目標下一個回合開始。"
        },
        image: require("../components/image/characters/sparkle/artificial-flower.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Nocturne",
          "zh-cn": "夜想曲",
          "zh-tw": "夜想曲"
        },
        effect_translation: {
          "en-us": "Increases all allies' ATK by 15%. When there are 1/2/3 Quantum-Type allies in the team, additionally increases Quantum-Type allies' ATK by 5%/15%/30%.",
          "zh-cn": "我方全体的攻击力提高15%。当我方队伍中存在1名/2名/3名量子属性的角色时，我方量子属性的角色的攻击力额外提高5%/15%/30%。",
          "zh-tw": "我方全體的攻擊力提高15%。當我方隊伍中包含1名/2名/3名量子屬性的角色時，我方量子屬性的角色的攻擊力額外提高5%/15%/30%。"
        },
        image: require("../components/image/characters/sparkle/nocturne.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "2"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "CD")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "EG")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "H")[0],
        value: "4000"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "134"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "180%"
      }],
      stat:[{
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "114_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "106_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "113_0")[0],
          set: 2,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "310_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "312_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23021")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "23003")[0],
          recommended: true,
          score: [
            {
              rank: "I",
              score: 80
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21011")[0],
          recommended: false,
          score: [
            {
              rank: "V",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Mono Quantum",
            "zh-cn": "纯量子",
            "zh-tw": "純量子",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1102"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1006"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1306"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1208"]
            },
          ]
        },
      ]
    },
    story:[{
      "en-us": "A member of the Masked Fools. Inscrutable and unscrupulous. A dangerous maestro of theatrics, utterly engrossed in the art of performance.",
      "zh-cn": "「假面愚者」的成员之一，难以捉摸，不择手段。",
      "zh-tw": "「假面愚者」的成員之一，難以捉摸，不擇手段。",
    },
    {
      "en-us": "Adorned with innumerable masks, she is the hero with a thousand faces.",
      "zh-cn": "危险的戏剧大师，沉迷于扮演，身怀千张假面，能化万种面相。",
      "zh-tw": "危險的戲劇大師，沉迷於扮演，身懷千張假面，能化萬種面相。",
    },
    {
      "en-us": "Wealth, status, power... None of those matters to Sparkle. The only thing that can get her attention is \"amusement.\"",
      "zh-cn": "财富、地位、权利…于花火而言都不重要，能让她出手的，唯有「乐趣」。",
      "zh-tw": "財富、地位、權力…於花火而言都不重要，能讓她出手的，唯有「樂趣」。",
    },
  ],
  formula:{
    stats:{
      "A" : 0.04,
      "AR" : 0.2,
      "BE" : 0.5,
      "CR" : 0.2,
      "CD" : 1,
      "D" : 0.04,
      "DR" : 1.2,
      "DBF" : 0,
      "DBI": 0,
      "DBM": 0,
      "DBP": 0,
      "DBL": 0,
      "DBQ" : 0,
      "DBW" : 0,
      "EG" : 3.35,
      "EH" : 0.2,
      "ER" : 1.5,
      "H" : 0.05,
      "HR" : 1.5,
      "O" : 0,
      "S" : 2.5,
    },
    relic_set_stats: {
      "MTH4": 1,
      "ETL4": 0.9,
      "FA2" : 1,
      "BK2" : 1,
      "CD2" : 1
    },
    name: {
      "en-us": "Ultimate",
      "zh-cn": "终结技",
      "zh-tw": "終結技"
    },
    icon: require("../components/image/characters/sparkle/u.webp"),
    description: {
      "en-us": "(100% + CD) * (100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (HP / 100) * (DEF / 100) * Relic Set",
      "zh-cn": "(100% + 暴伤) * (100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
      "zh-tw": "(100% + 暴傷) * (100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
    },
    calculation: "(100%+(CD)) * (100%+(EG)) * (100%+(ER)) * ((S) / 100) * ((H)/100) * ((D)/100) * (RS)"
  },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Quantum",
    pathIcon: harmonyIcon,
    elementIcon: quantumIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1307",
    image: require("../components/image/characters/black_swan/black_swan.webp"),
    background: require("../components/image/characters/black_swan/black_swan_full.webp"),
    e1: require("../components/image/characters/black_swan/e1.webp"),
    e2: require("../components/image/characters/black_swan/e2.webp"),
    e3: require("../components/image/characters/black_swan/e3.webp"),
    e4: require("../components/image/characters/black_swan/e4.webp"),
    e5: require("../components/image/characters/black_swan/e5.webp"),
    e6: require("../components/image/characters/black_swan/e6.webp"),
    basic_attack: require("../components/image/characters/black_swan/b.webp"),
    skill: require("../components/image/characters/black_swan/s.webp"),
    ultimate: require("../components/image/characters/black_swan/u.webp"),
    talent: require("../components/image/characters/black_swan/t.webp"),
    technique: require("../components/image/characters/black_swan/q.webp"),
    name: "black_swan",
    translation:{
      "en-us": "Black Swan",
      "zh-cn": "黑天鹅",
      "zh-tw": "黑天鵝"
    },
    story:[{
      "en-us": "A Memokeeper of the Garden of Recollection. An indolent and mysterious soothsayer.",
      "zh-cn": "流光忆庭的忆者，慵懒神秘的占卜师。",
      "zh-tw": "流光憶庭的憶者，慵懶神秘的占卜師。",
    },
    {
      "en-us": "\"Remembrance\" of men are hers to heed, threads of fate are hers to tug.",
      "zh-cn": "耐心聆听他人「记忆」，诸般命运尽在掌握。",
      "zh-tw": "耐心聆聽他人「記憶」，諸般命運盡在掌握。",
    },
  ],
    formula:{     
      stats:{
      "A" : 0.13,
      "AR" : 1.5,
      "BE" : 0.5,
      "CR" : 0.5,
      "CD" : 0.25,
      "D" : 0.02,
      "DR" : 0.1,
      "DBF" : 0,
      "DBI": 0,
      "DBM": 0,
      "DBP": 0,
      "DBL": 0,
      "DBQ" : 0,
      "DBW": 1.68,
      "EG" : 3.35,
      "EH" : 1.5,
      "ER" : 0.5,
      "H" : 0.02,
      "HR" : 0.6,
      "O" : 0,
      "S" : 2.5,
    },
    relic_set_stats: {
      "PDC4": 1,
      "PDC2": 1,
      "MWW4": 0.81,
      "MWW2": 0.9,
      "PGCE2" : 1,
      "FF2" : 1,
      "SSS2" : 1
    },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/black_swan/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Wind Boost %) * (100% + Effect Hit Rate % / 8) * (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 风属性伤害提高 %) * (100% + 效果命中 % / 8) * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 風屬性傷害提高 %) * (100% + 效果命中 % / 8) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%+(EH)/8) * ((S) / 100) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Wind",
    pathIcon: nihilityIcon,
    elementIcon: windIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "upcoming"
  },
  
  {
    id: "1308",
    image: require("../components/image/characters/acheron/acheron.webp"),
    background: require("../components/image/characters/acheron/acheron_full.webp"),
    e1: require("../components/image/characters/acheron/e1.webp"),
    e2: require("../components/image/characters/acheron/e2.webp"),
    e3: require("../components/image/characters/acheron/e3.webp"),
    e4: require("../components/image/characters/acheron/e4.webp"),
    e5: require("../components/image/characters/acheron/e5.webp"),
    e6: require("../components/image/characters/acheron/e6.webp"),
    basic_attack: require("../components/image/characters/acheron/b.webp"),
    skill: require("../components/image/characters/acheron/s.webp"),
    ultimate: require("../components/image/characters/acheron/u.webp"),
    talent: require("../components/image/characters/acheron/t.webp"),
    technique: require("../components/image/characters/acheron/q.webp"),
    name: "acheron",
    translation:{
      "en-us": "Acheron",
      "zh-cn": "黄泉",
      "zh-tw": "黃泉"
    },
    story:[{
      "en-us": "A drifter claiming to be a Galaxy Ranger. Her true name is unknown.",
      "zh-cn": "自称「巡海游侠」的旅人，本名不详。",
      "zh-tw": "自稱「巡海遊俠」的旅人，本名不詳。",
    },
    {
      "en-us": "She walks the cosmos alone, carrying with her a long sword.",
      "zh-cn": "身佩一柄长刀，独行银河",
      "zh-tw": "身佩一把長刀，獨行銀河。",
    },
  ],
    formula:{  
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 1.68,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 0,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
    relic_set_stats: {
      "PDDW4": 1,
      "PDDW2": 1,
      "BST4": 0.97,
      "BST2": 0.97,
      "IGT2" : 1,
      "IS2" : 0.97,
    },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/acheron/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Lightning Boost %) * (100% - CR% + CR% x (100% + CD%)) * (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 雷属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 雷屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * ((S) / 100) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Lightning",
    pathIcon: nihilityIcon,
    elementIcon: lightningIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },

  {
    id: "1309",
    image: require("../components/image/characters/robin/robin.webp"),
    background: require("../components/image/characters/robin/robin_full.webp"),
    e1: require("../components/image/characters/robin/e1.webp"),
    e2: require("../components/image/characters/robin/e2.webp"),
    e3: require("../components/image/characters/robin/e3.webp"),
    e4: require("../components/image/characters/robin/e4.webp"),
    e5: require("../components/image/characters/robin/e5.webp"),
    e6: require("../components/image/characters/robin/e6.webp"),
    basic_attack: require("../components/image/characters/robin/b.webp"),
    skill: require("../components/image/characters/robin/s.webp"),
    ultimate: require("../components/image/characters/robin/u.webp"),
    talent: require("../components/image/characters/robin/t.webp"),
    technique: require("../components/image/characters/robin/q.webp"),
    name: "robin",
    translation:{
      "en-us": "Robin",
      "zh-cn": "知更鸟",
      "zh-tw": "知更鳥"
    },
    story:[{
      "en-us": "A Halovian singer who was born in Penacony and has risen to cosmic fame. An elegant and demure young lady.",
      "zh-cn": "出生于匹诺康尼，闻名银河的天环族歌者，举止从容优雅的少女。",
      "zh-tw": "出生於匹諾康尼，聞名銀河的天環族歌者、舉止從容優雅的少女。",
    },
    {
      "en-us": "This time, she has been invited home by The Family to grace everyone with song during the Charmony Festival.",
      "zh-cn": "此次受家族宴请回到故乡，在「谐乐大典」为众宾献歌一曲。",
      "zh-tw": "此次受家族宴請回到故鄉，在「諧樂大典」為眾賓獻歌一曲。",
    },
    {
      "en-us": "She can use the power of \"Harmony\" to broadcast her music, manifesting \"resonance\" among not only her fans but all manner of lifeforms.",
      "zh-cn": "可以依靠「同谐」的力量传递歌声，在歌迷乃至万界生灵之中展现「共鸣」。",
      "zh-tw": "可以依靠「同諧」的力量傳遞歌聲，在歌迷乃至萬界生靈之中展現「共鳴」。",
    },
  ],
    formula:{  
      stats:{
        "A" : 0.13,
        "AR" : 1.5,
        "BE" : 0.5,
        "CR" : 0.5,
        "CD" : 0.25,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 1.68,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 0,
        "S" : 2.5,
      },
    relic_set_stats: {
      "KPP4": 1,
      "GWS2": 1,
      "BA2": 1,
      "FA2" : 0.9,
      "BK2" : 0.9
    },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/robin/u.webp"),
      description: {
        "en-us": "(1% X ATT) * (100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (HP / 200) * (DEF / 200) * Relic Set",
        "zh-cn": "(1% X 攻击力) * (100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 200) * (防御力 / 200) * 遗器套装",
        "zh-tw": "(1% X 攻擊力) * (100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 200) * (防禦力 / 200) * 遺器套裝",
      },
      calculation: "(1% * (A)) * (100%+(EG)) * (100%+(ER)) * ((S) / 100) * ((H)/200) * ((D)/200) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Physical",
    pathIcon: harmonyIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "upcoming"
  },

  {
    id: "1310",
    image: require("../components/image/characters/firefly/firefly.webp"),
    background: require("../components/image/characters/firefly/firefly_full.webp"),
    e1: require("../components/image/characters/firefly/e1.webp"),
    e2: require("../components/image/characters/firefly/e2.webp"),
    e3: require("../components/image/characters/firefly/e3.webp"),
    e4: require("../components/image/characters/firefly/e4.webp"),
    e5: require("../components/image/characters/firefly/e5.webp"),
    e6: require("../components/image/characters/firefly/e6.webp"),
    basic_attack: require("../components/image/characters/firefly/b.webp"),
    skill: require("../components/image/characters/firefly/s.webp"),
    ultimate: require("../components/image/characters/firefly/u.webp"),
    talent: require("../components/image/characters/firefly/t.webp"),
    technique: require("../components/image/characters/firefly/q.webp"),
    name: "firefly",
    translation:{
      "en-us": "Firefly",
      "zh-cn": "流萤",
      "zh-tw": "流螢"
    },
    story:[{
      "en-us": "A member of the Stellaron Hunters, clad in a set of mechanized armor known as \"SAM.\" Her character is marked by unwavering loyalty and steely resolve.",
      "zh-cn": "星核猎手成员，身着机械装甲「萨姆」战斗。忠于任务，性格坚强。",
      "zh-tw": "星核獵手成員，身著機械裝甲「薩姆」戰鬥。忠於任務，性格堅強。",
    },
    {
      "en-us": "Engineered as a weapon against the Swarm, she experiences accelerated growth, but a tragically shortened lifespan.",
      "zh-cn": "作为战胜虫群的兵器而诞生，其生长速度异于常人，但生命非常短暂。",
      "zh-tw": "作為戰勝蟲群的兵器而誕生，其生長速度異於常人，但生命非常短暫。",
    },
    {
      "en-us": "She joined the Stellaron Hunters in a quest for a chance at \"life\", seeking to defy her fated demise.",
      "zh-cn": "为了找寻「生」的机会而加入星核猎手，找寻违抗命运的方式。",
      "zh-tw": "為了找出「生」的機會而加入星核獵手，找出違抗命運的方式。",
    },
  ],
    formula:{  
      stats:{
        "A" : 0.15,
        "AR" : 1.8,
        "BE" : 1.15,
        "CR" : 0.5,
        "CD" : 0.25,
        "D" : 0.03,
        "DR" : 0.8,
        "DBF" : 1.68,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 1.3,
        "H" : 0.04,
        "HR" : 1.2,
        "O" : 0,
        "S" : 2.9,
      },
    relic_set_stats: {
      "ICAS4": 1,
      "ICAS2": 1,
      "WMDM2": 0.9,
      "TSM2": 0.9,
      "TKB2": 0.9,
    },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/firefly/u.webp"),
      description: {
        "en-us": "(10% X ATT) * (100% + Break Effect % X 10) * (100% + Effect RES % / 8) * (Speed / 80) * Relic Set",
        "zh-cn": "(10% X 攻击力) * (100% + 击破特攻 % X 10) * (100% + 效果抵抗 % / 8) * (速度/ 80) * 遗器套装",
        "zh-tw": "(10% X 攻擊力) * (100% + 擊破特攻 % X 10) * (100% + 效果抵抗 % / 8) * (速度/ 80) * 遺器套裝",
      },
      calculation: "(10% * (A)) * (100%+(BE)*10) * (100%+(ER)/8) * ((S) / 80) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Fire",
    pathIcon: destructionIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },


  {
    id: "1312",
    image: require("../components/image/characters/misha/misha.webp"),
    background: require("../components/image/characters/misha/misha_full.webp"),
    e1: require("../components/image/characters/misha/e1.webp"),
    e2: require("../components/image/characters/misha/e2.webp"),
    e3: require("../components/image/characters/misha/e3.webp"),
    e4: require("../components/image/characters/misha/e4.webp"),
    e5: require("../components/image/characters/misha/e5.webp"),
    e6: require("../components/image/characters/misha/e6.webp"),
    basic_attack: require("../components/image/characters/misha/b.webp"),
    skill: require("../components/image/characters/misha/s.webp"),
    ultimate: require("../components/image/characters/misha/u.webp"),
    talent: require("../components/image/characters/misha/t.webp"),
    technique: require("../components/image/characters/misha/q.webp"),
    name: "misha",
    translation:{
      "en-us": "Misha",
      "zh-cn": "米沙",
      "zh-tw": "米沙"
    },
    story:[{
      "en-us": "A well-behaved young man serving as a hotel bellboy in Penacony.",
      "zh-cn": "匹诺康尼酒店的门童侍应，乖巧懂事的小男孩。",
      "zh-tw": "匹諾康尼飯店的門童侍應，乖巧懂事的小男孩。",
    },
    {
      "en-us": "Misha has a great longing for the Nameless and dreams of one day embarking on a journey of his own.",
      "zh-cn": "对无名客抱有极大憧憬，梦想有朝一日自己也能踏上旅途。",
      "zh-tw": "對無名客抱有極大憧憬，夢想有朝一日自己也能踏上旅途。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 1.68,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/misha/u.webp"),
      description: {
        "en-us": "(120% X ATT) * (100% + Ice Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(120% X 攻击力) * (100% + 冰属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(120% X 攻擊力) * (100% + 冰屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(120% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Destruction",
    element: "Ice",
    pathIcon: destructionIcon,
    elementIcon: iceIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  
  {
    id: "1314",
    image: require("../components/image/characters/jade/jade.webp"),
    background: require("../components/image/characters/jade/jade_full.webp"),
    e1: require("../components/image/characters/jade/e1.webp"),
    e2: require("../components/image/characters/jade/e2.webp"),
    e3: require("../components/image/characters/jade/e3.webp"),
    e4: require("../components/image/characters/jade/e4.webp"),
    e5: require("../components/image/characters/jade/e5.webp"),
    e6: require("../components/image/characters/jade/e6.webp"),
    basic_attack: require("../components/image/characters/jade/b.webp"),
    skill: require("../components/image/characters/jade/s.webp"),
    ultimate: require("../components/image/characters/jade/u.webp"),
    talent: require("../components/image/characters/jade/t.webp"),
    technique: require("../components/image/characters/jade/q.webp"),
    name: "jade",
    translation:{
      "en-us": "Jade",
      "zh-cn": "翡翠",
      "zh-tw": "翡翠"
    },
    story:[{
      "en-us": "A senior manager in the IPC Strategic Investment Department and one of the Ten Stonehearts, known for her cornerstone \"Jade of Credit.\"",
      "zh-cn": "星际和平公司「战略投资部」的高级干部，「石心十人」之一，基石为「典贷翡翠」。",
      "zh-tw": "星際和平公司「策略投資部」的高級幹部，「石心十人」之一，基石為「典貸翡翠」。",
    },
    {
      "en-us": "A beautiful and elegant moneylender, she is skilled in deciphering the depths of people's minds, with a personal hobby called \"Bonajade Exchange.\"",
      "zh-cn": "冷艳优雅的放贷人，善于洞悉人心，有着名为「慈玉典押」的个人爱好。",
      "zh-tw": "冷豔優雅的放貸人，善於洞悉人心，有著名為「慈玉典押」的個人愛好。",
    },
    {
      "en-us": "She's willing to wait patiently for high-value acquisitions and adept at extracting value from seemingly destitute clients.",
      "zh-cn": "愿意为获取高价值的事物耐心等待，也擅长从看似一无所有的客户身上榨取价值。",
      "zh-tw": "願意為獲取高價值的事物耐心等待，也擅長從看似一無所有的客戶身上榨取價值。",
    },
  ],
  formula:{
    stats:{
      "A" : 0.04,
      "AR" : 1.2,
      "BE" : 0.5,
      "CR" : 2,
      "CD" : 1,
      "D" : 0.02,
      "DR" : 0.1,
      "DBF" : 0,
      "DBI": 0,
      "DBM": 0,
      "DBP": 0,
      "DBL": 0,
      "DBQ" : 1.68,
      "DBW": 0,
      "EG" : 3.35,
      "EH" : 0.3,
      "ER" : 0.5,
      "H" : 0.02,
      "HR" : 0.6,
      "O" : 0,
      "S" : 2.5,
    },
    name: {
      "en-us": "Ultimate",
      "zh-cn": "终结技",
      "zh-tw": "終結技"
    },
    icon: require("../components/image/characters/jade/u.webp"),
    description: {
      "en-us": "(120% X ATT) * (100% + Quantum Boost %) * (100% - CR% + CR% x (100% + CD%))",
      "zh-cn": "(120% X 攻击力) * (100% + 量子属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
      "zh-tw": "(120% X 攻擊力) * (100% + 量子屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
    },
    calculation: "(120% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
  },
  rarity_number: "five-star",
  rarity: fiveStarsIcon,
  path: "Erudition",
  element: "Quantum",
  color: "#1C29BA",
  pathIcon: eruditionIcon,
  elementIcon: quantumIcon,
  sortModel: [
    {
      field: 'formula',
      sort: 'desc',
    },
  ],
  type: "live"
  },

  {
    id: "1315",
    image: require("../components/image/characters/boothill/boothill.webp"),
    background: require("../components/image/characters/boothill/boothill_full.webp"),
    e1: require("../components/image/characters/boothill/e1.webp"),
    e2: require("../components/image/characters/boothill/e2.webp"),
    e3: require("../components/image/characters/boothill/e3.webp"),
    e4: require("../components/image/characters/boothill/e4.webp"),
    e5: require("../components/image/characters/boothill/e5.webp"),
    e6: require("../components/image/characters/boothill/e6.webp"),
    basic_attack: require("../components/image/characters/boothill/b.webp"),
    skill: require("../components/image/characters/boothill/s.webp"),
    ultimate: require("../components/image/characters/boothill/u.webp"),
    talent: require("../components/image/characters/boothill/t.webp"),
    technique: require("../components/image/characters/boothill/q.webp"),
    name: "boothill",
    translation:{
      "en-us": "Boothill",
      "zh-cn": "波提欧",
      "zh-tw": "波提歐"
    },
    story:[{
      "en-us": "A cyborg cowboy drifting among the stars. Extremely optimistic and unrestrained.",
      "zh-cn": "浪迹银河的改造人牛仔，极度乐观、放荡不羁。",
      "zh-tw": "浪跡銀河的改造人牛仔，極度樂觀、放蕩不羈。",
    },
    {
      "en-us": "He is a member of the Galaxy Rangers who swore to punish the wretched by any and all means...",
      "zh-cn": "身为「巡海游侠」的一员，为惩奸除恶，可以无所不用其极——",
      "zh-tw": "身為「巡海遊俠」的一員，為懲奸除惡，可以無所不用其極——",
    },
    {
      "en-us": "His flamboyant and brash actions were all to draw the attention of the Interastral Peace Corporation — the target of his revenge.",
      "zh-cn": "高调行事的背后，渴望以此引起复仇对象「星际和平公司」的注意。",
      "zh-tw": "高調行事的背後，渴望以此引起復仇對象「星際和平公司」的注意。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 1.68,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/boothill/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Physical Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(100% X 攻击力) * (100% + 物理属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(100% X 攻擊力) * (100% + 物理屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Physical",
    pathIcon: huntIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },

  {
    id: "1002",
    image: require("../components/image/characters/danheng/danheng.webp"),
    background: require("../components/image/characters/danheng/danheng_full.webp"),
    e1: require("../components/image/characters/danheng/e1.webp"),
    e2: require("../components/image/characters/danheng/e2.webp"),
    e3: require("../components/image/characters/danheng/e3.webp"),
    e4: require("../components/image/characters/danheng/e4.webp"),
    e5: require("../components/image/characters/danheng/e5.webp"),
    e6: require("../components/image/characters/danheng/e6.webp"),
    basic_attack: require("../components/image/characters/danheng/b.webp"),
    skill: require("../components/image/characters/danheng/s.webp"),
    ultimate: require("../components/image/characters/danheng/u.webp"),
    talent: require("../components/image/characters/danheng/t.webp"),
    technique: require("../components/image/characters/danheng/q.webp"),
    name: "dan-heng",
    translation:{
      "en-us": "Dan Heng",
      "zh-cn": "丹恒",
      "zh-tw": "丹恆"
    },
    story:[{
      "en-us":
        "A cold and reserved young man who wields a spear known as Cloud-Piercer. ",
      "zh-cn": "清冷寡言的少年，持有名为「击云」的长枪，于列车漫长的开拓之旅中担任护卫。",
      "zh-tw": "清冷寡言的少年，持有名為「擊雲」的長槍，於列車漫長的開拓之旅中擔任護衛。",
    },
    {
      "en-us":
        "He acts as the Express' guard on its long trailblazing expedition. ",
      "zh-cn": "丹恒对自己的过往始终讳莫如深，他与列车同行正是为了摆脱自己曾亲手造就的一切。",
      "zh-tw": "丹恆對自己的過往始終諱莫如深，他與列車同行正是為了擺脫自己曾親手造就的一切。",
    },{
      "en-us":
        "Dan Heng never talks much about his past. In fact, he joined the Express Crew to escape from it. But can the Express really help him outrun his past?",
      "zh-cn": "然而，列车真的会带着他远离「过去」么？",
      "zh-tw": "然而，列車真的會帶著他遠離「過去」麼？",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW" : 1.68,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/danheng/u.webp"),
      description: {
        "en-us": "(240% X ATT) * (100% + Wind Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(240% X 攻击力) * (100% + 风属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(240% X 攻擊力) * (100% + 風屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(240% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Hunt",
    element: "Wind",
    pathIcon: huntIcon,
    elementIcon: windIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1104",
    image: require("../components/image/characters/gepard/gepard.webp"),
    background: require("../components/image/characters/gepard/gepard_full.webp"),
    e1: require("../components/image/characters/gepard/e1.webp"),
    e2: require("../components/image/characters/gepard/e2.webp"),
    e3: require("../components/image/characters/gepard/e3.webp"),
    e4: require("../components/image/characters/gepard/e4.webp"),
    e5: require("../components/image/characters/gepard/e5.webp"),
    e6: require("../components/image/characters/gepard/e6.webp"),
    basic_attack: require("../components/image/characters/gepard/b.webp"),
    skill: require("../components/image/characters/gepard/s.webp"),
    ultimate: require("../components/image/characters/gepard/u.webp"),
    talent: require("../components/image/characters/gepard/t.webp"),
    technique: require("../components/image/characters/gepard/q.webp"),
    name: "gepard",
    translation:{
      "en-us": "Gepard",
      "zh-cn": "杰帕德",
      "zh-tw": "傑帕德"
    },
    story:[{
      "en-us":
        "The honorable and upstanding captain of the Silvermane Guards who bears the noble Landau family name.",
      "zh-cn": "高洁正直的银鬃铁卫戍卫官，出身于血脉高贵的郎道家族。",
      "zh-tw": "高潔正直的銀鬃鐵衛戍衛官，出身於血脈高貴的郎道家族。",
    },
    {
      "en-us":
        "In the frost-whipped city of Belobog, life can still go on in normality...",
      "zh-cn": "在时刻遭受风雪侵凌的贝洛伯格，人们的衣食住行尚能如旧——",
      "zh-tw": "在時刻遭受風雪侵凌的貝洛伯格，人們的衣食住行尚能如舊——",
    },{
      "en-us":
        "This is in no small part thanks to Gepard and his Silvermane Guards who protect the peace of everyday life.",
      "zh-cn": "正是因为杰帕德与他率领的银鬃铁卫在守护这平淡无奇的日常生活。",
      "zh-tw": "正是因為傑帕德與他率領的銀鬃鐵衛在守護這平淡無奇的日常生活。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 0,
        "DBI": 1.68,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/gepard/u.webp"),
      description: {
        "en-us": "30% X DEF",
        "zh-cn": "30% X 防御力",
        "zh-tw": "30% X 防禦力",
      },
      calculation: "30% * (D)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Preservation",
    element: "Ice",
    pathIcon: preservationIcon,
    elementIcon: iceIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1013",
    image: require("../components/image/characters/herta/herta.webp"),
    background: require("../components/image/characters/herta/herta_full.webp"),
    e1: require("../components/image/characters/herta/e1.webp"),
    e2: require("../components/image/characters/herta/e2.webp"),
    e3: require("../components/image/characters/herta/e3.webp"),
    e4: require("../components/image/characters/herta/e4.webp"),
    e5: require("../components/image/characters/herta/e5.webp"),
    e6: require("../components/image/characters/herta/e6.webp"),
    basic_attack: require("../components/image/characters/herta/b.webp"),
    skill: require("../components/image/characters/herta/s.webp"),
    ultimate: require("../components/image/characters/herta/u.webp"),
    talent: require("../components/image/characters/herta/t.webp"),
    technique: require("../components/image/characters/herta/q.webp"),
    name: "herta",
    translation:{
      "en-us": "Herta",
      "zh-cn": "黑塔",
      "zh-tw": "黑塔"
    },
    story:[{
      "en-us":
        "Herta Space Station's true master.",
      "zh-cn": "空间站「黑塔」真正的主人。",
      "zh-tw": "空間站「黑塔」真正的主人。",
    },
    {
      "en-us":
        "As the human with the highest IQ on The Blue, she only does what she's interested in, dropping whatever project immediately the moment she loses interest — the best example being the space station.",
      "zh-cn": "身为湛蓝星智商最高的人类，只做自己感兴趣的事，一旦失去兴趣就立刻走人——空间站就是最好的例子。",
      "zh-tw": "身為湛藍星智商最高的人類，只做自己感興趣的事，一旦失去興趣就立刻走人——空間站就是最好的例子。",
    },{
      "en-us":
        "She typically appears in the form of a remote-controlled puppet.",
      "zh-cn": "平时以远程操纵的人偶形态登场：",
      "zh-tw": "平時以遠程操縱的人偶形態登場：",
    },{
      "en-us":
        "\"It's about seventy percent similar to how I looked as a child.\" — Herta",
      "zh-cn": "“跟我小时候比，勉强七分相似吧。”——黑塔本人。",
      "zh-tw": "“跟我小時候比，勉強七分相似吧。”——黑塔本人。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 1.68,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/herta/u.webp"),
      description: {
        "en-us": "(120% X ATT) * (100% + Ice Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(120% X 攻击力) * (100% + 冰属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(120% X 攻擊力) * (100% + 冰屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(120% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Erudition",
    element: "Ice",
    pathIcon: eruditionIcon,
    elementIcon: iceIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1003",
    image: require("../components/image/characters/himeko/himeko.webp"),
    background: require("../components/image/characters/himeko/himeko_full.webp"),
    e1: require("../components/image/characters/himeko/e1.webp"),
    e2: require("../components/image/characters/himeko/e2.webp"),
    e3: require("../components/image/characters/himeko/e3.webp"),
    e4: require("../components/image/characters/himeko/e4.webp"),
    e5: require("../components/image/characters/himeko/e5.webp"),
    e6: require("../components/image/characters/himeko/e6.webp"),
    basic_attack: require("../components/image/characters/himeko/b.webp"),
    skill: require("../components/image/characters/himeko/s.webp"),
    ultimate: require("../components/image/characters/himeko/u.webp"),
    talent: require("../components/image/characters/himeko/t.webp"),
    technique: require("../components/image/characters/himeko/q.webp"),
    name: "himeko",
    translation:{
      "en-us": "Himeko",
      "zh-cn": "姬子",
      "zh-tw": "姬子"
    },
    story:[{
      "en-us":
        "An adventurous scientist who encountered the Astral Express as a young girl when it got stranded in her home world.",
      "zh-cn": "充满冒险精神的科学家，少女时代在故乡遭遇了搁浅的星穹列车。",
      "zh-tw": "充滿冒險精神的科學家，少女時代在故鄉遭遇了擱淺的星穹列車。",
    },
    {
      "en-us":
        "Years later, Himeko finally repaired the train and began her journey to the stars, but she realized that this was only the beginning. On her trailblazing journey, she would need many more companions...",
      "zh-cn": "若干年后，当姬子终于修复列车驶入群星时，她意识到这只是个开始。在「开拓」新世界的道路上，需要更多的同伴——",
      "zh-tw": "若干年後，當姬子終於修復列車駛入群星時，她意識到這只是個開始。在「開拓」新世界的道路上，需要更多的同伴——",
    },{
      "en-us":
        "And while they may have different destinations, they all gaze at the same starry sky.",
      "zh-cn": "即使同行的人们面朝不同的方向，他们仍处于同一片星空下。",
      "zh-tw": "即使同行的人們面朝不同的方向，他們仍處於同一片星空下。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 1.68,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/himeko/u.webp"),
      description: {
        "en-us": "(138% X ATT) * (100% + Fire Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(138% X 攻击力) * (100% + 火属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(138% X 攻擊力) * (100% + 火屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(138% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Erudition",
    element: "Fire",
    pathIcon: eruditionIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1109",
    image: require("../components/image/characters/hook/hook.webp"),
    background: require("../components/image/characters/hook/hook_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "249K"
      },
      {
        item: items.filter(id => id.id === "endotherm-chitin")[0],
        value: "50"
      },
      {
        item: items.filter(id => id.id === "ancient-engine")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "ancient-spindle")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "ancient-part")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "2.4M"
      },
      {
        item: items.filter(id => id.id === "guardians-lament")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "5"
      },
      {
        item: items.filter(id => id.id === "ancient-engine")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "ancient-spindle")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "ancient-part")[0],
        value: "28"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "105"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "54"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "12"
      },
    ],
    e1: require("../components/image/characters/hook/e1.webp"),
    e1_translation: {
      "en-us": "Enhanced Skill deals 20% increased DMG.",
      "zh-cn": "战技强化后造成的伤害提高20%。",
      "zh-tw": "戰技強化後造成的傷害提高20%。"
    },
    e2: require("../components/image/characters/hook/e2.webp"),
    e2_translation: {
      "en-us": "Extends the duration of Burn caused by Skill by 1 turn(s).",
      "zh-cn": "战技使敌方目标陷入的灼烧状态持续时间增加1回合。",
      "zh-tw": "戰技使敵方目標陷入的灼燒狀態持續時間增加1回合。"
    },
    e3: require("../components/image/characters/hook/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/hook/e4.webp"),
    e4_translation: {
      "en-us": "When Talent is triggered, there is a 100% base chance to Burn enemies adjacent to the target enemy, equivalent to that of Skill.",
      "zh-cn": "触发天赋时，有100%基础概率使指定敌方目标的相邻目标也陷入等同于战技的灼烧状态。",
      "zh-tw": "觸發天賦時，有100%基礎概率使指定敵方目標的相鄰目標也陷入等同於戰技的灼燒狀態。"
    },
    e5: require("../components/image/characters/hook/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15. Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/hook/e6.webp"),
    e6_translation: {
      "en-us": "Hook deals 20% more DMG to enemies afflicted with Burn.",
      "zh-cn": "虎克对灼烧状态下的敌方目标造成的伤害提高20%。",
      "zh-tw": "虎克對灼燒狀態下的敵方目標造成的傷害提高20%。"
    },
    basic_attack: require("../components/image/characters/hook/b.webp"),
    skill: require("../components/image/characters/hook/s.webp"),
    ultimate: require("../components/image/characters/hook/u.webp"),
    talent: require("../components/image/characters/hook/t.webp"),
    technique: require("../components/image/characters/hook/q.webp"),
    name: "hook",
    translation:{
      "en-us": "Hook",
      "zh-cn": "虎克",
      "zh-tw": "虎克"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 70
        }, 
        {
          recommended: false,
          value: 54
        },  
        {
          recommended: true,
          value: 50
        },  
        {
          recommended: false,
          value: 34
        }, 
        {
          recommended: true,
          value: 30
        }, 
        {
          recommended: true,
          value: 20
        }],
      skill:[
        {
          stat: "skill",
          value: "1",
          level: 9
        },
        {
          stat: "talent",
          value: "2",
          level: 9
        },
        {
        stat: "ultimate",
        value: "2",
        level: 9
        },
        {
          stat: "basic_attack",
          value: "3",
          level: 1
        },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Innocence",
          "zh-cn": "童真",
          "zh-tw": "童真"
        },
        effect_translation: {
          "en-us": "Hook restores HP equal to 5% of her Max HP whenever her Talent is triggered.",
          "zh-cn": "触发天赋时，回复等同于虎克生命上限5%的生命值。",
          "zh-tw": "觸發天賦時，回復等同於虎克生命上限5%的生命值。"
        },
        image: require("../components/image/characters/hook/innocence.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Naivete",
          "zh-cn": "无邪",
          "zh-tw": "無邪"
        },
        effect_translation: {
          "en-us": "Increases the chance to resist Crowd Control debuffs by 35%.",
          "zh-cn": "抵抗控制类负面状态的概率提高35%。",
          "zh-tw": "抵抗控制類負面狀態的概率提高35%。"
        },
        image: require("../components/image/characters/hook/naivete.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Playing With Fire",
          "zh-cn": "玩火",
          "zh-tw": "玩火"
        },
        effect_translation: {
          "en-us": "When using her Ultimate, Hook has her action Advanced Forward by 20% and Hook additionally regenerates 5 Energy.",
          "zh-cn": "施放终结技后，虎克的行动提前20%并额外恢复5点能量",
          "zh-tw": "施放終結技後，虎克的行動提前20%並額外恢復5點能量"
        },
        image: require("../components/image/characters/hook/playing-with-fire.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "CR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "DBF")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
      ],
      target_stats: [
        {
          stat: property.filter(id => id.id === "A")[0],
          value: "2600"
        },
        {
        stat: property.filter(id => id.id === "CR")[0],
        value: "70%"
        },
        {
          stat: property.filter(id => id.id === "CD")[0],
          value: "140%"
        },],
      stat:[{
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "107_0")[0],
          set: 4,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "309_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "306_0")[0],
          set: 2,
          score: 95,
        },
        {
          relic: relic.filter(id => id.id === "301_0")[0],
          set: 2,
          score: 95,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "24000")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 100,
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21026")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 80
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21019")[0],
          recommended: false,
          score: [
            {
              rank: "I",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1109"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1202", "1207"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1211", "1208", "1203", "1105"]
            },
          ]
        },
      ]
    },
    story:[{
      "en-us":
        "Head of the adventure squad The Moles, she calls herself Pitch-Dark Hook the Great.",
      "zh-cn": "冒险集团「鼹鼠党」的头头，自称「漆黑的虎克大人」。",
      "zh-tw": "冒險集團「鼴鼠黨」的頭頭，自稱「漆黑的虎克大人」。",
    },
    {
      "en-us":
        "She doesn't like to be called a \"kid\" and believes she can handle things herself without any help from adults.",
      "zh-cn": "不喜欢被人称作小家伙，认为自己不依靠大人也能独挡一面。",
      "zh-tw": "不喜歡被人稱作小傢伙，認為自己不依靠大人也能獨擋一面。",
    },{
      "en-us":
        "Adults adventure into the Fragmentum, Sampo adventures on the surface, and patients take their risky adventure seeking treatment from Natasha...",
      "zh-cn": "大人们去裂界里冒险，桑博先生去地面上冒险，病人们冒险接受娜塔莎的治疗……",
      "zh-tw": "大人們去裂界裡冒險，桑博先生去地面上冒險，病人們冒險接受娜塔莎的治療……",
    },{
      "en-us":
        "Under the leadership of Hook, children can also have their own adventures!",
      "zh-cn": "在虎克的带领下，孩子们也要有自己的冒险！",
      "zh-tw": "在虎克的帶領下，孩子們也要有自己的冒險！",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 1.68,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "MWW4": 1,
        "MWW2": 1,
        "WBD4" : 1,
        "WBD2" : 1,
        "RA2" : 1
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/hook/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Fire Boost %) * (100% - CR% + CR% x (100% + CD%)) * (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 火属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 火屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * ((S) / 100) * (RS)"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Destruction",
    element: "Fire",
    pathIcon: destructionIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1210",
    image: require("../components/image/characters/guinaifen/guinaifen.webp"),
    background: require("../components/image/characters/guinaifen/guinaifen_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "249K"
      },
      {
        item: items.filter(id => id.id === "endotherm-chitin")[0],
        value: "50"
      },
      {
        item: items.filter(id => id.id === "ancient-engine")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "ancient-spindle")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "ancient-part")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "2.4M"
      },
      {
        item: items.filter(id => id.id === "guardians-lament")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "5"
      },
      {
        item: items.filter(id => id.id === "ancient-engine")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "ancient-spindle")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "ancient-part")[0],
        value: "28"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "105"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "54"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "12"
      },
    ],
    e1: require("../components/image/characters/guinaifen/e1.webp"),
    e1_translation: {
      "en-us": "Enhanced Skill deals 20% increased DMG.",
      "zh-cn": "战技强化后造成的伤害提高20%。",
      "zh-tw": "戰技強化後造成的傷害提高20%。"
    },
    e2: require("../components/image/characters/guinaifen/e2.webp"),
    e2_translation: {
      "en-us": "Extends the duration of Burn caused by Skill by 1 turn(s).",
      "zh-cn": "战技使敌方目标陷入的灼烧状态持续时间增加1回合。",
      "zh-tw": "戰技使敵方目標陷入的灼燒狀態持續時間增加1回合。"
    },
    e3: require("../components/image/characters/guinaifen/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/guinaifen/e4.webp"),
    e4_translation: {
      "en-us": "When Talent is triggered, there is a 100% base chance to Burn enemies adjacent to the target enemy, equivalent to that of Skill.",
      "zh-cn": "触发天赋时，有100%基础概率使指定敌方目标的相邻目标也陷入等同于战技的灼烧状态。",
      "zh-tw": "觸發天賦時，有100%基礎概率使指定敵方目標的相鄰目標也陷入等同於戰技的灼燒狀態。"
    },
    e5: require("../components/image/characters/guinaifen/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15. Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/guinaifen/e6.webp"),
    e6_translation: {
      "en-us": "guinaifen deals 20% more DMG to enemies afflicted with Burn.",
      "zh-cn": "虎克对灼烧状态下的敌方目标造成的伤害提高20%。",
      "zh-tw": "虎克對灼燒狀態下的敵方目標造成的傷害提高20%。"
    },
    basic_attack: require("../components/image/characters/guinaifen/b.webp"),
    skill: require("../components/image/characters/guinaifen/s.webp"),
    ultimate: require("../components/image/characters/guinaifen/u.webp"),
    talent: require("../components/image/characters/guinaifen/t.webp"),
    technique: require("../components/image/characters/guinaifen/q.webp"),
    name: "guinaifen",
    translation:{
      "en-us": "Guinaifen",
      "zh-cn": "桂乃芬",
      "zh-tw": "桂乃芬"
    },
    story:[{
      "en-us":
        "An outworlder who ended up residing on the Xianzhou by accident. She is now a passionate and vivacious street performer.",
      "zh-cn": "因机缘巧合留在仙舟的化外民，如今是热情烂漫的街头艺人。",
      "zh-tw": "冒險集團「鼴鼠黨」的頭頭，自稱「漆黑的虎克大人」。",
    },
    {
      "en-us":
        "With her real name being Guinevere, Guinaifen is the Xianzhou name given to her by her good friend Sushang.",
      "zh-cn": "本名「格妮薇儿」，「桂乃芬」是好友素裳为她起的仙舟名。",
      "zh-tw": "本名「格妮薇兒」，「桂乃芬」是好友素裳為她起的仙舟名。",
    },{
      "en-us":
        "Faced with a whole new life on the Loufu and relying on her adoration of Xianzhou culture, Guinaifen quickly learned skills that would keep her clothed and fed — such as slurping noodles in a handstand, smashing slabs without harming the people it was placed upon,catching bullets with bare hands, and so on.",
      "zh-cn": "面对「罗浮」的全新人生，凭着对仙舟文化的热爱，桂乃芬很快学到了安身立命的一技之长——倒立吃面条、胸口碎大石、徒手接子弹等等。",
      "zh-tw": "面對「羅浮」的全新人生，憑著對仙舟文化的熱愛，桂乃芬很快學到了安身立命的一技之長——倒立吃麵條、胸口碎大石、徒手接子彈等等。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 1.68,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "MWW4": 1,
        "MWW2": 1,
        "WBD4" : 1,
        "WBD2" : 1,
        "RA2" : 1
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/guinaifen/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Fire Boost %) * (100% - CR% + CR% x (100% + CD%)) * (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 火属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 火屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * ((S) / 100) * (RS)"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Nihility",
    element: "Fire",
    pathIcon: nihilityIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1212",
    image: require("../components/image/characters/jingliu/jingliu.webp"),
    background: require("../components/image/characters/jingliu/jingliu_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "gelid-chitin")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "regret-of-infinite-ochema")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/jingliu/e1.webp"),
    e1_translation: {
      "en-us": "When using her Ultimate or Enhanced Skill, Jingliu's CRIT DMG increases by 24% for 1 turn(s). If only one enemy target is attacked, the DMG originally intended for adjacent targets will also be calculated against the current target, dealing Ice DMG equal to 100% of the DMG the adjacent targets would have received.",
      "zh-cn": "施放终结技或强化战技时，镜流的暴击伤害提高24%，持续1回合。若只攻击了1个敌方目标，则额外对该目标造成1次等同于镜流100%攻击力的冰属性伤害。",
      "zh-tw": "施放終結技或強化戰技時，鏡流的爆擊傷害提高24%，持續1回合。若只攻擊了1個敵方目標，則額外對該目標造成1次等同於鏡流100%攻擊力的冰屬性傷害。"
    },
    e2: require("../components/image/characters/jingliu/e2.webp"),
    e2_translation: {
      "en-us": "After using Ultimate, increases the DMG of next Enhanced Skill by 80%.",
      "zh-cn": "施放终结技后，下一次强化战技的伤害提高80%。",
      "zh-tw": "施放終結技後，下一次強化戰技的傷害提高80%。"
    },
    e3: require("../components/image/characters/jingliu/e3.webp"),
    e3_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e4: require("../components/image/characters/jingliu/e4.webp"),
    e4_translation: {
      "en-us": "While in the Spectral Transmigration state, the ATK obtained from consuming allies' HP is additionally increased by 90% of the total HP that has been consumed from the entire team. The cap for ATK increased in this way increases by 30%.",
      "zh-cn": "【转魄】状态下消耗队友生命值获得的攻击力额外提高，提高数值等同于我方全体生命值消耗总量的90%，获得的攻击力上限提高30%。",
      "zh-tw": "【轉魄】狀態下消耗隊友生命值獲得的攻擊力額外提高，提高數值等同於我方全體生命值消耗總量的90%，獲得的攻擊力上限提高30%。"
    },
    e5: require("../components/image/characters/jingliu/e5.webp"),
    e5_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e6: require("../components/image/characters/jingliu/e6.webp"),
    e6_translation: {
      "en-us": "When Jingliu enters the Spectral Transmigration state, obtain 1 stack(s) of Syzygy. While she is in the Spectral Transmigration state, her CRIT DMG increases by 50%.",
      "zh-cn": "镜流进入【转魄】状态时，【朔望】的上限层数提高1层并额外获得1层【朔望】。【转魄】状态下，暴击伤害提高50%。",
      "zh-tw": "鏡流進入【轉魄】狀態時，【朔望】的上限層數提高1層並額外獲得1層【朔望】。【轉魄】狀態下，爆擊傷害提高50%。"
    },
    basic_attack: require("../components/image/characters/jingliu/b.webp"),
    skill: require("../components/image/characters/jingliu/s.webp"),
    ultimate: require("../components/image/characters/jingliu/u.webp"),
    talent: require("../components/image/characters/jingliu/t.webp"),
    technique: require("../components/image/characters/jingliu/q.webp"),
    name: "jingliu",
    translation:{
      "en-us": "Jingliu",
      "zh-cn": "镜流",
      "zh-tw": "鏡流"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 80
        }, 
        {
          recommended: false,
          value: 60
        },  
        {
          recommended: true,
          value: 56
        },  
        {
          recommended: false,
          value: 42
        }, 
        {
          recommended: true,
          value: 38
        }, 
        {
          recommended: true,
          value: 24
        }],
      skill:[
        {
          stat: "skill",
          value: "1",
          level: 9
        },
        {
          stat: "ultimate",
          value: "1",
          level: 9
        },
        {
          stat: "talent",
          value: "2",
          level: 9
        },
        {
          stat: "basic_attack",
          value: "3",
          level: 1
        },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Deathrealm",
          "zh-cn": "死境",
          "zh-tw": "死境"
        },
        effect_translation: {
          "en-us": "While in the Spectral Transmigration state, increases Effect RES by 35%.",
          "zh-cn": "【转魄】状态下，效果抵抗提高35%。",
          "zh-tw": "【轉魄】狀態下，效果抗性提高35%。"
        },
        image: require("../components/image/characters/jingliu/deathrealm.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Sword Champion",
          "zh-cn": "剑首",
          "zh-tw": "劍首"
        },
        effect_translation: {
          "en-us": "After using Transcendent Flash, the next action will be Advanced Forward by 10%.",
          "zh-cn": "施放【无罅飞光】后，下次行动提前10%。",
          "zh-tw": "施放【無罅飛光】後，下次行動提前10%。"
        },
        image: require("../components/image/characters/jingliu/sword-champion.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Frost Wraith",
          "zh-cn": "霜魄",
          "zh-tw": "霜魄"
        },
        effect_translation: {
          "en-us": "While in the Spectral Transmigration state, increases Ultimate DMG by 20%.",
          "zh-cn": "【转魄】状态下，终结技造成的伤害提高20%。",
          "zh-tw": "【转魄】状态下，终结技造成的伤害提高20%。"
        },
        image: require("../components/image/characters/jingliu/frost-wraith.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "2"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "CD")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "DBI")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "CR")[0],
        value: "40%"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "200%"
      },
      {
        stat: property.filter(id => id.id === "A")[0],
        value: "3000"
      }],
      stat:[
        {
          stat: property.filter(id => id.id === "CD")[0],
          value: "1"
        },
        {
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "BE")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "104_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "108_0")[0],
          set: 4,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "309_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "301_0")[0],
          set: 2,
          score: 90,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23014")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "24000")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 80
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21012")[0],
          recommended: false,
          score: [
            {
              rank: "V",
              score: 84
            },
            {
              rank: "I",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1212"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1101", "1106",  "1202"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1208", "1203"]
            },
          ]
        },
        {
          name:{
            "en-us": "Dual Carry",
            "zh-cn": "双C",
            "zh-tw": "雙C",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1212", "1205"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1101", "1106"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1208", "1203", "1110"]
            },
          ]
        },
        {
          name:{
            "en-us": "Quantum Team",
            "zh-cn": "量子队",
            "zh-tw": "量子隊",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1212"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1006"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1101", "1106",  "1202"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1208", "1203", "1110"]
            },
          ]
        },
      ]
    },
    story:[{
      "en-us": "One of the legendary heroes making up the High-Cloud Quintet, and bestowed with the title \"Transcendent Flash.\"",
      "zh-cn": "傳奇「雲上五驍」之一，人送尊號「無罅飛光」。",
      "zh-tw": "傳奇「雲上五驍」之一，人送尊號「無罅飛光」。",
    },
    {
      "en-us":
        "Having ascended beyond mortal concepts of victory, she chose to walk a different path to obtain the power capable of slaying gods.",
      "zh-cn": "超脫了人間的勝負，為了獲得斬殺「神」的力量，她選擇走上截然不同的道路。",
      "zh-tw": "超脫了人間的勝負，為了獲得斬殺「神」的力量，她選擇走上截然不同的道路。",
    },{
      "en-us":
        "Thereafter, one Sword Champion of the Luofu was removed from the Xianzhou's records, and one traitor erased from the annuals was added.",
      "zh-cn": "至此之後，仙舟的紀錄中少了一個羅浮「劍首」，多了一個名字被抹去的「叛徒」。",
      "zh-tw": "至此之後，仙舟的紀錄中少了一個羅浮「劍首」，多了一個名字被抹去的「叛徒」。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 1.68,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "GBS4": 1,
        "HGF4": 1,
        "SSS2" : 0.9,
        "RA2" : 1
      },
      name: {
        "en-us": "Talent",
        "zh-cn": "天赋",
        "zh-tw": "天賦"
      },
      icon: require("../components/image/characters/jingliu/t.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Ice Boost %) * (100% + CD% / 5) * (100% - CR% + CR% x (100% + CD%)) * (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 冰属性伤害提高 %) * (100% + 暴伤% / 5) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 冰屬性傷害提高 %) * (100% + 暴傷% / 5) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%+(CD)/5) * (100%-(CR) + (CR)*(100%+(CD))) * ((S) / 100) * (RS) + (50%-(CR)) * 2000"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Ice",
    pathIcon: destructionIcon,
    elementIcon: iceIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1204",
    image: require("../components/image/characters/jing-yuan/jing-yuan.webp"),
    background: require("../components/image/characters/jing-yuan/jing-yuan_full.png"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "shape-shifters-lightning-staff")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "key-of-wisdom")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "key-of-knowledge")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "key-of-inspiration")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/jing-yuan/e1.webp"),
    e1_translation: {
      "en-us": "When Lightning-Lord attacks, the DMG multiplier on enemies adjacent to the target enemy increases by an extra amount equal to 25% of the DMG multiplier against the target enemy.",
      "zh-cn": "【神君】施放攻击时，对指定敌方单体的相邻目标造成的伤害倍率额外提高，提高数值等同于对主目标伤害倍率的25%。",
      "zh-tw": "【神君】施放攻擊時，對指定敵方單體的相鄰目標造成的傷害倍率額外提高，提高數值等同於對主目標傷害倍率的25%。"
    },
    e2: require("../components/image/characters/jing-yuan/e2.webp"),
    e2_translation: {
      "en-us": "After Lightning-Lord takes action, DMG caused by Jing Yuan's Basic ATK, Skill, and Ultimate increases by 20% for 2 turn(s).",
      "zh-cn": "【神君】行动后，景元的普攻、战技、终结技造成的伤害提高20%，持续2回合。",
      "zh-tw": "【神君】行動後，景元的普攻、戰技、終結技造成的傷害提高20%，持續2回合。"
    },
    e3: require("../components/image/characters/jing-yuan/e3.webp"),
    e3_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "终结技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "終結技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/jing-yuan/e4.webp"),
    e4_translation: {
      "en-us": "For each hit performed by the Lightning-Lord when it takes action, Jing Yuan regenerates 2 Energy.",
      "zh-cn": "【神君】每段攻击后，景元恢复2点能量。",
      "zh-tw": "【神君】每段攻擊後，景元恢復2點能量。"
    },
    e5: require("../components/image/characters/jing-yuan/e5.webp"),
    e5_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "战技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "戰技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/jing-yuan/e6.webp"),
    e6_translation: {
      "en-us": "Each hit performed by the Lightning-Lord when it takes action will make the target enemy Vulnerable. While Vulnerable, enemies receive 12% more DMG until the end of the Lightning-Lord's current turn, stacking up to 3 time(s).",
      "zh-cn": "【神君】每段攻击后会使指定敌方目标额外陷入易伤状态。易伤状态下的敌方目标受到的伤害提高12%，持续至【神君】本次攻击结束，该效果最多叠加3层。",
      "zh-tw": "【神君】每段攻擊後會使指定敵方目標額外陷入易傷狀態。易傷狀態下的敵方目標受到的傷害提高12%，持續至【神君】本次攻擊結束，該效果最多疊加3層。"
    },
    basic_attack: require("../components/image/characters/jing-yuan/b.webp"),
    skill: require("../components/image/characters/jing-yuan/s.webp"),
    ultimate: require("../components/image/characters/jing-yuan/u.webp"),
    talent: require("../components/image/characters/jing-yuan/t.webp"),
    technique: require("../components/image/characters/jing-yuan/q.webp"),
    name: "jing-yuan",
    translation:{
      "en-us": "Jing Yuan",
      "zh-cn": "景元",
      "zh-tw": "景元"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 80
        }, 
        {
          recommended: false,
          value: 50
        },  
        {
          recommended: false,
          value: 46
        },  
        {
          recommended: false,
          value: 34
        }, 
        {
          recommended: false,
          value: 30
        }, 
        {
          recommended: true,
          value: 24
        }],
      skill:[
        {
          stat: "talent",
          value: "1",
          level: 9
        },
        {
        stat: "ultimate",
        value: "2",
        level: 9
      },
      {
        stat: "skill",
        value: "2",
        level: 9
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Battalia Crush",
          "zh-cn": "破阵",
          "zh-tw": "破陣"
        },
        effect_translation: {
          "en-us": "If the Lightning-Lord's Hits Per Action is greater or equal to 6 in the next turn, its CRIT DMG increases by 25% for the next turn.At the start of the battle, immediately regenerates 15 Energy.",
          "zh-cn": "若【神君】下回合的攻击段数大于等于6段，则其下回合的暴击伤害提高25%。",
          "zh-tw": "若【神君】下回合的攻擊段數大於等於6段，則其下回合的暴擊傷害提高25%。"
        },
        image: require("../components/image/characters/jing-yuan/battalia-crush.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Savant Providence",
          "zh-cn": "绸缪",
          "zh-tw": "綢繆"
        },
        effect_translation: {
          "en-us": "At the start of the battle, immediately regenerates 15 Energy.",
          "zh-cn": "战斗开始时，立即恢复15点能量。",
          "zh-tw": "戰鬥開始時，立即恢復15點能量。"
        },
        image: require("../components/image/characters/jing-yuan/savant-providence.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "War Marshal",
          "zh-cn": "遣将",
          "zh-tw": "遣將"
        },
        effect_translation: {
          "en-us": "After the Skill is used, the CRIT Rate increases by 10% for 2 turn(s).",
          "zh-cn": "施放战技后，暴击率提升10%，持续2回合。",
          "zh-tw": "施放戰技後，暴擊率提升10%，持續2回合。"
        },
        image: require("../components/image/characters/jing-yuan/war-marshal.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "CR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "DBL")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "CR")[0],
        value: "70%"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "140%"
      },
      {
        stat: property.filter(id => id.id === "A")[0],
        value: "2600"
      }],
      stat:[{
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "BE")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "109_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "115_0")[0],
          set: 4,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "306_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "301_0")[0],
          set: 2,
          score: 90,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23010")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "23000")[0],
          recommended: false,
          score: [
            {
              rank: "I",
              score: 70
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21027")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1204"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1101", "1202", "1207"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1211", "1208", "1203", "1105"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
        "Although his appearance may be one of indolence, the Cloud Knight general who commands the Xianzhou Luofu is more meticulous than seemingly meets the eye.",
      "zh-cn": "位列帝弓七天将之一的「神策将军」，外表懒散、心思缜密。",
      "zh-tw": "位列帝弓七天將之一的「神策將軍」，外表懶散、心思縝密。",
    },
    {
      "en-us":
        "He does not consider saving a situation from the brink of disaster to be a show of wisdom, and is thus fastidious with routine affairs to avoid any potential problems.",
      "zh-cn": "不以危局中力挽狂澜为智策，因此在常事上十分下功夫，以免节外生枝。",
      "zh-tw": "不以危局中力挽狂瀾為智策，因此在常事上十分下功夫，以免節外生枝。",
    },{
      "en-us":
        "Due to his careful management, the Xianzhou have enjoyed many years of peace, with Jing Yuan's seemingly lazy demeanor having earned him the moniker of \"Dozing General.\"",
      "zh-cn": "因其细心谋划，仙舟承平日久，看似行事慵懒的景元反被送上绰号「闭目将军」。",
      "zh-tw": "因其細心謀劃，仙舟承平日久，看似行事慵懶的景元反被送上綽號「閉目將軍」。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 1.68,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "BST4": 1,
        "BST2": 1,
        "AGD4": 1,
        "AGD2": 1,
        "MWW4": 0.81,
        "MWW2": 0.9,
        "SSS2" : 1,
        "IS2" : 0.95
      },
      name: {
        "en-us": "Talent",
        "zh-cn": "天赋",
        "zh-tw": "天賦"
      },
      icon: require("../components/image/characters/jing-yuan/t.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Lightning Boost %) * (100% - CR% + CR% x (100% + CD%)) * (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 雷属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 雷屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * ((S) / 100) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Erudition",
    element: "Lightning",
    pathIcon: eruditionIcon,
    elementIcon: lightningIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1005",
    image: require("../components/image/characters/kafka/kafka.webp"),
    background: require("../components/image/characters/kafka/kafka_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "shape-shifters-lightning-staff")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "regret-of-infinite-ochema")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/kafka/e1.webp"),
    e1_translation: {
      "en-us": "When the Talent triggers a follow-up attack, there is a 100% base chance to increase the DoT received by the target by 30% for 2 turn(s).",
      "zh-cn": "天赋触发追加攻击时，有100%的基础概率使目标受到的持续伤害提高30%，持续2回合。",
      "zh-tw": "天賦觸發追加攻擊時，有100%的基礎概率使目標受到的持續傷害提高30%，持續2回合。"
    },
    e2: require("../components/image/characters/kafka/e2.webp"),
    e2_translation: {
      "en-us": "While Kafka is on the field, DoT dealt by all allies increases by 25%.",
      "zh-cn": "卡芙卡在场时，我方全体造成的持续伤害提高25%。",
      "zh-tw": "卡芙卡在場時，我方全體造成的持續傷害提高25%。"
    },
    e3: require("../components/image/characters/kafka/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/kafka/e4.webp"),
    e4_translation: {
      "en-us": "When an enemy target takes DMG from the Shock status inflicted by Kafka, Kafka additionally regenerates 2 Energy.",
      "zh-cn": "当敌方目标承受的由卡芙卡造成的触电状态产生伤害时，额外为卡芙卡恢复2点能量。",
      "zh-tw": "當敵方目標承受的由卡芙卡造成的觸電狀態產生傷害時，額外為卡芙卡恢復2點能量。"
    },
    e5: require("../components/image/characters/kafka/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/kafka/e6.webp"),
    e6_translation: {
      "en-us": "The Shock inflicted on the enemy target by the Ultimate, the Technique, or the Talent-triggered follow-up attack has a DMG multiplier increase of 156% and lasts 1 turn(s) longer.",
      "zh-cn": "终结技、秘技与天赋触发的追加攻击使敌方目标陷入的触电状态的伤害倍率提高156%，并使触电状态的持续时间增加1回合。",
      "zh-tw": "終結技、秘技與天賦觸發的追加攻擊使敵方目標陷入的觸電狀態的傷害倍率提高156%，並使觸電狀態的持續時間增加1回合。"
    },
    basic_attack: require("../components/image/characters/kafka/b.webp"),
    skill: require("../components/image/characters/kafka/s.webp"),
    ultimate: require("../components/image/characters/kafka/u.webp"),
    talent: require("../components/image/characters/kafka/t.webp"),
    technique: require("../components/image/characters/kafka/q.webp"),
    name: "kafka",
    translation:{
      "en-us": "Kafka",
      "zh-cn": "卡芙卡",
      "zh-tw": "卡芙卡"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 60
        }, 
        {
          recommended: false,
          value: 44
        },  
        {
          recommended: false,
          value: 34
        },  
        {
          recommended: false,
          value: 30
        }, 
        {
          recommended: true,
          value: 26
        }, 
        {
          recommended: false,
          value: 16
        }],
      skill:[{
        stat: "ultimate",
        value: "1",
        level: 9
      },
      {
        stat: "skill",
        value: "1",
        level: 9
      },
      {
        stat: "talent",
        value: "2",
        level: 6
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Torture",
          "zh-cn": "折磨",
          "zh-tw": "折磨"
        },
        effect_translation: {
          "en-us": "When DoTs immediately deal DMG to enemies due to Kafka's Skill or Ultimate, their DMG multiplier is proportionally increased. The increased proportion is equal to 24% of Kafka's Break Effect, up to a max of 24%.",
          "zh-cn": "施放战技和终结技使处于持续伤害类负面状态的敌方目标立即产生持续伤害时，伤害倍率按比例提高，提高的比例等同于卡芙卡击破特攻的24%，最高不超过24%。",
          "zh-tw": "施放戰技和終結技使處於持續傷害類負面狀態的敵方目標立即產生持續傷害時，傷害倍率按比例提高，提高的比例等同於卡芙卡擊破特攻的24%，最高不超過24%。"
        },
        image: require("../components/image/characters/kafka/torture.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Plunder",
          "zh-cn": "掠夺",
          "zh-tw": "掠奪"
        },
        effect_translation: {
          "en-us": "If an enemy is defeated while Shocked, Kafka additionally regenerates 5 Energy.",
          "zh-cn": "触电状态下的敌方目标被消灭时，卡芙卡额外恢复5点能量。",
          "zh-tw": "觸電狀態下的敵方目標被消滅時，卡芙卡額外恢復5點能量。"
        },
        image: require("../components/image/characters/kafka/plunder.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Thorns",
          "zh-cn": "荆棘",
          "zh-tw": "荊棘"
        },
        effect_translation: {
          "en-us": "Talent has a 20% increased base chance to increase the DoT that the enemies receive. Ultimate has a 20% increased base chance to Shock enemies.",
          "zh-cn": "天赋使敌方目标受到的持续伤害提高的基础概率提高20%，终结技使敌方目标陷入触电状态的基础概率提高20%。",
          "zh-tw": "天賦使敵方目標受到的持續傷害提高的基礎概率提高20%，終結技使敵方目標陷入觸電狀態的基礎概率提高20%。"
        },
        image: require("../components/image/characters/kafka/thorns.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "AR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "EH")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "DBL")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "A")[0],
        value: "3800"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "134"
      },
      {
        stat: property.filter(id => id.id === "EH")[0],
        value: "30%"
      }],
      stat:[{
        stat: property.filter(id => id.id === "AR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "A")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "BE")[0],
        value: "3"
      },
      {
        stat: property.filter(id => id.id === "EH")[0],
        value: "3"
      },],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "109_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "102_0")[0],
          set: 2,
          score: 90,
        },
        {
          relic: relic.filter(id => id.id === "114_0")[0],
          set: 2,
          score: 90,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "301_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23006")[0],
          recommended: false,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "24003")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 90
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21001")[0],
          score: [
            {
              rank: "V",
              score: 96
            },
            {
              rank: "I",
              score: 80
            }
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Double DoT",
            "zh-cn": "双 DoT",
            "zh-tw": "雙 DoT",
          },
          team:[
            {
              role:{
              "en-us": "DoT",
              "zh-cn": "DoT",
              "zh-tw": "DoT",
              },
              id: ["1005"]
            },
            {
              role:{
              "en-us": "DoT",
              "zh-cn": "DoT",
              "zh-tw": "DoT",
              },
              id: ["1108", "1111"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1202", "1006", "1106"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1105", "1211", "1203"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
        "On the Interastral Peace Corporation's wanted list, Kafka's entry only has two things — her name, and a single sentence: \"Likes collecting coats.\"",
      "zh-cn": "在星际和平公司的通缉档案里，卡芙卡只留下了名字和「爱好收集大衣」的纪录。",
      "zh-tw": "在星際和平公司的通緝檔案裡，卡芙卡只留下了名字和「愛好收集大衣」的紀錄。",
    },{
      "en-us":
        "Little is known about this Stellaron Hunter, other than that she is one of Destiny's Slave Elio's most trusted members.",
        "zh-cn": "人们对这位星核猎手所知甚少，只知道她是「命运的奴隶」艾利欧最信任的成员之一。",
        "zh-tw": "人們對這位星核獵手所知甚少，只知道她是「命運的奴隸」艾利歐最信任的成員之一。",
    },
    {
      "en-us":
        "In order to achieve Elio's envisioned future, Kafka gets to work.",
      "zh-cn": "为了到达艾利欧预见的「未来」，卡芙卡开始行动。",
      "zh-tw": "為了到達艾利歐預見的「未來」，卡芙卡開始行動。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.13,
        "AR" : 1.5,
        "BE" : 0.5,
        "CR" : 0.5,
        "CD" : 0.25,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 1.68,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 1,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "BST4": 1,
        "BST2": 1,
        "MWW4": 0.81,
        "MWW2": 0.9,
        "MTH4" : 0.81,
        "MTH2" : 0.9,
        "SSS2" : 1
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/kafka/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Lightning Boost %) * (100% + Break Effect % / 8) * (100% + Effect Hit Rate % / 8) * (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 雷属性伤害提高 %) * (100% + 击破特攻 % / 8) * (100% + 效果命中 % / 8) * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 雷屬性傷害提高 %) * (100% + 擊破特攻 % / 8) * (100% + 效果命中 % / 8) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%+(BE)/8) * (100%+(EH)/8) * ((S) / 100) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Lightning",
    pathIcon: nihilityIcon,
    elementIcon: lightningIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "upcoming"
  },
  {
    id: "1111",
    image: require("../components/image/characters/luka/luka.webp"),
    background: require("../components/image/characters/luka/luka_full.webp"),
    e1: require("../components/image/characters/luka/e1.webp"),
    e2: require("../components/image/characters/luka/e2.webp"),
    e3: require("../components/image/characters/luka/e3.webp"),
    e4: require("../components/image/characters/luka/e4.webp"),
    e5: require("../components/image/characters/luka/e5.webp"),
    e6: require("../components/image/characters/luka/e6.webp"),
    basic_attack: require("../components/image/characters/luka/b.webp"),
    skill: require("../components/image/characters/luka/s.webp"),
    ultimate: require("../components/image/characters/luka/u.webp"),
    talent: require("../components/image/characters/luka/t.webp"),
    technique: require("../components/image/characters/luka/q.webp"),
    name: "luka",
    translation:{
      "en-us": "Luka",
      "zh-cn": "卢卡",
      "zh-tw": "盧卡"
    },
    story:[{
      "en-us":
        "An optimistic and carefree fighter with a mechanical arm. Skilled in mixed martial arts and is a Wildfire member.",
      "zh-cn": "乐观阳光、不拘小节的机械臂自由格斗家，「地火」成员之一。",
      "zh-tw": "樂觀陽光、不拘小節的機械臂自由格鬥家，「地火」成員之一。",
    },
    {
      "en-us":
        "From the fight cage to the battlefield and from a fighter to a warrior, Luka uses his strength to protect the people of the Underworld.",
      "zh-cn": "从拳台到战场，从拳击手到战士，卢卡用这份力量去守护下层区的人们。",
      "zh-tw": "從拳台到戰場，從拳擊手到戰士，盧卡用這份力量去守護下層區的人們。",
    },
    {
      "en-us":
        "He wishes to bring hope to others precisely because he had experienced despair himself.",
      "zh-cn": "正因为自己曾经体会过绝望，所以他更渴望将希望带给其他人。",
      "zh-tw": "正因為自己曾經體會過絕望，所以他更渴望將希望帶給其他人。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.13,
        "AR" : 1.5,
        "BE" : 0.5,
        "CR" : 0.5,
        "CD" : 0.25,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 1.68,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 1,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/luka/u.webp"),
      description: {
        "en-us": "(198% X ATT) * (100% + Physical Boost %)",
        "zh-cn": "(198% X 攻击力) * (100% + 物理属性伤害提高 %)",
        "zh-tw": "(198% X 攻擊力) * (100% + 物理屬性傷害提高 %)",
      },
      calculation: "(198% * (A)) * (100%+(ED))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Nihility",
    element: "Physical",
    pathIcon: nihilityIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "upcoming"
  },

  
  {
    id: "1215",
    image: require("../components/image/characters/hanya/hanya.webp"),
    background: require("../components/image/characters/hanya/hanya_full.webp"),
    e1: require("../components/image/characters/hanya/e1.webp"),
    e2: require("../components/image/characters/hanya/e2.webp"),
    e3: require("../components/image/characters/hanya/e3.webp"),
    e4: require("../components/image/characters/hanya/e4.webp"),
    e5: require("../components/image/characters/hanya/e5.webp"),
    e6: require("../components/image/characters/hanya/e6.webp"),
    basic_attack: require("../components/image/characters/hanya/b.webp"),
    skill: require("../components/image/characters/hanya/s.webp"),
    ultimate: require("../components/image/characters/hanya/u.webp"),
    talent: require("../components/image/characters/hanya/t.webp"),
    technique: require("../components/image/characters/hanya/q.webp"),
    name: "hanya",
    translation:{
      "en-us": "Hanya",
      "zh-cn": "寒鸦",
      "zh-tw": "寒鴉"
    },
    story:[{
      "en-us":
        "One of the judges of the Xianzhou Luofu's Ten-Lords Commission.",
      "zh-cn": "仙舟「罗浮」十王司的判官之一",
      "zh-tw": "仙舟「羅浮」十王司的判官之一。",
    },
    {
      "en-us":
        "Ordained by the Ten-Lords and wielding the authority of the Oracle Brush, she reads the multitudes of human sins and transgressions, then issues punishments and karmic retribution.",
      "zh-cn": "受命十王，执掌冥谶天笔，读取罪业种种，并下达业报判罚。",
      "zh-tw": "受命十王，執掌冥讖天筆、讀取罪業種種，並下達業報判罰。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.13,
        "AR" : 1.5,
        "BE" : 0.5,
        "CR" : 0.5,
        "CD" : 0.25,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 1.68,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 1,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/hanya/u.webp"),
      description: {
        "en-us": "(198% X ATT) * (100% + Physical Boost %)",
        "zh-cn": "(198% X 攻击力) * (100% + 物理属性伤害提高 %)",
        "zh-tw": "(198% X 攻擊力) * (100% + 物理屬性傷害提高 %)",
      },
      calculation: "(198% * (A)) * (100%+(ED))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Harmony",
    element: "Physical",
    pathIcon: harmonyIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  
  {
    id: "1203",
    image: require("../components/image/characters/luocha/luocha.webp"),
    background: require("../components/image/characters/luocha/luocha_full.webp"),
    e1: require("../components/image/characters/luocha/e1.webp"),
    e2: require("../components/image/characters/luocha/e2.webp"),
    e3: require("../components/image/characters/luocha/e3.webp"),
    e4: require("../components/image/characters/luocha/e4.webp"),
    e5: require("../components/image/characters/luocha/e5.webp"),
    e6: require("../components/image/characters/luocha/e6.webp"),
    basic_attack: require("../components/image/characters/luocha/b.webp"),
    skill: require("../components/image/characters/luocha/s.webp"),
    ultimate: require("../components/image/characters/luocha/u.webp"),
    talent: require("../components/image/characters/luocha/t.webp"),
    technique: require("../components/image/characters/luocha/q.webp"),
    name: "luocha",
    translation:{
      "en-us": "Luocha",
      "zh-cn": "罗刹",
      "zh-tw": "羅剎"
    },
    story:[{
      "en-us":
        "A handsome blond young man who carries a giant coffin on his back.",
      "zh-cn": "金发俊雅的年轻人，背着巨大的棺椁。",
      "zh-tw": "金發俊雅的年輕人，背著巨大的棺槨。",
    },
    {
      "en-us":
        "As an intergalactic merchant, he was unfortunately caught in the Xianzhou Luofu's Stellaron crisis.",
      "zh-cn": "身为天外行商的他，不幸被卷入仙舟罗浮的星核危机。",
      "zh-tw": "身為天外行商的他，不幸被捲入仙舟羅浮的星核危機。",
    },{
      "en-us":
        "And that is how he found his mastery of medicine to come in handy.",
      "zh-cn": "一手精湛医术莫名有了用武之地。",
      "zh-tw": "一手精湛醫術莫名有了用武之地。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.1,
        "AR" : 1.5,
        "BE" : 0.1,
        "CR" : 0.2,
        "CD" : 0.1,
        "D" : 0.02,
        "DR" : 0.5,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 1.8,
        "S" : 2.5,
      },
      name: {
        "en-us": "Skill",
        "zh-cn": "战技",
        "zh-tw": "戰技"
      },
      icon: require("../components/image/characters/luocha/s.webp"),
      description: {
        "en-us": "(40% X ATK) * (100% + Outgoing Healing Boost %)",
        "zh-cn": "(40% X 攻击力) * (100% + 治疗量加成 %)",
        "zh-tw": "(40% X 攻擊力) * (100% + 治療量加成 %)",
      },
      calculation: "(40% * (A)) * (100%+(O))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Abundance",
    element: "Imaginary",
    pathIcon: abundanceIcon,
    elementIcon: imaginaryIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1001",
    image: require("../components/image/characters/march7th/march7th.webp"),
    background: require("../components/image/characters/march7th/march7th_full.png"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "249K"
      },
      {
        item: items.filter(id => id.id === "horn-of-snow")[0],
        value: "50"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "2.4M"
      },
      {
        item: items.filter(id => id.id === "destroyers-final-road")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "5"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "28"
      },
      {
        item: items.filter(id => id.id === "safeguard-of-amber")[0],
        value: "105"
      },
      {
        item: items.filter(id => id.id === "oath-of-steel")[0],
        value: "54"
      },
      {
        item: items.filter(id => id.id === "endurance-of-bronze")[0],
        value: "12"
      },
    ],
    e1: require("../components/image/characters/march7th/e1.webp"),
    e1_translation: {
      "en-us": "Every time March 7th's Ultimate Freezes a target, she regenerates 6 Energy.",
      "zh-cn": "终结技每冻结1个目标，为三月七恢复6点能量。",
      "zh-tw": "終結技每凍結1個目標，為三月七恢復6點能量。"
    },
    e2: require("../components/image/characters/march7th/e2.webp"),
    e2_translation: {
      "en-us": "Upon entering battle, grants a Shield equal to 24% of March 7th's DEF plus 320 to the ally with the lowest HP percentage, lasting for 3 turn(s).",
      "zh-cn": "进入战斗时，为当前生命值百分比最低的我方目标提供等同于三月七24%防御力+320的护盾，持续3回合。",
      "zh-tw": "進入戰鬥時，為目前生命值百分比最低的我方目標提供等同於三月七24%防禦力+320的護盾，持續3回合。"
    },
    e3: require("../components/image/characters/march7th/e3.webp"),
    e3_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "终结技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "終結技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/march7th/e4.webp"),
    e4_translation: {
      "en-us": "The Talent's Counter effect can be triggered 1 more time in each turn. The DMG dealt by Counter increases by an amount that is equal to 30% of March 7th's DEF.",
      "zh-cn": "天赋的反击效果每回合可触发的次数增加1次。使反击造成的伤害值提高，提高数值等同于三月七防御力的30%。",
      "zh-tw": "天賦的反擊效果每回合可觸發的次數增加1次。使反擊造成的傷害值提高，提高數值等同於三月七防禦力的30%。"
    },
    e5: require("../components/image/characters/march7th/e5.webp"),
    e5_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "战技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "戰技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/march7th/e6.webp"),
    e6_translation: {
      "en-us": "Allies under the protection of the Shield granted by the Skill restores HP equal to 4% of their Max HP plus 106 at the beginning of each turn.",
      "zh-cn": "在战技提供的护盾保护下的我方目标，每回合开始时回复等同于各自4%生命上限+106的生命值。",
      "zh-tw": "在戰技提供的護盾保護下的我方目標，每回合開始時回復等同於各自4%生命上限+106的生命值。"
    },
    basic_attack: require("../components/image/characters/march7th/b.webp"),
    skill: require("../components/image/characters/march7th/s.webp"),
    ultimate: require("../components/image/characters/march7th/u.webp"),
    talent: require("../components/image/characters/march7th/t.webp"),
    technique: require("../components/image/characters/march7th/q.webp"),
    name: "march-7th",
    translation:{
      "en-us": "March 7th",
      "zh-cn": "三月七",
      "zh-tw": "三月七"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 55
        }, 
        {
          recommended: false,
          value: 42
        },  
        {
          recommended: false,
          value: 38
        },  
        {
          recommended: false,
          value: 34
        }, 
        {
          recommended: true,
          value: 30
        }, 
        {
          recommended: true,
          value: 20
        }],
      skill:[
        {
          stat: "skill",
          value: "1",
          level: 9
        },
        {
        stat: "ultimate",
        value: "3",
        level: 1
      },
      {
        stat: "talent",
        value: "3",
        level: 1
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[
      {
        translation: {
          "en-us": "Purify",
          "zh-cn": "纯洁",
          "zh-tw": "純潔"
        },
        effect_translation: {
          "en-us": "Skill removes 1 debuff from an ally.",
          "zh-cn": "施放战技时，解除指定我方单体的1个负面效果。",
          "zh-tw": "施放戰技時，解除指定我方單體的1個負面效果。"
        },
        image: require("../components/image/characters/march7th/purify.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Reinforce",
          "zh-cn": "加护",
          "zh-tw": "加護"
        },
        effect_translation: {
          "en-us": "The duration of the Shield generated from Skill is extended for 1 turn(s).",
          "zh-cn": "战技提供的护盾持续时间增加1回合。",
          "zh-tw": "戰技提供的護盾持續時間增加1回合。"
        },
        image: require("../components/image/characters/march7th/reinforce.webp"),
        value: "1"
      },{
        translation: {
          "en-us": "Ice Spell",
          "zh-cn": "冰咒",
          "zh-tw": "冰咒"
        },
        effect_translation: {
          "en-us": "Increases Ultimate's base chance to Freeze enemies by 15%.",
          "zh-cn": "施放终结技时，冻结敌方目标的基础概率提高15%。",
          "zh-tw": "施放終結技時，凍結敵方目標的基礎機率提高15%。"
        },
        image: require("../components/image/characters/march7th/ice-spell.webp"),
        value: "2"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "ER")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "DBI")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "EH")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "DR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "EG")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "H")[0],
        value: "4000"
      },
      {
        stat: property.filter(id => id.id === "D")[0],
        value: "3000"
      },
      {
        stat: property.filter(id => id.id === "EH")[0],
        value: "60%"
      }],
      stat:[
        {
          stat: property.filter(id => id.id === "HR")[0],
          value: "1"
        },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "EH")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "103_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "106_0")[0],
          set: 2,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "304_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "302_0")[0],
          set: 2,
          score: 90,
        },
        {
          relic: relic.filter(id => id.id === "310_0")[0],
          set: 2,
          score: 90,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "24002")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 100
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21002")[0],
          recommended: true,
          score: [
            {
              rank: "I",
              score: 80
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21023")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 80
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21009")[0],
          recommended: false,
          score: [
            {
              rank: "I",
              score: 80
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Counterattack",
            "zh-cn": "反击",
            "zh-tw": "反擊",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1107"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1202", "1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1001","1110"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
        "A spirited, quirky young girl who is into all the things girls her age should be interested in, for example, taking photos.",
      "zh-cn": "精灵古怪的少女，热衷于这个年纪的女孩子应当「热衷」的所有事，比如照相。",
      "zh-tw": "精靈古怪的少女，熱衷於這個年紀的女孩子應當「熱衷」的所有事，比如照相。",
    },
    {
      "en-us":
        "She was awakened from a piece of drifting eternal ice only to find out that she knows nothing about herself or her past. While initially in low spirits due to her lack of identity, she decided to name herself after the date she came into this new life.",
      "zh-cn": "从一块漂流的恒冰中苏醒，却发现自己对身世与过往都一无所知。短暂的消沉之后，她决定以重获新生的日期为自己命名。",
      "zh-tw": "從一塊漂流的恆冰中甦醒，卻發現自己對身世與過往都一無所知。短暫的消沉之後，她決定以重獲新生的日期為自己命名。",
    },{
      "en-us":
        "And thus, on that day, March 7th was born.",
      "zh-cn": "这一天，三月七「诞生」了。",
      "zh-tw": "這一天，三月七「誕生」了。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.02,
        "AR" : 0.2,
        "BE" : 0.2,
        "CR" : 0.4,
        "CD" : 0.2,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 1.5,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "KPP4": 1,
        "GWS2": 1,
        "BA2": 1,
        "FA2" : 0.9,
        "BK2" : 0.9
      },
      name: {
        "en-us": "Skill",
        "zh-cn": "战技",
        "zh-tw": "戰技"
      },
      icon: require("../components/image/characters/march7th/s.webp"),
      description: {
        "en-us": "(100% + Energy Regeneration Rate %) * (100% + Effect Hit %) * (100% + Effect RES %) * (Speed / 100) * (HP / 100) * (DEF / 100) * Relic Set",
        "zh-cn": "(100% + 能量恢复效率 %) * (100% + 效果命中 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
        "zh-tw": "(100% + 能量恢復效率 %) * (100% + 效果命中 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
      },
      calculation: "(100%+(EG)) * (100%+(EH)) * (100%+(ER)) * ((S) / 100) * ((H)/100) * ((D)/100) * (RS)"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Preservation",
    element: "Ice",
    pathIcon: preservationIcon,
    elementIcon: iceIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  
  
{
  id: "1224",
  image: require("../components/image/characters/march7th-hunt/march7th-hunt.webp"),
  background: require("../components/image/characters/march7th-hunt/march7th-hunt_full.webp"),
  e1: require("../components/image/characters/march7th-hunt/e1.webp"),
  e2: require("../components/image/characters/march7th-hunt/e2.webp"),
  e3: require("../components/image/characters/march7th-hunt/e3.webp"),
  e4: require("../components/image/characters/march7th-hunt/e4.webp"),
  e5: require("../components/image/characters/march7th-hunt/e5.webp"),
  e6: require("../components/image/characters/march7th-hunt/e6.webp"),
  basic_attack: require("../components/image/characters/march7th-hunt/b.webp"),
  skill: require("../components/image/characters/march7th-hunt/s.webp"),
  ultimate: require("../components/image/characters/march7th-hunt/u.webp"),
  talent: require("../components/image/characters/march7th-hunt/t.webp"),
  technique: require("../components/image/characters/march7th-hunt/q.webp"),
  name: "march7th-hunt",
  translation:{
    "en-us": "March 7th",
    "zh-cn": "三月七",
    "zh-tw": "三月七"
  },
  story:[{
    "en-us": "March 7th in a Xianzhou-styled outfit. A sword-wielding female martial artist.",
    "zh-cn": "换上仙舟服饰的三月七，执剑的武侠少女。",
    "zh-tw": "換上仙舟服飾的三月七，執劍的武俠少女。",
  },
  {
    "en-us": "Learning swordplay from both Yunli and Yanqing, she is eager to create more beautiful memories on the Xianzhou.",
    "zh-cn": "向云璃与彦卿拜师学艺，为在仙舟留下更多美好的「回忆」而跃跃欲试。",
    "zh-tw": "向雲璃與彥卿拜師學藝，為在仙舟留下更多美好的「回憶」而躍躍欲試。",
  },
],
formula:{
  stats:{
    "A" : 0.04,
    "AR" : 1.2,
    "BE" : 0.5,
    "CR" : 2,
    "CD" : 1,
    "D" : 0.02,
    "DR" : 0.1,
    "DBF" : 0,
    "DBI": 0,
    "DBM": 1.68,
    "DBP": 0,
    "DBL": 0,
    "DBQ" : 0,
    "DBW": 0,
    "EG" : 3.35,
    "EH" : 0.3,
    "ER" : 0.5,
    "H" : 0.02,
    "HR" : 0.6,
    "O" : 0,
    "S" : 2.5,
  },
  relic_set_stats: {
    "MWW4": 1,
    "MWW2": 1,
    "WBD4" : 1,
    "WBD2" : 1,
    "RA2" : 1
  },
  name: {
    "en-us": "Ultimate",
    "zh-cn": "终结技",
    "zh-tw": "終結技"
  },
  icon: require("../components/image/characters/dan-heng-imbibitor-lunae/u.webp"),
  description: {
    "en-us": "(100% X ATT) * (100% + Imaginary Boost %) * (100% - CR% + CR% x (100% + CD%)) * (Speed / 100) * Relic Set",
    "zh-cn": "(100% X 攻击力) * (100% + 虚数属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (速度/ 100) * 遗器套装",
    "zh-tw": "(100% X 攻擊力) * (100% + 虛數屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * (速度/ 100) * 遺器套裝",
  },
  calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * ((S) / 100) * (RS)"
},
  rarity_number: "four-star",
  rarity: fourStarsIcon,
  path: "Hunt",
  element: "Imaginary",
  pathIcon: huntIcon,
  elementIcon: imaginaryIcon,
  sortModel: [
    {
      field: 'formula',
      sort: 'desc',
    },
  ],
  type: "live"
},
  {
    id: "1105",
    image: require("../components/image/characters/natasha/natasha.webp"),
    background: require("../components/image/characters/natasha/natasha_full.webp"),
    e1: require("../components/image/characters/natasha/e1.webp"),
    e2: require("../components/image/characters/natasha/e2.webp"),
    e3: require("../components/image/characters/natasha/e3.webp"),
    e4: require("../components/image/characters/natasha/e4.webp"),
    e5: require("../components/image/characters/natasha/e5.webp"),
    e6: require("../components/image/characters/natasha/e6.webp"),
    basic_attack: require("../components/image/characters/natasha/b.webp"),
    skill: require("../components/image/characters/natasha/s.webp"),
    ultimate: require("../components/image/characters/natasha/u.webp"),
    talent: require("../components/image/characters/natasha/t.webp"),
    technique: require("../components/image/characters/natasha/q.webp"),
    name: "natasha",
    translation:{
      "en-us": "Natasha",
      "zh-cn": "娜塔莎",
      "zh-tw": "娜塔莎"
    },
    story:[{
      "en-us":
        "A fastidious doctor who always wears a curious smile.",
      "zh-cn": "做事严谨的医生，总带着难以捉摸的微笑。",
      "zh-tw": "做事嚴謹的醫生，總帶著難以捉摸的微笑。",
    },
    {
      "en-us":
        "In the Underworld where medical resources are few and far between, Natasha is one of the very few doctors whom the people can turn to.",
      "zh-cn": "在医疗资源匮乏的下层区，娜塔莎作为为数不多的医生照料着地下的男女老幼。",
      "zh-tw": "在醫療資源匱乏的下層區，娜塔莎作為為數不多的醫生照料著地下的男女老幼。",
    },{
      "en-us":
        "Even the rambunctious Hook would politely greet her...\"Hi, Auntie Natasha.\"",
      "zh-cn": "即使是最调皮的虎克，见了她也要乖乖喊一声「娜塔莎姐姐」。",
      "zh-tw": "即使是最調皮的虎克，見了她也要乖乖喊一聲「娜塔莎姐姐」。",
    },
  ],
    formula:{
    stats:{
      "A" : 0.04,
      "AR" : 0.2,
      "BE" : 0.5,
      "CR" : 0.2,
      "CD" : 0.1,
      "D" : 0.04,
      "DR" : 1.2,
      "DBF" : 0,
      "DBI": 0,
      "DBM": 0,
      "DBP": 0,
      "DBL": 0,
      "DBQ" : 0,
      "DBW" : 0,
      "EG" : 3.35,
      "EH" : 0.2,
      "ER" : 1.5,
      "H" : 0.05,
      "HR" : 1.5,
      "O" : 1.8,
      "S" : 2.5,
    },
    relic_set_stats: {
      "MTH2" : 1,
      "MTH4" : 1,
      "PWC4" : 1,
      "PWC2" : 1,
      "LD2" : 1,
      "FA2" : 1,
      "BK2" : 1,
      "SV2" : 1,
    },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/natasha/u.webp"),
      description: {
        "en-us": "(100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (100% + Outgoing Healing Boost %) * (HP / 100) * (DEF / 100)  * Relic Set",
        "zh-cn": "(100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (100% + 治疗量加成 %) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
        "zh-tw": "(100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (100% + 治療量加成 %) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
      },
      calculation: "(100%+(EG)) * (100%+(ER)) * ((S) / 100) * (100%+(O)) * ((H)/100) * ((D)/100) * (RS)"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Abundance",
    element: "Physical",
    pathIcon: abundanceIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1106",
    image: require("../components/image/characters/pela/pela.webp"),
    background: require("../components/image/characters/pela/pela_full.png"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "249K"
      },
      {
        item: items.filter(id => id.id === "horn-of-snow")[0],
        value: "50"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "2.4M"
      },
      {
        item: items.filter(id => id.id === "guardians-lament")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "5"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "28"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-obsession")[0],
        value: "105"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-desolation")[0],
        value: "54"
      },
      {
        item: items.filter(id => id.id === "obsidian-of-dread")[0],
        value: "12"
      },
    ],
    e1: require("../components/image/characters/pela/e1.webp"),
    e1_translation: {
      "en-us": "When an enemy is defeated, Pela regenerates 5 Energy.",
      "zh-cn": "敌方目标被消灭时，佩拉恢复5点能量。",
      "zh-tw": "敵方目標被消滅時，佩拉恢復5點能量。"
    },
    e2: require("../components/image/characters/pela/e2.webp"),
    e2_translation: {
      "en-us": "Using Skill to remove buff(s) increases SPD by 10% for 2 turn(s).",
      "zh-cn": "施放战技解除增益效果时，速度提高10%，持续2回合。",
      "zh-tw": "施放戰技解除增益效果時，速度提高10%，持續2回合。"
    },
    e3: require("../components/image/characters/pela/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/pela/e4.webp"),
    e4_translation: {
      "en-us": "When using Skill, there is a 100% base chance to reduce the target enemy's Ice RES by 12% for 2 turn(s).",
      "zh-cn": "施放战技时，有100%的基础概率使敌方目标冰抗性降低12%，持续2回合。",
      "zh-tw": "施放戰技時，有100%的基礎概率使敵方目標冰抗性降低12%，持續2回合。"
    },
    e5: require("../components/image/characters/pela/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过12级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過12級。"
    },
    e6: require("../components/image/characters/pela/e6.webp"),
    e6_translation: {
      "en-us": "When Pela attacks a debuffed enemy, she deals Additional Ice DMG equal to 40% of Pela's ATK to the enemy.",
      "zh-cn": "施放攻击后，若敌方目标处于负面效果，则对其造成等同于佩拉40%攻击力的冰属性附加伤害。",
      "zh-tw": "施放攻擊後，若敵方目標處於負面效果，則對其造成等同於佩拉40%攻擊力的冰屬性附加傷害。"
    },
    basic_attack: require("../components/image/characters/pela/b.webp"),
    skill: require("../components/image/characters/pela/s.webp"),
    ultimate: require("../components/image/characters/pela/u.webp"),
    talent: require("../components/image/characters/pela/t.webp"),
    technique: require("../components/image/characters/pela/q.webp"),
    name: "pela",
    translation:{
      "en-us": "Pela",
      "zh-cn": "佩拉",
      "zh-tw": "佩拉"
    },
    guide:{
      eidolons: [
        {
          recommended: false,
          value: 48
        }, 
        {
          recommended: false,
          value: 44
        },  
        {
          recommended: false,
          value: 40
        },  
        {
          recommended: false,
          value: 34
        }, 
        {
          recommended: true,
          value: 32
        }, 
        {
          recommended: true,
          value: 25
        }],
      skill:[{
        stat: "ultimate",
        value: "1",
        level: 9
      },
      {
        stat: "talent",
        value: "2",
        level: 9
      },
      {
        stat: "skill",
        value: "3",
        level: 1
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[
      {
        translation: {
          "en-us": "The Secret Strategy",
          "zh-cn": "秘策",
          "zh-tw": "秘策"
        },
        effect_translation: {
          "en-us": "When Pela is on the battlefield, all allies' Effect Hit Rate increases by 10%.",
          "zh-cn": "佩拉在场时，我方全体的效果命中提高10%。",
          "zh-tw": "佩拉在場時，我方全體的效果命中提高10%。"
        },
        image: require("../components/image/characters/pela/the-secret-strategy.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Wipe Out",
          "zh-cn": "追歼",
          "zh-tw": "追殲"
        },
        effect_translation: {
          "en-us": "Using Skill to remove buff(s) increases the DMG of the next attack by 20%.",
          "zh-cn": "施放战技解除增益效果时，下一次攻击造成的伤害提高20%。",
          "zh-tw": "施放戰技解除增益效果時，下一次攻擊造成的傷害提高20%。"
        },
        image: require("../components/image/characters/pela/wipe-out.webp"),
        value: "2"
      },{
        translation: {
          "en-us": "Bash",
          "zh-cn": "痛击",
          "zh-tw": "痛擊"
        },
        effect_translation: {
          "en-us": "Deals 20% more DMG to debuffed enemies.",
          "zh-cn": "对处于负面效果的敌方目标造成的伤害提高20%。",
          "zh-tw": "對處於負面效果的敵方目標造成的傷害提高20%。"
        },
        image: require("../components/image/characters/pela/bash.webp"),
        value: "3"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "EH")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "3"
      },
      {
        stat: property.filter(id => id.id === "DBI")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "EH")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "EG")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "EH")[0],
        value: "70%"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "134"
      },
      {
        stat: property.filter(id => id.id === "H")[0],
        value: "3000"
      }],
      stat:[{
        stat: property.filter(id => id.id === "EH")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "3"
      },],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "113_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "114_0")[0],
          set: 2,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "302_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "310_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23007")[0],
          recommended: false,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "22000")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 90
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21015")[0],
          recommended: false,
          score: [
            {
              rank: "I",
              score: 80
            }
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1205", "1005", "1102", "1213"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1202", "1207"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1105", "1211", "1203"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
        "The meticulous Intelligence Officer of the Silvermane Guards. While young, she is undeniably brilliant.",
      "zh-cn": "行事周密的银鬃铁卫情报官，年纪不大但头脑出众。",
      "zh-tw": "行事周密的銀鬃鐵衛情報官，年紀不大但頭腦出眾。",
    },
    {
      "en-us":
        "Whether it relates to maneuvering troops, distributing supplies, or the terrain, Pela can answer any problems with calm certainty.",
      "zh-cn": "无论是部队调动、物资分配还是地形状况，佩拉都能冷静地即问即答，毫无错漏。",
      "zh-tw": "無論是部隊調動、物資分配還是地形狀況，佩拉都能冷靜地即問即答，毫無錯漏。",
    },{
      "en-us":
        "As for her phone case...\"It has nothing to do with work, captain.\"",
      "zh-cn": "至于佩拉的手机壳…「这与工作无关，长官。」",
      "zh-tw": "至於佩拉的手機殼…「這與工作無關，長官。」",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 0.5,
        "BE" : 0.5,
        "CR" : 0.2,
        "CD" : 0.1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 1.5,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "ETL4": 1,
        "LD2": 1,
        "MTH2": 1,
        "FA2" : 1,
        "BK2" : 1
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/pela/u.webp"),
      description: {
        "en-us": "(100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (HP / 100) * (DEF / 100) * Relic Set",
        "zh-cn": "(100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
        "zh-tw": "(100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
      },
      calculation: "(100%+(EG)) * (100%+(ER)) * ((S) / 100) * ((H)/100) * ((D)/100) * (RS)"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Nihility",
    element: "Ice",
    pathIcon: nihilityIcon,
    elementIcon: iceIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1201",
    image: require("../components/image/characters/qingque/qingque.webp"),
    background: require("../components/image/characters/qingque/qingque_full.webp"),
    e1: require("../components/image/characters/qingque/e1.webp"),
    e2: require("../components/image/characters/qingque/e2.webp"),
    e3: require("../components/image/characters/qingque/e3.webp"),
    e4: require("../components/image/characters/qingque/e4.webp"),
    e5: require("../components/image/characters/qingque/e5.webp"),
    e6: require("../components/image/characters/qingque/e6.webp"),
    basic_attack: require("../components/image/characters/qingque/b.webp"),
    skill: require("../components/image/characters/qingque/s.webp"),
    ultimate: require("../components/image/characters/qingque/u.webp"),
    talent: require("../components/image/characters/qingque/t.webp"),
    technique: require("../components/image/characters/qingque/q.webp"),
    name: "qingque",
    translation:{
      "en-us": "Qingque",
      "zh-cn": "青雀",
      "zh-tw": "青雀"
    },
    story:[{
      "en-us":
        "An average Diviner of the Divination Commission, who will never slack off when it comes to slacking off.",
      "zh-cn": "太卜司的普通卜者，在「忙里偷闲」这件事上绝不偷闲。",
      "zh-tw": "太卜司的普通卜者，在「忙裡偷閒」這件事上絕不偷閒。",
    },
    {
      "en-us":
        "She passed the Divination Commission's entrance exam to please her parents, only to discover what she had originally thought to be a relaxing public servant job to instead be very intensive and a worker's nightmare.",
      "zh-cn": "顺应父母的期望考入太卜司，却发现本以为清闲的铁饭碗是高强度的职场苦海。",
      "zh-tw": "順應父母的期望考入太卜司，卻發現本以為清閒的鐵飯碗是高強度的職場苦海。",
    },{
      "en-us":
        "After several years of experience, Qingque had finally honed her craft — No matter which department she changes to, she remains the lowest level of Diviners.",
      "zh-cn": "经过数年历练，青雀终于磨砺了一身本领——无论部门流转，她仍旧是最低职级的卜者。",
      "zh-tw": "經過數年曆練，青雀終於磨礪了一身本領——無論部門流轉，她仍舊是最低職級的卜者。",
    },{
      "en-us":
        "\"When there's nothing else left to do, why not bury your head in a book or play cards? What more could I ask for?\"",
      "zh-cn": "没事翻翻书，玩玩古代牌戏…人生如此，夫复何求？",
      "zh-tw": "沒事翻翻書，玩玩古代牌戲…人生如此，夫復何求？",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 1.68,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/qingque/u.webp"),
      description: {
        "en-us": "(120% X ATT) * (100% + Quantum Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(120% X 攻击力) * (100% + 量子属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(120% X 攻擊力) * (100% + 量子屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(120% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Erudition",
    element: "Quantum",
    color: "#1C29BA",
    pathIcon: eruditionIcon,
    elementIcon: quantumIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1108",
    image: require("../components/image/characters/sampo/sampo.webp"),
    background: require("../components/image/characters/sampo/sampo_full.webp"),
    e1: require("../components/image/characters/sampo/e1.webp"),
    e2: require("../components/image/characters/sampo/e2.webp"),
    e3: require("../components/image/characters/sampo/e3.webp"),
    e4: require("../components/image/characters/sampo/e4.webp"),
    e5: require("../components/image/characters/sampo/e5.webp"),
    e6: require("../components/image/characters/sampo/e6.webp"),
    basic_attack: require("../components/image/characters/sampo/b.webp"),
    skill: require("../components/image/characters/sampo/s.webp"),
    ultimate: require("../components/image/characters/sampo/u.webp"),
    talent: require("../components/image/characters/sampo/t.webp"),
    technique: require("../components/image/characters/sampo/q.webp"),
    name: "sampo",
    translation:{
      "en-us": "Sampo",
      "zh-cn": "桑博",
      "zh-tw": "桑博"
    },
    story:[{
      "en-us":
        "A silver-tongued salesman. Where there is profit to be made, you can be sure Sampo is nearby.",
      "zh-cn": "口若悬河的倒货商人，只要有「利」的地方，就有桑博的身影。",
      "zh-tw": "口若懸河的倒貨商人，只要有「利」的地方，就有桑博的身影。",
    },
    {
      "en-us":
        "Sampo's unique knowledge makes it hard not to approach him for help, but becoming his \"customer\" is not necessarily a good thing.",
      "zh-cn": "桑博手中绝无仅有的情报让人不得不接近他，不过成为他的「客人」并不是什么好事。",
      "zh-tw": "桑博手中絕無僅有的情報讓人不得不接近他，不過成為他的「客人」並不是什麼好事。",
    },{
      "en-us":
        "After all, \"customers\" can quickly turn into \"commodities\" for the right price.",
      "zh-cn": "毕竟只要价钱合适，「客人」也随时可以转化为「商品」。",
      "zh-tw": "畢竟只要價錢合適，「客人」也隨時可以轉化為「商品」。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.13,
        "AR" : 1.5,
        "BE" : 0.5,
        "CR" : 0.5,
        "CD" : 0.25,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW" : 1.68,
        "EG" : 3.35,
        "EH" : 1,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/sampo/u.webp"),
      description: {
        "en-us": "(96% X ATT) * (100% + Wind Boost %)",
        "zh-cn": "(96% X 攻击力) * (100% + 风属性伤害提高 %)",
        "zh-tw": "(96% X 攻擊力) * (100% + 風屬性傷害提高 %)",
      },
      calculation: "(96% * (A)) * (100%+(ED))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Nihility",
    element: "Wind",
    pathIcon: nihilityIcon,
    elementIcon: windIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1102",
    image: require("../components/image/characters/seele/seele.webp"),
    background: require("../components/image/characters/seele/seele_full.png"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "void-cast-iron")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "guardians-lament")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "conquerors-will")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "usurpers-scheme")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "thiefs-instinct")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-starchaser")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-demon-slayer")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-beast-hunter")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/seele/e1.webp"),
    e1_translation: {
      "en-us": "When dealing DMG to an enemy whose HP percentage is 80% or lower, CRIT Rate increases by 15%.",
      "zh-cn": "对当前生命值百分比小于等于80%的敌方目标造成伤害时，暴击率提高15%。",
      "zh-tw": "對當前生命值百分比小於等於80%的敵方目標造成傷害時，暴擊率提高15%。"
    },
    e2: require("../components/image/characters/seele/e2.webp"),
    e2_translation: {
      "en-us": "The SPD Boost effect of Seele's Skill can stack up to 2 time(s).",
      "zh-cn": "战技的加速效果可以叠加，最多叠加2层。",
      "zh-tw": "戰技的加速效果可以疊加，最多疊加2層。"
    },
    e3: require("../components/image/characters/seele/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "战技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "戰技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e4: require("../components/image/characters/seele/e4.webp"),
    e4_translation: {
      "en-us": "Seele regenerates 15 Energy when she defeats an enemy.",
      "zh-cn": "希儿消灭敌方目标时，自身恢复15点能量。",
      "zh-tw": "希兒消滅敵方目標時，自身恢復15點能量。"
    },
    e5: require("../components/image/characters/seele/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "终结技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "終結技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e6: require("../components/image/characters/seele/e6.webp"),
    e6_translation: {
      "en-us": "After Seele uses her Ultimate, inflict the target enemy with Butterfly Flurry for 1 turn(s). Enemies suffering from Butterfly Flurry will take Additional Quantum DMG equal to 15% of Seele's Ultimate DMG every time they are attacked. If the target enemy is defeated by the Butterfly Flurry DMG triggered by other allies' attacks, Seele's Talent will not be triggered. When Seele is knocked down, the Butterfly Flurry inflicted on the enemies will be removed.",
      "zh-cn": "施放终结技后使受到攻击的敌方单体陷入【乱蝶】状态，持续1回合。【乱蝶】状态下的敌方目标受到攻击后，额外受到1次等同于希儿终结技伤害15%的量子属性附加伤害。若该目标被我方其他目标触发【乱蝶】状态的附加伤害消灭，将不会触发希儿的天赋。当希儿陷入无法战斗状态时，敌方目标的【乱蝶】状态被解除。",
      "zh-tw": "施放終結技後使受到攻擊的敵方單體陷入【亂蝶】狀態，持續1回合。【亂蝶】狀態下的敵方目標受到攻擊後，額外受到1次等同於希兒終結技傷害15%的量子屬性附加傷害。若該目標被我方其他目標觸發【亂蝶】狀態的附加傷害消滅，將不會觸發希兒的天賦。當希兒陷入無法戰斗狀態時，敵方目標的【亂蝶】狀態被解除。"
    },
    basic_attack: require("../components/image/characters/seele/b.webp"),
    skill: require("../components/image/characters/seele/s.webp"),
    ultimate: require("../components/image/characters/seele/u.webp"),
    talent: require("../components/image/characters/seele/t.webp"),
    technique: require("../components/image/characters/seele/q.webp"),
    name: "seele",
    translation:{
      "en-us": "Seele",
      "zh-cn": "希儿",
      "zh-tw": "希兒"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 40
        }, 
        {
          recommended: false,
          value: 31
        },  
        {
          recommended: false,
          value: 29
        },  
        {
          recommended: true,
          value: 27
        }, 
        {
          recommended: false,
          value: 17
        }, 
        {
          recommended: true,
          value: 13
        }],
      skill:[
        {
          stat: "skill",
          value: "1",
          level: 9
        },
        {
        stat: "ultimate",
        value: "1",
        level: 9
      },
      {
        stat: "talent",
        value: "1",
        level: 9
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Nightshade",
          "zh-cn": "夜行",
          "zh-tw": "夜行"
        },
        effect_translation: {
          "en-us": "When current HP percentage is 50% or lower, reduces the chance of being attacked by enemies.",
          "zh-cn": "若当前生命值百分比小于等于50%，则被敌方目标攻击的概率降低。",
          "zh-tw": "若當前生命值百分比小於等於50%，則被敵方目標攻擊的概率降低。"
        },
        image: require("../components/image/characters/seele/nightshade.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Lacerate",
          "zh-cn": "割裂",
          "zh-tw": "割裂"
        },
        effect_translation: {
          "en-us": "While Seele is in the buffed state, her Quantum RES PEN increases by 20%.",
          "zh-cn": "增幅状态下希儿的量子属性抗性穿透提高20%",
          "zh-tw": "增幅狀態下希兒的量子屬性抗性穿透提高20%"
        },
        image: require("../components/image/characters/seele/lacerate.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Rippling Waves",
          "zh-cn": "涟漪",
          "zh-tw": "漣漪"
        },
        effect_translation: {
          "en-us": "After using a Basic ATK, Seele's next action will be Advanced Forward by 20%.",
          "zh-cn": "施放普攻后，希儿的下一次行动提前20%。",
          "zh-tw": "施放普攻後，希兒的下一次行動提前20%。"
        },
        image: require("../components/image/characters/seele/rippling-waves.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "CR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "DBQ")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "A")[0],
        value: "3000"
      },
      {
        stat: property.filter(id => id.id === "CR")[0],
        value: "70%"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "140%"
      }],
      stat:[{
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "BE")[0],
        value: "3"
      },],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "108_0")[0],
          set: 4,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "309_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "301_0")[0],
          set: 2,
          score: 98,
        },
        {
          relic: relic.filter(id => id.id === "306_0")[0],
          set: 2,
          score: 98,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23001")[0],
          recommended: false,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "24001")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 80
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21010")[0],
          score: [
            {
              rank: "V",
              score: 80
            },
            {
              rank: "I",
              score: 70
            }
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Mono Quantum",
            "zh-cn": "纯量子",
            "zh-tw": "純量子",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1102"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1306"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1110"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1208"]
            },
          ]
        },
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1102"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1202", "1207"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1211", "1208", "1203", "1105"]
            },
          ]
        },
      ]
    },
    story:[{
      "en-us":
        "A spirited and valiant member of Wildfire who grew up in the perilous Underworld of Belobog. She is accustomed to being on her own.",
      "zh-cn": "飒爽俊逸的「地火」成员，成长于地底危险混乱的环境，习惯独来独往。",
      "zh-tw": "颯爽俊逸的「地火」成員，成長於地底危險混亂的環境，習慣獨來獨往。",
    },
    {
      "en-us":
        "The protectors and the protected, the oppressors and the oppressed... The world Seele grew up knowing was just a simple dichotomy...",
      "zh-cn": "保护与被保护，压迫与被压迫，世界向希儿展示的始终是非黑即白的那一面——",
      "zh-tw": "保護與被保護，壓迫與被壓迫，世界向希兒展示的始終是非黑即白的那一面——",
    },{
      "en-us":
        "That is, until \"that girl\" appeared.",
      "zh-cn": "直至「那名少女」的出现。",
      "zh-tw": "直至「那名少女」的出現。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 1.68,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "GBS4": 1,
        "GBS2": 1,
        "RA2" : 1,
        "FF2" : 1,
        "SSS2" : 0.98,
        "IS2" : 0.98,
        
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/seele/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Quantum Boost %) * (100% + CD% / 5) * (100% - CR% + CR% x (100% + CD%)) * (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 量子属性伤害提高 %) * (100% + 暴伤% / 5) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (速度 / 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 量子屬性傷害提高 %) * (100% + 暴傷% / 5) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * (速度 / 100) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%+(CD)/5) * (100%-(CR) + (CR)*(100%+(CD))) * ((S) / 100) * (RS)"
    }, 
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Quantum",
    pathIcon: huntIcon,
    elementIcon: quantumIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1103",
    image: require("../components/image/characters/serval/serval.webp"),
    background: require("../components/image/characters/serval/serval_full.png"),
    e1: require("../components/image/characters/serval/e1.webp"),
    e2: require("../components/image/characters/serval/e2.webp"),
    e3: require("../components/image/characters/serval/e3.webp"),
    e4: require("../components/image/characters/serval/e4.webp"),
    e5: require("../components/image/characters/serval/e5.webp"),
    e6: require("../components/image/characters/serval/e6.webp"),
    basic_attack: require("../components/image/characters/serval/b.webp"),
    skill: require("../components/image/characters/serval/s.webp"),
    ultimate: require("../components/image/characters/serval/u.webp"),
    talent: require("../components/image/characters/serval/t.webp"),
    technique: require("../components/image/characters/serval/q.webp"),
    name: "serval",
    translation:{
      "en-us": "Serval",
      "zh-cn": "希露瓦",
      "zh-tw": "希露瓦"
    },
    story:[{
      "en-us":
        "The free and rebellious eldest daughter of the Landau family, once a close friend of Cocolia, her interests has now led her to become a mechanic.",
      "zh-cn": "自由叛逆的朗道家长女，曾是可可利亚的挚友，如今是兴趣使然的机械师。",
      "zh-tw": "自由叛逆的朗道家長女，曾是可可利亞的摯友，如今是興趣使然的機械師。",
    },
    {
      "en-us":
        "In the perpetually winter-stricken Belobog, she opened a workshop called \"Neverwinter\" that puts business on hold from time to time for rock 'n' roll performances.",
      "zh-cn": "在永冬的贝洛伯格经营着名为「永动」的机械屋，时不时停业举办摇滚露天演出。",
      "zh-tw": "在永冬的貝洛伯格經營著名為「永動」的機械屋，時不時停業舉辦搖滾露天演出。",
    },{
      "en-us":
        "And should anyone ask her about the workshop's profitability...",
      "zh-cn": "如果有人过问机械屋怎么赚钱…",
      "zh-tw": "如果有人過問機械屋怎麼賺錢…",
    },{
      "en-us":
        "\"This is just a hobby, dear. I'm not short of money.\"",
      "zh-cn": "「这是兴趣，亲爱的，我不缺钱。」",
      "zh-tw": "「這是興趣，親愛的，我不缺錢。」",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 1.68,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/serval/u.webp"),
      description: {
        "en-us": "(108% X ATT) * (100% + Lightning Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(108% X 攻击力) * (100% + 雷属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(108% X 攻擊力) * (100% + 雷屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(108% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Erudition",
    element: "Lightning",
    pathIcon: eruditionIcon,
    elementIcon: lightningIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1006",
    image: require("../components/image/characters/silverwolf/silverwolf.webp"),
    background: require("../components/image/characters/silverwolf/silverwolf_full.webp"),
    e1: require("../components/image/characters/silverwolf/e1.webp"),
    e2: require("../components/image/characters/silverwolf/e2.webp"),
    e3: require("../components/image/characters/silverwolf/e3.webp"),
    e4: require("../components/image/characters/silverwolf/e4.webp"),
    e5: require("../components/image/characters/silverwolf/e5.webp"),
    e6: require("../components/image/characters/silverwolf/e6.webp"),
    basic_attack: require("../components/image/characters/silverwolf/b.webp"),
    skill: require("../components/image/characters/silverwolf/s.webp"),
    ultimate: require("../components/image/characters/silverwolf/u.webp"),
    talent: require("../components/image/characters/silverwolf/t.webp"),
    technique: require("../components/image/characters/silverwolf/q.webp"),
    name: "silver-wolf",
    translation:{
      "en-us": "Silver Wolf",
      "zh-cn": "银狼",
      "zh-tw": "銀狼"
    },
    story:[{
      "en-us":
        "The universe is just another game to this super hacker.",
      "zh-cn": "将宇宙视为游戏的超级骇客。",
      "zh-tw": "將宇宙視為遊戲的超級駭客。",
    },
    {
      "en-us":
        "No matter how thorny the defense system, Silver Wolf can crack it with ease. Her hacking battle with Screwllum of the Genius Society has become stuff of legends in the hacking world.",
      "zh-cn": "无论怎样棘手的防御系统，银狼都能轻松破解。她与「天才俱乐部」螺丝咕姆的数据攻防战，现已成为骇客界的传说。",
      "zh-tw": "無論怎樣棘手的防禦系統，銀狼都能輕鬆破解。她與「天才俱樂部」螺絲咕姆的數據攻防戰，現已成為駭客界的傳說。",
    },{
      "en-us":
        "How many more levels are there to beat in the universe? Silver Wolf looks forward to finding out.",
      "zh-cn": "宇宙中还有多少亟待攻破的关卡？银狼对此十分期待。",
      "zh-tw": "宇宙中還有多少亟待攻破的關卡？銀狼對此十分期待。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 1.68,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 1.5,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "GBS4": 1,
        "GBS2": 1,
        "PGCE2" : 1,
        "IS2" : 1
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/silverwolf/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Quantum Boost %) * (100% - CR% + CR% x (100% + CD%)) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 量子属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 量子屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Quantum",
    pathIcon: nihilityIcon,
    elementIcon: quantumIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1206",
    image: require("../components/image/characters/sushang/sushang.webp"),
    background: require("../components/image/characters/sushang/sushang_full.png"),
    e1: require("../components/image/characters/sushang/e1.webp"),
    e2: require("../components/image/characters/sushang/e2.webp"),
    e3: require("../components/image/characters/sushang/e3.webp"),
    e4: require("../components/image/characters/sushang/e4.webp"),
    e5: require("../components/image/characters/sushang/e5.webp"),
    e6: require("../components/image/characters/sushang/e6.webp"),
    basic_attack: require("../components/image/characters/sushang/b.webp"),
    skill: require("../components/image/characters/sushang/s.webp"),
    ultimate: require("../components/image/characters/sushang/u.webp"),
    talent: require("../components/image/characters/sushang/t.webp"),
    technique: require("../components/image/characters/sushang/q.webp"),
    name: "sushang",
    translation:{
      "en-us": "Sushang",
      "zh-cn": "素裳",
      "zh-tw": "素裳"
    },
    story:[{
      "en-us":
        "A naive and enthusiastic newcomer to the Cloud Knights who wields a greatsword.",
      "zh-cn": "单纯热心的云骑军新人，执一柄重剑。",
      "zh-tw": "單純熱心的雲騎軍新人，執一柄重劍。",
    },
    {
      "en-us":
        "She yearns for the historic legends of the Cloud Knights, and is eager to become such a legendary figure herself.",
      "zh-cn": "憧憬着云骑军历史上的传奇，渴望成为响当当的人物。",
      "zh-tw": "憧憬著雲騎軍歷史上的傳奇，渴望成為響噹噹的人物。",
    },{
      "en-us":
        "As such, Sushang firmly believes in the philosophies \"be eager to help those in need,\" \"do one good deed a day and reflect on oneself three times a day,\" leading her to busy days full of helping others.",
      "zh-cn": "为此，素裳坚决恪守「急人所急，有求必应；日行一善，三省吾身」的信条，过着助人为乐的忙碌日子。",
      "zh-tw": "為此，素裳堅決恪守「急人所急，有求必應；日行一善，三省吾身」的信條，過著助人為樂的忙碌日子。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 1.68,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/sushang/u.webp"),
      description: {
        "en-us": "(192% X ATT) * (100% + Physical Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(192% X 攻击力) * (100% + 物理属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(192% X 攻擊力) * (100% + 物理屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(192% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Hunt",
    element: "Physical",
    pathIcon: huntIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1202",
    image: require("../components/image/characters/tingyun/tingyun.webp"),
    background: require("../components/image/characters/tingyun/tingyun_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "249K"
      },
      {
        item: items.filter(id => id.id === "lightning-crown-of-the-past-shadow")[0],
        value: "50"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "2.4M"
      },
      {
        item: items.filter(id => id.id === "destroyers-final-road")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "5"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "28"
      },
      {
        item: items.filter(id => id.id === "stellaris-symphony")[0],
        value: "105"
      },
      {
        item: items.filter(id => id.id === "ancestral-hymn")[0],
        value: "54"
      },
      {
        item: items.filter(id => id.id === "harmonic-tune")[0],
        value: "12"
      },
    ],
    e1: require("../components/image/characters/tingyun/e1.webp"),
    e1_translation: {
      "en-us": "After using their Ultimate, the ally with Benediction gains a 20% increase in SPD for 1 turn.",
      "zh-cn": "得到【赐福】的我方单体施放终结技后速度提高20%，持续一回合。",
      "zh-tw": "得到【賜福】的我方單體施放終結技後速度提高20%，持續一回合。"
    },
    e2: require("../components/image/characters/tingyun/e2.webp"),
    e2_translation: {
      "en-us": "The ally with Benediction regenerates 5 Energy after defeating an enemy. This effect can only be triggered once per turn.",
      "zh-cn": "得到【赐福】的我方单体在消灭敌方目标时恢复5点能量，每回合1次。",
      "zh-tw": "得到【賜福】的我方單體在消滅敵方目標時恢復5點能量，每回合1次。"
    },
    e3: require("../components/image/characters/tingyun/e3.webp"),
    e3_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "终结技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "終結技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/tingyun/e4.webp"),
    e4_translation: {
      "en-us": "The DMG multiplier provided by Benediction increases by 20%.",
      "zh-cn": "【赐福】附加的伤害倍率提高20%。",
      "zh-tw": "【賜福】附加的傷害倍率提高20%。"
    },
    e5: require("../components/image/characters/tingyun/e5.webp"),
    e5_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "战技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "戰技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/tingyun/e6.webp"),
    e6_translation: {
      "en-us": "Ultimate regenerates 10 more Energy for the target ally.",
      "zh-cn": "终结技为我方目标恢复的能量提高10点。",
      "zh-tw": "終結技為我方目標恢復的能量提高10點。"
    },
    basic_attack: require("../components/image/characters/tingyun/b.webp"),
    skill: require("../components/image/characters/tingyun/s.webp"),
    ultimate: require("../components/image/characters/tingyun/u.webp"),
    talent: require("../components/image/characters/tingyun/t.webp"),
    technique: require("../components/image/characters/tingyun/q.webp"),
    name: "tingyun",
    translation:{
      "en-us": "Tingyun",
      "zh-cn": "停云",
      "zh-tw": "停雲"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 70
        }, 
        {
          recommended: false,
          value: 54
        },  
        {
          recommended: true,
          value: 50
        },  
        {
          recommended: false,
          value: 34
        }, 
        {
          recommended: true,
          value: 30
        }, 
        {
          recommended: true,
          value: 20
        }],
      skill:[
        {
          stat: "ultimate",
          value: "1",
          level: 9
        },
        {
          stat: "skill",
          value: "1",
          level: 9
        },
        {
          stat: "talent",
          value: "3",
          level: 1
        },
        {
          stat: "basic_attack",
          value: "3",
          level: 1
        },
      ],
      extra_skill:[
        {
          translation: {
            "en-us": "Nourished Joviality",
            "zh-cn": "驻晴",
            "zh-tw": "駐晴"
          },
          effect_translation: {
            "en-us": "Tingyun's SPD increases by 20% for 1 turn after using Skill.",
            "zh-cn": "施放战技时，停云自身速度提高20%，持续1回合。",
            "zh-tw": "施放戰技時，停雲自身速度提升20%，持續1回合。"
          },
          image: require("../components/image/characters/tingyun/nourished-joviality.webp"),
          value: "1"
        },
        {
          translation: {
            "en-us": "Jubilant Passage",
            "zh-cn": "亨通",
            "zh-tw": "亨通"
          },
          effect_translation: {
            "en-us": "Tingyun immediately regenerates 5 Energy at the start of her turn.",
            "zh-cn": "停云的回合开始时，自身立即恢复5点能量。",
            "zh-tw": "停雲的回合開始時，自身立即恢復5點能量。"
          },
          image: require("../components/image/characters/tingyun/jubilant-passage.webp"),
          value: "1"
        },
    {
      translation: {
        "en-us": "Knell Subdual",
        "zh-cn": "止厄",
        "zh-tw": "止厄"
      },
      effect_translation: {
        "en-us": "Increases Basic ATK DMG by 40%.",
        "zh-cn": "普攻造成的伤害提高40%",
        "zh-tw": "普攻造成的傷害提高40%"
      },
      image: require("../components/image/characters/tingyun/knell-subdual.webp"),
      value: "3"
    },
      ],
      traces:[
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DBL")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "EG")[0]
        },
      ],
      target_stats: [
        {
          stat: property.filter(id => id.id === "H")[0],
          value: "4000"
        },
        {
        stat: property.filter(id => id.id === "S")[0],
        value: "143"
        },
        {
          stat: property.filter(id => id.id === "A")[0],
          value: "2500"
        },],
      stat:[{
        stat: property.filter(id => id.id === "AR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "114_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "102_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "113_0")[0],
          set: 2,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "302_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "308_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23003")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120,
            },
            {
              rank: "I",
              score: 100,
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21025")[0],
          recommended: false,
          score: [
            {
              rank: "V",
              score: 70
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21018")[0],
          recommended: true,
          score: [
            {
              rank: "I",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1205", "1204", "1102"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1211", "1208", "1203", "1105"]
            },
          ]
        },
      ]
    },
    story:[{
      "en-us":
        "A silver-tongued Foxian girl, Tingyun is the Head Representative of the Whistling Flames, a merchant guild officially approved by the Sky-Faring Commission.",
      "zh-cn": "八面玲珑的狐人少女，天舶司商团「鸣火」的首席代表。",
      "zh-tw": "八面玲瓏的狐人少女，天舶司商團「鳴火」的首席代表。",
    },
    {
      "en-us":
        "She has such a way with words that often leaves her audience eagerly waiting for more of her captivating tales. As a result of her supervision, the Xianzhou trade fairs are now known throughout the galaxy.",
      "zh-cn": "停云天生生得一副慧心妙舌，但凡她开口，人们就免不了想听她多说几句。在她的调度下，仙舟的贸易庆典逐渐声名远扬。",
      "zh-tw": "停雲天生生得一副慧心妙舌，但凡她開口，人們就免不了想听她多說幾句。在她的調度下，仙舟的貿易慶典逐漸聲名遠揚。",
    },{
      "en-us":
        "\"Try one's best to avoid conflict when possible, and persuade those who can be persuaded\" — that's Tingyun's motto.",
      "zh-cn": "能不战斗就尽量不去战斗，能劝为己用就尽量劝为己用——这便是停云的原则。",
      "zh-tw": "能不戰鬥就盡量不去戰鬥，能勸為己用就盡量勸為己用——這便是停雲的原則。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 0.2,
        "CD" : 0.1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "MTH4" : 1,
        "MTH2" : 1,
        "MWW4" : 1,
        "MWW2" : 1,
        "LD2" : 1,
        "FA2" : 1,
        "SV2" : 1,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/tingyun/u.webp"),
      description: {
        "en-us": "(100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (HP / 100) * (DEF / 100) * Relic Set",
        "zh-cn": "(100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
        "zh-tw": "(100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
      },
      calculation: "(100%+(EG)) * (100%+(ER)) * ((S) / 100) * ((H)/100) * ((D)/100) * (RS)"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Harmony",
    element: "Lightning",
    pathIcon: harmonyIcon,
    elementIcon: lightningIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1112",
    image: require("../components/image/characters/topaz&numby/topaz&numby.webp"),
    background: require("../components/image/characters/topaz&numby/topaz&numby_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "regret-of-infinite-ochema")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "silvermane-medal")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "silvermane-insignia")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "silvermane-badge")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-starchaser")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-demon-slayer")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "arrow-of-the-beast-hunter")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/topaz&numby/e1.webp"),
    e1_translation: {
      "en-us": "When enemies afflicted with Proof of Debt receive follow-up attacks, they will enter the Debtor state. This can only take effect 1 time in a single action. Debtor increases CRIT DMG from follow-up attacks dealt to affected enemies by 25%, stacking up to 2 time(s). When Proof of Debt is removed, Debtor is also removed.",
      "zh-cn": "陷入【负债证明】状态下的敌方目标受到追加攻击时，将会陷入【被执行】状态，单次攻击内最多生效1次。【被执行】使敌方目标受到的追加攻击的暴击伤害提高25%，最多叠加2层。【负债证明】被移除时，【被执行】也会被移除。",
      "zh-tw": "陷入【負債證明】狀態下的敵方目標受到追加攻擊時，將會陷入【被執行】狀態，單次攻擊內最多生效1次。【被執行】使敵方目標受到的追加攻擊的暴擊傷害提高25%，最多疊加2層。【負債證明】被移除時，【被執行】也會被移除。"
    },
    e2: require("../components/image/characters/topaz&numby/e2.webp"),
    e2_translation: {
      "en-us": "After Numby takes action and launches an attack, Topaz regenerates 5 Energy.",
      "zh-cn": "账账自身行动并发动攻击后，托帕恢复5点能量。",
      "zh-tw": "帳帳自身行動並發動攻擊後，托帕恢復5點能量。"
    },
    e3: require("../components/image/characters/topaz&numby/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15.; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/topaz&numby/e4.webp"),
    e4_translation: {
      "en-us": "After Numby's turn begins, Topaz's action is Advanced Forward by 20%.",
      "zh-cn": "账账自身回合开始时，托帕的行动提前20%。",
      "zh-tw": "帳帳自身回合開始時，托帕的行動提前20%。"
    },
    e5: require("../components/image/characters/topaz&numby/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/topaz&numby/e6.webp"),
    e6_translation: {
      "en-us": "Numby's attack count while in the Windfall Bonanza! state increases by 1, and its Fire RES PEN increases by 10% during its attacks.",
      "zh-cn": "账账处于【涨幅惊人！】状态的攻击次数增加1次，且攻击时火属性抗性穿透提高10%。",
      "zh-tw": "帳帳處於【漲幅驚人！】狀態的攻擊次數增加1次，且攻擊時火屬性抗性穿透提高10%。"
    },
    basic_attack: require("../components/image/characters/topaz&numby/b.webp"),
    skill: require("../components/image/characters/topaz&numby/s.webp"),
    ultimate: require("../components/image/characters/topaz&numby/u.webp"),
    talent: require("../components/image/characters/topaz&numby/t.webp"),
    technique: require("../components/image/characters/topaz&numby/q.webp"),
    name: "topaz&numby",
    translation:{
      "en-us": "Topaz & Numby",
      "zh-cn": "托帕&账账",
      "zh-tw": "托帕&帳賬"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 66
        }, 
        {
          recommended: false,
          value: 48
        },  
        {
          recommended: false,
          value: 44
        },  
        {
          recommended: false,
          value: 34
        }, 
        {
          recommended: false,
          value: 30
        }, 
        {
          recommended: true,
          value: 24
        }],
      skill:[
        {
          stat: "skill",
          value: "1",
          level: 9
        },
        {
          stat: "ultimate",
          value: "1",
          level: 9
        },
        {
          stat: "talent",
          value: "2",
          level: 9
        },
        {
          stat: "basic_attack",
          value: "3",
          level: 1
        },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Overdraft",
          "zh-cn": "透支",
          "zh-tw": "透支"
        },
        effect_translation: {
          "en-us": "When Topaz uses Basic ATK to deal DMG, it will be considered as a follow-up attack.",
          "zh-cn": "托帕施放普攻造成伤害时，被视为发动了追加攻击。",
          "zh-tw": "托帕施放普攻造成傷害時，被視為發動了追加攻擊。"
        },
        image: require("../components/image/characters/topaz&numby/overdraft.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Financial Turmoil",
          "zh-cn": "金融动荡",
          "zh-tw": "金融動盪"
        },
        effect_translation: {
          "en-us": "Increases Topaz and Numby's DMG dealt to enemy targets with Fire Weakness by 15%.",
          "zh-cn": "托帕和账账对拥有火属性弱点的敌方目标造成的伤害提高15%。",
          "zh-tw": "托帕和帳帳對擁有火屬性弱點的敵方目標造成的傷害提高15%。"
        },
        image: require("../components/image/characters/topaz&numby/financial-turmoil.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Technical Adjustment",
          "zh-cn": "技术性调整",
          "zh-tw": "技術性調整"
        },
        effect_translation: {
          "en-us": "After Numby uses an attack while in the Windfall Bonanza! state, Topaz additionally regenerates 10 Energy.",
          "zh-cn": "当账账处于【涨幅惊人！】状态施放攻击后，额外使托帕恢复10点能量。",
          "zh-tw": "當帳帳處於【漲幅驚人！】狀態施放攻擊後，額外使托帕恢復10點能量。"
        },
        image: require("../components/image/characters/topaz&numby/technical-adjustment.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "DBF")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "2"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "CR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "DBF")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "CR")[0],
        value: "70%"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "140%"
      },
      {
        stat: property.filter(id => id.id === "A")[0],
        value: "3000"
      }],
      stat:[{
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "BE")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "107_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "115_0")[0],
          set: 4,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "306_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "301_0")[0],
          set: 2,
          score: 90,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23016")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120,
            },
            {
              rank: "I",
              score: 100,
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "24001")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 80
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21010")[0],
          score: [
            {
              rank: "V",
              score: 80
            },
            {
              rank: "I",
              score: 70
            }
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1112"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1101", "1202", "1207"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1211", "1208", "1203", "1105"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us": "Topaz, Senior Manager of the Strategic Investment Department in the Interastral Peace Corporation, and leader of the Special Debts Picket Team.",
      "zh-cn": "星际和平公司旗下「战略投资部」高级干部托帕，领导特殊债务纠察小组。",
      "zh-tw": "星際和平公司旗下「戰略投資部」高級幹部托帕，領導特殊債務糾察小組。",
    },
    {
      "en-us":
        "Already a member of the \"Ten Stonehearts\" at a young age, Topaz's core stone is the \"Topaz of Debt Retrieval.\" Her partner, the Warp Trotter \"Numby\", is also capable of acutely perceiving where \"riches\" are located. It can even perform jobs involving security, debt collection, and actuarial sciences.",
      "zh-cn": "年纪轻轻便已成为「石心十人」之一，基石为「催讨黄玉」。搭档次元扑满「账账」则能敏锐感知「财富」所在，即便是安保、催债、精算等工作也不在话下。",
      "zh-tw": "年紀輕輕便已成為「石心十人」之一，基石為「催討黃玉」。搭檔次元撲滿「帳帳」則能敏銳感知「財富」所在，即使是保全、催債、精算等工作也不在話下。",
    },{
      "en-us": "Presently they are traversing the cosmos together, chasing down various debts and liabilities that negatively influence the IPC's commercial ventures.",
      "zh-cn": "如今他们正一同巡行银河，追究各类影响公司业务开展的债务纠纷。。",
      "zh-tw": "如今他們正一同巡行銀河，追究各類影響公司業務开展的债务纠纷。。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 1.68,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "FLF4": 1,
        "FLF2": 1,
        "AGD4": 1,
        "AGD2": 1,
        "MWW4": 0.81,
        "MWW2": 0.9,
        "SSS2" : 1,
        "IS2" : 0.95
      },
      name: {
        "en-us": "Talent",
        "zh-cn": "天赋",
        "zh-tw": "天賦"
      },
      icon: require("../components/image/characters/topaz&numby/t.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Fire Boost %) * (100% - CR% + CR% x (100% + CD%)) * (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 火属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 火屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * ((S) / 100) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Fire",
    pathIcon: huntIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "8001",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    background: require("../components/image/characters/trailblazer/male/destruction.webp"),
    e1: require("../components/image/characters/trailblazer/male/e1.webp"),
    e2: require("../components/image/characters/trailblazer/male/e2.webp"),
    e3: require("../components/image/characters/trailblazer/male/e3.webp"),
    e4: require("../components/image/characters/trailblazer/male/e4.webp"),
    e5: require("../components/image/characters/trailblazer/male/e5.webp"),
    e6: require("../components/image/characters/trailblazer/male/e6.webp"),
    basic_attack: require("../components/image/characters/trailblazer/destruction/b.webp"),
    skill: require("../components/image/characters/trailblazer/destruction/s.webp"),
    ultimate: require("../components/image/characters/trailblazer/destruction/u.webp"),
    talent: require("../components/image/characters/trailblazer/destruction/t.webp"),
    technique: require("../components/image/characters/trailblazer/destruction/q.webp"),
    name: "trail-blazer-destruction",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    story:[{
      "en-us":
        "They are awakened during the opening events of the game by Kafka and Silver Wolf,",
      "zh-cn": "在反物质军团入侵期间，(他/她)作为星核的载体被卡夫卡和银狼唤醒。",
      "zh-tw": "在反物質軍團入侵期間，(他/她)作為星核的載體被卡夫卡和銀狼喚醒。",
    },
    {
      "en-us":
        "who leave them to be found by March 7th and Dan Heng on Herta Space Station during the Antimatter Legion's invasion.",
      "zh-cn": "她们离开了空间站「黑塔」并将开拓者留在了这里，之后，开拓者被三月七和丹恒发现，并加入了星穹列车列车组。",
      "zh-tw": "她們離開了空間站「黑塔」並將開拓者留在了這裡，之後，開拓者被三月七和丹恆發現，並加入了星穹列車列車組。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 1.68,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/trailblazer/destruction/u.webp"),
      description: {
        "en-us": "(300% X ATT) * (100% + Physical Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(300% X 攻击力) * (100% + 物理属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(300% X 攻擊力) * (100% + 物理屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(300% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Physical",
    pathIcon: destructionIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "8002",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    background: require("../components/image/characters/trailblazer/female/destruction.webp"),
    e1: require("../components/image/characters/trailblazer/female/e1.webp"),
    e2: require("../components/image/characters/trailblazer/female/e2.webp"),
    e3: require("../components/image/characters/trailblazer/female/e3.webp"),
    e4: require("../components/image/characters/trailblazer/female/e4.webp"),
    e5: require("../components/image/characters/trailblazer/female/e5.webp"),
    e6: require("../components/image/characters/trailblazer/female/e6.webp"),
    basic_attack: require("../components/image/characters/trailblazer/destruction/b.webp"),
    skill: require("../components/image/characters/trailblazer/destruction/s.webp"),
    ultimate: require("../components/image/characters/trailblazer/destruction/u.webp"),
    talent: require("../components/image/characters/trailblazer/destruction/t.webp"),
    technique: require("../components/image/characters/trailblazer/destruction/q.webp"),
    name: "trail-blazer-destruction",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    story:[{
      "en-us":
        "They are awakened during the opening events of the game by Kafka and Silver Wolf,",
      "zh-cn": "在反物质军团入侵期间，(他/她)作为星核的载体被卡夫卡和银狼唤醒。",
      "zh-tw": "在反物質軍團入侵期間，(他/她)作為星核的載體被卡夫卡和銀狼喚醒。",
    },
    {
      "en-us":
        "who leave them to be found by March 7th and Dan Heng on Herta Space Station during the Antimatter Legion's invasion.",
      "zh-cn": "她们离开了空间站「黑塔」并将开拓者留在了这里，之后，开拓者被三月七和丹恒发现，并加入了星穹列车列车组。",
      "zh-tw": "她們離開了空間站「黑塔」並將開拓者留在了這裡，之後，開拓者被三月七和丹恆發現，並加入了星穹列車列車組。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 1.68,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/trailblazer/destruction/u.webp"),
      description: {
        "en-us": "(300% X ATT) * (100% + Physical Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(300% X 攻击力) * (100% + 物理属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(300% X 攻擊力) * (100% + 物理屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(300% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Physical",
    pathIcon: destructionIcon,
    elementIcon: physicalIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "8003",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    background: require("../components/image/characters/trailblazer/male/preservation.webp"),
    e1: require("../components/image/characters/trailblazer/male/e1.webp"),
    e2: require("../components/image/characters/trailblazer/male/e2.webp"),
    e3: require("../components/image/characters/trailblazer/male/e3.webp"),
    e4: require("../components/image/characters/trailblazer/male/e4.webp"),
    e5: require("../components/image/characters/trailblazer/male/e5.webp"),
    e6: require("../components/image/characters/trailblazer/male/e6.webp"),
    basic_attack: require("../components/image/characters/trailblazer/preservation/b.webp"),
    skill: require("../components/image/characters/trailblazer/preservation/s.webp"),
    ultimate: require("../components/image/characters/trailblazer/preservation/u.webp"),
    talent: require("../components/image/characters/trailblazer/preservation/t.webp"),
    technique: require("../components/image/characters/trailblazer/preservation/q.webp"),
    name: "trail-blazer-preservation",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    story:[{
      "en-us":
        "They are awakened during the opening events of the game by Kafka and Silver Wolf,",
      "zh-cn": "在反物质军团入侵期间，(他/她)作为星核的载体被卡夫卡和银狼唤醒。",
      "zh-tw": "在反物質軍團入侵期間，(他/她)作為星核的載體被卡夫卡和銀狼喚醒。",
    },
    {
      "en-us":
        "who leave them to be found by March 7th and Dan Heng on Herta Space Station during the Antimatter Legion's invasion.",
      "zh-cn": "她们离开了空间站「黑塔」并将开拓者留在了这里，之后，开拓者被三月七和丹恒发现，并加入了星穹列车列车组。",
      "zh-tw": "她們離開了空間站「黑塔」並將開拓者留在了這裡，之後，開拓者被三月七和丹恆發現，並加入了星穹列車列車組。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 1.68,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/trailblazer/preservation/u.webp"),
      description: {
        "en-us": "(50% X ATT * (100% + Fire Boost %) + 75% X DEF) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(50% X 攻击力 * (100% + 火属性伤害提高 %) + 75% X 防御力) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(50% X 攻擊力 * (100% + 火屬性傷害提高 %) + 75% X 防禦力) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(50% * (A) * (100%+(ED)) + 75% * (D)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Preservation",
    element: "Fire",
    pathIcon: preservationIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "8004",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    background: require("../components/image/characters/trailblazer/female/preservation.webp"),
    e1: require("../components/image/characters/trailblazer/female/e1.webp"),
    e2: require("../components/image/characters/trailblazer/female/e2.webp"),
    e3: require("../components/image/characters/trailblazer/female/e3.webp"),
    e4: require("../components/image/characters/trailblazer/female/e4.webp"),
    e5: require("../components/image/characters/trailblazer/female/e5.webp"),
    e6: require("../components/image/characters/trailblazer/female/e6.webp"),
    basic_attack: require("../components/image/characters/trailblazer/preservation/b.webp"),
    skill: require("../components/image/characters/trailblazer/preservation/s.webp"),
    ultimate: require("../components/image/characters/trailblazer/preservation/u.webp"),
    talent: require("../components/image/characters/trailblazer/preservation/t.webp"),
    technique: require("../components/image/characters/trailblazer/preservation/q.webp"),
    name: "trail-blazer-preservation",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    story:[{
      "en-us":
        "They are awakened during the opening events of the game by Kafka and Silver Wolf,",
      "zh-cn": "在反物质军团入侵期间，(他/她)作为星核的载体被卡夫卡和银狼唤醒。",
      "zh-tw": "在反物質軍團入侵期間，(他/她)作為星核的載體被卡夫卡和銀狼喚醒。",
    },
    {
      "en-us":
        "who leave them to be found by March 7th and Dan Heng on Herta Space Station during the Antimatter Legion's invasion.",
      "zh-cn": "她们离开了空间站「黑塔」并将开拓者留在了这里，之后，开拓者被三月七和丹恒发现，并加入了星穹列车列车组。",
      "zh-tw": "她們離開了空間站「黑塔」並將開拓者留在了這裡，之後，開拓者被三月七和丹恆發現，並加入了星穹列車列車組。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 1.68,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/trailblazer/preservation/u.webp"),
      description: {
        "en-us": "(50% X ATT * (100% + Fire Boost %) + 75% X DEF) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(50% X 攻击力 * (100% + 火属性伤害提高 %) + 75% X 防御力) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(50% X 攻擊力 * (100% + 火屬性傷害提高 %) + 75% X 防禦力) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(50% * (A) * (100%+(ED)) + 75% * (D)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Preservation",
    element: "Fire",
    pathIcon: preservationIcon,
    elementIcon: fireIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  
  {
    id: "8005",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    background: require("../components/image/characters/trailblazer/male/harmony.webp"),
    e1: require("../components/image/characters/trailblazer/male/e1.webp"),
    e2: require("../components/image/characters/trailblazer/male/e2.webp"),
    e3: require("../components/image/characters/trailblazer/male/e3.webp"),
    e4: require("../components/image/characters/trailblazer/male/e4.webp"),
    e5: require("../components/image/characters/trailblazer/male/e5.webp"),
    e6: require("../components/image/characters/trailblazer/male/e6.webp"),
    basic_attack: require("../components/image/characters/trailblazer/harmony/b.webp"),
    skill: require("../components/image/characters/trailblazer/harmony/s.webp"),
    ultimate: require("../components/image/characters/trailblazer/harmony/u.webp"),
    talent: require("../components/image/characters/trailblazer/harmony/t.webp"),
    technique: require("../components/image/characters/trailblazer/harmony/q.webp"),
    name: "trail-blazer-harmony",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    story:[{
      "en-us":
        "They are awakened during the opening events of the game by Kafka and Silver Wolf,",
      "zh-cn": "在反物质军团入侵期间，(他/她)作为星核的载体被卡夫卡和银狼唤醒。",
      "zh-tw": "在反物質軍團入侵期間，(他/她)作為星核的載體被卡夫卡和銀狼喚醒。",
    },
    {
      "en-us":
        "who leave them to be found by March 7th and Dan Heng on Herta Space Station during the Antimatter Legion's invasion.",
      "zh-cn": "她们离开了空间站「黑塔」并将开拓者留在了这里，之后，开拓者被三月七和丹恒发现，并加入了星穹列车列车组。",
      "zh-tw": "她們離開了空間站「黑塔」並將開拓者留在了這裡，之後，開拓者被三月七和丹恆發現，並加入了星穹列車列車組。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 1,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 1.68,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "WMDM4" : 1,
        "WMDM2" : 1,
        "TSM4" : 1,
        "TSM2" : 1,
        "TKB2" : 1,
        "LD2" : 1
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/trailblazer/harmony/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Imaginary Boost %) * (100% - CR% + CR% x (100% + CD%)) * (100% + Break Effect % / 8) (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 虚数属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (100% + 击破特攻 % / 8 * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 虛數屬性傷害提高 %) * (100% - 爆擊% + 暴傷% x (100% + 暴傷%)) * (100% + 擊破特攻 % / 8) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A))  * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * (100%+(BE)/8) * ((S) / 100) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Imaginary",
    pathIcon: harmonyIcon,
    elementIcon: imaginaryIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "8006",
    image: require("../components/image/characters/trailblazer/trailblazer.webp"),
    background: require("../components/image/characters/trailblazer/female/harmony.webp"),
    e1: require("../components/image/characters/trailblazer/female/e1.webp"),
    e2: require("../components/image/characters/trailblazer/female/e2.webp"),
    e3: require("../components/image/characters/trailblazer/female/e3.webp"),
    e4: require("../components/image/characters/trailblazer/female/e4.webp"),
    e5: require("../components/image/characters/trailblazer/female/e5.webp"),
    e6: require("../components/image/characters/trailblazer/female/e6.webp"),
    basic_attack: require("../components/image/characters/trailblazer/harmony/b.webp"),
    skill: require("../components/image/characters/trailblazer/harmony/s.webp"),
    ultimate: require("../components/image/characters/trailblazer/harmony/u.webp"),
    talent: require("../components/image/characters/trailblazer/harmony/t.webp"),
    technique: require("../components/image/characters/trailblazer/harmony/q.webp"),
    name: "trail-blazer-harmony",
    translation:{
      "en-us": "Trail Blazer",
      "zh-cn": "开拓者",
      "zh-tw": "開拓者"
    },
    story:[{
      "en-us":
        "They are awakened during the opening events of the game by Kafka and Silver Wolf,",
      "zh-cn": "在反物质军团入侵期间，(他/她)作为星核的载体被卡夫卡和银狼唤醒。",
      "zh-tw": "在反物質軍團入侵期間，(他/她)作為星核的載體被卡夫卡和銀狼喚醒。",
    },
    {
      "en-us":
        "who leave them to be found by March 7th and Dan Heng on Herta Space Station during the Antimatter Legion's invasion.",
      "zh-cn": "她们离开了空间站「黑塔」并将开拓者留在了这里，之后，开拓者被三月七和丹恒发现，并加入了星穹列车列车组。",
      "zh-tw": "她們離開了空間站「黑塔」並將開拓者留在了這裡，之後，開拓者被三月七和丹恆發現，並加入了星穹列車列車組。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 1,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 1.68,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "WMDM4" : 1,
        "WMDM2" : 1,
        "TSM4" : 1,
        "TSM2" : 1,
        "TKB2" : 1,
        "LD2" : 1
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/trailblazer/harmony/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Imaginary Boost %) * (100% - CR% + CR% x (100% + CD%)) * (100% + Break Effect % / 8) (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 虚数属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (100% + 击破特攻 % / 8 * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 虛數屬性傷害提高 %) * (100% - 爆擊% + 暴傷% x (100% + 暴傷%)) * (100% + 擊破特攻 % / 8) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A))  * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * (100%+(BE)/8) * ((S) / 100) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Harmony",
    element: "Imaginary",
    pathIcon: harmonyIcon,
    elementIcon: imaginaryIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },

  {
    id: "1004",
    image: require("../components/image/characters/welt/welt.webp"),
    background: require("../components/image/characters/welt/welt_full.webp"),
    e1: require("../components/image/characters/welt/e1.webp"),
    e2: require("../components/image/characters/welt/e2.webp"),
    e3: require("../components/image/characters/welt/e3.webp"),
    e4: require("../components/image/characters/welt/e4.webp"),
    e5: require("../components/image/characters/welt/e5.webp"),
    e6: require("../components/image/characters/welt/e6.webp"),
    basic_attack: require("../components/image/characters/welt/b.webp"),
    skill: require("../components/image/characters/welt/s.webp"),
    ultimate: require("../components/image/characters/welt/u.webp"),
    talent: require("../components/image/characters/welt/t.webp"),
    technique: require("../components/image/characters/welt/q.webp"),
    name: "welt",
    translation:{
      "en-us": "Welt",
      "zh-cn": "瓦尔特",
      "zh-tw": "瓦爾特"
    },
    story:[{
      "en-us":
        "The wise and experienced former Anti-Entropy Sovereign who inherits the name of the world — Welt. He has saved Earth from annihilation time and time again.",
      "zh-cn": "老成持重的前逆熵盟主，继承了「世界」之名，曾屡次拯救世界于灭亡的边缘。",
      "zh-tw": "老成持重的前逆熵盟主，繼承了「世界」之名，曾屢次拯救世界於滅亡的邊緣。",
    },
    {
      "en-us":
        "After the incident with St. Fountain came to a close, welt had no choice but to venture with the initiator of the incident to the other side of the portal.",
      "zh-cn": "圣方丹事件结束后，瓦尔特被迫与事件始作俑者去向星门另一侧。",
      "zh-tw": "聖方丹事件結束後，瓦爾特被迫與事件始作俑者去向星門另一側。",
    },{
      "en-us":
        "Perhaps even he did not expect the new journey nor companions that awaited him there.",
      "zh-cn": "或许连他自己也未曾料到，在那里等待着的，将是全新的旅途和同伴。",
      "zh-tw": "或許連他自己也未曾料到，在那裡等待著的，將是全新的旅途和同伴。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 1.68,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 1,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/welt/u.webp"),
      description: {
        "en-us": "(90% X ATT) * (100% + Imaginary Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(90% X 攻击力) * (100% + 虚数属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
      },
      calculation: "(90% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Nihility",
    element: "Imaginary",
    pathIcon: nihilityIcon,
    elementIcon: imaginaryIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },

  {
    id: "1209",
    image: require("../components/image/characters/yanqing/yanqing.webp"),
    background: require("../components/image/characters/yanqing/yanqing_full.png"),
    e1: require("../components/image/characters/yanqing/e1.webp"),
    e2: require("../components/image/characters/yanqing/e2.webp"),
    e3: require("../components/image/characters/yanqing/e3.webp"),
    e4: require("../components/image/characters/yanqing/e4.webp"),
    e5: require("../components/image/characters/yanqing/e5.webp"),
    e6: require("../components/image/characters/yanqing/e6.webp"),
    basic_attack: require("../components/image/characters/yanqing/b.webp"),
    skill: require("../components/image/characters/yanqing/s.webp"),
    ultimate: require("../components/image/characters/yanqing/u.webp"),
    talent: require("../components/image/characters/yanqing/t.webp"),
    technique: require("../components/image/characters/yanqing/q.webp"),
    name: "yanqing",
    translation:{
      "en-us": "Yanqing",
      "zh-cn": "彦卿",
      "zh-tw": "彥卿"
    },
    story:[{
      "en-us":
        "The spirited lieutenant of the Xianzhou Luofu, and also its most proficient swordsman.",
      "zh-cn": "意气飞扬的云骑骁卫，仙舟「罗浮」最强剑士。",
      "zh-tw": "意氣飛揚的雲騎驍衛，仙舟「羅浮」最強劍士。",
    },
    {
      "en-us":
        "He is born for swords and obsessed with them. Whenever a sword rests in his hand, none would dare to underestimate this genius still in the early days of his youth.",
      "zh-cn": "为剑生亦为剑痴，当彦卿手中握剑时，无人敢小看这位尚在总角之年的天才。",
      "zh-tw": "為劍生亦為劍痴，當彥卿手中握劍時，無人敢小看這位尚在總角之年的天才。",
    },{
      "en-us":
        "Perhaps the only thing capable of dulling his treasured blade's sharp edge is time.",
      "zh-cn": "或许能让手中宝剑微微收敛锋芒的，只有时间。",
      "zh-tw": "或許能讓手中寶劍微微收斂鋒芒的，只有時間。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 1.68,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Skill",
        "zh-cn": "战技",
        "zh-tw": "戰技"
      },
      icon: require("../components/image/characters/yanqing/s.webp"),
      description: {
        "en-us": "(110% X ATT) * (100% + Ice Boost %) * (100% - CR% + CR% x (100% + CD%)) * (Speed / 100)",
        "zh-cn": "(110% X 攻击力) * (100% + 冰属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (速度/ 100)",
        "zh-tw": "(110% X 攻擊力) * (100% + 冰屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * (速度/ 100)",
      },
      calculation: "(110% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * ((S) / 100)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Hunt",
    element: "Ice",
    pathIcon: huntIcon,
    elementIcon: iceIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1207",
    image: require("../components/image/characters/yukong/yukong.webp"),
    background: require("../components/image/characters/yukong/yukong_full.webp"),
    e1: require("../components/image/characters/yukong/e1.webp"),
    e2: require("../components/image/characters/yukong/e2.webp"),
    e3: require("../components/image/characters/yukong/e3.webp"),
    e4: require("../components/image/characters/yukong/e4.webp"),
    e5: require("../components/image/characters/yukong/e5.webp"),
    e6: require("../components/image/characters/yukong/e6.webp"),
    basic_attack: require("../components/image/characters/yukong/b.webp"),
    skill: require("../components/image/characters/yukong/s.webp"),
    ultimate: require("../components/image/characters/yukong/u.webp"),
    talent: require("../components/image/characters/yukong/t.webp"),
    technique: require("../components/image/characters/yukong/q.webp"),
    name: "yukong",
    translation:{
      "en-us": "Yukong",
      "zh-cn": "驭空",
      "zh-tw": "馭空"
    },
    story:[{
      "en-us":
        "The Xianzhou Luofu's Head of the Sky-Faring Commission is gentle, yet seasoned and authoritative. ",
      "zh-cn": "仙舟「罗浮」天舶司的主司，性情温和，处事老练。",
      "zh-tw": "仙舟「羅浮」天舶司的主司，性情溫和，處事老練。",
    },
    {
      "en-us":
        "Having been a pilot since young, she had become the commission's head with her outstanding combat achievements, yet she no longer flies due to a particularly brutal battle. ",
      "zh-cn": "年轻时作为飞行士久历战阵，以优秀的战绩成为主司，却因一场惨烈的战争不再飞翔天际。",
      "zh-tw": "年輕時作為飛行士久歷戰陣，以優秀的戰績成為主司，卻因一場慘烈的戰爭不再飛翔天際。",
    },{
      "en-us":
        "Now, her shine had already dimmed as she shifted her focus to official duties, but she is always seen guiding the course of the Luofu.",
      "zh-cn": "如今虽已褪去锋芒，专心操持公务，但「罗浮」的航向上始终有她的身影。",
      "zh-tw": "如今雖已褪去鋒芒，專心操持公務，但「羅浮」的航向上始終有她的身影。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 1.68,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/yukong/u.webp"),
      description: {
        "en-us": "(228% X ATT) * (100% + Imaginary Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(228% X 攻击力) * (100% + 虚数属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(228% X 攻擊力) * (100% + 虛數屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(228% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Harmony",
    element: "Imaginary",
    pathIcon: harmonyIcon,
    elementIcon: imaginaryIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1213",
    image: require("../components/image/characters/dan-heng-imbibitor-lunae/dan-heng-imbibitor-lunae.webp"),
    background: require("../components/image/characters/dan-heng-imbibitor-lunae/dan-heng-imbibitor-lunae_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "suppressing-edict")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "immortal-lumintwig")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "immortal-aeroblossom")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "immortal-scionette")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "worldbreaker-blade")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "lifeless-blade")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "shattered-blade")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/dan-heng-imbibitor-lunae/e1.webp"),
    e1_translation: {
      "en-us": "Increase the number of stackable Righteous Heart stacks by 4, and 1 extra stack of Righteous Heart will be gained for each hit per attack.",
      "zh-cn": "使【亢心】的可叠加层数增加4层，且每段攻击额外获得1层【亢心】",
      "zh-tw": "使【亢心】的可疊加層數增加4層，且每段攻擊額外獲得1層【亢心】"
    },
    e2: require("../components/image/characters/dan-heng-imbibitor-lunae/e2.webp"),
    e2_translation: {
      "en-us": "After using his Ultimate, Dan Heng • Imbibitor Lunae’s action is Advanced Forward by 100% and gains 1 extra Squama Sacrosancta.",
      "zh-cn": "施放终结技后丹恒•饮月行动提前100%，并且会额外获得1个【逆鳞】。",
      "zh-tw": "施放終結技後丹恆•飲月行動提前100%，並且會額外獲得1個【逆鱗】。"
    },
    e3: require("../components/image/characters/dan-heng-imbibitor-lunae/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2,最多不超过15级; 普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2,最多不超過15級; 普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/dan-heng-imbibitor-lunae/e4.webp"),
    e4_translation: {
      "en-us": "Buffs provided by Dominating Roar persists until the end of this character’s next turn.",
      "zh-cn": "【叱咤】的增益效果会持续到下一个自身回合结束。",
      "zh-tw": "【叱吒】的增益效果會持續到下一個自身回合結束。"
    },
    e5: require("../components/image/characters/dan-heng-imbibitor-lunae/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级; 天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級; 天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/dan-heng-imbibitor-lunae/e6.webp"),
    e6_translation: {
      "en-us": "After any other ally uses their Ultimate, the Imaginary RES PEN of Dan Heng • Imbibitor Lunae’s next Fulgurant Leap attack increases by 20%, up to 3 stacks.",
      "zh-cn": "我方其他角色施放终结技后，丹恒·饮月下一次施放【盘拿耀跃】时虚数属性抗性穿透提高20%，该效果最多叠加3层。",
      "zh-tw": "我方其他角色施放終結技後，丹恆·飲月下一次施放【盤拿耀躍】時虛數屬性抗性穿透提高20%，該效果最多疊加3層。"
    },
    basic_attack: require("../components/image/characters/dan-heng-imbibitor-lunae/b.webp"),
    skill: require("../components/image/characters/dan-heng-imbibitor-lunae/s.webp"),
    ultimate: require("../components/image/characters/dan-heng-imbibitor-lunae/u.webp"),
    talent: require("../components/image/characters/dan-heng-imbibitor-lunae/t.webp"),
    technique: require("../components/image/characters/dan-heng-imbibitor-lunae/q.webp"),
    name: "dan-heng-imbibitor-lunae",
    translation:{
      "en-us": "Dan Heng • Imbibitor Lunae",
      "zh-cn": "丹恒 • 饮月君",
      "zh-tw": "丹恆 • 飲月君"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 90
        }, 
        {
          recommended: false,
          value: 53
        },  
        {
          recommended: false,
          value: 50
        },  
        {
          recommended: false,
          value: 38
        }, 
        {
          recommended: true,
          value: 34
        }, 
        {
          recommended: false,
          value: 12
        }],
      skill:[
        {
          stat: "basic_attack",
          value: "1",
          level: 6
        },
        {
        stat: "ultimate",
        value: "2",
        level: 9
      },
      {
        stat: "skill",
        value: "2",
        level: 9
      },
      {
        stat: "talent",
        value: "2",
        level: 9
      },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Star Veil",
          "zh-cn": "伏辰",
          "zh-tw": "伏辰"
        },
        effect_translation: {
          "en-us": "At the start of the battle, immediately regenerates 15 Energy.",
          "zh-cn": "战斗开始时，立即恢复15点能量。",
          "zh-tw": "戰鬥開始時，立即恢復15點能量。"
        },
        image: require("../components/image/characters/dan-heng-imbibitor-lunae/star_veil.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Aqua Reign",
          "zh-cn": "修禹",
          "zh-tw": "修禹"
        },
        effect_translation: {
          "en-us": "Increases the chance to resist Crowd Control debuffs by 35%.",
          "zh-cn": "抵抗控制类负面状态的概率提高35%。",
          "zh-tw": "抵抗控制類負面狀態的概率提高35%。"
        },
        image: require("../components/image/characters/dan-heng-imbibitor-lunae/aqua_reign.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Jolt Anew",
          "zh-cn": "起蟄",
          "zh-tw": "起蟄"
        },
        effect_translation: {
          "en-us": "This character’s CRIT DMG increases by 24% when dealing DMG to enemy targets with Imaginary Weakness.",
          "zh-cn": "对拥有虚数属性弱点的敌方目标造成伤害时，暴击伤害提高24%。",
          "zh-tw": "對擁有虛數屬性弱點的敵方目標造成傷害時，暴擊傷害提高24%。"
        },
        image: require("../components/image/characters/dan-heng-imbibitor-lunae/jolt_anew.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "DBM")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "CR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "DBM")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "AR")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "CR")[0],
        value: "70%"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "140%"
      },
      {
        stat: property.filter(id => id.id === "A")[0],
        value: "2600"
      }],
      stat:[{
        stat: property.filter(id => id.id === "CR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CD")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "AR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "HR")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "102_0")[0],
          set: 4,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "112_0")[0],
          set: 4,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "309_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23015")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120,
            },
            {
              rank: "I",
              score: 100,
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "24000")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 80,
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21019")[0],
          recommended: false,
          score: [
            {
              rank: "I",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Hypercarry",
            "zh-cn": "三保一",
            "zh-tw": "三保一",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1213"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1009", "1202", "1207"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1106", "1006"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1211", "1208", "1203", "1105"]
            },
          ]
        },
        {
          name:{
            "en-us": "Mono Imaginary",
            "zh-cn": "纯虚数",
            "zh-tw": "純虛數",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1213"]
            },
            {
              role:{
              "en-us": "Sub DPS",
              "zh-cn": "副C",
              "zh-tw": "副C",
              },
              id: ["1004"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1207"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1203"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
        "High Elder of the Luofu, bearer of the Azure Dragon's legacy, bringing forth clouds and rain, and entrusted with the duty of guarding the Ambrosial Arbor.",
      "zh-cn": "罗浮龙尊，掌苍龙之传。行云布雨，膺责守望不死建木。",
      "zh-tw": "羅浮龍尊，掌蒼龍之傳。行雲布雨，膺責守望不死建木。",
    },
    {
      "en-us":
        "Revered with the title of \"Imbibitor Lunae.\" — History of the Xianzhou: The Five Dragons' Exodus",
      "zh-cn": "尊号「饮月君」。——《仙舟通鉴 • 五龙远徙》",
      "zh-tw": "尊號「飲月君」。——《仙舟通鑑 • 五龍遠徙》",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 1.68,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "MWW4": 1,
        "MWW2": 1,
        "WBD4" : 1,
        "WBD2" : 1,
        "RA2" : 1
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/dan-heng-imbibitor-lunae/u.webp"),
      description: {
        "en-us": "(100% X ATT) * (100% + Imaginary Boost %) * (100% - CR% + CR% x (100% + CD%)) * (Speed / 100) * Relic Set",
        "zh-cn": "(100% X 攻击力) * (100% + 虚数属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%)) * (速度/ 100) * 遗器套装",
        "zh-tw": "(100% X 攻擊力) * (100% + 虛數屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%)) * (速度/ 100) * 遺器套裝",
      },
      calculation: "(100% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD))) * ((S) / 100) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Destruction",
    element: "Imaginary",
    pathIcon: destructionIcon,
    elementIcon: imaginaryIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  
  {
    id: "1214",
    image: require("../components/image/characters/xueyi/xueyi.webp"),
    background: require("../components/image/characters/xueyi/xueyi_full.webp"),
    e1: require("../components/image/characters/xueyi/e1.webp"),
    e2: require("../components/image/characters/xueyi/e2.webp"),
    e3: require("../components/image/characters/xueyi/e3.webp"),
    e4: require("../components/image/characters/xueyi/e4.webp"),
    e5: require("../components/image/characters/xueyi/e5.webp"),
    e6: require("../components/image/characters/xueyi/e6.webp"),
    basic_attack: require("../components/image/characters/xueyi/b.webp"),
    skill: require("../components/image/characters/xueyi/s.webp"),
    ultimate: require("../components/image/characters/xueyi/u.webp"),
    talent: require("../components/image/characters/xueyi/t.webp"),
    technique: require("../components/image/characters/xueyi/q.webp"),
    name: "xueyi",
    translation:{
      "en-us": "Xueyi",
      "zh-cn": "雪衣",
      "zh-tw": "雪衣"
    },
    story:[{
      "en-us": "One of the judges of the Ten-Lords Commission. Of the four duties of a judge (detention, interrogation, incarceration, and punishment), she is charged with detention.",
      "zh-cn": "仙舟「罗浮」的十王司判官之一，负责拘、锁、刑、问的四判官中的「拘」。",
      "zh-tw": "仙舟「羅浮」的十王司判官之一，負責拘、鎖、刑、問的四判官中的「拘」。",
    },
    {
      "en-us": "Holding iron chains and a Marasunder Awl in her hands, she tirelessly tracks down wanted criminals and subdues them.",
      "zh-cn": "手持铁索与破魔锥，不知疲倦地寻索重犯，将其勾摄镇伏。",
      "zh-tw": "手持鐵索與破魔錐，不知疲倦地尋索重犯，將其勾攝鎮伏。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 1.2,
        "BE" : 0.5,
        "CR" : 2,
        "CD" : 1,
        "D" : 0.02,
        "DR" : 0.1,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 1.68,
        "DBW": 0,
        "EG" : 3.35,
        "EH" : 0.3,
        "ER" : 0.5,
        "H" : 0.02,
        "HR" : 0.6,
        "O" : 0,
        "S" : 2.5,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/xueyi/u.webp"),
      description: {
        "en-us": "(228% X ATT) * (100% + Imaginary Boost %) * (100% - CR% + CR% x (100% + CD%))",
        "zh-cn": "(228% X 攻击力) * (100% + 虚数属性伤害提高 %) * (100% - 暴击% + 暴伤% x (100% + 暴伤%))",
        "zh-tw": "(228% X 攻擊力) * (100% + 虛數屬性傷害提高 %) * (100% - 暴擊% + 暴傷% x (100% + 暴傷%))",
      },
      calculation: "(228% * (A)) * (100%+(ED)) * (100%-(CR) + (CR)*(100%+(CD)))"
    },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Destruction",
    element: "Quantum",
    pathIcon: destructionIcon,
    elementIcon: quantumIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1208",
    image: require("../components/image/characters/fuxuan/fuxuan.webp"),
    background: require("../components/image/characters/fuxuan/fuxuan_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "308K"
      },
      {
        item: items.filter(id => id.id === "nail-of-the-ape")[0],
        value: "65"
      },
      {
        item: items.filter(id => id.id === "artifexs-gyreheart")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "artifexs-cogwheel")[0],
        value: "15"
      },
      {
        item: items.filter(id => id.id === "artifexs-module")[0],
        value: "15"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "3M"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "8"
      },
      {
        item: items.filter(id => id.id === "artifexs-gyreheart")[0],
        value: "58"
      },
      {
        item: items.filter(id => id.id === "artifexs-cogwheel")[0],
        value: "56"
      },
      {
        item: items.filter(id => id.id === "artifexs-module")[0],
        value: "41"
      },
      {
        item: items.filter(id => id.id === "safeguard-of-amber")[0],
        value: "139"
      },
      {
        item: items.filter(id => id.id === "oath-of-steel")[0],
        value: "69"
      },
      {
        item: items.filter(id => id.id === "endurance-of-bronze")[0],
        value: "18"
      },
    ],
    e1: require("../components/image/characters/fuxuan/e1.webp"),
    e1_translation: {
      "en-us": "All units with Knowledge increase their CRIT DMG by 30%.",
      "zh-cn": "【鉴知】使暴击伤害提高30%。",
      "zh-tw": "【鑑知】使暴擊傷害提高30%。"
    },
    e2: require("../components/image/characters/fuxuan/e2.webp"),
    e2_translation: {
      "en-us": "When Matrix of Prescience is active while any team member is struck by a killing blow, all allies who were struck by a killing blow during this action will not be knocked down and 70% of their Max HP is immediately restored. This effect can trigger 1 time per battle.",
      "zh-cn": "【穷观阵】开启时，若我方目标受到致命伤害，则本次行动中所有受到致命伤害的我方目标都不会陷入无法战斗状态，并立即回复等同于其自身生命上限70%的生命值。该效果单场战斗中可以触发1次。",
      "zh-tw": "【窮觀陣】開啟時，若我方目標受到致命傷害，則本次行動中所有受到致命傷害的我方目標都不會陷入無法戰斗狀態，並立即回復等同於其自身生命上限70%的生命值。該效果單場戰鬥中可以觸發1次。"
    },
    e3: require("../components/image/characters/fuxuan/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "战技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "戰技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e4: require("../components/image/characters/fuxuan/e4.webp"),
    e4_translation: {
      "en-us": "When other allies under Matrix of Prescience are attacked, Fu Xuan regenerates 5 Energy.",
      "zh-cn": "当处于【穷观阵】的我方其他目标受到攻击后，符玄恢复5点能量。",
      "zh-tw": "當處於【窮觀陣】的我方其他目標受到攻擊後，符玄恢復5點能量。"
    },
    e5: require("../components/image/characters/fuxuan/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "终结技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "終結技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e6: require("../components/image/characters/fuxuan/e6.webp"),
    e6_translation: {
      "en-us": "Once Matrix of Prescience is activated, it will accumulate the total HP lost by all team members in the current battle. Fu Xuan's Ultimate DMG will increase by 200% of the total HP lost by all team members in the current battle. The total HP lost by all team members in the current battle is capped at 120% of Fu Xuan's Max HP. This value will be reset and re-accumulated after her Ultimate has been used.",
      "zh-cn": "【穷观阵】开启时，会累计我方全体本场战斗中的已损失生命值。符玄施放终结技造成的伤害提高，提高数值等同于本场战斗中累计已损失生命值的200%。本场战斗中累计已损失生命值最高不超过符玄生命上限的120%，施放终结技后会清空并进行重新累计。",
      "zh-tw": "【窮觀陣】開啟時，會累計我方全體本場戰鬥中的已損失生命值。符玄施放終結技造成的傷害提高，提高數值等同於本場戰鬥中累計已損失生命值的200%。本場戰鬥中累計已損失生命值最高不超過符玄生命上限的120%，施放終結技後會清空並進行重新累計。"
    },
    basic_attack: require("../components/image/characters/fuxuan/b.webp"),
    skill: require("../components/image/characters/fuxuan/s.webp"),
    ultimate: require("../components/image/characters/fuxuan/u.webp"),
    talent: require("../components/image/characters/fuxuan/t.webp"),
    technique: require("../components/image/characters/fuxuan/q.webp"),
    name: "fuxuan",
    translation:{
      "en-us": "Fu Xuan",
      "zh-cn": "符玄",
      "zh-tw": "符玄"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 80
        }, 
        {
          recommended: false,
          value: 53
        },  
        {
          recommended: true,
          value: 50
        },  
        {
          recommended: false,
          value: 38
        }, 
        {
          recommended: true,
          value: 34
        }, 
        {
          recommended: true,
          value: 25
        }],
      skill:[
      {
        stat: "skill",
        value: "1",
        level: 9
      },
      {
        stat: "talent",
        value: "1",
        level: 9
      },
      {
      stat: "ultimate",
      value: "2",
      level: 6
    },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Dunjia, the Metamystic",
          "zh-cn": "遁甲星舆",
          "zh-tw": "遁甲星輿"
        },
        effect_translation: {
          "en-us": "At the start of the battle, immediately regenerates 15 Energy.",
          "zh-cn": "施放终结技时为我方其他目标回复等同于符玄5%生命上限+133的生命值。",
          "zh-tw": "施放終結技時為我方其他目標回復等同於符玄5%生命上限+133的生命值。"
        },
        image: require("../components/image/characters/fuxuan/dunjia-the-metamystic.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Taiyi, the Macroscopic",
          "zh-cn": "太乙式盘",
          "zh-tw": "太乙式盤"
        },
        effect_translation: {
          "en-us": "Increases the chance to resist Crowd Control debuffs by 35%.",
          "zh-cn": "【穷观阵】开启时，符玄施放战技将额外恢复20点能量。",
          "zh-tw": "【窮觀陣】開啟時，符玄施放戰技將額外恢復20點能量。"
        },
        image: require("../components/image/characters/fuxuan/taiyi-the-macroscopic.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Liuren, the Sexagenary",
          "zh-cn": "六壬兆堪",
          "zh-tw": "六壬兆堪"
        },
        effect_translation: {
          "en-us": "This character’s CRIT DMG increases by 24% when dealing DMG to enemy targets with Imaginary Weakness.",
          "zh-cn": "【穷观阵】开启时，若敌方目标对我方施加了控制类负面状态，则我方全体抵抗本次行动中敌方目标施加的所有控制类负面状态。该效果可以触发1次。再次开启【穷观阵】后将刷新效果可触发次数。",
          "zh-tw": "【窮觀陣】開啟時，若敵方目標對我方施加了控制類負面狀態，則我方全體抵抗本次行動中敵方目標施加的所有控制類負面狀態。該效果可以觸發1次。再次開啟【窮觀陣】後將刷新效果可觸發次數。"
        },
        image: require("../components/image/characters/fuxuan/liuren-the-sexagenary.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "HR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "CR")[0],
        value: "3"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "S")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "EG")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "H")[0],
        value: "6500"
      },
      {
        stat: property.filter(id => id.id === "D")[0],
        value: "1500"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "30%"
      }],
      stat:[{
        stat: property.filter(id => id.id === "HR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "H")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "106_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "113_0")[0],
          set: 2,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "302_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "310_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23011")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "24002")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 70
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21009")[0],
          recommended: false,
          score: [
            {
              rank: "I",
              score: 60
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Mono Quantum",
            "zh-cn": "纯量子",
            "zh-tw": "純量子",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1102"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1006"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1306"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1208"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
        "The head of the Xianzhou Luofu's Divination Commission. A confident and blunt sage.",
      "zh-cn": "仙舟「罗浮」太卜司之首，自信耿直的智者。",
      "zh-tw": "仙舟「羅浮」太卜司之首，自信耿直的智者。",
    },
    {
      "en-us":
        "Using her third eye and the Matrix of Prescience, Fu Xuan calculates the Xianzhou's route and predicts the fortune of future events. She firmly believes that everything she does is the \"best solution\" for the situation.",
      "zh-cn": "凭借第三眼与穷观阵为仙舟占算航路，预卜事务吉凶，坚信自己所做的一切便是事情的「最优解」。",
      "zh-tw": "憑藉第三眼與窮觀陣為仙舟佔算航路，預卜事務吉凶，堅信自己所做的一切便是事情的「最優解」。",
    },
    {
      "en-us":
        "Fu Xuan is waiting for the general's promised \"abdication.\" However, that day still seems... very far away.",
      "zh-cn": "符玄等待着将军承诺的「退位让贤」，然而这一天的到来…似乎还遥遥无期。",
      "zh-tw": "符玄等待著將軍承諾的「退位讓賢」，然而這一天的到來…似乎還遙遙無期。",
    },
  ],
    formula:{
      stats:{
        "A" : 0.04,
        "AR" : 0.2,
        "BE" : 0.5,
        "CR" : 0.2,
        "CD" : 0.1,
        "D" : 0.04,
        "DR" : 1.2,
        "DBF" : 0,
        "DBI": 0,
        "DBM": 0,
        "DBP": 0,
        "DBL": 0,
        "DBQ" : 0,
        "DBW" : 0,
        "EG" : 3.35,
        "EH" : 0.2,
        "ER" : 1.5,
        "H" : 0.05,
        "HR" : 1.5,
        "O" : 0,
        "S" : 2.5,
      },
      relic_set_stats: {
        "GWS2" : 1,
        "LD2" : 1,
        "FA2" : 1,
        "BK2" : 1,
      },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/fuxuan/u.webp"),      
      description: {
        "en-us": "(100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (HP / 100) * (DEF / 100) * Relic Set",
        "zh-cn": "(100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
        "zh-tw": "(100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
      },
      calculation: "(100%+(EG)) * (100%+(ER)) * ((S) / 100) * ((H)/100) * ((D)/100) * (RS)"
    },
    rarity_number: "five-star",
    rarity: fiveStarsIcon,
    path: "Preservation",
    element: "Quantum",
    pathIcon: preservationIcon,
    elementIcon: quantumIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
  {
    id: "1110",
    image: require("../components/image/characters/lynx/lynx.webp"),
    background: require("../components/image/characters/lynx/lynx_full.webp"),
    ascension_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "240K"
      },
      {
        item: items.filter(id => id.id === "nail-of-the-ape")[0],
        value: "50"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "13"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "4"
      },
    ],
    trace_materials:
    [
      {
        item: items.filter(id => id.id === "credit")[0],
        value: "2.4M"
      },
      {
        item: items.filter(id => id.id === "regret-of-infinite-ochema")[0],
        value: "12"
      },
      {
        item: items.filter(id => id.id === "tracks-of-destiny")[0],
        value: "5"
      },
      {
        item: items.filter(id => id.id === "squirming-core")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "glimmering-core")[0],
        value: "42"
      },
      {
        item: items.filter(id => id.id === "extinguished-core")[0],
        value: "28"
      },
      {
        item: items.filter(id => id.id === "flower-of-eternity")[0],
        value: "105"
      },
      {
        item: items.filter(id => id.id === "sprout-of-life")[0],
        value: "54"
      },
      {
        item: items.filter(id => id.id === "seed-of-abundance")[0],
        value: "12"
      },
    ],
    e1: require("../components/image/characters/lynx/e1.webp"),
    e1_translation: {
      "en-us": "When healing allies with HP equal to or lower than 50%, Lynx's Outgoing Healing increases by 20%. This effect also works on continuous healing.",
      "zh-cn": "为当前生命值百分比小于等于50%的我方目标提供治疗时，玲可的治疗量提高20%，该效果对持续治疗效果也会生效。",
      "zh-tw": "為當前生命值百分比小於等於50%的我方目標提供治療時，玲可的治療量提高20%，該效果對持續治療效果也會生效。"
    },
    e2: require("../components/image/characters/lynx/e2.webp"),
    e2_translation: {
      "en-us": "A target with \"Survival Response\" can resist debuff application for 1 time(s).",
      "zh-cn": "持有【求生反应】的目标被施加负面效果时可抵抗1次负面效果。",
      "zh-tw": "持有【求生反應】的目標被施加負面效果時可抵抗1次負面效果。"
    },
    e3: require("../components/image/characters/lynx/e3.webp"),
    e3_translation: {
      "en-us": "Skill Lv. +2, up to a maximum of Lv. 15; Basic ATK Lv. +1, up to a maximum of Lv. 10.",
      "zh-cn": "战技等级+2，最多不超过15级；普攻等级+1，最多不超过10级。",
      "zh-tw": "戰技等級+2，最多不超過15級；普攻等級+1，最多不超過10級。"
    },
    e4: require("../components/image/characters/lynx/e4.webp"),
    e4_translation: {
      "en-us": "When \"Survival Response\" is gained, increases the target's ATK by an amount equal to 3% of Lynx's Max HP for 1 turn(s).",
      "zh-cn": "获得【求生反应】时，使该目标攻击力提高，提高数值等同于玲可3%生命上限的攻击力，持续1回合。",
      "zh-tw": "獲得【求生反應】時，使該目標攻擊力提高，提高數值等同于玲可3%生命上限的攻擊力，持續1回合。。"
    },
    e5: require("../components/image/characters/lynx/e5.webp"),
    e5_translation: {
      "en-us": "Ultimate Lv. +2, up to a maximum of Lv. 15.; Talent Lv. +2, up to a maximum of Lv. 15.",
      "zh-cn": "终结技等级+2，最多不超过15级；天赋等级+2，最多不超过15级。",
      "zh-tw": "終結技等級+2，最多不超過15級；天賦等級+2，最多不超過15級。"
    },
    e6: require("../components/image/characters/lynx/e6.webp"),
    e6_translation: {
      "en-us": "Additionally boosts the Max HP increasing effect of \"Survival Response\" by an amount equal to 6% of Lynx's Max HP and increases Effect RES by 30%.",
      "zh-cn": "【求生反应】的生命上限提高效果额外提高，提高数值等同于玲可6%的生命上限，并使其效果抵抗提高30%。",
      "zh-tw": "【求生反應】的生命上限提高效果額外提高，提高數值等同于玲可6%的生命上限，並使其效果抵抗提高30%。"
    },
    basic_attack: require("../components/image/characters/lynx/b.webp"),
    skill: require("../components/image/characters/lynx/s.webp"),
    ultimate: require("../components/image/characters/lynx/u.webp"),
    talent: require("../components/image/characters/lynx/t.webp"),
    technique: require("../components/image/characters/lynx/q.webp"),
    name: "lynx",
    translation:{
      "en-us": "Lynx",
      "zh-cn": "玲可",
      "zh-tw": "玲可"
    },
    guide:{
      eidolons: [
        {
          recommended: true,
          value: 80
        }, 
        {
          recommended: false,
          value: 53
        },  
        {
          recommended: true,
          value: 50
        },  
        {
          recommended: false,
          value: 33
        }, 
        {
          recommended: true,
          value: 30
        }, 
        {
          recommended: true,
          value: 15
        }],
      skill:[
        {
        stat: "ultimate",
        value: "1",
        level: 9
      },
      {
        stat: "skill",
        value: "1",
        level: 9
      },
      {
        stat: "talent",
        value: "1",
        level: 9
      },
      {
        stat: "basic_attack",
        value: "3",
        level: 1
      },
      ],
      extra_skill:[{
        translation: {
          "en-us": "Advance Surveying",
          "zh-cn": "提前勘测",
          "zh-tw": "提前勘測"
        },
        effect_translation: {
          "en-us": "After a target with \"Survival Response\" is hit, Lynx regenerates 2 Energy immediately.",
          "zh-cn": "当持有【求生反应】的目标受到攻击后，玲可立即恢复2点能量。",
          "zh-tw": "當持有【求生反應】的目標受到攻擊後，玲可立即恢復2點能量。"
        },
        image: require("../components/image/characters/lynx/advance-surveying.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Exploration Techniques",
          "zh-cn": "探险技术",
          "zh-tw": "探險技術"
        },
        effect_translation: {
          "en-us": "Increases the chance to resist Crowd Control debuffs by 35%.",
          "zh-cn": "抵抗控制类负面状态的概率提高35%。",
          "zh-tw": "抵抗控制類負面狀態的概率提高35%。"
        },
        image: require("../components/image/characters/lynx/exploration-techniques.webp"),
        value: "1"
      },
      {
        translation: {
          "en-us": "Survival in the Extreme",
          "zh-cn": "极境求生",
          "zh-tw": "極境求生"
        },
        effect_translation: {
          "en-us": "Extends the duration of the continuous healing effect granted by Talent for 1 turn(s).",
          "zh-cn": "天赋产生的持续回复效果延长1回合。",
          "zh-tw": "天賦產生的持續回復效果延長1回合。"
        },
        image: require("../components/image/characters/lynx/survival-in-the-extreme.webp"),
        value: "1"
      },
      ],
      traces:[{
        stat: property.filter(id => id.id === "HR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
      ],
      main_stat:
        [{
          main_stat: mainStats.filter(id => id.id === "body")[0],
          stat: property.filter(id => id.id === "O")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "feet")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "planar_sphere")[0],
          stat: property.filter(id => id.id === "HR")[0]
        },
        {
          main_stat: mainStats.filter(id => id.id === "link_rope")[0],
          stat: property.filter(id => id.id === "EG")[0]
        },
      ],
      target_stats: [{
        stat: property.filter(id => id.id === "H")[0],
        value: "5000"
      },
      {
        stat: property.filter(id => id.id === "D")[0],
        value: "1500"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "30%"
      }],
      stat:[{
        stat: property.filter(id => id.id === "HR")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "S")[0],
        value: "1"
      },
      {
        stat: property.filter(id => id.id === "ER")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "DR")[0],
        value: "2"
      },
      {
        stat: property.filter(id => id.id === "H")[0],
        value: "3"
      },
    ],
      cavern_relic: [
        {
          relic: relic.filter(id => id.id === "101_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "114_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "113_0")[0],
          set: 2,
          score: 100,
        },
      ],
      planar_ornaments: [
        {
          relic: relic.filter(id => id.id === "302_0")[0],
          set: 2,
          score: 100,
        },
        {
          relic: relic.filter(id => id.id === "310_0")[0],
          set: 2,
          score: 100,
        },
      ],
      max_lightcone_score: 120,
      lightcone: [ 
        {
          lightcone: lightConeRows.filter(id => id.id === "23013")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 120
            },
            {
              rank: "I",
              score: 100
            }
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21028")[0],
          recommended: false,
          score: [
            {
              rank: "I",
              score: 80
            },
          ]
        },
        {
          lightcone: lightConeRows.filter(id => id.id === "21021")[0],
          recommended: true,
          score: [
            {
              rank: "V",
              score: 70
            },
          ]
        },
      ],
      temp_comp: [
        {
          name:{
            "en-us": "Mono Quantum",
            "zh-cn": "纯量子",
            "zh-tw": "純量子",
          },
          team:[
            {
              role:{
              "en-us": "Main DPS",
              "zh-cn": "主C",
              "zh-tw": "主C",
              },
              id: ["1102"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1006"]
            },
            {
              role:{
              "en-us": "Support",
              "zh-cn": "辅助",
              "zh-tw": "輔助",
              },
              id: ["1306"]
            },
            {
              role:{
              "en-us": "Survival",
              "zh-cn": "生存",
              "zh-tw": "生存",
              },
              id: ["1208"]
            },
          ]
        }
      ]
    },
    story:[{
      "en-us":
      "\"In the Landau family, things are quite simple: If you want to do something, just go ahead and do it.\" The youngest daughter of the Landau family, and one of Belobog's best extreme environments explorers.",
      "zh-cn": "「我们朗道家的事情都很简单：想去做的话就去。」朗道家年龄最小的女孩，贝洛伯格首屈一指的极地探险家。",
      "zh-tw": "「我們朗道家的事情都很簡單：想去做的話就去。」朗道家年齡最小的女孩，貝洛伯格首屈一指的極地探險家。",
    },
    {
      "en-us":
        "She is highly capable of action despite her apparent lack of motivation. She's only appears introverted and lazy to avoid unnecessary socialization.",
      "zh-cn": "看似慵懒，实际上执行力极强。散发生人勿近的气场只是为了避免不必要的社交。",
      "zh-tw": "看似慵懶，實際上執行力極強。散發生人勿近的氣場只是為了避免不必要的社交。",
    },
    {
      "en-us":
        "As for what counts as unnecessary socialization — \"Um, aren't all socialization unnecessary?\"",
      "zh-cn": "至于如何定义不必要的社交——「呃…所有社交不都是没必要的吗？」",
      "zh-tw": "至於如何定義不必要的社交——「呃…所有社交不都是沒必要的嗎？」",
    },
  ],
  formula:{
    stats:{
      "A" : 0.04,
      "AR" : 0.2,
      "BE" : 0.5,
      "CR" : 0.2,
      "CD" : 0.1,
      "D" : 0.04,
      "DR" : 1.2,
      "DBF" : 0,
      "DBI": 0,
      "DBM": 0,
      "DBP": 0,
      "DBL": 0,
      "DBQ" : 0,
      "DBW" : 0,
      "EG" : 3.35,
      "EH" : 0.2,
      "ER" : 1.5,
      "H" : 0.05,
      "HR" : 1.5,
      "O" : 1.8,
      "S" : 2.5,
    },
    relic_set_stats: {
      "MTH2" : 1,
      "MTH4" : 1,
      "PWC4" : 1,
      "PWC2" : 1,
      "LD2" : 1,
      "FA2" : 1,
      "BK2" : 1,
      "SV2" : 1,
    },
      name: {
        "en-us": "Ultimate",
        "zh-cn": "终结技",
        "zh-tw": "終結技"
      },
      icon: require("../components/image/characters/lynx/u.webp"),
      description: {
        "en-us": "(100% + Energy Regeneration Rate %) * (100% + Effect RES %) * (Speed / 100) * (100% + Outgoing Healing Boost %) * (HP / 100) * (DEF / 100)  * Relic Set",
        "zh-cn": "(100% + 能量恢复效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (100% + 治疗量加成 %) * (生命值 / 100) * (防御力 / 100) * 遗器套装",
        "zh-tw": "(100% + 能量恢復效率 %) * (100% + 效果抵抗 %) * (速度/ 100) * (100% + 治療量加成 %) * (生命值 / 100) * (防禦力 / 100) * 遺器套裝",
      },
      calculation: "(100%+(EG)) * (100%+(ER)) * ((S) / 100) * (100%+(O)) * ((H)/100) * ((D)/100) * (RS)"
  },
    rarity_number: "four-star",
    rarity: fourStarsIcon,
    path: "Abundance",
    element: "Quantum",
    pathIcon: abundanceIcon,
    elementIcon: quantumIcon,
    sortModel: [
      {
        field: 'formula',
        sort: 'desc',
      },
    ],
    type: "live"
  },
];