import { useContext, useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import {
  query,
  onSnapshot,
  addDoc,
  doc,
  getDoc,
  collection,
} from "@firebase/firestore";
import { db } from "../../firebase";
import "../index.scss";
import "./singleCharacter.scss";
import Datatable from "../../components/datatable/datatable";
import {
  characterRows,
  lightConeRows,
  relic,
  calculateRelic,
  property,
} from "../../components/utils";
import { LanguageContext } from "../../App";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { ThumbUpAlt } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import HorizontalDisplayAds from "../../components/ads/horizontalDisplayAds";

const SingleCharacterRanking = () => {
  const params = useParams();
  const [selectedId, setSelectedId] = useState("");
  const navigate = useNavigate();
  const [highlightedClass, setHighlightedClass] = useState("");

  function highlightClass(className) {
    setHighlightedClass(className);
    var elms = document.getElementsByClassName("property-" + className);
    var n = elms.length;

    for (var i = 0; i < n; i++) {
      elms[i].style.cursor = "pointer";
      elms[i].style.transition = "all 0.2s ease-in-out";
      elms[i].style["-webkit-filter"] =
        "drop-shadow(0px 0px 5px red) drop-shadow(0px 0px 10px red) drop-shadow(0px 0px 15px red)";
    }
  }

  function dehighlightClass(className) {
    var elms = document.getElementsByClassName("property-" + className);
    var n = elms.length;

    for (var i = 0; i < n; i++) {
      elms[i].style["-webkit-filter"] = "";
    }
  }

  window.addEventListener(
    "scroll",
    function () {
      if (highlightedClass !== "") {
        dehighlightClass(highlightedClass);
        setHighlightedClass("");
      }
    },
    false
  );

  // Initialize Character with Name
  useEffect(() => {

    var character = characterRows.filter(
      (id) => id.name === params.characterName
    );
    if (character.length === 0) {
      navigate("/");
    } else {
      setSelectedId(character[0]);
      handleSelectedCharacter(character[0].id);
    }
  }, [params]);

  const languageContext = useContext(LanguageContext);
  const language = {
    ascension_materials: {
      "zh-cn": "总晋阶材料",
      "zh-tw": "總晋阶材料",
      "en-us": "Total Ascension Materials",
    },
    trace_materials: {
      "zh-cn": "总行迹材料",
      "zh-tw": "總行迹材料",
      "en-us": "Total Trace Materials",
    },
    score: {
      "zh-cn": "分数",
      "zh-tw": "分數",
      "en-us": "Score",
    },
    grade: {
      "zh-cn": "级别",
      "zh-tw": "級別",
      "en-us": "Grade",
    },
    custom_formula: {
      "en-us": "Custom Formula",
      "zh-cn": "自定义公式",
      "zh-tw": "自定義公式",
    },
    obtain: {
      "en-us": "Availability : ",
      "zh-cn": "获取方式 : ",
      "zh-tw": "獲取方式 : ",
    },
    based: {
      "en-us": "Mainly based on LV 1 of ",
      "zh-cn": "主要依据 LV 1 的 ",
      "zh-tw": "主要依據 LV 1 的",
    },
    complexity: {
      "en-us":
        "Due to the complexity, the formula doesn't include Eidolons and Lightcone.",
      "zh-cn": "基于复杂性，此公式并没计算星魂以及光锥。",
      "zh-tw": "基於復雜性，此公式並沒計算星魂以及光錐。",
    },
    searched_ranking: {
      "en-us": "Your Searched ",
      "zh-cn": "您查询的",
      "zh-tw": "您查詢的",
    },
    last_updated_at: {
      "en-us": "Updated At : ",
      "zh-cn": "更新于 : ",
      "zh-tw": "更新於 : ",
    },
    full_ranking: {
      "en-us": "'s Full Ranking",
      "zh-cn": "的完整排行榜",
      "zh-tw": "的完整排行榜",
    },
    top_lightcone: {
      "en-us": "Most Used Light Cone",
      "zh-cn": "最常用的光锥",
      "zh-tw": "最常用的光錐",
    },
    recommended_lightcone: {
      "en-us": "Light Cone Strength",
      "zh-cn": "光锥强度",
      "zh-tw": "光錐强度",
    },
    recommended_eidolons: {
      "en-us": "Eidolon Strength",
      "zh-cn": "星魂强度",
      "zh-tw": "星魂強度",
    },
    eidolon: {
      "en-us": "Eidolon",
      "zh-cn": "星魂",
      "zh-tw": "星魂",
    },
    development_guide: {
      "en-us": "Build Guide",
      "zh-cn": "养成攻略",
      "zh-tw": "養成攻略",
    },
    recommended_skill: {
      "en-us": "Skill",
      "zh-cn": "技能",
      "zh-tw": "技能",
    },
    recommended_traces: {
      "en-us": "Main Traces",
      "zh-cn": "主要行迹",
      "zh-tw": "主要行跡",
    },
    recommended_traces_priority: {
      "en-us": "Other Traces Priority",
      "zh-cn": "其他行迹优先度",
      "zh-tw": "其他行跡優先度",
    },
    recommended_main_stat: {
      "en-us": "Main Stat",
      "zh-cn": "主词条",
      "zh-tw": "主詞條",
    },
    recommended_sub_stat: {
      "en-us": "Sub Stat Priority",
      "zh-cn": "副词条优先度",
      "zh-tw": "副詞條優先度",
    },
    target_stats: {
      "en-us": "Target Stats",
      "zh-cn": "目标面板",
      "zh-tw": "目標面板",
    },
    recommended_cavern: {
      "en-us": "Cavern Relics",
      "zh-cn": "隧洞遗器",
      "zh-tw": "隧洞遺器",
    },
    recommended_planar: {
      "en-us": "Planar Ornaments",
      "zh-cn": "位面饰品",
      "zh-tw": "位面飾品",
    },
    top_cavern: {
      "en-us": "Most Used Cavern Relics",
      "zh-cn": "最常用的隧洞遗器",
      "zh-tw": "最常用的隧洞遺器",
    },
    top_planar: {
      "en-us": "Most Used Planar Ornaments",
      "zh-cn": "最常用的位面饰品",
      "zh-tw": "最常用的位面飾品",
    },
    set: {
      "en-us": " Set",
      "zh-cn": " 件套",
      "zh-tw": " 件套",
    },
    close: {
      "en-us": "Close",
      "zh-cn": "关闭",
      "zh-tw": "關閉",
    },
    basic_attack: {
      "en-us": "Basic ATK",
      "zh-cn": "普通攻击",
      "zh-tw": "普通攻擊",
    },
    ultimate: {
      "en-us": "Ultimate",
      "zh-cn": "终结技",
      "zh-tw": "終結技",
    },
    talent: {
      "en-us": "Talent",
      "zh-cn": "天赋",
      "zh-tw": "天賦",
    },
    skill: {
      "en-us": "Skill",
      "zh-cn": "战技",
      "zh-tw": "戰技",
    },
    level: {
      "en-us": "Level",
      "zh-cn": "等级",
      "zh-tw": "等級",
    },
    team_comp: {
      "en-us": "Team Composition",
      "zh-cn": "配队思路",
      "zh-tw": "配隊思路",
    },
    type: {
      "en-us": "Type",
      "zh-cn": "种类",
      "zh-tw": "種類",
    },
    obtain_tooltip: {
      "en-us": "Obtain",
      "zh-cn": "获得",
      "zh-tw": "獲得",
    },
    rarity_tooltip: {
      "en-us": "Rarity",
      "zh-cn": "稀有度",
      "zh-tw": "稀有度",
    },
  };

  const [rankingRows, setRankingRows] = useState([]);
  const [secondRankingRows, setSecondRankingRows] = useState([]);
  const [processedRankingRows, setProcessedRankingRows] = useState([]);
  const [cacheRankingRows, setCacheRankingRows] = useState([]);
  const [randomSeedRanking, setRandomSeedRanking] = useState(0);
  const [modifiedCharacterRows, setModifiedCharacterRows] = useState([]);
  const [selectedCharacters, setSelectedCharacters] = useState(
    modifiedCharacterRows
  );
  const [lightCones, setLightCones] = useState([]);
  const [maxLightConesCount, setMaxLightConesCount] = useState(100);

  const [cavernRelics, setCavernRelics] = useState([]);
  const [maxCavernRelicsCount, setMaxCavernRelicsCount] = useState(100);

  const [planarRelics, setPlanarRelics] = useState([]);
  const [maxPlanarRelicsCount, setMaxPlanarRelicsCount] = useState(100);
  const [popupUid, setPopupUid] = useState("");
  const [popupProfile, setPopupProfile] = useState("");

  const [reportDate, setReportDate] = useState({
    id: "0",
    last_report: new Date()
  });

  useEffect(() => {

    async function initializeReportDate()
    {
      if(typeof selectedId.id === "undefined")
        return;

      const reportDateRef = doc(db, "report-refresh", selectedId.id);
      const reportDateDocSnap = await getDoc(reportDateRef);
  
      if (reportDateDocSnap.exists()) {
        var reportDateData = reportDateDocSnap.data();
        setReportDate(reportDateData);
      }
    }
    initializeReportDate();
  },[selectedId])
  useEffect(() => {

    async function popUp() {
      if (popupUid === "") {
        return;
      }

      const userRef = doc(db, "character/id/" + selectedId.id, popupUid);
      const userDocSnap = await getDoc(userRef);

      if (userDocSnap.exists()) {
        var characterData = userDocSnap.data();
        characterData = calculateRelic(
          characterData,
          languageContext.language,
          selectedId
        );
        setPopupProfile(characterData);
      }

    }
    popUp();
  }, [popupUid]);

  useEffect(() => {
    function processModifiedCharacterRows() {
      var modifiedRows = [];
      for (let i = 0; i < characterRows.length; i++) {
        switch (characterRows[i].id) {
          case "8001":
            var currentRow = characterRows[i];
            currentRow.id = "8001,8002";
            modifiedRows.push(currentRow);
            break;
          case "8002":
            break;
          case "8003":
            var currentRow = characterRows[i];
            currentRow.id = "8003,8004";
            modifiedRows.push(currentRow);
            break;
          case "8004":
            break;
          case "8005":
            var currentRow = characterRows[i];
            currentRow.id = "8005,8006";
            modifiedRows.push(currentRow);
            break;
          case "8006":
            break;
          default:
            modifiedRows.push(characterRows[i]);
        }
      }
      setModifiedCharacterRows(modifiedRows);
    }
    processModifiedCharacterRows();
  }, [characterRows]);

  useEffect(() => {
    setSelectedCharacters(modifiedCharacterRows);
  }, [modifiedCharacterRows]);

  function calculateFormula(formula, data) {
    var calculation = formula.calculation;
    var hasFourSet = false;

    if (calculation.includes("RS")) {
      var relicSetBonus = 1;
      for (let i = 0; i < data.rs.length; i++) {

        if(String(data.rs[i].d) === "4")
          hasFourSet = true;

        if (
          typeof formula.relic_set_stats[
            data.rs[i].n + String(data.rs[i].d)
          ] === "number"
        ) {
          relicSetBonus =
            relicSetBonus *
            formula.relic_set_stats[data.rs[i].n + String(data.rs[i].d)];
        } else {
          relicSetBonus = relicSetBonus * 0.8;
        }
      }

      if(data.rs.length === 2 && hasFourSet === false)
        relicSetBonus = 0.8;

      if (data.rs.length === 1) relicSetBonus = 0.7;

      if (data.rs.length === 0) relicSetBonus = 0.6;

      calculation = calculation.replaceAll("RS", relicSetBonus);
    }

    calculation = calculation.replaceAll("(A)", data.A);
    calculation = calculation.replaceAll("(D)", data.D);
    calculation = calculation.replaceAll("(H)", data.H);
    
    if(typeof data.BE === "undefined")
      calculation = calculation.replaceAll("(BE)", "0%");
    else
      calculation = calculation.replaceAll("(BE)", data.BE + "%");
    
    if(typeof data.EH === "undefined")
      calculation = calculation.replaceAll("(EH)", "0%");
    else
      calculation = calculation.replaceAll("(EH)", data.EH + "%");

    if(typeof data.ER === "undefined")
      calculation = calculation.replaceAll("(ER)", "0%");
    else
      calculation = calculation.replaceAll("(ER)", data.ER + "%");

    if(typeof data.EG === "undefined")
      calculation = calculation.replaceAll("(EG)", "0%");
    else
      calculation = calculation.replaceAll("(EG)", data.EG + "%");
    
    if(typeof data.ED === "undefined")
      calculation = calculation.replaceAll("(ED)", "0%");
    else
      calculation = calculation.replaceAll("(ED)", data.ED + "%");

    if (data.cr >= 100) {
      calculation = calculation.replaceAll("(CR)", "100%");
    } else {
      calculation = calculation.replaceAll("(CR)", data.CR + "%");
    }

    calculation = calculation.replaceAll("(CD)", data.CD + "%");
    calculation = calculation.replaceAll("(O)", data.O + "%");
    calculation = calculation.replaceAll("(S)", data.S);
    calculation = calculation.replaceAll("%", "/100");
    calculation = calculation.replace(/[^-()\d/*+.]/g, "");

    return Number(Number(eval(calculation)).toFixed(0));
  }

  function handleSelectedCharacter(id) {
    try {
      var currentCharacter = {};
      for (let i = 0; i < modifiedCharacterRows.length; i++) {
        if (String(modifiedCharacterRows[i].id) === String(id)) {
          currentCharacter = modifiedCharacterRows[i];
          setSelectedId(modifiedCharacterRows[i]);
          break;
        }
      }

      if (String(id).split(",").length === 1) {
        var rankingRows = [];
        const userDocsCollection = collection(
          db,
          "report-three/character/" + String(id)
        );
        const userDocsQuery = query(userDocsCollection);
        const unsubscribe = onSnapshot(userDocsQuery, (snapshot) => {
          unsubscribe();

          if (snapshot.size === 0) {
            setRankingRows([]);
            setSecondRankingRows([]);
            setRandomSeedRanking(Math.random());
            return;
          }

          rankingRows = Object.assign([], rankingRows);
          snapshot.docChanges().forEach((change) => {

            if (change.type === "added") {
              rankingRows.push({
                temp_id: change.doc.id,
                character: change.doc.data().character,
                count: change.doc.data().count,
                sequence: change.doc.data().sequence,
                uid: change.doc.data().uid,
              });
            }

            if (change.type === "modified") {
              for (let i = 0; i < rankingRows.length; i++) {
                if (rankingRows[i].temp_id === change.doc.id) {
                  rankingRows[i] = {
                    temp_id: change.doc.id,
                    character: change.doc.data().character,
                    count: change.doc.data().count,
                    sequence: change.doc.data().sequence,
                    uid: change.doc.data().uid,
                  };
                }
              }
            }

            if (change.type === "removed") {
              rankingRows = rankingRows.filter(
                (item) => item.temp_id !== change.doc.id
              );
            }
          });

          setRankingRows(rankingRows);
          setRandomSeedRanking(Math.random());
        });
      } else {
        // Trail Blazer
        // Get 2 Genders

        var rankingRows = [];
        const firstUserDocsCollection = collection(
          db,
          "report-three/character/" + String(id).split(",")[0]
        );
        const firstUserDocsQuery = query(firstUserDocsCollection);
        const firstUnsubscribe = onSnapshot(firstUserDocsQuery, (snapshot) => {
          firstUnsubscribe();
          rankingRows = Object.assign([], rankingRows);
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              rankingRows.push({
                temp_id: change.doc.id,
                character: change.doc.data().character,
                count: change.doc.data().count,
                sequence: change.doc.data().sequence,
                uid: change.doc.data().uid,
              });
            }

            if (change.type === "modified") {
              for (let i = 0; i < rankingRows.length; i++) {
                if (rankingRows[i].temp_id === change.doc.id) {
                  rankingRows[i] = {
                    temp_id: change.doc.id,
                    character: change.doc.data().character,
                    count: change.doc.data().count,
                    sequence: change.doc.data().sequence,
                    uid: change.doc.data().uid,
                  };
                }
              }
            }

            if (change.type === "removed") {
              rankingRows = rankingRows.filter(
                (item) => item.temp_id !== change.doc.id
              );
            }
          });

          setRankingRows(rankingRows);
          setRandomSeedRanking(Math.random());
        });

        var secondRankingRows = [];
        const secondUserDocsCollection = collection(
          db,
          "report-three/character/" + String(id).split(",")[1]
        );
        const secondUserDocsQuery = query(secondUserDocsCollection);
        const secondUnsubscribe = onSnapshot(
          secondUserDocsQuery,
          (snapshot) => {
            secondUnsubscribe();

            secondRankingRows = Object.assign([], secondRankingRows);
            snapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                secondRankingRows.push({
                  temp_id: change.doc.id,
                  character: change.doc.data().character,
                  count: change.doc.data().count,
                  sequence: change.doc.data().sequence,
                  uid: change.doc.data().uid,
                });
              }

              if (change.type === "modified") {
                for (let i = 0; i < secondRankingRows.length; i++) {
                  if (secondRankingRows[i].temp_id === change.doc.id) {
                    secondRankingRows[i] = {
                      temp_id: change.doc.id,
                      character: change.doc.data().character,
                      count: change.doc.data().count,
                      sequence: change.doc.data().sequence,
                      uid: change.doc.data().uid,
                    };
                  }
                }
              }

              if (change.type === "removed") {
                secondRankingRows = secondRankingRows.filter(
                  (item) => item.temp_id !== change.doc.id
                );
              }
            });

            setSecondRankingRows(secondRankingRows);
            setRandomSeedRanking(Math.random());
          }
        );
      }
    } catch (e) {
      alert("We've encountered some errors.\nPlease try again later.");
      const ref = collection(db, "error");
      addDoc(ref, {
        code: "Single Character Blade : Handle Selected Character",
        error: e.message,
        created_at: new Date(),
      });
    }
  }

  useEffect(() => {
    function combineRankingRows() {
      try {
        var rankingRow = Object.assign([], rankingRows);
        var secondRankingRow = Object.assign([], secondRankingRows);

        if (rankingRow.length > 0 && secondRankingRow.length > 0) {
          for (let i = 0; i < secondRankingRow.length; i++) {
            rankingRow.push(secondRankingRow[i]);
          }
          setRankingRows(rankingRow);
          setRandomSeedRanking(Math.random());
        }
      } catch (e) {
        alert("We've encountered some errors.\nPlease try again later.");
        const ref = collection(db, "error");
        addDoc(ref, {
          code: "Character : Combine Ranking Rows",
          error: e.message,
          created_at: new Date(),
        });
      }
    }
    combineRankingRows();
  }, [secondRankingRows]);

  useEffect(() => {
    
    function processNewRankingRows() {
      try {
        if (typeof rankingRows === "undefined") {
          setProcessedRankingRows([]);
          return;
        }
        var processedRows = [];
        var existedUid = [];

        for (let i = 0; i < rankingRows.length; i++) {
          for (let j = 0; j < rankingRows[i].character.length; j++) {
            if (existedUid.includes(rankingRows[i].character[j].u)) {
            } else {
              existedUid.push(rankingRows[i].character[j].u);

              var region = "";
              switch (rankingRows[i].character[j].u.substring(0, 1)) {
                case "0":
                  region = "miHoYo Internal";
                  break;
                case "5":
                  region = "CN-Alternative";
                  break;
                case "2":
                case "1":
                  region = "CN";
                  break;
                case "6":
                  region = "NA";
                  break;
                case "7":
                  region = "EU";
                  break;
                case "8":
                  region = "Asia";
                  break;
                case "9":
                  region = "THM";
                  break;
                default:
                  break;
              }

              if (typeof rankingRows[i].character[j].rs !== "undefined") {
                for (
                  let k = 0;
                  k < rankingRows[i].character[j].rs.length;
                  k++
                ) {
                  
                  if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Pan-Cosmic Commercial Enterprise"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "PGCE";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Firmament Frontline: Glamoth"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "FF";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Penacony, Land of the Dreams"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "PL";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "The Ashblazing Grand Duke"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "AGD";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Prisoner in Deep Confinement"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "PDC";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Watchmaker, Master of Dream Machinations"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "WMDM";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Pioneer Diver of Dead Waters"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "PDDW";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Izumo Gensei and Takama Divine Realm"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "IGT";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Watchmaker, Master of Dream Machinations"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "WMDM";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Duran, Dynasty of Running Wolves"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "DDRW";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Forge of the Kalpagni Lantern"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "FKL";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Iron Cavalry Against the Scourge"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "ICAS";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "The Wind-Soaring Valorous"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "TWSV";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "The Wondrous BananAmusement Park"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "TWBP";
                  }
                  else if (
                    rankingRows[i].character[j].rs[k].n ===
                    "Lushaka, the Sunken Seas"
                  ) {
                    rankingRows[i].character[j].rs[k].n = "LSS";
                  }


                  rankingRows[i].character[j].rs[k].name = relic.find(
                    (id) => id.code === rankingRows[i].character[j].rs[k].n
                  ).translation_set[languageContext.language];
                  rankingRows[i].character[j].rs[k].image = relic.find(
                    (id) => id.code === rankingRows[i].character[j].rs[k].n
                  ).image;
                  rankingRows[i].character[j].rs[k].colour = relic.find(
                    (id) => id.code === rankingRows[i].character[j].rs[k].n
                  ).colour;
                }
              }

              rankingRows[i].character[j].ED = 0;
              switch(selectedId.element)
              {
                case "Quantum":
                  if(typeof rankingRows[i].character[j].DBQ !== "undefined")
                    rankingRows[i].character[j].ED = rankingRows[i].character[j].DBQ * 100;
                  break;
                case "Wind":
                  if(typeof rankingRows[i].character[j].DBW !== "undefined")
                    rankingRows[i].character[j].ED = rankingRows[i].character[j].DBW * 100;
                  break;
                case "Imaginary":
                  if(typeof rankingRows[i].character[j].DBM !== "undefined")
                    rankingRows[i].character[j].ED = rankingRows[i].character[j].DBM* 100;
                  break;
                case "Physical":
                  if(typeof rankingRows[i].character[j].DBP !== "undefined")
                    rankingRows[i].character[j].ED = rankingRows[i].character[j].DBP* 100;
                  break;
                case "Lightning":
                  if(typeof rankingRows[i].character[j].DBL !== "undefined")
                    rankingRows[i].character[j].ED = rankingRows[i].character[j].DBL * 100;
                  break;
                case "Fire":
                  if(typeof rankingRows[i].character[j].DBF !== "undefined")
                    rankingRows[i].character[j].ED = rankingRows[i].character[j].DBF * 100;
                  break;
                case "Ice":
                  if(typeof rankingRows[i].character[j].DBI !== "undefined")
                    rankingRows[i].character[j].ED = rankingRows[i].character[j].DBI * 100;
                  break;
                default:
                  break;
              }

              processedRows.push({
                id: processedRows.length + 1,
                region: region,
                formula: calculateFormula(
                  selectedId.formula,
                  rankingRows[i].character[j]
                ),
                level: rankingRows[i].character[j].l,
                name: rankingRows[i].character[j].n,
                uid: rankingRows[i].character[j].u,
                eidolon: rankingRows[i].character[j].r,
                HP: Number(rankingRows[i].character[j].H),
                Attack: Number(rankingRows[i].character[j].A),
                Defend: Number(rankingRows[i].character[j].D),
                Speed: Number(rankingRows[i].character[j].S),
                BreakEffect: Number(rankingRows[i].character[j].BE),
                OutgoingHealingBoost: Number(rankingRows[i].character[j].O),
                EnergyRegenerationRate: Number(rankingRows[i].character[j].EG),
                EffectHitRate: Number(rankingRows[i].character[j].EH),
                CriticalRate: Number(rankingRows[i].character[j].CR),
                CriticalDamage: Number(rankingRows[i].character[j].CD),
                CritRatio: Number(
                  (
                    Number(rankingRows[i].character[j].CR) * 2 +
                    Number(rankingRows[i].character[j].CD)
                  ).toFixed(1)
                ),
                RelicCriticalRate: rankingRows[i].character[j].RCR,
                RelicCriticalDamage: rankingRows[i].character[j].RCD,
                RelicCritRatio: Number(
                  (
                    Number(rankingRows[i].character[j].RCR) * 2 +
                    Number(rankingRows[i].character[j].RCD)
                  ).toFixed(1)
                ),
                EffectResistance: Number(rankingRows[i].character[j].ER) ,
                ElementalDamage: Number(rankingRows[i].character[j].ED.toFixed(1)),
                RelicSet: rankingRows[i].character[j].rs,
                LightCone: rankingRows[i].character[j].lc,
                updated_at: rankingRows[i].character[j].d,
              });
            }
          }
        }

        processedRows = processedRows.sort((a, b) =>
          a.formula < b.formula ? 1 : -1
        );

        var cacheRows = [];
        if (localStorage.getItem("profiles") !== "undefined") {
          var cacheProfiles = JSON.parse(localStorage.getItem("profiles"));
          if (cacheProfiles === null) cacheProfiles = [];

          for (let i = 0; i < cacheProfiles.length; i++) {
            cacheRows.push(cacheProfiles[i].split(" ")[0]);
          }
        }

        var cacheRankingRows = [];
        var currentLightConeRows = [];
        var currentRelicRows = [];

        for (let i = 0; i < processedRows.length; i++) {
          processedRows[i].id = i + 1;
          processedRows[i].no = i + 1;

          var foundLightCone = false;
          if (processedRows[i].LightCone.i !== "") {
            for (let j = 0; j < currentLightConeRows.length; j++) {
              if (processedRows[i].LightCone.i === currentLightConeRows[j].i) {
                foundLightCone = true;
                currentLightConeRows[j].count =
                  currentLightConeRows[j].count + 1;
                break;
              }
            }

            if (foundLightCone === false) {
              var currentLightCone = lightConeRows.filter(
                (id) => id.id === processedRows[i].LightCone.i
              )[0];
              currentLightConeRows.push({
                i: processedRows[i].LightCone.i,
                lightcone: currentLightCone,
                count: 1,
              });
            }
          }

          // Cavern

          for (let k = 0; k < processedRows[i].RelicSet.length; k++) {
            var foundRelic = false;
            for (let j = 0; j < currentRelicRows.length; j++) {
              if (
                processedRows[i].RelicSet[k].n === currentRelicRows[j].n &&
                processedRows[i].RelicSet[k].d === currentRelicRows[j].d
              ) {
                foundRelic = true;
                currentRelicRows[j].count = currentRelicRows[j].count + 1;
                break;
              }
            }

            if (foundRelic === false) {
              var currentRelic = relic.filter(
                (id) => id.code === processedRows[i].RelicSet[k].n
              )[0];
              currentRelicRows.push({
                relic: currentRelic,
                n: processedRows[i].RelicSet[k].n,
                d: processedRows[i].RelicSet[k].d,
                count: 1,
              });
            }
          }

          if (cacheRows.includes(processedRows[i].uid) === true) {
            cacheRankingRows.push(processedRows[i]);
          }
        }
        setProcessedRankingRows(processedRows);
        setCacheRankingRows(cacheRankingRows);

        currentLightConeRows = currentLightConeRows.sort((a, b) =>
          a.count < b.count ? 1 : -1
        );
        if (currentLightConeRows.length > 0) {
          setMaxLightConesCount(currentLightConeRows[0].count);
        }
        
        setLightCones(currentLightConeRows);

        var cavernRelicsRow = currentRelicRows.filter(
          (id) => id.relic.type === "Cavern Relics"
        );
        cavernRelicsRow = cavernRelicsRow.sort((a, b) =>
          a.count < b.count ? 1 : -1
        );
        if (cavernRelicsRow.length > 0) {
          setMaxCavernRelicsCount(cavernRelicsRow[0].count);
        }
        setCavernRelics(cavernRelicsRow);

        var planarRelicsRow = currentRelicRows.filter(
          (id) => id.relic.type === "Planar Ornaments"
        );
        planarRelicsRow = planarRelicsRow.sort((a, b) =>
          a.count < b.count ? 1 : -1
        );
        if (planarRelicsRow.length > 0) {
          setMaxPlanarRelicsCount(planarRelicsRow[0].count);
        }
        setPlanarRelics(planarRelicsRow);
      } catch (e) {
        alert("We've encountered some errors.\nPlease try again later.");
        const ref = collection(db, "error");
        addDoc(ref, {
          code: "Character : Processed Ranking Rows",
          character_id: selectedId,
          error: e.message,
          created_at: new Date(),
        });
      }
    }
    processNewRankingRows();
  }, [rankingRows, secondRankingRows, randomSeedRanking]);

  return (
    <div>
      {typeof selectedId !== "string" ? (
        <div className="singleCharacter">
          <Helmet>
            <title>
              {selectedId.translation[languageContext.language]} | HSR
              Statistics
            </title>
            <meta
              data-rh="true"
              property="description"
              content={
                selectedId.story[0][languageContext.language] +
                " " +
                selectedId.story[1][languageContext.language] +
                " ..."
              }
            />
            <meta
              data-rh="true"
              property="og:description"
              content={
                selectedId.story[0][languageContext.language] +
                " " +
                selectedId.story[1][languageContext.language] +
                " ..."
              }
            />
            <meta
              data-rh="true"
              property="twitter:description"
              content={
                selectedId.story[0][languageContext.language] +
                " " +
                selectedId.story[1][languageContext.language] +
                " ..."
              }
            />
            <meta
              data-rh="true"
              property="og:image"
              content={selectedId.background}
            />
            <meta
              data-rh="true"
              property="twitter:image"
              content={selectedId.background}
            />
          </Helmet>
          <Navbar />
          <div className="singleCharacterContainer starry-night">
            
        <div class="sub-container">
            <div class="sky">
              <div class="stars"></div>
              <div class="stars2"></div>
              <div class="stars3"></div>
            </div>
          </div>
            <div className="divider"></div>
            <div className="top">
              <h2 className="title">
                {selectedId.translation[languageContext.language]}
              </h2>
              <div className="property">
                <img
                  className="element"
                  alt={selectedId.element}
                  title={selectedId.element}
                  src={selectedId.elementIcon}
                />
                <img
                  className="path"
                  alt={selectedId.path}
                  title={selectedId.path}
                  src={selectedId.pathIcon}
                />
              </div>
              <img
                className="rarity"
                alt={selectedId.rarity_number}
                src={selectedId.rarity}
              />
              <div className="story">
                {selectedId.story.map((item, i) => {
                  return <h4 key={i}>{item[languageContext.language]}</h4>;
                })}
              </div>
              <img
                className="image"
                alt={selectedId.translation[languageContext.language]}
                src={selectedId.background}
              />
            </div>
              <HorizontalDisplayAds/>
              <div className="secondTop">
                <div className="row">
                  <h2>{language.top_lightcone[languageContext.language]}</h2>
                  {lightCones.length > 0 ? (
                    lightCones.slice(0, 5).map((item, i) => {
                      return (
                        <div key={i} className="innerRows lightcone">
                          <img
                            className={
                              "lightconeImage " + item.lightcone.rarity_number
                            }
                            alt={
                              item.lightcone.translation[
                                languageContext.language
                              ]
                            }
                            src={item.lightcone.image}
                          ></img>
                          <div className="barchart">
                            <h4 className="name">
                              {
                                item.lightcone.translation[
                                  languageContext.language
                                ]
                              }
                            </h4>
                            <h4
                              className="count"
                              style={{
                                width:
                                  (item.count / maxLightConesCount) * 100 + "%",
                                minWidth: "fit-content",
                              }}
                            >
                              {item.count}
                            </h4>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h4>NA</h4>
                  )}
                </div>
                <div className="row">
                  <h2>{language.top_cavern[languageContext.language]}</h2>
                  {cavernRelics.length > 0 ? (
                    cavernRelics.slice(0, 5).map((item, i) => {
                      return (
                        <div key={"cavern-relic-" + i} className="innerRows">
                          <img
                            className="relicImage"
                            alt={
                              item.relic.translation_set[
                                languageContext.language
                              ]
                            }
                            src={item.relic.image}
                          ></img>
                          <div className="barchart">
                            <h4 className="name">
                              {
                                item.relic.translation_set[
                                  languageContext.language
                                ]
                              }
                              {" - "}
                              {item.d}
                              {language.set[languageContext.language]}
                            </h4>
                            <h4
                              className="count"
                              style={{
                                width:
                                  (item.count / maxCavernRelicsCount) * 100 +
                                  "%",
                                minWidth: "fit-content",
                              }}
                            >
                              {item.count}
                            </h4>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h4>NA</h4>
                  )}
                </div>
                <div className="row">
                  <h2>{language.top_planar[languageContext.language]}</h2>
                  {planarRelics.length > 0 ? (
                    planarRelics.slice(0, 5).map((item, i) => {
                      return (
                        <div key={"planar-relics-" + i} className="innerRows">
                          <img
                            className="relicImage"
                            alt={
                              item.relic.translation_set[
                                languageContext.language
                              ]
                            }
                            src={item.relic.image}
                          ></img>

                          <div className="barchart">
                            <h4 className="name">
                              {
                                item.relic.translation_set[
                                  languageContext.language
                                ]
                              }
                              {" - "}
                              {item.d}
                              {language.set[languageContext.language]}
                            </h4>
                            <h4
                              className="count"
                              style={{
                                width:
                                  (item.count / maxPlanarRelicsCount) * 100 +
                                  "%",
                                minWidth: "fit-content",
                              }}
                            >
                              {item.count}
                            </h4>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h4>NA</h4>
                  )}
                </div>
              </div>
            <HorizontalDisplayAds/>
            <div className="middle">
              <div className="formula">
                <h2>{language.custom_formula[languageContext.language]}</h2>
                <h4>{language.complexity[languageContext.language]}</h4>
                <h4>
                  {selectedId.formula.description[languageContext.language]}
                </h4>
              </div>
            </div>
            <div className="bottom">
              {cacheRankingRows.length > 0 ? (
                <div className="section">
                  <h2>
                    {language.searched_ranking[languageContext.language]}
                    {selectedId.translation[languageContext.language]}
                  </h2>
                  <Datatable
                    type="ranking"
                    characterId={selectedId}
                    overrideDataRow={cacheRankingRows}
                    setPopupUid={setPopupUid}
                  />
                </div>
              ) : (
                <p></p>
              )}

              <h2>
                {selectedId.translation[languageContext.language]}
                {language.full_ranking[languageContext.language]}
              </h2>
              <h3>
                {language.last_updated_at[languageContext.language]} {typeof reportDate.last_report !== "undefined" 
                ? new Date(reportDate.last_report.seconds * 1000).toLocaleString('en-US',
              {
                year: 'numeric', month: 'short', day: 'numeric',
                hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
              }) : ""}
              </h3>
              <Datatable
                type="ranking"
                className="datatable"
                characterId={selectedId}
                overrideDataRow={processedRankingRows}
                setPopupUid={setPopupUid}
              />
            </div>
            {popupProfile !== "" ? (
              <div className="popupContainer">
                <p
                  className="closeButton"
                  onClick={() => {
                    setPopupUid("");
                    setPopupProfile("");
                  }}
                >
                  x
                </p>

                <h2>
                  {popupProfile.n} - LV {popupProfile.l}
                </h2>
                {typeof popupProfile.rl !== "undefined" ? (
                  <div className="relics">
                    {popupProfile.rl.map((item, i) => {
                      return (
                        <div
                          key={item.i}
                          className={
                            item.r === 3
                              ? "relic three-star"
                              : item.r === 4
                              ? "relic four-star"
                              : item.r === 5
                              ? "relic five-star"
                              : "relic two-star"
                          }
                        >
                          <div className="relicTop">
                            {typeof item.set === "number" ? (
                              <div className="relic-set">
                                <div
                                  className="relic-set-desc"
                                  style={{ backgroundColor: item.set_color }}
                                >
                                  <h4>{item.set}</h4>
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                            <img
                              className="relic-image"
                              style={{ cursor: "pointer" }}
                              title={item.name}
                              alt={item.i}
                              src={item.image}
                            />
                            <h4 className="level">+ {item.l}</h4>
                            <div
                              className={
                                "main-stat-container property-" + item.m.n
                              }
                              title={item.score_text}
                              onMouseOver={() => {
                                highlightClass(item.m.n);
                              }}
                              onMouseLeave={() => {
                                dehighlightClass(item.m.n);
                              }}
                            >
                              <div className="inner">
                                <img
                                  className="main-stat"
                                  alt={item.m.name}
                                  src={item.m.image}
                                ></img>
                                <h4 className="main-value">{item.m.v}</h4>
                              </div>
                              <h4 className="description">{item.m.name}</h4>
                            </div>
                            <div className="vertical_line"></div>
                          </div>

                          <div className="horizontal_line"></div>
                          <div className="relic-score">
                            <div className="score">
                              <h4 className="score-title">
                                {language.score[languageContext.language]}
                              </h4>
                              <h4 className="value">{item.score}</h4>
                            </div>
                            <div className="rank">
                              <h4 className="score-title">
                                {language.grade[languageContext.language]}
                              </h4>
                              <h4 className={"value rank-" + item.grade}>
                                {item.grade}
                              </h4>
                            </div>
                          </div>
                          <div className="horizontal_line"></div>
                          <div
                            className="subproperties"
                            title={item.score_text}
                          >
                            {item.s.map((subp, i) => {
                              return (
                                <div
                                  key={i}
                                  className={"subproperty property-" + subp.n}
                                  onMouseOver={() => {
                                    highlightClass(subp.n);
                                  }}
                                  onMouseLeave={() => {
                                    dehighlightClass(subp.n);
                                  }}
                                >
                                  <div className="inner">
                                    <img
                                      className={"sub-stat " + subp.n}
                                      alt={subp.n}
                                      src={subp.image}
                                    ></img>
                                    <h4
                                      className={
                                        "description score-" + subp.score
                                      }
                                    >
                                      {subp.name}
                                    </h4>
                                  </div>
                                  <div className="sub-value-container">
                                    {subp.e !== "undefined" && subp.e > 1 ? (
                                      <h4 className="enhancement">
                                        +{subp.e - 1}
                                      </h4>
                                    ) : (
                                      <h4 className="empty-enhancement">+0</h4>
                                    )}
                                    <h4
                                      className={
                                        "sub-value score-" + subp.score
                                      }
                                    >
                                      {subp.v}
                                    </h4>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="closeBottomButton">
                  <button
                    className="button"
                    onClick={() => {
                      setPopupUid("");
                      setPopupProfile("");
                    }}
                  >
                    {language.close[languageContext.language]}
                  </button>
                </div>
                <br></br>
                <br></br>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default SingleCharacterRanking;
